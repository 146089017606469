import React, { useEffect, useState } from 'react';
import { useTab } from "../../../components/Tab"
import { Tips } from './Tips';
import { Game } from './Game';
import { Report } from './Report';
import { tabData } from './tips-data';
import { generateNumbersArr } from '../../../../utils';
import { generateQuestions } from './generateQuestions';
import { Settings } from './Settings';
import { TIME } from '../global/Timer';

export const DIFFICULTY_LEVEL = {
    EASY: 'EASY',
    MEDIUM: 'MEDIUM',
    HARD: 'HARD',
}

export const Mathlympics = () => {
    const [showGame, setShowGame] = useState(false);
    const { getTabs, selectedTab, setSelectedTab } = useTab({ tabData: tabData(showGame) });
    const [currentIndex, setCurrentIndex] = useState(0);

    // const [reports, setReports] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [guesses, setGuesses] = useState([]);
    const [difficulty, setDifficulty] = useState(DIFFICULTY_LEVEL.EASY);
    const [showTimer, setShowTimer] = useState(true);
    const [timerOption, setTimerOption] = useState('one');
    const [time, setTime] = useState(TIME[timerOption]);
    const [timePerQuestion, setTimePerQuestion] = useState([TIME[timerOption]]);

    useEffect(() => {
        setTime(TIME[timerOption]);
        // eslint-disable-next-line
    }, [timerOption]);

    useEffect(() => {
        initializeData();
        // eslint-disable-next-line
    }, [difficulty]);

    const initializeData = () => {
        const max = difficulty === DIFFICULTY_LEVEL.EASY ?
            8 :
            difficulty === DIFFICULTY_LEVEL.MEDIUM ?
                15 : 20;
        const min = difficulty === DIFFICULTY_LEVEL.EASY ?
            1 :
            difficulty === DIFFICULTY_LEVEL.MEDIUM ?
                8 : 15;
        const numbersArr = generateNumbersArr(min, max);
        const results = generateQuestions(numbersArr);
        setQuestions(results.questions);
        setAnswers(results.answers);
    }

    return (
        <div className="mathlympics-board">
            {!showGame && (
                <Settings
                    difficulty={difficulty}
                    setDifficulty={setDifficulty}
                    showTimer={showTimer}
                    setShowTimer={setShowTimer}
                    timerOption={timerOption}
                    setTimeOption={setTimerOption}
                    showGame={showGame}
                />
            )}
            {getTabs()}
            {selectedTab === 'tips' && <Tips />}
            {selectedTab === 'game' && (
                <Game
                    questions={questions}
                    guesses={guesses}
                    setGuesses={setGuesses}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    time={time}
                    setTime={setTime}
                    onGameEnd={() => {
                        setSelectedTab('report');
                        setTime(TIME[timerOption]);
                    }}
                    showGame={showGame}
                    setShowGame={setShowGame}
                    onGameStart={() => {
                        initializeData()
                        setTimePerQuestion([TIME[timerOption]]);
                    }}
                    timePerQuestion={timePerQuestion}
                    setTimePerQuestion={setTimePerQuestion}
                    showTimer={showTimer}
                />
            )}
            {selectedTab === 'report' && (
                <Report
                    questions={questions}
                    answers={answers}
                    guesses={guesses}
                    currentIndex={currentIndex}
                    timePerQuestion={timePerQuestion}
                />
            )}
        </div>
    )
}