import React from 'react';

export const MusicSymbolTypes = ({ symbolType, type }) => {
    return (
        <div className="types-of-symbol-container">
            {symbolType.map(({ name, value, beats, symbol, font }, index) => (
                <div key={index} className={`symbol-container ${value}-container`}>
                    <div className="symbol-name">
                        <div className={`symbol-type ${value}`}>
                            {symbol ? <img src={symbol} alt="" />
                                :
                                font ? font : null}
                        </div>
                        {name.toUpperCase()} </div>
                    <span>= {beats} {type}</span>
                </div>
            ))}
        </div>
    )
}