import React, { useEffect, useState } from 'react';
import { Footer, IconItems, Loader } from '../components';
import { useResources } from '../../queries';
import { useLoader } from '../../hooks/use-loader';

export const Resources = ({
    checkHeight,
    history,
}) => {
    const { data: icons = [] } = useResources();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 50);
    }, [])

    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />;

    return (
        <div id="resources-page" className="resources page" onScroll={() => checkHeight('.heading')}>
            <div className="container resources-landing-container">
                <h2 className="heading">Resources</h2>
                <IconItems icons={icons} history={history} />
            </div>
            <Footer />
        </div>
    )
}