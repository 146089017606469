export default function() {
    removeBar();
    removeOldCircles();
    removeTuningCircles();
    removeXs();
}

const removeBar = function () {
    let bar = document.querySelectorAll('.bar');
    if (bar.length) {
        bar.forEach(line => line.remove());
    }
}

const removeOldCircles = function () {
    let old = document.querySelectorAll('.note-circle');
    if (old.length) {
        old.forEach(note => note.remove());
    };
}

const removeTuningCircles = () => {
    for (let num = 1; num < 7; num++) {
        if (document.querySelector('.note-circle-string-' + num)) {
            document.querySelector('.note-circle-string-' + num).innerHTML = '';
            document.querySelector('.note-circle-string-' + num).classList.add('d-none');
        }
    }
}

const removeXs = () => {
    for (let num = 1; num < 7; num++) {
        document.querySelector(`.x-string-${num}`).classList.add('d-none');
        document.querySelector(`.x-string-${num}`).classList.remove('flex-ctr');
    }
}