import React from 'react';
import { useAsset } from '../../../../queries';
import { convertTimeToNumber } from '../../../../utils';

export const Report = ({ questions, answers, guesses, currentIndex, timePerQuestion }) => {
    const { data: checkmarkIcon } = useAsset({
        folder: 'life-skills',
        name: 'checkmark.png',
    });

    const { data: closeIcon } = useAsset({
        folder: 'logo',
        name: 'close.png',
    });

    const computedTimes = timePerQuestion.map(time => convertTimeToNumber(time)).reduce((acc, currentTime, index, arr) => {
        const nextIndex = index + 1;
        const nextTime = arr[nextIndex] ? arr[nextIndex] : 0;
        const difference = (currentTime - nextTime) / 1000;
        acc.push(difference);
        return acc;
    }, []);

    const filterLastQuestion = () => {
        const newQuestions = questions.slice(0, currentIndex + 1);
        const lastQuestionCorrect = guesses[currentIndex] === answers[currentIndex];
        const lastTimePerQuestion = computedTimes[currentIndex];
        if (!lastQuestionCorrect && lastTimePerQuestion === 1) {
            newQuestions.pop();
            computedTimes.pop();
        }
        return newQuestions;
    }

    const filteredQuestions = filterLastQuestion();

    const getAverageTime = (times) => {
        const computedTotal = times.reduce((acc, time) => acc + time, 0);
        return computedTotal / times.length;
    }
    const reducedStat = filteredQuestions.reduce((acc, question, index) => {
        const currentTime = computedTimes[index];
        if (question[1] === '+') {
            const total = acc.addition.total + 1;
            acc.addition.total = total;
            acc.addition.timeSpent += currentTime;
            const averageTime = getAverageTime([...acc.addition.times, currentTime]);
            acc.addition.averageTime = averageTime;
            acc.addition.times.push(currentTime);
            if (answers[index] === Number(guesses[index])) {
                acc.addition.correct += 1;
            }
        } else if (question[1] === '-') {
            const total = acc.subtraction.total + 1;
            acc.subtraction.total = total;
            acc.subtraction.timeSpent += currentTime;
            const averageTime = getAverageTime([...acc.subtraction.times, currentTime]);
            acc.subtraction.averageTime = averageTime;
            acc.subtraction.times.push(currentTime);
            if (answers[index] === Number(guesses[index])) {
                acc.subtraction.correct += 1;
            }
        } else if (question[1] === 'x') {
            const total = acc.multiplication.total + 1;
            acc.multiplication.total = total;
            acc.multiplication.timeSpent += currentTime;
            const averageTime = getAverageTime([...acc.multiplication.times, currentTime]);
            acc.multiplication.averageTime = averageTime;
            acc.multiplication.times.push(currentTime);
            if (answers[index] === Number(guesses[index])) {
                acc.multiplication.correct += 1;
            }
        } else if (question[1] === '/') {
            const total = acc.division.total + 1;
            acc.division.total = total;
            acc.division.timeSpent += currentTime;
            const averageTime = getAverageTime([...acc.division.times, currentTime]);
            acc.division.averageTime = averageTime;
            acc.division.times.push(currentTime);
            if (answers[index] === Number(guesses[index])) {
                acc.division.correct += 1;
            }
        }

        const total = acc.cumulative.total + 1;
        acc.cumulative.total = total;
        acc.cumulative.timeSpent += currentTime;
        const averageTime = getAverageTime([...acc.cumulative.times, currentTime]);
        acc.cumulative.averageTime = averageTime;
        acc.cumulative.times.push(currentTime);
        if (answers[index] === Number(guesses[index])) {
            acc.cumulative.correct += 1;
        }

        return acc;
    }, {
        addition: { correct: 0, total: 0, times: [], timeSpent: 0, averageTime: 0 },
        subtraction: { correct: 0, total: 0, times: [], timeSpent: 0, averageTime: 0 },
        multiplication: { correct: 0, total: 0, times: [], timeSpent: 0, averageTime: 0 },
        division: { correct: 0, total: 0, times: [], timeSpent: 0, averageTime: 0 },
        cumulative: { correct: 0, total: 0, times: [], timeSpent: 0, averageTime: 0 }
    });

    const { addition, subtraction, multiplication, division, cumulative } = reducedStat;

    const longest = Math.max(...cumulative.times);
    const quickest = Math.min(...cumulative.times);

    return (
        <div className='report-container'>
            <ol className="report-list">
                <h4>Report</h4>
                {filteredQuestions.map((question, index) => (
                    <li key={index} className="report-item">
                        {answers[index] === Number(guesses[index]) ? (
                            <img src={checkmarkIcon} alt="checkmark" className="correct" />
                        ) : (
                            <img src={closeIcon} alt="checkmark" className="incorrect" />
                        )}
                        <span>{question[0]} {question[1]} {question[2]} = {guesses[index]}</span>
                    </li>
                ))}
            </ol>
            <div className="stat-container">
                <h4>Statistics</h4>
                <p className="statistic-content-container">
                    <span className="stat-label">Overall:</span>
                    <span className="ratio">Correction Ratio: {cumulative.correct} / {cumulative.total}</span>
                    <span className="time-statistic">Average Time: {cumulative.averageTime.toFixed(2)} seconds</span>
                    <span className="time-statistic">Longest Time: {longest} {longest > 1 ? 'seconds' : 'second'}</span>
                    <span className="time-statistic">Quickest Time: {quickest === 0 ? 1 : quickest} {quickest > 1 ? 'seconds' : 'second'}</span>
                    <span className="time-statistic">Total Time: {cumulative.timeSpent} seconds</span>
                </p>
                <p className="statistic-content-container">
                    <span className="stat-label">Addition:</span>
                    <span className="ratio">Correct Ratio: {addition.correct} / {addition.total}</span>
                    <span className="time-statistic">Average Time: {addition.averageTime.toFixed(2)} seconds</span>
                    <span className="time-statistic">Total Time: {addition.timeSpent} seconds</span>
                </p>
                <p className="statistic-content-container">
                    <span className="stat-label">Subtraction:</span>
                    <span className="ratio">Correct Ratio: {subtraction.correct} / {subtraction.total}</span>
                    <span className="time-statistic">Average Time: {subtraction.averageTime.toFixed(2)} seconds</span>
                    <span className="time-statistic">Total Time: {subtraction.timeSpent} seconds</span>
                </p>
                <p className="statistic-content-container">
                    <span className="stat-label">Multiplication:</span>
                    <span className="ratio">Correct Ratio: {multiplication.correct} / {multiplication.total}</span>
                    <span className="time-statistic">Average Time: {multiplication.averageTime.toFixed(2)} seconds</span>
                    <span className="time-statistic">Total Time: {multiplication.timeSpent} seconds</span>
                </p>
                <p className="statistic-content-container">
                    <span className="stat-label">Division:</span>
                    <span className="ratio">Correct Ratio: {division.correct} / {division.total}</span>
                    <span className="time-statistic">Average Time: {division.averageTime.toFixed(2)} seconds</span>
                    <span className="time-statistic">Total Time: {division.timeSpent} seconds</span>
                </p>
            </div>
        </div>
    )
}