import React, { useState } from 'react';
import './scss/main.scss';
import Navigation from './components/components/navigation/Navigation';
import routes from './routes';
import Toast from './components/components/Toast';

export const App = () => {
  const [isTransparent, updateIsTransparent] = useState(false);
  const [toastTime, setToastTime] = useState(3000);

  const checkHeight = (element, useBottom) => {
    const screenWidth = window.screen.width;
    const elementHeight = useBottom ? document.querySelector(element).getBoundingClientRect().bottom : document.querySelector(element).getBoundingClientRect().top;
    const navHeight = document.querySelector(`${screenWidth > 1000 ? '.navigation.desktop' : '.navigation.mobile'}`).getBoundingClientRect().bottom;
    const bool = (elementHeight + 1.5) < navHeight;
    updateIsTransparent(bool);
  }

  return (
    <div className="main">
      <Navigation {...{ isTransparent }} />
      {routes({ checkHeight, updateIsTransparent, setToastTime })}
      {Toast(toastTime)}
    </div>
  );
}
