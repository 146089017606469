import removeOld from './RemoveOld';
const standardTuning = ['e', 'b', 'g', 'd', 'a', 'e'];
const notes = ['c', 'c♯', 'd', 'd♯', 'e', 'f', 'f♯', 'g', 'g♯', 'a', 'a♯', 'b'];
const notes1 = ['c', 'd♭', 'd', 'e♭', 'e', 'f', 'g♭', 'g', 'a♭', 'a', 'b♭', 'b'];

const noteFunctions = {
    initializeNote: (string, fret) => {
        removeOld();
        let noteStrum = document.querySelector(`.string-${string}.fret-${fret}`);
        let note = noteFunctions.determineNote(string, fret);
        noteStrum.innerHTML = `<div class="note-circle">${note}</div>`;
    },

    determineNote: (string, fret) => {
        let strIndex = string - 1;
        let start = standardTuning[strIndex];
        let index = notes.findIndex(note => note === start);
        let copy = [...notes, ...notes, ...notes];
        let currIndex = index + fret;
        return copy[currIndex];
    },

    showAllX: async (x, pitch) => {
        if (x === 'b' && pitch === 'sharp') {
            x = 'b';
            pitch = 'natural';
        } else if (x === 'e' && pitch === 'sharp') {
            x = 'e';
            pitch = 'natural';
        } else if (x === 'c' && pitch === 'flat') {
            x = 'c';
            pitch = 'natural';
        } else if (x === 'f' && pitch === 'flat') {
            x = 'f'
            pitch = 'natural';
        }

        let string1 = noteFunctions.getXInstances(x, 0, pitch);
        let string2 = noteFunctions.getXInstances(x, 1, pitch);
        let string3 = noteFunctions.getXInstances(x, 2, pitch);
        let string4 = noteFunctions.getXInstances(x, 3, pitch);
        let string5 = noteFunctions.getXInstances(x, 4, pitch);
        let string6 = noteFunctions.getXInstances(x, 5, pitch);
        
        await removeOld();

        noteFunctions.showEachXStringNum(string1, 1, x, pitch);
        noteFunctions.showEachXStringNum(string2, 2, x, pitch);
        noteFunctions.showEachXStringNum(string3, 3, x, pitch);
        noteFunctions.showEachXStringNum(string4, 4, x, pitch);
        noteFunctions.showEachXStringNum(string5, 5, x, pitch);
        noteFunctions.showEachXStringNum(string6, 6, x, pitch);
        
        if (standardTuning.includes(x) && pitch === 'natural') {
            standardTuning.forEach((note, index) => {
                if (x === note) {
                    let tuningCircle = document.querySelector('.note-circle-string-' + (index + 1));
                    tuningCircle.classList.remove('d-none');
                    tuningCircle.classList.add('flex-ctr');
                    tuningCircle.innerText = x;
                }
            })
        }
    },

    getXInstances: (x, num, pitch) => {
        let tune = standardTuning[num];
        let copy;

        if (pitch === 'natural' || pitch === 'sharp') {
            copy = [...notes, ...notes, ...notes];
        } else if (pitch === 'flat') {
            copy = [...notes1, ...notes1, ...notes1];
        }
        let index = copy.findIndex(note => note === tune);
        let string = copy.slice(index + 1, index + 20);
        let firstInstance = string.findIndex(note => {
            if (pitch === 'natural') {
                return note === x;
            } else if (pitch === 'flat') {
                return note === (x + '♭');
            } else if (pitch === 'sharp') {
                return note === (x + '♯');
            } else {
                return note === x;
            }
        });
        if (firstInstance < 7) {
            return [firstInstance, firstInstance + 12];
        }
        return [firstInstance];
    },

    showEachXStringNum: (instances, num, note, pitch) => {
        if (pitch === 'natural') {
            instances.forEach(index => {
                if (document.querySelector(`.string-${num}.fret-${index + 1}`)) {
                    document.querySelector(`.string-${num}.fret-${index + 1}`).innerHTML = `<div class="note-circle">${note}</div>`;;
                }
            });
        } else if (pitch === 'sharp') {
            instances.forEach(index => {
                if (document.querySelector(`.string-${num}.fret-${index + 1}`)) {
                    document.querySelector(`.string-${num}.fret-${index + 1}`).innerHTML = `<div class="note-circle">${note}♯</div>`;;
                }
            });
        } else if (pitch === 'flat') {
            instances.forEach(index => {
                if (document.querySelector(`.string-${num}.fret-${index + 1}`)) {
                    document.querySelector(`.string-${num}.fret-${index + 1}`).innerHTML = `<div class="note-circle">${note}♭</div>`;;
                }
            });
        }
    }
}

export default noteFunctions;