import React from 'react';
import { Redirect } from 'react-router-dom';
import { RetailProduct } from '../product-page/RetailProduct';
import { OnlineProduct } from '../product-page/OnlineProduct';
import { InstructionsBuilder } from '../product-page/online-games/InstructionsBuilder';
import { FeedbackForm, Footer } from '../components';
import { useProduct } from '../../queries';
// import { Reviews } from "../product-page/Reviews";

export const ProductDetails = ({ checkHeight, match, history, setToastTime }) => {
    const { id, type } = match.params;
    const { data: product, isFetching, isLoading } = useProduct({ id });

    const isReady = !isLoading && !isFetching;

    if (isReady && product && Object.keys(product).length) {
        return (
            <div id={`${type}-details-page`} className="product-detail page" onScroll={() => checkHeight('.breadcrumbs')}>
                <div className="container">
                    {isReady && product.isOnline && <OnlineProduct {...product} history={history} setToastTime={setToastTime} />}
                    {isReady && !product.isOnline && <RetailProduct product={product} />}
                </div>
                {isReady && product.isOnline && product.instructions && <InstructionsBuilder instructions={product.instructions} goal={product.goal} setUpInstructions={product.setUpInstructions} facts={product.facts} />}
                <FeedbackForm product={product.name} type={product.type} />
                <Footer />
            </div>
        )
    } else if (isReady) {
        return <Redirect to="/" />
    }
    return null;
}