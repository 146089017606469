import React from 'react';

export const NumberTab = ({ boards, selectedNumber, setSelectedNumber }) => {
    const createNumbersArr = () => {
        const numbersArr = [];
        for (let i = 1; i <= boards.length; i++) {
            numbersArr.push(i);
        }
        return numbersArr;
    }

    return (
        <div className="select-puzzle-container">
            <h2 className="select-puzzle-header">Select A Puzzle:</h2>
            <div className="select-puzzle-labels">
                {createNumbersArr().map((number, index) => (
                    <div key={index} className="label-container">
                        <label htmlFor={number.toString()}>
                            <input type="radio" id={number.toString()} name="select-puzzle" checked={selectedNumber.toString() === number.toString()} value={number.toString()} onChange={(e) => setSelectedNumber(e.target.value)} />
                            {number}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}
