import React from 'react';

export const FlippingButton = ({
    buttonText,
    color,
    onClick,
    width = 210,
    shouldTransform = false,
    isDisabled = false,
}) => (
    <div className="outer-container" style={{ transform: shouldTransform ? `translate(-${width / 2}px, 0)` : '' }}>
        <div className="inner-container">
            <button disabled={isDisabled} style={{ width }} className={`displayed-btn ${color}`} onClick={onClick}>{buttonText}</button>
            <button disabled={isDisabled} style={{ width }} className={`hovered-btn ${color}`} onClick={onClick}>{buttonText}</button>
        </div>
    </div>
)
