import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLoader } from '../../hooks/use-loader';
import { Breadcrumb, FeedbackForm, Footer, Loader } from '../components';
import { Guitar101, GuitarChords, MusicBasics, MusicScales } from '../music-page';

export const MusicDetails = ({
    checkHeight,
    match,
}) => {
    const { id } = match.params;
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 50);
    }, [])

    const isLoading = useLoader(isReady);

    const displayResource = () => {
        if (id === 'basics') return <MusicBasics />;
        else if (id === 'guitar-101') return <Guitar101 />;
        else if (id === 'guitar-chords') return <GuitarChords />;
        else if (id === 'scales') return <MusicScales />;
        return <Redirect to="/music" />;
    }

    if (isLoading) return <Loader />;

    return (
        <div id="music-details-page" className="music-details page" onScroll={() => checkHeight('.breadcrumbs')}>
            <div className="container">
                <Breadcrumb type="music" />
                <div className="resource-container">
                    {displayResource()}
                </div>
            </div>
            <FeedbackForm product="resource-music" type="resource" />
            <Footer />
        </div>
    )
}