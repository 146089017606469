import React from 'react';
import {
    calculateDiscountedPrice,
    // checkPreOrder, 
    isDifferent
} from '../../utils';

export const ProductCard = ({ product, handleRouting }) => {
    const discountedPrice = calculateDiscountedPrice(product.price, product.discount);

    const getPlayerText = () => {
        const players = product.players;
        if (typeof players === 'number') {
            switch (players) {
                case 1:
                    return '1P';
                case 2:
                    return '2P';
                default: 
                    return 'G'
            }
        }
    }
    
    const getPlayerStyle = () => {
        const players = product.players;
        if (typeof players === 'number') {
            switch (players) {
                case 1:
                    return 'one-player';
                case 2:
                    return 'two-player';
                default: 
                    return 'group'
            }
        }
    }

    return (
        <div className="product-card" key={product.id} onClick={() => handleRouting(product.type, product.id)}>
            <img className={`product-img product-img-${product.id} ${product.imgPadding ? 'img-padding' : ''}`} src={product.images?.[0]} alt={product.name} />
            <div className="copy-container">
                <div className="top-container">
                    <h3 className="product-name">{product.name}</h3>
                    <div className="product-overview">{product.overview ? product.overview : 'Overview TBA'}</div>
                </div>
                <div className="bottom-container">
                    {product.isOnline &&
                        product.price === 0 && (
                            <h5 className="product-pricing">
                                <span className={`player ${getPlayerStyle()}`}>{getPlayerText()}</span>
                                <span className={`free-price ${getPlayerStyle()}`}>Free Online</span>
                            </h5>
                        )}
                    {!product.isOnline && <h5 className="product-pricing">
                        <span className={`${isDifferent(product.price, discountedPrice) ? 'discounted-price' : 'product-price'}`}>${product.price}</span>
                        {product.price > discountedPrice && <span className="product-price">${discountedPrice}</span>}
                    </h5>}
                    <button className={`view-btn default-btn ${product.isOnline ? getPlayerStyle() : ''}`} onClick={() => handleRouting(product.type, product.id)}>View</button>
                </div>
            </div>
        </div>
    )
}