import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import { Footer } from "../components/Footer";
import { validator } from "../../utils";
import { mutationUrls, useMutationQuery, useAsset } from "../../queries";

export const Contact = ({ checkHeight }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [expirationDate, setExpirationDate] = useState();
    const [count, setCount] = useState(0);

    const { data: me, isFetched: meIsFetched } = useAsset({
        folder: '',
        name: 'japan.jpeg',
    });

    const { mutate } = useMutationQuery();

    const reset = () => {
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    useEffect(() => {
        if (count === 2) {
            const expirationDate = dayjs().add(1, 'h');
            setExpirationDate(expirationDate);
        }
    }, [count]);

    const checkDate = () => {
        if (!expirationDate) return true;
        const currentDate = dayjs();
        const isValid = currentDate > expirationDate;
        if (isValid) {
            setExpirationDate();
            setCount(0);
        };
        return isValid;
    }

    const submit = async (e) => {
        e.preventDefault();
        if (name && email && subject && message && checkDate()) {
            const currentCount = count + 1;
            try {
                await mutate({
                    url: mutationUrls.message,
                    body: { name, email, subject, message }
                });
                toast('Submitted form', { className: 'success' });
                reset();
            } catch {
                toast('Sorry there was a problem.', { className: 'error' });
            }
            setCount(currentCount);
        } else {
            if (!(name && email && subject && message)) toast('Please fill out all the required form fields', { className: 'error' });
            else if (count >= 2) toast('Please wait to leave more feedback.', { className: 'error' });
        }
    }

    return (
        <div id="contact-page" className="contact page" onScroll={() => checkHeight('.heading')}>
            <div className="container contact">
                <div className="contact-container">
                    {meIsFetched ? <img className="me-or-company" src={me} alt="me" /> : <div className="me-or-company"></div> }
                    <div className="contact-form-container">
                        <h1 className="heading">Contact Us</h1>
                        <p className="message-text">Feel free to leave a comment, ask a question, or share your favorite dad joke.</p>
                        <form className="contact-form" action="submit">
                            <div className="form-field">
                                <span>Name</span>
                                <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-field">
                                <span>Email</span>
                                <input className={`${validator({ type: 'email', text: email }) ? 'validated' : 'invalid'}`} required type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-field">
                                <span>Subject</span>
                                <input type="text" name="subject" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                            </div>
                            <div className="form-field">
                                <span>Leave a message...</span>
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>
                            <button className="submit-btn" onClick={submit}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}