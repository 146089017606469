import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { PeriodicTable } from './PeriodicTable';
import { useResource } from '../../../queries';

export const Chemistry = () => {
    const { data: content = [] } = useResource({
        category: 'science',
        id: 'chemistry'
    });

    const { TableOfContent } = useTableOfContent(content);

    return (
        <div className="learning-pages">
            <TableOfContent icons={content} />
            <ContentContainer
                content={content}
                additionalContent={{
                    'Periodic Table': <PeriodicTable />,
                }}
            />
        </div>
    )
}