import React, { useState } from "react";
import { validator } from "../../utils";
import { toast } from "react-toastify";
import { mutationUrls, useMutationQuery } from '../../queries';
import { useAsset } from '../../queries';

export const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [focus, setFocus] = useState(false);
    const { mutate } = useMutationQuery();

    const { data: sendIcon } = useAsset({
        folder: 'logo',
        name: 'send.png',
    });

    const handleInput = (e) => {
        e.stopPropagation();
        if (e?.key === 'Enter') subscribe();
        if (!e?.key || e?.key !== 'Enter') setEmail(e.target.value);
    }

    const subscribe = async () => {
        const isValidEmail = validator({ type: 'email', text: email });

        if (isValidEmail) {
            try {
                await mutate({
                    url: mutationUrls.subscribe,
                    body: { email }
                });
                toast('Successfully added your email', { className: 'success' });
                setEmail('');
                setFocus(false);
            } catch (err) {
                let toastMessage = 'Sorry this appears to be a duplicate email.'
                if (err.response.status === 401) {
                    toastMessage = 'Looks like there was a problem processing.'
                }
                toast(toastMessage, { className: 'error' });
            }
        } else {
            toast('Please validate your email address.', { className: 'error' });
        }
    }

    return (
        <section className="subscribe-container">
            <p className="subscribe-label">Subscribe</p>
            <div className={`subscribe-form ${focus ? (validator({ text: email, type: 'email' }) ? 'validated' : 'focus') : ''}`}>
                <input
                    type="text"
                    className="subscribe-input"
                    name="email-subscription"
                    placeholder="Subscribe"
                    value={email}
                    onKeyDown={handleInput}
                    onChange={handleInput}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    tabIndex={0}
                />
                <button
                    className="subscribe"
                    onClick={subscribe}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    tabIndex={0}>
                    <img className="send-logo-btn" src={sendIcon} alt="subscribe" />
                </button>
            </div>
        </section>
    )
}