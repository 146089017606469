import React from 'react';
import { checkSelectedWithinDateRange } from './checkSelectedWithinDateRange';

export const ZodiacImages = ({
    zodiacs,
    onClick,
    selected,
    type,
}) => zodiacs.map((zodiac, index) => {
    let isSelected;
    if (type === 'astrology') {
        isSelected = checkSelectedWithinDateRange({
            zodiac, 
            selected
        });
    } else if (type === 'chinese-zodiac') {
        isSelected = zodiac?.years?.includes(Number(selected));
    }

    return (
        <img
            key={index}
            onClick={() => onClick(zodiac)}
            className={`zodiac-${index + 1} p-abs ${isSelected ? 'selected' : ''}`}
            src={zodiac.img}
            alt={zodiac.name}
        />
    )
})