import React, { useState } from 'react';
import dayjs from 'dayjs';
import { ZodiacImages } from './ZodiacImages';
import { DateSelector } from './DateSelector'
import { Overview } from './Overview';
import { ZodiacList } from './ZodiacList';
import { checkSelectedWithinDateRange } from './checkSelectedWithinDateRange';
import { useResource } from '../../../queries';

const month = dayjs().format('MMM');
const day = dayjs().format('D');

const sortZodiacs = (zodiacs, selected) => {
    const index = (zodiacs || []).findIndex(zodiac => checkSelectedWithinDateRange({
        zodiac,
        selected,
    }));

    if (index > -1) {
        const beginning = zodiacs.slice(index);
        return [...beginning, ...zodiacs.slice(0, index)]
    }
    return zodiacs || [];
}

export const Astrology = () => {
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [selectedDay, setSelectedDay] = useState(day);

    const { data: content } = useResource({
        category: 'recreation',
        id: 'astrology'
    });

    const zodiacs = content?.zodiac || [];
    const overview = content?.overview || '';

    const sortedZodiacs = sortZodiacs(zodiacs, selectedMonth + ' ' + selectedDay);

    return (
        <div className="learning-pages">
            <div className="zodiac-wheel">
                <ZodiacImages
                    zodiacs={zodiacs}
                    onClick={(zodiac) => {
                        const [month, day] = zodiac.beginningDate.split(' ');
                        setSelectedDay(day);
                        setSelectedMonth(month);
                    }}
                    selected={selectedMonth + ' ' + selectedDay}
                    type="astrology"
                />
                <DateSelector
                    selectedDay={selectedDay}
                    selectedMonth={selectedMonth}
                    setSelectedDay={setSelectedDay}
                    setSelectedMonth={setSelectedMonth}
                />
            </div>
            <div className="learning-tips">
                <Overview overview={overview} />
                {zodiacs.length > 0 && (
                    <ZodiacList zodiacs={sortedZodiacs} />
                )}
            </div>
        </div>
    )
}
