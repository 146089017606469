import React, { Fragment } from 'react';
import { tips } from './tips-data';

export const Tips = () => (
    <div className="tips-container">
        {tips.map(({ exclude, heading, descriptions }, index) => (
            <div key={index} className="tip">
                <p className="tip-heading">
                    {!exclude && (
                        <span className="tip-number">{index}</span>
                    )}
                    {heading}
                </p>
                {descriptions.map((item, i) => {
                    if (item.type === 'example') {
                        if (item.expression) {
                            return (
                                <p key={i} className="tip-description">
                                    <strong>Example: </strong>
                                    <span>Given</span>
                                    <span className="tip-example">{item.expression}</span>
                                    {item.text.includes('^2') ?
                                        item.text.split('^2').map((text, idx) => {
                                            if (idx === 0) return text;
                                            return <Fragment key={idx}><sup>2</sup> {text}</Fragment>
                                        }) :
                                        item.text
                                    }
                                </p>
                            )
                        }
                        return (
                            <p key={i} className="tip-description">
                                <strong>Example: </strong>
                                <span>{item.text}</span>
                            </p>
                        )
                    }
                    return (
                        <p key={i} className="tip-description">{item.text}</p>
                    )
                })}
            </div>
        ))}
    </div>
);