import React from 'react';
import { Link } from "react-router-dom";
import { useAsset } from '../../../queries';

export const DesktopHeader = ({
    routeObj,
    isTransparent,
}) => {
    const { data: acornLogo } = useAsset({
        folder: 'logo',
        name: 'budding-acorn.png',
    })

    return (
        <header className={`navigation desktop ${isTransparent && 'transparent'}`}>
            <Link to="/" className={`company-logo-link link ${routeObj.main ? "match-route" : ""}`}>
                <img src={acornLogo} alt="Budding Acorn" className="company-logo" />
            </Link>
            <ul className="link-container">
                <li className={`${routeObj.games ? "match-route" : ""}`}>
                    <Link to="/products/games" className="link">Games</Link>
                </li>
                <li className={`${routeObj.music ? "match-route" : ""}`}>
                    <Link to="/music" className="link">Music</Link>
                </li>
                <li className={`${routeObj.blogs ? "match-route" : ""}`}>
                    <Link to="/blogs" className="link">Blogs</Link>
                </li>
                {/* 
                    <li className={`${routeObj.books ? "match-route" : ""}`}>
                        <Link to="/products/books" className="link">Books</Link>
                    </li> 
                */}
                <li className={`${routeObj.resources ? "match-route" : ""}`}>
                    <Link to="/resources" className="link">Resources</Link>
                </li>
                <li className={`${routeObj.about ? "match-route" : ""}`}>
                    <Link to="/about" className="link">About</Link>
                </li>
            </ul>
        </header>
    )
}