import React from 'react';

const IconItem = ({ icon, history, customUrl }) => {
    const handleRouting = () => {
        if (customUrl) return history.push({ pathname: `/${icon.type}/${icon.id}` });
        else if (icon.type) history.push({ pathname: `/resources/${icon.type}/${icon.id}` });
        else history.push({ pathname: `/resources/${icon.id}` });
    }

    return (
        <div
            id={icon.id}
            className={`icon-item ${icon.available && 'available'}`}
            onClick={() => {
                if (icon.available) handleRouting();
            }}
        >
            <img src={icon.img} alt={icon.id} />
            <h2 className="icon-name">{icon.name}</h2>
        </div>
    )
}

export const IconItems = ({ icons, history, customUrl }) => (
    <div className="icon-item-container">
        {icons.map(icon => <IconItem key={icon.id} icon={icon} history={history} customUrl={customUrl} />)}
    </div>
)