import React, { useState } from 'react';

export const useTab = ({ tabData }) => {
    const [selectedTab, setSelectedTab] = useState(tabData[0].value);

    const getTabs = () => (
        <div className="tabs-container">
            {tabData.map(({ value, label, disabled }, index) => (
                <label tabIndex={0}
                    key={index}
                    style={{ minWidth: 100 / tabData.length + '%' }}
                    className={`tab ${selectedTab === value ? 'selected-tab' : ''}`}
                    htmlFor={value}
                    onKeyDown={(event) => {
                        if (disabled) return;
                        if (event.key === 'Enter') setSelectedTab(value);
                    }}
                >
                    <input
                        type="radio"
                        id={value}
                        name="tab"
                        value={value}
                        checked={selectedTab === value}
                        onChange={() => {
                            if (disabled) return;
                            setSelectedTab(value);
                        }}
                    />
                    <span>{label}</span>
                </label>
            ))}
        </div>
    )

    return {
        selectedTab,
        setSelectedTab,
        getTabs,
    }
}