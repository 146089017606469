const DIRECTION_MAP = {
    VERTICAL_TOP: [-1, 0],
    VERTICAL_BOTTOM: [1, 0],
    HORIZONTAL_LEFT: [0, -1],
    HORIZONTAL_RIGHT: [0, 1],
    DIAGONAL_TOP_LEFT: [-1, -1],
    DIAGONAL_TOP_RIGHT: [-1, 1],
    DIAGONAL_BOTTOM_LEFT: [1, -1],
    DIAGONAL_BOTTOM_RIGHT: [1, 1],
};

export const useCheckWinner = ({ winCount, turn, username, opponent }) => {
    const initializeStartingPoint = ({ row, column, direction, value, board, prevRow, prevColumn }) => {
        const square = board?.[row]?.[column];
        if (square !== value) {
            return [prevRow, prevColumn];
        }
        return initializeStartingPoint({
            row: row + direction[0],
            column: column + direction[1],
            direction,
            value,
            board,
            prevRow: row,
            prevColumn: column,
        })
    }

    const getCount = ({ row, column, count, direction, value, board }) => {
        const square = board?.[row]?.[column];
        if (square !== value) return count;
        return getCount({
            row: row + direction[0],
            column: column + direction[1],
            count: count + 1,
            direction,
            value,
            board,
        })
    }

    const checkForWin = ({ row, column, value, board }) => {
        const startingVertical = initializeStartingPoint({ row, column, direction: DIRECTION_MAP.VERTICAL_TOP, value, board });
        const verticalCount = getCount({ row: startingVertical[0], column: startingVertical[1], count: 0, direction: DIRECTION_MAP.VERTICAL_BOTTOM, value, board });
        const startingHorizontal = initializeStartingPoint({ row, column, direction: DIRECTION_MAP.HORIZONTAL_LEFT, value, board });
        const horizontalCount = getCount({ row: startingHorizontal[0], column: startingHorizontal[1], count: 0, direction: DIRECTION_MAP.HORIZONTAL_RIGHT, value, board });
        const startingDiagonalTop = initializeStartingPoint({ row, column, direction: DIRECTION_MAP.DIAGONAL_TOP_LEFT, value, board });
        const diagonalTopCount = getCount({ row: startingDiagonalTop[0], column: startingDiagonalTop[1], count: 0, direction: DIRECTION_MAP.DIAGONAL_BOTTOM_RIGHT, value, board });
        const startingDiagonalBottom = initializeStartingPoint({ row, column, direction: DIRECTION_MAP.DIAGONAL_BOTTOM_LEFT, value, board });
        const diagonalBottomCount = getCount({ row: startingDiagonalBottom[0], column: startingDiagonalBottom[1], count: 0, direction: DIRECTION_MAP.DIAGONAL_TOP_RIGHT, value, board });

        return verticalCount >= winCount ||
            horizontalCount >= winCount ||
            diagonalTopCount >= winCount ||
            diagonalBottomCount >= winCount
    }

    const checkForTie = ({ board }) => 
        board.flatMap(row => row.map(column => column))
            .every(square => square);
    
    const handleMessage = ({ isWin, isTie }) => {
        const messages = {
            [username]: '',
            [opponent]: '',
            finished: isWin || isTie,
        }

        if (isWin) {
            messages[username] = turn === username ? 'You won!' : 'You lost!';
            messages[opponent] = turn === opponent ? 'You won!' : 'You lost!';
        } else if (isTie) {
            messages[username] = 'Cats game';
            messages[opponent] = 'Cats game';
        }
        
        return messages;
    }

    const getMessages = ({ row, column, value, board }) => {
        const isWin = checkForWin({ row, column, value, board });
        const isTie = checkForTie({ board });
        const messages = handleMessage({ isWin, isTie });
        return messages;
    }

    return { getMessages };
}