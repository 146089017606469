import React, { useEffect, useState } from 'react';
import { Checkerboard } from '../checkerboard';
import { useCheckers } from './use-checkers';
import { useAsset } from '../../../../queries';
import { toast } from 'react-toastify'; // TODO: this will be replaced with the socket event listener
import { socket, SOCKET_CLIENT_EVENTS } from '../WaitingRoom';

export const Board = ({
    activeGame,
    setActiveGame,
    username,
    opponent,
}) => {
    const board = activeGame?.board ?? [];
    const turn = activeGame?.turn ?? activeGame?.players?.[0];
    const isFirst = activeGame?.isFirst ?? activeGame?.players?.[0];
    const color = isFirst === turn ? 'black' : 'red';

    const [selectedPiece, setSelectedPiece] = useState(null);
    const [moves, setMoves] = useState([]);
    const [pieces, setPieces] = useState([]);

    const { data: blackKing } = useAsset({
        folder: 'online',
        name: 'checkers-black-crown.png',
    });

    const { data: redKing } = useAsset({
        folder: 'online',
        name: 'checkers-red-crown.png',
    });

    const { getColoredPiecePositions, filterPieceSquareOptions, checkPossibleJumpOptions, checkKingMe, checkBlocked, getMessage, checkGameEnd } = useCheckers({ board, color });

    useEffect(() => {
        if (turn) {
            const newPieces = [];
            const positions = getColoredPiecePositions(board, color);

            // LOGIC TO CHECK IF PLAYER CANNOT MOVE
            if (activeGame && checkBlocked(positions)) {
                // game over for this players
                // TODO: refactor using socket to send message to both players
                socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], { ...activeGame, messages: {
                    [username]: 'Game over for you', 
                    [opponent]: `Game over for ${turn === username ? opponent : username}`,
                    finished: true 
                } });
                return;
            }

            // LOGIC TO ENFORCE JUMP IF AVAILABLE
            const requiredJump = [];
            positions.forEach(position => {
                const jumpOptions = checkPossibleJumpOptions({ position, isPieceCheck: true });
                if (jumpOptions.length > 0) {
                    requiredJump.push(...jumpOptions);
                }
            });

            if (requiredJump.length > 0) {
                setPieces(requiredJump);
            } else {
                positions.forEach((position) => {
                    const piecePositions = filterPieceSquareOptions({ position, isPieceCheck: true });
                    newPieces.push(...piecePositions);
                });

                setPieces(newPieces.filter((piece, index) => index === newPieces.findIndex(p => p[0] === piece[0] && p[1] === piece[1])));
            }
        }
        // eslint-disable-next-line
    }, [turn]);

    useEffect(() => {
        if (selectedPiece) {
            const directions = filterPieceSquareOptions({ position: selectedPiece });
            setMoves(directions);
        }
        // eslint-disable-next-line
    }, [selectedPiece]);

    const handlePieceClick = (piece, square) => {
        // if it's not the player's turn, return
        if (turn !== username) return;
        if (pieces.find(p => p[0] === square[0] && p[1] === square[1])) {
            // selects a piece to move, and this should show the options that this piece can move
            setSelectedPiece(square);
        }
    }

    const handleMove = async (newPosition) => {
        const [row, column] = newPosition;
        const [oldRow, oldColumn] = selectedPiece;
        const piece = board?.[oldRow]?.[oldColumn];
        const newBoard = board.map(row => row.map(column => column));
        const isKing = checkKingMe(piece, row);
        piece.king = isKing;
        newBoard[row][column] = piece; // newly moved piece
        newBoard[oldRow][oldColumn] = null; // old piece position

        const lastSquareIsNewPosition = moves.find(move => {
            if (Array.isArray(move) && Array.isArray(move.at(-1))) {
                const last = move.at(-1);
                const lastPosition = last.at(-1);
                return lastPosition[0] === row && lastPosition[1] === column;
            }
            return move[0] === row && move[1] === column;
        })

        let hasJumped = false;

        // handle captured piece logic
        if (lastSquareIsNewPosition) {
            const options = lastSquareIsNewPosition.at(-1);
            if (Array.isArray(options)) {
                const newPositions = [[oldRow, oldColumn]];
                options.forEach((option) => {
                    const lastPosition = newPositions.at(-1);
                    const capturedRow = lastPosition[0] + ((option[0] - lastPosition[0]) / 2);
                    const capturedColumn = lastPosition[1] + ((option[1] - lastPosition[1]) / 2);
                    newBoard[capturedRow][capturedColumn] = null;
                    hasJumped = true;
                    newPositions.push(option);
                });
            } else {
                const capturedRow = oldRow + ((row - oldRow) / 2);
                const capturedColumn = oldColumn + ((column - oldColumn) / 2);
                if (newBoard?.[capturedRow]?.[capturedColumn]) {
                    hasJumped = true;
                    newBoard[capturedRow][capturedColumn] = null;
                }
            }
        }

        // LOGIC TO CHECK IF GAME IS OVER
        const { black, red } = await checkGameEnd(newBoard);
        if (black === 0 || red === 0) {
            // TODO: refactor using socket to send message to both players
            toast.success('Game over for ' + (black === 0 ? 'black' : 'red') + '!');
            // TODO: reset the game or redirect players back to the lobby
        }

        setSelectedPiece(null);
        setMoves([]);

        const data = {
            ...activeGame,
            board: newBoard,
            turn: turn === username ? opponent : username,
            messages: getMessage({ newBoard, isFirst, username, opponent, hasJumped, turn }),
        }

        setActiveGame(data);
        socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], data);
    }

    return (
        <Checkerboard
            moves={moves}
            board={board}
            selected={selectedPiece}
            piece={(piece, square) => (
                <button
                    className={`piece ${piece.color} ${turn !== username ? 'disabled' : ''} ${pieces.find(position => position[0] === square[0] && position[1] === square[1]) ? 'available-piece' : ''} ${selectedPiece && selectedPiece[0] === square[0] && selectedPiece[1] === square[1] ? 'selected-piece' : ''}`.trim()}
                    onClick={() => handlePieceClick(piece, square)}
                    disabled={turn !== username}
                >
                    {piece.king && piece.color === 'black' && blackKing && <img className={`king ${isFirst !== username ? 'inverted' : ''}`} src={blackKing} alt="black-king" />}
                    {piece.king && piece.color === 'red' && redKing && <img className={`king ${isFirst !== username ? 'inverted' : ''}`} src={redKing} alt="red-king" />}
                </button>
            )}
            onMove={handleMove}
            inverted={isFirst !== username}
        />
    )
}