import React from 'react';
import { formatContent } from './format-content';

export const Question = ({
    questions,
    selectedIndex,
    setSelectedIndex,
    show,
    setShow
}) => {
    const selected = questions?.[selectedIndex];

    return (
        <>
            <div className="btn-container">
                <button
                    className="default-btn"
                    disabled={selectedIndex <= 0}
                    onClick={() => {
                        setSelectedIndex(prevIndex => prevIndex - 1)
                        setShow(false);
                    }}
                >
                    Previous
                </button>
                <button
                    className="default-btn"
                    disabled={show || !selected?.answer}
                    onClick={() => setShow(true)}
                >
                    Reveal
                </button>
                <button
                    className="default-btn"
                    disabled={selectedIndex >= questions.length - 1}
                    onClick={() => {
                        setSelectedIndex(prevIndex => prevIndex + 1)
                        setShow(false);
                    }}
                >
                    Next
                </button>
            </div>

            {formatContent(selected?.question, 'question')}
        </>
    )
};