import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Card, useCards } from '../cards';
import { ButtonContainer } from './ButtonContainer';
import { useBlackjack } from './use-blackjack';
import { convertListToString } from '../../../../utils';

export const Blackjack = () => {
    const [isHold, setIsHold] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const { formatHands, validateDraw, validateHand, checkValue } = useBlackjack();
    const { hands, deal, draw, groupDraw, reset } = useCards({
        initialHands: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
        },
        formatHands,
        validateDraw,
        gameOver,
    });
    const myHand = hands[1] ?? [];

    useEffect(() => {
        initializeData();
        // eslint-disable-next-line
    }, []);

    const initializeData = () => {
        reset();
        setIsHold(false);
        deal({ numberOfCards: 2, reset: true });
    }

    const playerName = (number, addArticle) => {
        const difference = Number(number) - 1;
        if (difference === 4 && addArticle) return 'the Dealer';
        else if (difference === 4) return 'Dealer';
        else if (difference === 0) return 'You';
        return `CPU ${difference}`;
    }

    useEffect(() => {
        if (myHand && !validateHand(myHand)) setIsHold(true);
        // eslint-disable-next-line
    }, [myHand]);

    const getWinner = (newHands) => {
        const values = Object.entries(newHands).reduce((acc, [key, hand]) => {
            return { ...acc, [key]: checkValue(hand) };
        }, {});

        const winners = [];

        for (const key in values) {
            if (values[key] <= 21) winners.push({ key, value: values[key] });
        }

        return winners;
    }

    const declareWinner = ({ hands }) => {
        const valid = getWinner(hands);
        const dealer = valid.find(winner => winner.key === '5');
        const winners = valid.filter(winner => !dealer || winner.value >= dealer.value);

        let message = '';
        const winnerStatement = convertListToString(winners.map(winner => playerName(winner.key, true)));
        const includesDealer = winners.find(winner => winner.key === '5');
        if (winners.length === 1 && includesDealer) {
            message = "The dealer wins!";
        } else if (includesDealer) {
            message = `${winnerStatement} tied!`;
        } else if (winners.length === 4 && !includesDealer) {
            message = "Everyone beat the dealer!";
        } else if (winners.length === 0) {
            message = "Everyone lost!"
        } else {
            message = `${winnerStatement} wins!`;
        }
        toast.success(message);
    }

    const handleCPU = () => {
        groupDraw({
            group: [2, 3, 4, 5],
            numberOfCards: 3,
            format: (card) => ({ ...card, show: true }),
            dealer: 5,
            onComplete: (payload) => {
                declareWinner(payload);
                setTimeout(() => {
                    setGameOver(true);
                    initializeData();
                }, 3000);
                setTimeout(() => {
                    setGameOver(false);
                }, 3500);
            },
        });
    }

    useEffect(() => {
        if (isHold) {
            handleCPU();
        }
        // eslint-disable-next-line
    }, [isHold]);

    return (
        <section className="blackjack-board">
            <div className="game-table">
                {Object.entries(hands).map(([handNumber, hand], index) => {
                    const shouldHold = handNumber === '1' ? isHold || !validateHand(hand) : isHold && !validateDraw(hand);
                    return (
                        <div key={index} className={`player-cards hand-${handNumber}`}>
                            <span className="player-name">{playerName(handNumber)}</span>
                            {hand.map((card, cardIndex) => (
                                <Card
                                    key={cardIndex}
                                    card={{ ...card, class: 'card-position-' + cardIndex }}
                                />
                            ))}
                            <ButtonContainer
                                handNumber={handNumber}
                                draw={() => draw({ handNumber: 1 })}
                                hold={() => setIsHold(true)}
                                shouldHold={shouldHold}
                            />
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

/*
 * Not going to include any betting
 * So no consideration of "Double Down", "Splitting", or "Insurance"
 */