import {
    S1, S2, S3, S4, S5, S6, S7, S8, S9, // switches
    D1, D2, D3, D4, D5, D6, D7, D8, D9, // dashed
    C1, C2, C3, C4, C5, C6, C7, C8, C9, // controls
    H1, H2, H3, H4, H5, H6, H7, H8, H9, // hard
    B, // block
    // P, // prior
} from '.';

export const CreateSliderMapOptions = (puzzle) => {
    const includesValue = (value) => !!puzzle.flat(2).find(square => square === value);

    const hideOption = (values) => !values.every(value => puzzle.flat(2).find(square => square.includes(value)));

    const options = [
        {
            label: '',
            value: '',
        },
        {
            label: 'Start',
            value: '1',
            disabled: includesValue('1'),
        },
        {
            label: 'Exit',
            value: '2',
            disabled: includesValue('2'),
        },
        {
            label: 'Block',
            value: B,
        },
        {
            label: 'Switch 1',
            value: S1,
        },
        {
            label: 'Dash 1',
            value: D1,
        },
        {
            label: 'Switch 2',
            value: S2,
            hide: hideOption([S1]),
        },
        {
            label: 'Dash 2',
            value: D2,
            hide: hideOption([D1]),
        },
        {
            label: 'Switch 3',
            value: S3,
            hide: hideOption([S1, S2]),
        },
        {
            label: 'Dash 3',
            value: D3,
            hide: hideOption([D1, D2]),
        },
        {
            label: 'Switch 4',
            value: S4,
            hide: hideOption([S1, S2, S3]),
        },
        {
            label: 'Dash 4',
            value: D4,
            hide: hideOption([D1, D2, D3]),
        },
        {
            label: 'Switch 5',
            value: S5,
            hide: hideOption([S1, S2, S3, S4]),
        },
        {
            label: 'Dash 5',
            value: D5,
            hide: hideOption([D1, D2, D3, D4]),
        },
        {
            label: 'Switch 6',
            value: S6,
            hide: hideOption([S1, S2, S3, S4, S5]),
        },
        {
            label: 'Dash 6',
            value: D6,
            hide: hideOption([D1, D2, D3, D4, D5]),
        },
        {
            label: 'Switch 7',
            value: S7,
            hide: hideOption([S1, S2, S3, S4, S5, S6]),
        },
        {
            label: 'Dash 7',
            value: D7,
            hide: hideOption([D1, D2, D3, D4, D5, D6]),
        },
        {
            label: 'Switch 8',
            value: S8,
            hide: hideOption([S1, S2, S3, S4, S5, S6, S7]),
        },
        {
            label: 'Dash 8',
            value: D8,
            hide: hideOption([D1, D2, D3, D4, D5, D6, D7]),
        },
        {
            label: 'Switch 9',
            value: S9,
            hide: hideOption([S1, S2, S3, S4, S5, S6, S7, S8]),
        },
        {
            label: 'Dash 9',
            value: D9,
            hide: hideOption([D1, D2, D3, D4, D5, D6, D7, D8]),
        },
        {
            label: 'Control 1',
            value: C1,
        },
        {
            label: 'Hard 1',
            value: H1,
        },
        {
            label: 'Control 2',
            value: C2,
            hide: hideOption([C1]),
        },
        {
            label: 'Hard 2',
            value: H2,
            hide: hideOption([H1]),
        },
        {
            label: 'Control 3',
            value: C3,
            hide: hideOption([C1, C2]),
        },
        {
            label: 'Hard 3',
            value: H3,
            hide: hideOption([H1, H2]),
        },
        {
            label: 'Control 4',
            value: C4,
            hide: hideOption([C1, C2, C3]),
        },
        {
            label: 'Hard 4',
            value: H4,
            hide: hideOption([H1, H2, H3]),
        },
        {
            label: 'Control 5',
            value: C5,
            hide: hideOption([C1, C2, C3, C4]),
        },
        {
            label: 'Hard 5',
            value: H5,
            hide: hideOption([H1, H2, H3, H4]),
        },
        {
            label: 'Control 6',
            value: C6,
            hide: hideOption([C1, C2, C3, C4, C5]),
        },
        {
            label: 'Hard 6',
            value: H6,
            hide: hideOption([H1, H2, H3, H4, H5]),
        },
        {
            label: 'Control 7',
            value: C7,
            hide: hideOption([C1, C2, C3, C4, C5, C6]),
        },
        {
            label: 'Hard 7',
            value: H7,
            hide: hideOption([H1, H2, H3, H4, H5, H6]),
        },
        {
            label: 'Control 8',
            value: C8,
            hide: hideOption([C1, C2, C3, C4, C5, C6, C7]),
        },
        {
            label: 'Hard 8',
            value: H8,
            hide: hideOption([H1, H2, H3, H4, H5, H6, H7]),
        },
        {
            label: 'Control 9',
            value: C9,
            hide: hideOption([C1, C2, C3, C4, C5, C6, C7, C8]),
        },
        {
            label: 'Hard 9',
            value: H9,
            hide: hideOption([H1, H2, H3, H4, H5, H6, H7, H8]),
        },
    ];

    return options;
}