import React from "react";
import { toTitleCase } from "../../../../utils";
import { MODES } from '../WaitingRoom/WaitingRoom';
import { Sonar } from ".";
import turnMarker from "../turn-marker.png";

export const BOARD_TYPES = {
    HOME: 'home-board',
    AWAY: 'away-board',
}

export const SQUARE_OPTIONS = {
    SHIP: 'ship',
    HIT: 'hit',
    MISS: 'miss',
}

export const GameBoard = ({
    mode,
    board,
    fire,
    // these are only for MODES.IN_PLAY
    type,
    title,
    turn,
    // end of MODES.IN_PLAY props
}) => {
    const squareStyling = (value) => {
        switch (value) {
            case SQUARE_OPTIONS.MISS:
                return 'miss';
            case SQUARE_OPTIONS.HIT:
                return 'hit';
            default:
                return '';
        }
    }

    return (
        <section className={`game-board-container ${mode === MODES.IN_PLAY && type ? type : ''}`}>
            <div className="title-container">
                <h3>{title ?? toTitleCase(mode, '_')}</h3>
                {turn && (
                    <img src={turnMarker} alt="turn-marker" className="turn-marker" />
                )}
            </div>
            <div className="game-board">
                {mode === MODES.IN_PLAY && type === BOARD_TYPES.AWAY && <Sonar />}
                {board?.map((row, rowIndex) => (
                    <div key={rowIndex} className="board-row">
                        {row.map((square, squareIndex) => (
                            <button
                                disabled={mode === MODES.IN_PLAY && type === BOARD_TYPES.HOME}
                                key={squareIndex}
                                id={mode + '-' + rowIndex + '-' + squareIndex}
                                className={`board-square square-${rowIndex}-${squareIndex}`}
                                onClick={() => fire([rowIndex, squareIndex])}
                            >
                                <div className={`square-hole ${squareStyling(square)}`}></div>
                            </button>
                        ))}
                    </div>
                ))}
            </div>
        </section>
    )
}