import React from 'react';
import { useCheckWinner } from '../../../../hooks';
import { socket, SOCKET_CLIENT_EVENTS } from '../WaitingRoom';

export const Board = ({
    activeGame,
    setActiveGame,
    disabled,
    setDisabled,
    username,
    opponent,
    hoveredColumn,
    setHoveredColumn,
}) => {
    const board = activeGame?.board;
    const turn = activeGame?.turn;
    const players = activeGame?.players; // [red, yellow];
    const red = players?.[0];

    const { getMessages } = useCheckWinner({
        winCount: 4,
        username,
        opponent,
        turn,
    });

    const getNextSquare = ({ row, column }) => {
        const square = board[row][column];
        if (square === null) return [row, column];
        return getNextSquare({ row: row - 1, column });
    }

    const handleClick = async (column) => {
        const lastRow = board.length - 1;
        const square = getNextSquare({ row: lastRow, column });
        const squareRow = square[0];
        const squareColumn = square[1];

        if (squareRow >= 0) {
            const newBoard = [...board].map(row => [...row]) // deep copy of the board
            setDisabled(true);
            newBoard[squareRow][squareColumn] = turn === red ? 'R' : 'Y';
            const value = turn === red ? 'R' : 'Y';
            const data = {
                ...activeGame,
                board: newBoard,
                turn: username === turn ? opponent : username,
                messages: getMessages({ row: squareRow, column, value, board: newBoard }),
            };
            setActiveGame(data);
            socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], data);
            setDisabled(false);
        }
    }

    return (
        <div className="board">
            <div className="pointers">
                {board?.[0].map((_, columnIndex) => (
                    <div className="pointer-holder">
                        <div className={`pointer ${hoveredColumn === columnIndex && turn === username ? (turn === red ? 'hovered-red' : 'hovered-yellow') : ''}`}></div>
                    </div>
                ))}
            </div>
            {board.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                    {row.map((column, columnIndex) => (
                        <div
                            key={columnIndex}
                            className={`square ${turn === username ? 'hovered' : ''}`}
                            onClick={() => {
                                if (disabled || turn !== username) return;
                                handleClick(columnIndex);
                            }}
                            onMouseEnter={() => setHoveredColumn(columnIndex)}
                            onMouseLeave={() => setHoveredColumn()}
                        >
                            <div className={`center ${column !== null ? (column === 'R' ? 'red' : 'yellow') : ''}`}></div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}