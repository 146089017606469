import React from 'react';
import { FlippingButton } from '../components/FlippingButton';
import { useAsset } from '../../queries';

export const Specifications = ({ product }) => {
    const downloadLink = `https://drive.google.com/uc?export=download&id=${product.ruleId}`;
    const openLink = `https://drive.google.com/file/d/${product.ruleId}/view?usp=sharing`;

    const { data: ageLogo, isFetched: ageIsFetched } = useAsset({
        folder: 'logo',
        name: 'age.png',
    });

    const { data: clockLogo, isFetched: clockIsFetched } = useAsset({
        folder: 'logo',
        name: 'clock.png',
    });

    const { data: playersLogo, isFetched: playersIsFetched } = useAsset({
        folder: 'logo',
        name: 'players.png',
    });

    const { data: puzzleLogo, isFetched: puzzleIsFetched } = useAsset({
        folder: 'logo',
        name: 'puzzle.png',
    });

    const { data: rulerLogo, isFetched: rulerIsFetched } = useAsset({
        folder: 'logo',
        name: 'ruler.png',
    });

    const { data: rulesLogo, isFetched: rulesIsFetched } = useAsset({
        folder: 'logo',
        name: 'rules.png',
    });

    const { data: scaleLogo, isFetched: scaleIsFetched } = useAsset({
        folder: 'logo',
        name: 'scale.png',
    });

    const viewRules = (event) => {
        event.stopPropagation();
        window.open(openLink, "_blank", "noopener noreferer");
    }

    const downloadRules = (event) => {
        event.stopPropagation();
        window.open(downloadLink);
    }

    return (
        <div className="specifications line-break">
            <h4 className="section-heading">Specifications</h4>
            {product.type === 'games' && (
                <div className="specs">
                    <div className="spec age">
                        {ageIsFetched ? <img className='spec-logo age' src={ageLogo} alt="age" /> : <div className="spec-logo"></div>}
                        <h6>{product.age}+</h6>
                    </div>
                    <div className="spec game-play">
                        {clockIsFetched ? <img className='spec-logo time' src={clockLogo} alt="clock" /> : <div className="spec-logo"></div>}
                        <h6>{product.gamePlayLength?.[0]} - {product.gamePlayLength?.[1]} min</h6>
                    </div>
                    <div className="spec number-of-players">
                        {playersIsFetched ? <img className='spec-logo players' src={playersLogo} alt="players" /> : <div className="spec-logo"></div>}
                        {product.players[1] ?
                            <h6>{product.players[0]} - {product.players[1]}</h6>
                            :
                            <h6>{product.players[0]}+</h6>
                        }
                    </div>
                    <div className="spec dimensions">
                        {rulerIsFetched ? <img className='spec-logo dimensions' src={rulerLogo} alt="ruler" /> : <div className="spec-logo"></div>}
                        <h6>{product.length} x {product.width} x {product.height}</h6>
                    </div>
                    <div className="spec weight">
                        {scaleIsFetched ? <img className='spec-logo weight' src={scaleLogo} alt="scale" /> : <div className="spec-logo"></div>}
                        <h6>{product.weight}</h6>
                    </div>
                    {product?.gameContent ? (
                        <div className="spec game-content">
                            {puzzleIsFetched ? <img className="spec-logo pieces" src={puzzleLogo} alt="game-contents" /> : <div className="spec-logo"></div>}
                            <ul>{product.gameContent.map(item => <li key={item}>{item}</li>)}</ul>
                        </div>
                    ) : null}
                    {product.ruleId ? (
                        <div className="spec rules">
                            {rulesIsFetched ? <img className="spec-logo rules" src={rulesLogo} alt="rules" /> : <div className="spec-logo"></div>}
                            <div className="rules-container-of-container">
                                <div className="rules-container">
                                    <FlippingButton
                                        buttonText="View Rules"
                                        color="green"
                                        onClick={viewRules}
                                        width={150}
                                    />
                                </div>
                                <div className="rules-container">
                                    <FlippingButton
                                        buttonText="Download Rules"
                                        color="skyblue"
                                        onClick={downloadRules}
                                        width={210}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    )
}