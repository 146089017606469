import React from 'react';

export const SliderArrows = ({ inMotion, piecePosition, updatePosition }) => (
    <div className="arrows-container">
        <button disabled={inMotion} className="computer-key up" onClick={() => updatePosition({ type: 'row', position: piecePosition, direction: '-' })}>Up</button>
        <div className="arrow-row">
            <button disabled={inMotion} className="computer-key left" onClick={() => updatePosition({ type: 'column', position: piecePosition, direction: '-' })}>Left</button>
            <button disabled={inMotion} className="computer-key right" onClick={() => updatePosition({ type: 'column', position: piecePosition, direction: '+' })}>Right</button>
        </div>
        <button disabled={inMotion} className="computer-key down" onClick={() => updatePosition({ type: 'row', position: piecePosition, direction: '+' })}>Down</button>
    </div>
);