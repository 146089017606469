import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { MusicStaff } from './MusicStaff';
import { MusicButtons } from './MusicButtons';
import { useAsset } from '../../../queries';

export const BassNotes = () => {
    const [hidden, setHidden] = useState([]);
    const spaces = ['low-a', 'bass-c', 'bass-e', 'bass-g'];
    const lines = ['low-g', 'low-b', 'bass-d', 'bass-f', 'bass-a'];

    const hiddenTypes = {
        lines: () => setHidden(spaces),
        spaces: () => setHidden(lines),
        reset: () => setHidden([]),
    }

    const { data: bassClef } = useAsset({
        folder: 'music',
        name: 'bass-clef.png'
    });

    return (
        <>
            <MusicStaff
                type="bass"
                clef={bassClef}
                notes={[
                    { name: 'G', value: 'low-g' },
                    { name: 'A', value: 'low-a' },
                    { name: 'B', value: 'low-b' },
                    { name: 'C', value: 'bass-c' },
                    { name: 'D', value: 'bass-d' },
                    { name: 'E', value: 'bass-e' },
                    { name: 'F', value: 'bass-f' },
                    { name: 'G', value: 'bass-g' },
                    { name: 'A', value: 'bass-a' },
                ]}
                hidden={hidden}
            />
            <MusicButtons hiddenTypes={hiddenTypes} />

            <div className="learning-tips">
                <p><span className="btn-indicator">Click</span> the buttons above.</p><br /><br />
                {isEqual(hidden, []) && <p>Knowing the notes on the bass staff will come in handy as you begin your musical career/journey.</p>}
                {isEqual(hidden, spaces) && <p>A mnemonic that can help you remember the notes for each line on the bass staff:<br /><br /><span className="mnemonic-tip"><span className="underline">G</span>rizzly <span className="underline">B</span>ears <span className="underline">D</span>on't <span className="underline">F</span>ear <span className="underline">A</span>nything</span></p>}
                {isEqual(hidden, lines) && <p>A mnemonic that can help you remember the notes for each space on the bass staff:<br /><br /><span className="mnemonic-tip"><span className="underline">A</span>ll <span className="underline">C</span>ows <span className="underline">E</span>at <span className="underline">G</span>rass</span></p>}
            </div>
        </>
    )
}
