export const useUsername = () => {
    const getUsername = () => {
        const username = localStorage.getItem('username');
        return username;
    }

    const setUsername = (username) => {
        localStorage.setItem('username', username);
    }

    return {
        getUsername,
        setUsername,
    }
}