import React from 'react';
import { useResource } from '../../../queries';
import { IconItems } from '../../components';

export const CookingDash = ({ history }) => {
    const { data: content = [] } = useResource({
        category: 'life-skills',
        id: 'cooking'
    });
    
    return (
        <div className="learning-pages">
            <IconItems icons={content} history={history} />
        </div>
    )
}