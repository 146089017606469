const roundPrice = (price) =>
    (Math.round(100 * price) / 100).toFixed(2);

export const discountFixed = (price, discount) =>
    roundPrice(price - discount);

export const discountPercentage = (price, discount) =>
    roundPrice(price * discount);

export const calculateDiscountedPrice = (price, discount) => {
    if (discount > 0) {
        return discount > 1 ?
            discountFixed(price, discount)
            :
            discountPercentage(price, discount)
    }
    return price;
}

export const isDifferent = (price, discount) =>
    price > discount;

export const calculateCart = (cart) => cart.reduce((total, item) => {
    let originalPrice = item.price * item.qty;
    let discount = item.discount > 0 ? item.discount : 1;
    if (item.discount > 1) {
        discount *= item.qty;
    }
    const discountedPrice = calculateDiscountedPrice(originalPrice, discount);
    const difference = (originalPrice - discountedPrice);

    return {
        original: total.original + originalPrice,
        discount: total.discount + difference,
    }
}, { original: 0, discount: 0 });