import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Breadcrumb, FeedbackForm, Footer, IconItems } from '../components';
import { useResourceCategory } from '../../queries';
import { Loader } from '../components';
import { useLoader } from '../../hooks/use-loader';

export const ResourceCategory = ({
    checkHeight,
    match,
    history,
}) => {
    const { id } = match.params;
    const { data: resource = [] } = useResourceCategory({ id });

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 50);
    }, []);

    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />;

    if (resource) {
        return (
            <div id="resource-details-page" className="resource page" onScroll={() => checkHeight('.breadcrumbs')}>
                <div className="container">
                    <Breadcrumb type="resource" />
                    <div className="resource-container">
                        <IconItems icons={resource} history={history} />
                    </div>
                </div>
                <FeedbackForm product={'resource-' + id} type="resource" />
                <Footer />
            </div>
        );
    }
    return <Redirect to="/resources" />;
}
