export const checkSelectedWithinDateRange = ({
    zodiac,
    selected
}) => {
    let startDay = zodiac.beginningDate.substring(4);
    let endDay = zodiac.endingDate.substring(4);
    const [month, day] = selected.split(' ');
    if ((day >= startDay && zodiac.period[0] === month) || (day <= endDay && zodiac.period[1] === month)) {
        return true;
    }
    return;
}