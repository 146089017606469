export const tips = [
    {
        exclude: true,
        heading: 'Combine/Choose',
        descriptions: [
            {
                text: 'Use one or a combination of the below tips to help you better your mental math skills and solve different arithmetic problems.',
                type: 'text',
            },
            {
                text: 'Numbers are all around you so do not be afraid the next time you have to estimate how much your grocery cart will cost or how much to tip.',
                type: 'text',
            },
        ],
    },
    {
        heading: 'Try Rounding',
        descriptions: [
            {
                text: 'Try rounding to easier numbers you can do in your head and adjusting the numbers accordingly.',
                type: 'text',
            },
            {
                text: 'round 27 to 30 and multiply that by 15 (15 x 30 = 450). The rounding difference, or how much was rounded was 3 (30 - 27 = 3). Multiply 15 by the rounding difference (3 x 15 = 45). Finally subtract 450 - 45 = 405.',
                type: 'example',
                expression: '27 x 15',
            }
        ],
    },
    {
        heading: 'Break + Group',
        descriptions: [
            {
                text: 'Try breaking up the numbers into smaller numbers to add.',
                type: 'text',
            },
            {
                text: 'break 36 into 30 + 6 and break 88 into 80 + 8. Group 30 and 80 which is 110, and 6 and 8 which is 14. Now sum up the individual sums. The result is 124 (110 + 14 = 124).',
                type: 'example',
                expression: '36 + 88',
            }
        ],
    },
    {
        heading: 'Start on Left',
        descriptions: [
            {
                text: 'Start handling numbers from left to right to get a better ballpark estimate. Start with the most left place (i.e. \'tens\' place).',
                type: 'text',
            },
            {
                text: 'handle the \'tens\' place first: 5 and 3 is 8. So you know the number is at least greater than 80 because the \'tens\' place represents 8 \'tens\' or 80. Then sum up the \'ones\' place: 7 and 4 is 11. Because 11 is greater than 9 carry over the one to the \'tens\' place. The result is 91.',
                type: 'example',
                expression: '57 + 34',
            }
        ],
    },
    {
        heading: 'Learn Squares',
        descriptions: [
            {
                text: 'Learn the perfect squares of basic numbers, which is the product a number by itself.',
                type: 'text',
            },
            {
                text: 'either you know 13^2, or 13 times 13 is 169, or you can start with another perfect square you may know like 12^2 which is 144. Because 12 is one less than 13, you can simply add the two numbers together 12 and 13 which is 25 and that is the what you will add to 144 to get 169.',
                type: 'example',
                expression: '13 + 13',
            }
        ],
    },
    {
        heading: 'Multiplying Difference of 2',
        descriptions: [
            {
                text: 'If you need to multiply a number by another number that is two more than it or two less than it, you can take the square of the number in between it and subtract one.',
                type: 'text',
            },
            {
                text: 'you square the number in between 24 and 26 which is 252, or 25 times 25, or 625. Then subtract 1 from that and you get the product of 24 and 26, or 624.',
                type: 'example',
                expression: '24 x 26',
            }
        ],
    },
    {
        heading: 'Squaring Numbers with 5',
        descriptions: [
            {
                text: 'When you have a double-digit number that ends in 5 and you are multiplying by itself, the product will always end with 25. The product will start with the other number times the number plus one.',
                type: 'text',
            },
            {
                text: 'take the 2 and multiply it by 3 (2 + 1  = 3) which is 6. Then place 25 at the end. The result is 625.',
                type: 'example',
                expression: '25 x 25',
            }
        ],
    },
    {
        heading: 'Handling 11',
        descriptions: [
            {
                text: 'Multiplying a single-digit number by 11, it’ll be two of that single-digit number.',
                type: 'text',
            },
            {
                text: 'it would be two of that single-digit number (9 and 9) which is 99.',
                type: 'example',
                expression: '9 x 11',
            },
            {
                text: 'Multiplying a 2-digit number by 11, take the number and add the digits together and place it in between the 2-digit number. If the sum is greater than 9, carry one over to the hundreds column.',
                type: 'text',
            },
            {
                text: 'take 2 and 9 and add them together (2 + 9 = 11). The number in the \'hundreds\' place would have been 2, but because the sum of the digits was greater than 9, you would need to carry the 1 to the \'hundreds\' place which gets you 3 (1 + 2). The result is 319',
                type: 'example',
                expression: '29 x 11',
            },
        ],
    },
    {
        heading: 'Handling 10',
        descriptions: [
            {
                text: 'Multiplying a number by 10, add zero to the end of the other number.',
                type: 'text',
            },
            {
                text: 'add a 0 to the end of 43 which gets you 430.',
                type: 'example',
                expression: '43 x 10',
            }
        ],
    },
    {
        heading: 'Handling 9',
        descriptions: [
            {
                text: 'Multiplying a single-digit number by 9, multiply the number by 10 and subtract that single-digit number from the result, or you can learn the nifty math hand trick (which will be explained next).',
                type: 'text',
            },
            {
                text: 'multiply 8 by 10 which is 80 and subtract 8 from that which is 72.',
                type: 'example',
                expression: '9 x 8',
            },
            {
                text: 'Math hand trick for multiplying single-digit numbers by 9, hold both hands up with palm facing towards you. Whatever single-digit number is being multiplied, place that number finger down. Count the number of fingers before it and after it. The number before it will be the \'tens\' place and the number after it will be the \'ones\' place.',
                type: 'text',
            },
            {
                text: 'using the multiple of nine hand trick, you place the third finger down and count the number of fingers left of it and right of it. There are 2 fingers before it and 7 fingers after it. The resulting product is 27. Refer to the image directly beneath.',
                type: 'example',
                expression: '9 x 3',
            },
            {
                text: 'Multiplying 2-digits numbers by 9, take that \'tens\' place digit and place it as the \'hundreds\' place for the product. The new \'tens\' place will be counting how many numbers are contained in between its \'tens\' and \'ones\' place and this will be \'tens\' place for the product. And lastly the ones place, it will be difference of 10 and that \'ones\' place number.',
                type: 'text',
            },
            {
                text: 'The \'hundreds\' place will be the \'tens\' place of the 2-digit number so 4. To determine the \'tens\' place count the numbers between 4 and 8 - which are 5, 6, and 7 - so the total count is 3. And the \'one\' place will be the difference of the \'ones\' place digit from 10 which will be 2. The result is 432.',
                type: 'example',
                expression: '48 x 9',
            },
        ],
    },
    {
        heading: 'Handling 6',
        descriptions: [
            {
                text: 'This tip is to check if your solution is relatively correct. If you are multiplying a number by 6, you can quickly estimate this is correct by making sure the sum of the digits are a multiple of 3 (3, 6, or 9) and that the product is an even number (ends in 2, 4, 6, 8, or 0).',
                type: 'text',
            },
            {
                text: 'the result is 216. To check this you can sum up the digits too 9 (2 + 1 + 6) and that is a multiple of 3. And the number is an even number.',
                type: 'example',
                expression: '36 x 6',
            }
        ],
    },
    {
        heading: 'Handling 5',
        descriptions: [
            {
                text: 'Multiplying a number by 5, half the number then add a zero to the end, or add a zero to the end and then half that number.',
                type: 'text',
            },
            {
                text: 'half of 38 is 19 and then add a zero to the end which is 190.',
                type: 'example',
                expression: '38 x 5',
            }
        ],
    },
    {
        heading: 'Handling 4',
        descriptions: [
            {
                text: 'Multiplying a number by 4, double each individual digit and double that result again. Carry over to the \'tens\' place from the \'ones\' place as needed.',
                type: 'text',
            },
            {
                text: 'double 3 and 4 and you get 6 and 8. And then double it again and you get 12 and 16. So you would need to carry the 1 in 16 over to the \'tens\' place which will get you 13 and 6. The result is 136.',
                type: 'example',
                expression: '34 x 4',
            },
            {
                text: 'Tip to also remember when multiplying another number by 4, the last two-digits of the product should be a multiple of 4, or divisible by 4.',
                type: 'text',
            },
            {
                text: 'From the above example, the last two-digits of the product 136 is 36. 36 is a multiple of 4 (9 x 4).',
                type: 'example',
            },
        ],
    },
    {
        heading: 'Handling 3',
        descriptions: [
            {
                text: 'This tip is more of a check to see if your answer is relatively correct. This is a trick to see if the number is divisible by 3, or a multiple of 3. Add the digits up until you get a single-digit number, and see if the number is a multiple of 3 (in other words 3, 6 or 9).',
                type: 'text',
            },
            {
                text: 'you figured out it was 81, but to make sure that it is correct you can add 8 and 1 together to get 9. 9 is a multiple of 3, therefore it is a multiple of 3.',
                type: 'example',
                expression: '3 x 27',
            }
        ],
    },
    {
        heading: 'Handling 2',
        descriptions: [
            {
                text: 'Multiplying a number by 2, simply double each of its individual digits.Carry over to the \'tens\' place from the \'ones\' place as needed.',
                type: 'text',
            },
            {
                text: 'double 2 and 8 and you get 4 and 18. So you would need to carry the 1 in 18 over to the \'tens\' place which will get you 5 and 8. The result is 58.',
                type: 'example',
                expression: '29 x 2',
            },
            {
                text: 'Tip to also remember when multiplying another number by 2, the product should always be an even number (or in other words ends in 2, 4, 6, 8, or 0).',
                type: 'text',
            },
            {
                text: 'From the above example, 58 is an even number.',
                type: 'example',
            },
        ],
    },
    {
        heading: 'Handling 1',
        descriptions: [
            {
                text: 'Multiplying a number by 1, will result in the other number.',
                type: 'text',
            },
            {
                text: 'will always get you the other number, or 39.',
                type: 'example',
                expression: '1 x 39',
            },
            {
                text: 'Dividing a number by one will also return you the other number.',
                type: 'text',
            },
            {
                text: 'will always get you the other number, or 54.',
                type: 'example',
                expression: '54 ÷ 1',
            },
        ],
    },
    {
        heading: 'Handling 0',
        descriptions: [
            {
                text: 'Multiplying a number by 0 will always result in 0.',
                type: 'text',
            },
            {
                text: 'will always result in 0.',
                type: 'example',
                expression: '34 x 0',
            },
            {
                text: 'Dividing 0 by any number greater than 0 will get you 0 as well.',
                type: 'text',
            },
            {
                text: 'will always result in 0.',
                type: 'example',
                expression: '0 ÷ 58',
            },
        ],
    },
];

export const tabData = (gameStarted) => [
    { label: 'Tips', value: 'tips', disabled: gameStarted },
    { label: 'Game', value: 'game' },
    { label: 'Report', value: 'report', disabled: gameStarted  },
];