import React from 'react';
import { useResource } from '../../../queries';

const UnitTableRow = ({ first, second }) => {
    return (
        <li className="cooking-table-row">
            <span className="cooking-table-column">{first}</span>
            <span className="cooking-table-column">{second}</span>
        </li>
    )
}

export const CookingUnits = () => {
    const { data: content = [] } = useResource({
        category: 'life-skills',
        id: 'cooking-units'
    });

    const getReduced = (arr) => arr.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
    }, {})

    const reducedUnits = getReduced(content?.units ?? []);
    const reducedConversions = getReduced(content?.conversions ?? []);

    return (
        <div className="learning-pages">
            <div className="section-container">
                <h5 className="section-heading">Cooking Units</h5>
                {Object.values(reducedUnits).map((category, categoryIndex) => (
                    <div key={categoryIndex}>
                        <h5 className="cooking-type">{category[0].type}</h5>
                        <ul className="cooking-table">
                            {category.map((item, index) => (
                                <UnitTableRow
                                    key={categoryIndex + '-' + index}
                                    first={item.unit}
                                    second={item.abbr}
                                />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <div className="section-container">
                <h5 className="section-heading">Temperatures</h5>
                <ul className="cooking-table">
                    {(content?.temperatures || []).map((item, index) => (
                        <UnitTableRow
                            key={index}
                            first={item.food}
                            second={item.point}
                        />
                    ))}
                </ul>
            </div>

            <div className="section-container">
                <h5 className="section-heading">Conversions</h5>
                {Object.values(reducedConversions).map((category, categoryIndex) => (
                    <div key={categoryIndex}>
                        <h5 className="cooking-type">{category[0].type}</h5>
                        <ul className="cooking-table">
                            {category.map((item, index) => (
                                <UnitTableRow
                                    key={categoryIndex + '-' + index}
                                    first={item.base}
                                    second={item.new}
                                />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <div className="section-container">
                <h5 className="section-heading">Additional</h5>
                <p>{(content?.additional || []).map((item) => (
                    <span key={item}>{item}</span>
                ))}</p>
            </div>
        </div>
    )
}