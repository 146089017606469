import React from 'react';
import { useGuitarChordsByNotes } from '../../../queries';

export const ChordMap = ({ note }) => {
    const { data: chords } = useGuitarChordsByNotes({ note });

    return (
        <div className="chord-diagram-containers flex-wrap">
            {chords?.map((chordInstance, chordId) => {
                let [first, second, third, fourth, fifth, sixth] = chordInstance.chord;
                let filled = <div className="filled"></div>
                let closed = 'X';
                let opened = <div className="opened"></div>
                let fretOne;
                let fretTwo;
                let fretThree;
                let fretFour;

                if (first > 4 || second > 4 || third > 4 || fourth > 4 || fifth > 4 || sixth > 4) {
                    let nonZeroNumbers = chordInstance.chord.filter(item => Number.isInteger(item) && item > 0);
                    fretFour = Math.max(...nonZeroNumbers);
                    fretThree = fretFour - 1;
                    fretTwo = fretThree - 1;
                    fretOne = fretTwo - 1;
                    return null;
                } else {
                    return (
                        <div key={chordId} id={chordInstance.name} className="chord-diagram flex-col align-ctr">
                            <h4 className="chord-name">{chordInstance.name}</h4>
                            <div className="fret-board">
                                <div className="string-1 flex p-rel">
                                    <div className="outside p-abs">{first === 'x' ? closed : first === 0 ? opened : null}</div>
                                    <div className="first">{(first === 1 /*|| first === fretOne*/) && filled}</div>
                                    <div className="second">{(first === 2 /*|| first === fretTwo*/) && filled}</div>
                                    <div className="third">{(first === 3 /*|| first === fretThree*/) && filled}</div>
                                    <div className="fourth">{(first === 4 /*|| first === fretFour*/) && filled}</div>
                                </div>
                                <div className="string-2 flex p-rel">
                                    <div className="outside p-abs">{second === 'x' ? closed : second === 0 ? opened : null}</div>
                                    <div className="first">{(second === 1 /*|| second === fretOne*/) && filled}</div>
                                    <div className="second">{(second === 2 /*|| second === fretTwo*/) && filled}</div>
                                    <div className="third">{(second === 3 /*|| second === fretThree*/) && filled}</div>
                                    <div className="fourth">{(second === 4 /*|| second === fretFour*/) && filled}</div>
                                </div>
                                <div className="string-3 flex p-rel">
                                    <div className="outside p-abs">{third === 'x' ? closed : third === 0 ? opened : null}</div>
                                    <div className="first">{(third === 1 /*|| third === fretOne*/) && filled}</div>
                                    <div className="second">{(third === 2 /*|| third === fretTwo*/) && filled}</div>
                                    <div className="third">{(third === 3 /*|| third === fretThree*/) && filled}</div>
                                    <div className="fourth">{(third === 4 /*|| third === fretFour*/) && filled}</div>
                                </div>
                                <div className="string-4 flex p-rel">
                                    <div className="outside p-abs">{fourth === 'x' ? closed : fourth === 0 ? opened : null}</div>
                                    <div className="first">{(fourth === 1 /*|| (fourth === fretOne*/) && filled}</div>
                                    <div className="second">{(fourth === 2 /*|| fourth === fretTwo*/) && filled}</div>
                                    <div className="third">{(fourth === 3 /*|| fourth === fretThree*/) && filled}</div>
                                    <div className="fourth">{(fourth === 4 /*|| fourth === fretFour*/) && filled}</div>
                                </div>
                                <div className="string-5 flex p-rel">
                                    <div className="outside p-abs">{fifth === 'x' ? closed : fifth === 0 ? opened : null}</div>
                                    <div className="first">{(fifth === 1 /*|| fifth === fretOne*/) && filled}</div>
                                    <div className="second">{(fifth === 2 /*|| fifth === fretTwo*/) && filled}</div>
                                    <div className="third">{(fifth === 3 /*|| fifth === fretThree*/) && filled}</div>
                                    <div className="fourth">{(fifth === 4 /*|| fifth === fretFour*/) && filled}</div>
                                </div>
                                <div className="string-6 flex p-rel">
                                    <div className="outside p-abs">{sixth === 'x' ? closed : sixth === 0 ? opened : null}</div>
                                    <div className="first p-rel">{(sixth === 1 /*|| sixth === fretOne*/) && filled}{fretOne &&
                                        <div className="fret-num p-abs">{fretOne}</div>}
                                    </div>
                                    <div className="second p-rel">{(sixth === 2 /*|| sixth === fretTwo*/) && filled}{fretTwo &&
                                        <div className="fret-num p-abs">{fretTwo}</div>}
                                    </div>
                                    <div className="third p-rel">{(sixth === 3 /*|| sixth === fretThree*/) && filled}{fretThree &&
                                        <div className="fret-num p-abs">{fretThree}</div>}
                                    </div>
                                    <div className="fourth p-rel">{(sixth === 4 /*|| sixth === fretFour*/) && filled}{fretFour &&
                                        <div className="fret-num p-abs">{fretFour}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}