import React from 'react';
import { Breadcrumb, FlippingButton } from '../components';
import {
    Battleship,
    Blackjack,
    CardDisplay,
    Checkers,
    Codebreaker,
    Connect4,
    DadJokes,
    Jokes,
    LogicPuzzles,
    Mancala,
    Mathlympics,
    NumberRoyale,
    Rebus,
    Riddles,
    SecretNumber,
    Sinker,
    Slider,
    TicTacToe,
    War,
    Wordle
} from './online-games';
import { useAsset } from '../../queries';

export const OnlineProduct = ({ history, ...product }) => {
    const { data: arrowLogo, isFetched: arrowIsFetched } = useAsset({
        folder: 'logo',
        name: 'arrow.png',
    })

    const handleScroll = () => {
        const size = window.screen.width < 600 ? 85 : 95;
        const { top } = document.querySelector('.instructions-container').getBoundingClientRect();
        document.querySelector('.page').scrollTo({
            top: top - size,
            behavior: 'smooth',
        });
    }

    return (
        <>
            <div className="top-page-container">
                <Breadcrumb type="product" />
                {product?.instructions && (
                    <div className="direction-btn-container">
                        <FlippingButton
                            buttonText="Read Direction"
                            color="blue"
                            onClick={handleScroll}
                            width={200}
                        />
                        <button className='mobile-btn' onClick={handleScroll}>
                            {arrowIsFetched ? <img className="arrow-logo" src={arrowLogo} alt="arrow" /> : <div className="arrow-logo"></div>}
                        </button>
                    </div>
                )}
            </div>
            <div className="online-product-details">
                {product.name === 'Battleship' && <Battleship {...product} />}
                {product.name === 'Blackjack' && <Blackjack {...product} />}
                {product.name === 'Card Display' && <CardDisplay {...product} />}
                {product.name === 'Checkers' && <Checkers {...product} />}
                {product.name === 'Codebreaker' && <Codebreaker {...product} />}
                {product.name === 'Connect 4' && <Connect4 {...product} />}
                {product.name === 'Dad Jokes' && <DadJokes {...product} />}
                {product.name === 'Jokes' && <Jokes {...product} />}
                {product.name === 'Logic Puzzles' && <LogicPuzzles {...product} />}
                {product.name === 'Mancala' && <Mancala {...product} />}
                {product.name === 'Mathlympics' && <Mathlympics {...product} />}
                {product.name === 'Number Royale' && <NumberRoyale {...product} />}
                {product.name === 'Rebus' && <Rebus {...product} />}
                {product.name === 'Riddles' && <Riddles {...product} />}
                {product.name === 'Secret Number' && <SecretNumber {...product} />}
                {product.name === 'Sinker' && <Sinker {...product} />}
                {product.name === 'Slider' && <Slider {...product} history={history} />}
                {product.name === 'Tic Tac Toe' && <TicTacToe {...product} />}
                {/* {product.name === 'Trivia' && <Trivia {...product} />} */}
                {product.name === 'War' && <War {...product} />}
                {product.name === 'Wordle' && <Wordle {...product} />}
            </div>
        </>
    );
}