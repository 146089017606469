export const trebleNotes = [
    { name: 'C', value: 'middle-c' },
    { name: 'D', value: 'low-d' },
    { name: 'E', value: 'e' },
    { name: 'F', value: 'f' },
    { name: 'G', value: 'g' },
    { name: 'A', value: 'a' },
    { name: 'B', value: 'b' },
    { name: 'C', value: 'c' },
    { name: 'D', value: 'd' },
    { name: 'E', value: 'high-e' },
    { name: 'F', value: 'high-f' },
];

export const bassNotes = [
    { name: 'G', value: 'low-g' },
    { name: 'A', value: 'low-a' },
    { name: 'B', value: 'low-b' },
    { name: 'C', value: 'bass-c' },
    { name: 'D', value: 'bass-d' },
    { name: 'E', value: 'bass-e' },
    { name: 'F', value: 'bass-f' },
    { name: 'G', value: 'bass-g' },
    { name: 'A', value: 'bass-a' },
    { name: 'B', value: 'bass-b' },
    { name: 'C', value: 'bass-middle-c' },
];