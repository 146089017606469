export const randomize = (arr, returnedNum = arr.length) => {
    for (var i = 0; i < arr.length; i++) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }

    return arr.slice(0, returnedNum);
}

export const initializeBoard = ({ rows, columns, value = '' }) => {
    const board = [];
    const row = Array(columns).fill(value);
    for (let i = 0; i < rows; i++) {
        board.push(row);
    }
    return board;
}

export const randomNumber = (max, min = 0, floored) => floored ? Math.floor(Math.random() * (max - min) + min) : Math.ceil(Math.random() * (max - min) + min);

/*
    @param {number} min inclusive
    @param {number} max inclusive
*/
export const generateNumbersArr = (min, max) => {
    const numbers = [];
    for (let i = min; i <= max; i++) {
        numbers.push(i);
    }
    return numbers;
}
