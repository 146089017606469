import React from 'react';
import { cloneDeep } from 'lodash';
import { PositionNumberTag } from '../../../components/PositionNumberTag';
import { numberPosition } from '../../../../utils';

export const ScoreSheet = ({ room, setRoom, setSelectedTab }) => {
    const {
        players = [],
        scores = [],
        rounds,
        isMost,
        bonusPerRound = false,
        bonusPerRoundScores = [],
        finalBonus,
        finalBonusScores = [],
    } = room;

    const updateScore = (value, roundIndex, playerIndex) => {
        const scoresCopy = [...cloneDeep(scores)];
        if (!scoresCopy[roundIndex]) scoresCopy[roundIndex] = [];
        scoresCopy[roundIndex][playerIndex] = value;
        setRoom({ ...room, scores: scoresCopy });
    }

    const updateBonusPerRoundScore = (value, roundIndex, playerIndex) => {
        const bonusPerRoundScoresCopy = [...cloneDeep(bonusPerRoundScores)];
        if (!bonusPerRoundScoresCopy[roundIndex]) bonusPerRoundScoresCopy[roundIndex] = [];
        bonusPerRoundScoresCopy[roundIndex][playerIndex] = value;
        setRoom({ ...room, bonusPerRoundScores: bonusPerRoundScoresCopy });
    }

    const updateFinalBonusScore = (value, index) => {
        const finalBonusScoresCopy = [...cloneDeep(finalBonusScores)];
        finalBonusScoresCopy[index] = value;
        setRoom({ ...room, finalBonusScores: finalBonusScoresCopy });
    }

    const getScoreRows = () => {
        const scoreRows = [];
        for (let i = 1; i <= rounds; i++) {
            scoreRows.push('#' + i);
            if (bonusPerRound) scoreRows.push('#' + i + ' Bonus');
        }
        return scoreRows;
    }

    const createScoreRows = () => {
        const scoreRows = getScoreRows();

        return scoreRows.map((row, rowIndex) => (
            <ul key={rowIndex} className="item-row score-row">
                <li className="item-round-number">{row}</li>
                {players.map((_player, playerIndex) => (
                    <li key={rowIndex + '-' + playerIndex} className='score'>
                        {bonusPerRound && row.toString().includes('B') ? (
                            <input
                                className="score-input"
                                type="number"
                                value={bonusPerRoundScores?.[Math.floor(rowIndex / 2)]?.[playerIndex]}
                                onChange={(e) => updateBonusPerRoundScore(e.target.value, Math.floor(rowIndex / 2), playerIndex)}
                            />
                        ) : (
                            <input
                                className="score-input"
                                type="number"
                                value={scores?.[bonusPerRound ? Math.ceil(rowIndex / 2) : rowIndex]?.[playerIndex]}
                                onChange={(e) => updateScore(e.target.value, bonusPerRound ? Math.ceil(rowIndex / 2) : rowIndex, playerIndex)}
                            />
                        )}
                    </li>
                ))}
            </ul>
        ))
    }

    const createFinalBonusScoreRows = () => (
        <ul className="item-row score-row">
            <li className="item-round-number">Final Bonus</li>
            {players.map((_player, playerIndex) => (
                <li key={'final-bonus-' + playerIndex} className='score'>
                    <input
                        className="score-input"
                        type="number"
                        value={finalBonusScores?.[playerIndex]}
                        onChange={(e) => updateFinalBonusScore(e.target.value, playerIndex)}
                    />
                </li>
            ))}
        </ul>
    )

    const getTotalScores = () => {
        const totalScores = [];
        for (let i = 0; i < players.length; i++) {
            const roundsTotal = scores.flatMap(round => Number(round?.[i]) || 0).reduce((acc, curr) => acc + curr, 0);
            const bonusPerRoundTotal = bonusPerRoundScores.flatMap(round => Number(round?.[i]) || 0).reduce((acc, curr) => acc + curr, 0);
            const finalBonusTotal = Number(finalBonusScores?.[i]) || 0;
            totalScores.push(roundsTotal + bonusPerRoundTotal + finalBonusTotal);
        }
        return totalScores;
    }
    const totalScores = getTotalScores();

    const maxScore = Math.max(...totalScores);
    const minScore = Math.min(...totalScores);

    const winner = isMost ? maxScore : minScore;
    const sortedPositions = [...new Set([...totalScores].sort((a, b) => isMost ? b - a : a - b))];

    const createFinalScoreRow = () => (
        <ul className="item-row score-row">
            <li className="item-round-number">Total</li>
            {totalScores.map((total, index) => (
                <li key={index} className={`score ${winner === total ? 'winner' : ''}`}>
                    {total}
                </li>
            ))}
        </ul>
    )

    return (
        <div className="score-sheet-container tab-section-container">
            <div className="score-sheet-header">
                <strong>Score Sheet:</strong>
                <button
                    className="default-btn"
                    onClick={() => {
                        setRoom({ ...room, scores: [], bonusPerRoundScores: [], finalBonusScores: [] });
                        setSelectedTab('details');
                        setTimeout(() => {
                            setSelectedTab('score');
                        }, 0);
                    }}
                >
                    Reset
                </button>
            </div>
            <div className="score-sheet-table">
                <ul className="item-row player-row">
                    <div className="empty-square"></div>
                    {players.map((player, index) => (
                        <li key={index} className="player-name">
                            {sortedPositions.find(num => num > 0) ?
                                <PositionNumberTag
                                    position={sortedPositions.findIndex(num => totalScores[index] === num) + 1}
                                /> : null}
                            {player}
                        </li>
                    ))}
                </ul>
                {createScoreRows()}
                {finalBonus && createFinalBonusScoreRows()}
                {players.length ? createFinalScoreRow() : null}
            </div>

            <div className="score-sheet-summary">
                <div className="score-sheet-summary-header">
                    <strong>Summary:</strong>
                </div>

                <div className="score-sheet-summary-table-heading">
                    <span>Position</span>
                    <span>Points</span>
                </div>

                {sortedPositions.map((position, positionIdx) => {
                    const indices = [];
                    totalScores.filter((num, index) => {
                        if (num === position) {
                            indices.push(index);
                        }
                        return num === position
                    });

                    return (
                        <div key={position} className="score-sheet-summary-row">
                            <div className="score-sheet-left-side">
                                <PositionNumberTag position={positionIdx + 1} />
                                <span className="score-sheet-summary-players">{indices.map(index => players[index]).join(', ')}</span>
                            </div>
                            <span className={`score-sheet-summary-total position-number-tag ${numberPosition(positionIdx + 1)}`}>{position}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}