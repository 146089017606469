import React, { useEffect } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import { DesktopHeader, MobileHeader, SlideOut } from '.';

export const Navigation = ({ history, isTransparent }) => {
    const matches = history.location.pathname;
    const isMain = useRouteMatch("/");
    const isGames = matches.includes("/products/games");
    const isBooks = matches.includes("/products/books");
    const isBlogs = matches.includes("/blogs");
    const isResources = useRouteMatch("/resources");
    const isMusic = matches.includes("/music");
    const isAbout = useRouteMatch("/about");
    const isContact = useRouteMatch("/contact");

    const routeObj = {
        main: isMain?.isExact,
        games: isGames,
        books: isBooks,
        blogs: isBlogs,
        resources: isResources,
        music: isMusic,
        about: isAbout?.isExact,
        contact: isContact?.isExact,
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            const screenWidth = window.innerWidth;
            if (document.querySelector('.overlay.show') && screenWidth > 850) {
                document.getElementById('slide-out').classList.remove('opened');
                setTimeout(() => {
                    document.querySelector('#root').style.height = 'unset';
                    document.querySelector('#root').style.overflowY = 'unset';
                    document.querySelector('#root').style.position = 'unset';
                    document.querySelector('.overlay').classList.remove('show');
                }, 500);
            }
        })
    }, [])

    return (
        <>
            <DesktopHeader routeObj={routeObj} isTransparent={isTransparent} />
            <MobileHeader routeObj={routeObj} isTransparent={isTransparent} />
            <SlideOut routeObj={routeObj} history={history} />
        </>
    )
}

export default withRouter(Navigation)