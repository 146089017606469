import React, { useEffect, useState } from 'react';
import { useUsername } from '../../../../hooks';
import { ACTIVE_GAME_LOCAL_STORAGE_KEYS, getDecryptedValue, setEncryptedValue } from '../../../../utils';
import { MODES, socket, SOCKET_CLIENT_EVENTS, USER_STATUSES, WaitingRoom } from '../WaitingRoom';
import { Board } from './Board';

const initialBoard = [
    4, 4, 4, 4, 4, 4, 0, 4, 4, 4, 4, 4, 4, 0
];

export const Mancala = ({ ...product }) => {
    const [mode, setMode] = useState();
    const [activeGame, setActiveGame] = useState();
    const { getUsername } = useUsername();
    const username = getUsername() ?? '';
    const opponent = activeGame?.players?.find(player => player !== username);
    const players = [username, opponent].sort((a, b) => a.localeCompare(b));
    const first = players?.[0];
    const second = players?.[1];

    const loadData = () => {
        if (getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.MANCALA)) {
            const activeGame = JSON.parse(getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.MANCALA));
            setActiveGame(activeGame);
            setMode(MODES.IN_PLAY);
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_BUSY });
        } else {
            setMode(MODES.WAITING);
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_AVAILABLE });
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [mode]);

    const startGame = () => {
        setMode(MODES.IN_PLAY);
    }

    const onGameEnd = (data) => {
        setTimeout(() => {
            const newData = {
                ...data,
                board: initialBoard,
            }
            setActiveGame(newData);
            setEncryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.MANCALA, JSON.stringify(newData));
        }, 3000);
    }

    return (
        <section className="mancala-board">
            <WaitingRoom
                mode={mode}
                setMode={setMode}
                product={product}
                startGame={startGame}
                activeGame={activeGame}
                setActiveGame={setActiveGame}
                activeGameLocalStorageKey={ACTIVE_GAME_LOCAL_STORAGE_KEYS.MANCALA}
                onGameEnd={onGameEnd}
                additionalGameData={{ board: initialBoard }}
            />
            {activeGame?.board && (
                <Board
                    home={first}
                    away={second}
                    activeGame={activeGame ?? { board: initialBoard }}
                    setActiveGame={setActiveGame}
                    username={username}
                    opponent={opponent}
                />
            )}
        </section>
    )
}

// class Mancala extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             gems: [4, 4, 4, 4, 4, 4, 0, 4, 4, 4, 4, 4, 4, 0],
//             turn: 0,
//             user: props.user.id,
//             player1: 0,
//             player2: 0,
//             home: 6,
//             oppHome: 13,
//             room: '',
//             secondTurn: false,
//             shouldCont: true,
//             showModal: false,
//             showInstructions: false,
//             username1: '',
//             username2: '',
//             gameName: 'mancala',
//             leftAfter: false
//         }
//     }

//     componentDidMount() {
//         if (this.state.gameName === 'mancala') {
//             socket.on('joined game', async data => {
//                 this.handleTurnDisplay();
//             });

//             socket.on('mancala winner announced', async data => {
//                 await this.setState({ ...data, user: this.props.user.id });
//                 if (this.state.gems[6] > this.state.gems[13]) {
//                     this.openModal(`${this.state.username1} Wins!`);
//                 } else if (this.state.gems[6] < this.state.gems[13]) {
//                     this.openModal(`${this.state.username2} Wins!`);
//                 } else {
//                     this.openModal('It\'s a tie!');
//                 }
//             });
//         }
//     }

//     handleTurnDisplay() {
//         const { turn, player1, player2, user } = this.state;
//         if (player1 === turn) {
//             if (user === turn) {
//                 for (let i = 1; i < 7; i++) {
//                     if (document.querySelector(`#pocket-${i}`)) {
//                         document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//                         document.querySelector(`#pocket-${i}`).classList.add('fade-blue');
//                         document.querySelector(`#pocket-${i}`).classList.add('pointer');
//                         document.querySelector(`#pocket-${i} .stone-marble`).removeAttribute('disabled');
//                         if (this.state.gems[i - 1] === 0) {
//                             document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//                             document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                             document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//                         }
//                     }
//                 }
//                 for (let i = 7; i < 13; i++) {
//                     if (document.querySelector(`#pocket-${i}`)) {
//                         document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//                         document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                         document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//                     }
//                 }
//             } else {
//                 for (let i = 1; i < 13; i++) {
//                     if (i < 7) {
//                         if (document.querySelector(`#pocket-${i}`)) {
//                             document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//                             document.querySelector(`#pocket-${i}`).classList.add('fade-blue');
//                             if (this.state.gems[i - 1] === 0) {
//                                 document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//                                 document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                             }
//                         }
//                     } else if (i > 6) {
//                         if (document.querySelector(`#pocket-${i}`)) {
//                             document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//                         }
//                     }
//                     if (document.querySelector(`#pocket-${i}`)) {
//                         document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                         document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//                     }
//                 }
//             }
//         } else if (player2 === turn) {
//             if (user === turn) {
//                 for (let i = 1; i < 7; i++) {
//                     if (document.querySelector(`#pocket-${i}`)) {
//                         document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//                         document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                         document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//                     }
//                 }
//                 for (let i = 7; i < 13; i++) {
//                     if (document.querySelector(`#pocket-${i}`)) {
//                         document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//                         document.querySelector(`#pocket-${i}`).classList.add('fade-green');
//                         document.querySelector(`#pocket-${i}`).classList.add('pointer');
//                         document.querySelector(`#pocket-${i} .stone-marble`).removeAttribute('disabled');
//                         if (this.state.gems[i] === 0) {
//                             document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//                             document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                             document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//                         }
//                     }
//                 }
//             } else {
//                 for (let i = 1; i < 13; i++) {
//                     if (i < 7) {
//                         if (document.querySelector(`#pocket-${i}`)) {
//                             document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//                         }
//                     } else if (i > 6) {
//                         if (document.querySelector(`#pocket-${i}`)) {
//                             document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//                             document.querySelector(`#pocket-${i}`).classList.add('fade-green');
//                             if (this.state.gems[i] === 0) {
//                                 document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//                                 document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                             }
//                         }
//                     }
//                     if (document.querySelector(`#pocket-${i}`)) {
//                         document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//                         document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//                     }
//                 }
//             }
//         }
//     }

//     removeColor() {
//         for (let i = 1; i < 7; i++) {
//             document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', true);
//             document.querySelector(`#pocket-${i}`).classList.remove('fade-blue');
//             document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//         }
//         for (let i = 7; i < 13; i++) {
//             document.querySelector(`#pocket-${i} .stone-marble`).setAttribute('disabled', 'true');
//             document.querySelector(`#pocket-${i}`).classList.remove('fade-green');
//             document.querySelector(`#pocket-${i}`).classList.remove('pointer');
//         }
//     }

//     async turn(indexNum) {
//         let gems = [...this.state.gems];
//         let pocketCount = gems[indexNum];
//         await this.setHome();
//         gems = await this.handleIncrement(gems, pocketCount, indexNum);
//         await this.setState({ gems });
//         await this.checkWinner(gems);
//         await this.handleTurnDisplay();
//         let data = { ...this.state };
//         delete data.showInstructions;
//         await socket.emit('handle turn', data);
//     }

//     async handleIncrement(pockets, pocketCount, indexNum) {
//         let copy = [...pockets];
//         let { oppHome, home } = this.state;
//         pockets.forEach(async (pocket, index) => {
//             if (!this.state.secondTurn && this.state.shouldCont) {
//                 if (index === indexNum) {
//                     copy[index] = 0;
//                 } else if (index === oppHome || index < indexNum) {
//                     return;
//                 } else if (index > indexNum && pocketCount > 0 && index !== home) {
//                     copy[index] = pocket + 1;
//                     pocketCount -= 1;
//                     if (pocketCount === 0 && copy[index] < 2) {
//                         await this.setState({ shouldCont: false });
//                         if ((home === 6 && index < home) || (home === 13 && oppHome < index && index < home)) {
//                             copy = await this.handleCapture(copy, index, home);
//                         }
//                     } else if (pocketCount === 0) {
//                         pocketCount = copy[index];
//                         copy[index] = 0;
//                     }
//                 } else if (index === home && pocketCount > 0) {
//                     copy[index] = pocket + 1;
//                     pocketCount -= 1;
//                     if (pocketCount === 0) {
//                         await this.setState({ secondTurn: true });
//                         await this.handleTurnDisplay();
//                     }
//                 } else if (index > indexNum && pocketCount === 0 && index - 1 !== home) {
//                     copy[index] += 1;
//                     pocketCount -= 1;
//                 }
//             }
//         });

//         if (!this.state.secondTurn) {
//             if (pocketCount > 0) {
//                 await this.setState({ shouldCont: true });
//                 return this.handleIncrement(copy, pocketCount, -1);
//             } else {
//                 const { turn, player1, player2 } = this.state;
//                 await this.setState({ turn: turn === player1 ? player2 : player1, shouldCont: true });
//                 return copy;
//             }
//         } else {
//             await this.setState({ secondTurn: false, shouldCont: true });
//             return copy;
//         }
//     }

//     handleCapture(gems, index, home) {
//         let capturedGems;
//         if (index === 0 || index === 12) {
//             capturedGems = gems[0] + gems[12];
//             gems[0] = 0;
//             gems[12] = 0;
//         } else if (index === 1 || index === 11) {
//             capturedGems = gems[1] + gems[11];
//             gems[1] = 0;
//             gems[11] = 0;
//         } else if (index === 2 || index === 10) {
//             capturedGems = gems[2] + gems[10];
//             gems[2] = 0;
//             gems[10] = 0;
//         } else if (index === 3 || index === 9) {
//             capturedGems = gems[3] + gems[9];
//             gems[3] = 0;
//             gems[9] = 0;
//         } else if (index === 4 || index === 8) {
//             capturedGems = gems[4] + gems[8];
//             gems[4] = 0;
//             gems[8] = 0;
//         } else if (index === 5 || index === 7) {
//             capturedGems = gems[5] + gems[7];
//             gems[5] = 0;
//             gems[7] = 0;
//         }
//         gems[home] += capturedGems;
//         return gems;
//     }

//     async checkWinner(gems) {
//         if ((gems[0] === 0 && gems[1] === 0 && gems[2] === 0 && gems[3] === 0 && gems[4] === 0 && gems[5] === 0) || (gems[7] === 0 && gems[8] === 0 && gems[9] === 0 && gems[10] === 0 && gems[11] === 0 && gems[12] === 0)) {
//             let captured1 = gems[0] + gems[1] + gems[2] + gems[3] + gems[4] + gems[5];
//             let captured2 = gems[7] + gems[8] + gems[9] + gems[10] + gems[11] + gems[12];
//             gems[6] += captured1;
//             gems[13] += captured2;
//             gems = [0, 0, 0, 0, 0, 0, gems[6], 0, 0, 0, 0, 0, 0, gems[13]];
//             await this.setState({ gems, showModal: true });
//             socket.emit('announce winner', this.state);
//         }
//     }


//     closeModal = async () => {
//         await this.removeColor();
//         await this.reset();
//     }

//     render() {
//         let { showInstructions, showModal, player1, player2, turn } = this.state;
//         let [pocket1, pocket2, pocket3, pocket4, pocket5, pocket6, homePocketLeft, pocket7, pocket8, pocket9, pocket10, pocket11, pocket12, homePocketRight] = this.state.gems;
//         // if (this.props.opp.room) {
//         return (
//             <div className="mancala game-wrapper">
//                 <div className="mancala-board">
//                     <div id="home-1" className="home-pocket left">
//                         <h2 className="player-text player-1-text player-1-text-a" style={{ visibility: turn === player1 ? 'visible' : 'hidden' }}>{this.state.username1}</h2>
//                         <h2 className="player-text player-1-text player-1-text-b" style={{ visibility: turn === player1 ? 'hidden' : 'visible' }}>{this.state.username1}</h2>
//                         <div className="stone-marble in-home blue">
//                             <h3>{homePocketLeft}</h3>
//                         </div>
//                     </div>
//                     <div className="outer-small-pocket-holder">
//                         <div className="top-pocket-holder">
//                             <div id="pocket-6" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-6 .stone-marble').getAttribute('disabled')) { this.turn(5) } }}
//                                     className="stone-marble orange">
//                                     <h3>{pocket6}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-5" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-5 .stone-marble').getAttribute('disabled')) { this.turn(4) } }}
//                                     className="stone-marble blue">
//                                     <h3>{pocket5}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-4" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-4 .stone-marble').getAttribute('disabled')) { this.turn(3) } }}
//                                     className="stone-marble orange">
//                                     <h3>{pocket4}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-3" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-3 .stone-marble').getAttribute('disabled')) { this.turn(2) } }}
//                                     className="stone-marble green">
//                                     <h3>{pocket3}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-2" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-2 .stone-marble').getAttribute('disabled')) { this.turn(1) } }}
//                                     className="stone-marble green">
//                                     <h3>{pocket2}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-1" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-1 .stone-marble').getAttribute('disabled')) { this.turn(0) } }}
//                                     className="stone-marble blue">
//                                     <h3>{pocket1}</h3>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="bottom-pocket-holder">
//                             <div id="pocket-7" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-7 .stone-marble').getAttribute('disabled')) { this.turn(7) } }}
//                                     className="stone-marble green">
//                                     <h3>{pocket7}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-8" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-8 .stone-marble').getAttribute('disabled')) { this.turn(8) } }}
//                                     className="stone-marble orange">
//                                     <h3>{pocket8}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-9" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-9 .stone-marble').getAttribute('disabled')) { this.turn(9) } }}
//                                     className="stone-marble blue">
//                                     <h3>{pocket9}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-10" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-10 .stone-marble').getAttribute('disabled')) { this.turn(10) } }}
//                                     className="stone-marble orange">
//                                     <h3>{pocket10}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-11" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-11 .stone-marble').getAttribute('disabled')) { this.turn(11) } }}
//                                     className="stone-marble blue">
//                                     <h3>{pocket11}</h3>
//                                 </div>
//                             </div>
//                             <div id="pocket-12" className="small-pocket">
//                                 <div onClick={() => { if (!document.querySelector('#pocket-12 .stone-marble').getAttribute('disabled')) { this.turn(12) } }}
//                                     className="stone-marble green">
//                                     <h3>{pocket12}</h3>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div id="home-2" className="home-pocket right">
//                         <h2 className="player-text player-2-text player-2-text-a" style={{ visibility: turn === player2 ? 'visible' : 'hidden' }}>{this.state.username2}</h2>
//                         <h2 className="player-text player-2-text player-2-text-b" style={{ visibility: turn === player2 ? 'hidden' : 'visible' }}>{this.state.username2}</h2>
//                         <div className="stone-marble in-home green">
//                             <h3>{homePocketRight}</h3>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
