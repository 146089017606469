// timestamp HH:MM:SS => milliseconds
export const convertTimeToNumber = (time) => {
    const hasTwoColons = time.indexOf(':') !== time.lastIndexOf(':');
    if (hasTwoColons) {
        const firstColonIndex = time.indexOf(':');
        const secondColonIndex = time.lastIndexOf(':');
        const hours = time.substring(0, firstColonIndex);
        const minutes = time.substring(firstColonIndex + 1, secondColonIndex);
        const seconds = time.substring(secondColonIndex + 1);
        return hours * 3600000 + minutes * 60000 + seconds * 1000;
    }

    const colonIndex = time.indexOf(':');
    const minutes = time.substring(0, colonIndex);
    const seconds = time.substring(colonIndex + 1);
    return minutes * 60000 + seconds * 1000;
}