import { cloneDeep } from 'lodash';
import { initializeBoard } from '../../../../utils';

export const DIRECTION_MAP = {
    DIAGONAL_TOP_LEFT: [-1, -1],
    DIAGONAL_TOP_RIGHT: [-1, 1],
    DIAGONAL_BOTTOM_LEFT: [1, -1],
    DIAGONAL_BOTTOM_RIGHT: [1, 1],
};

export const useCheckers = ({ board, color }) => {
    const getInitialCheckerboard = () => {
        const initialBoard = initializeBoard({ rows: 8, columns: 8, value: null });
        const newBoard = initialBoard.map((row, rowIndex) => {
            if (rowIndex < 3) {
                return row.map((column, columnIndex) => {
                    // even row
                    if (rowIndex % 2 === 0) {
                        if (columnIndex % 2 === 0) {
                            return { color: 'red', king: false };
                        }
                        return column;
                    } else {
                        // odd row
                        if (columnIndex % 2 !== 0) {
                            return { color: 'red', king: false };
                        }
                        return column;
                    }
                })
            } else if (rowIndex > 4) {
                return row.map((column, columnIndex) => {
                    // even row
                    if (rowIndex % 2 === 0) {
                        if (columnIndex % 2 === 0) {
                            return { color: 'black', king: false };
                        }
                        return column;
                    } else {
                        // odd row
                        if (columnIndex % 2 !== 0) {
                            return { color: 'black', king: false };
                        }
                        return column;
                    }
                })
            }
            return row;
        });

        return newBoard;
    }

    const getColoredPiecePositions = (newBoard, color) => {
        return newBoard.reduce((acc, row, rowIndex) => {
            if (!acc) acc = [];
            const newSquares = [];
            row.forEach((square, squareIndex) => {
                if (square?.color === color) {
                    newSquares.push([rowIndex, squareIndex]);
                }
            })
            return acc.concat(newSquares);
        }, [])
    }

    const checkIsNull = (row, column) => board?.[row]?.[column] === null;

    const checkIsOpponentPiece = (row, column) => {
        const piece = board?.[row]?.[column];
        return piece && piece.color !== color;
    }

    const getPieceDirections = (piece) => {
        if (piece.king) {
            return [
                DIRECTION_MAP.DIAGONAL_TOP_LEFT,
                DIRECTION_MAP.DIAGONAL_TOP_RIGHT,
                DIRECTION_MAP.DIAGONAL_BOTTOM_LEFT,
                DIRECTION_MAP.DIAGONAL_BOTTOM_RIGHT,
            ];
        }
        if (piece.color === 'red') {
            return [
                DIRECTION_MAP.DIAGONAL_BOTTOM_LEFT,
                DIRECTION_MAP.DIAGONAL_BOTTOM_RIGHT,
            ];
        }
        return [
            DIRECTION_MAP.DIAGONAL_TOP_LEFT,
            DIRECTION_MAP.DIAGONAL_TOP_RIGHT,
        ];
    }

    const checkPossibleJumpOptions = ({ position, isPieceCheck, options = [], piece = board[position?.[0]]?.[position?.[1]] }) => {
        const directions = getPieceDirections(piece);

        if (!directions.length) return options;

        directions.forEach(direction => {
            const [row, column] = position;
            if (checkIsOpponentPiece(row + direction[0], column + direction[1])) {
                const newRow = row + direction[0] * 2;
                const newColumn = column + direction[1] * 2;
                if (checkIsNull(newRow, newColumn)) {
                    if (isPieceCheck) {
                        options.push([row, column]);
                    } else {
                        if (options.length) {
                            const newestOption = cloneDeep(options);
                            const last = [options.at(-1)];
                            last.push([newRow, newColumn]);
                            newestOption.push(last);
                            options.push(newestOption);
                        } else {
                            options.push([newRow, newColumn]);
                        }
                        return checkPossibleJumpOptions({ position: [newRow, newColumn], isPieceCheck, options, piece });
                    }
                }
            }
        });

        return options;
    }

    const filterPieceSquareOptions = ({ position, isPieceCheck }) => {
        const directions = getPieceDirections(board[position[0]][position[1]]);

        // ENFORCE ONLY JUMP OPTIONS IF AVAILABLE
        const requiredJump = checkPossibleJumpOptions({ position, isPieceCheck });
        if (requiredJump.length > 0) return requiredJump;

        return directions.map(direction => {
            const [row, column] = position;
            if (checkIsNull(row + direction[0], column + direction[1])) {
                if (isPieceCheck) return [row, column];
                return [row + direction[0], column + direction[1]];
            }
            if (checkIsOpponentPiece(row + direction[0], column + direction[1], color)) {
                const newRow = row + direction[0] * 2;
                const newColumn = column + direction[1] * 2;
                if (checkIsNull(newRow, newColumn)) {
                    if (isPieceCheck) return [row, column];
                    return [newRow, newColumn];
                }
            }
            return null;
        }).filter(Boolean);
    }

    const checkKingMe = (piece, row) => {
        let isKing = piece.king;
        if (piece.color === 'red' && row === 7) {
            isKing = true;
        }
        if (piece.color === 'black' && row === 0) {
            isKing = true;
        }
        return isKing;
    }

    const checkBlocked = (positions) => positions.length === 0;

    const getMessage = ({ newBoard, isFirst, username, opponent, hasJumped, turn }) => {
        const redPositions = getColoredPiecePositions(newBoard, 'red');
        const blackPositions = getColoredPiecePositions(newBoard, 'black');
        
        const messages = {
            [username]: turn === username ? `${opponent}'s turn` : 'Your turn',
            [opponent]: turn === username ? 'Your turn' : `${opponent}'s turn`,
            finished: redPositions.length === 0 || blackPositions.length === 0,
        }
        
        if (hasJumped) {
            messages[username] = `Your opponent has ${isFirst === username ? redPositions.length : blackPositions.length} pieces left`;
            messages[opponent] = `You have ${isFirst === username ? redPositions.length : blackPositions.length} pieces left`;
        }

        return messages;
    }

    const checkGameEnd = (newBoard) => {
        const redPositions = getColoredPiecePositions(newBoard, 'red');
        const blackPositions = getColoredPiecePositions(newBoard, 'black');
        return {
            red: redPositions.length,
            black: blackPositions.length,
        };
    }

    return {
        getInitialCheckerboard,
        getColoredPiecePositions,
        filterPieceSquareOptions,
        checkPossibleJumpOptions,
        checkKingMe,
        checkBlocked,
        getMessage,
        checkGameEnd,
    }
}

