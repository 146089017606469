import React from 'react';

export const ChordTable = ({ 
    handleChordCreation,
    root,
    handleChordPitch,
    handleChordScale,
    handleChordType,
    chordPitch,
    chordScale,
}) => (
    <div className="table-holder chord-holder flex">
        <div className="table-section">
            <h3 className="table-heading">Root</h3>
            <div className="letter-table flex">
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="chord-a" type="radio" name="root" value="a" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-a">A</label>
                    </div>
                    <div className="radio-holder">
                        <input id="chord-b" type="radio" name="root" value="b" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-b">B</label>
                    </div>
                    <div className="radio-holder">
                        <input id="chord-c" type="radio" name="root" value="c" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-c">C</label>
                    </div>
                    <div className="radio-holder">
                        <input id="chord-d" type="radio" name="root" value="d" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-d">D</label>
                    </div>
                </div>
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="chord-e" type="radio" name="root" value="e" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-e">E</label>
                    </div>
                    <div className="radio-holder">
                        <input id="chord-f" type="radio" name="root" value="f" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-f">F</label>
                    </div>
                    <div className="radio-holder">
                        <input id="chord-g" type="radio" name="root" value="g" onChange={e => handleChordCreation(e.target.value)} /><label htmlFor="chord-g">G</label>
                    </div>
                </div>
            </div>
        </div>
        <div className="dividing-line"></div>
        <div className="table-section">
            <h3 className="table-heading">Pitch</h3>
            <div className="type-table flex">
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="chord-natural" type="radio" name="chord-pitch" onChange={e => handleChordPitch(e.target.value)} value="natural" defaultChecked /><label htmlFor="chord-natural">Natural (♮)</label>
                    </div>
                    {root !== 'b' && root !== 'e' &&
                        <div className="radio-holder">
                            <input id="chord-sharp" type="radio" name="chord-pitch" onChange={e => handleChordPitch(e.target.value)} value="sharp" /><label htmlFor="chord-sharp">Sharp (♯)</label>
                        </div>}
                    {root !== 'c' && root !== 'f' &&
                        <div className="radio-holder">
                            <input id="chord-flat" type="radio" name="chord-pitch" onChange={e => handleChordPitch(e.target.value)} value="flat" /><label htmlFor="chord-flat">Flat (♭)</label>
                        </div>}
                </div>
            </div>
        </div>
        <div className="dividing-line"></div>
        <div className="table-section">
            <h3 className="table-heading">Scale</h3>
            <div className="type-table flex">
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="chord-major" type="radio" name="scale" value="major" onChange={e => handleChordScale(e.target.value)} defaultChecked /><label htmlFor="chord-major">Major*</label>
                    </div>
                    <div className="radio-holder">
                        <input id="chord-minor" type="radio" name="scale" value="minor" onChange={e => handleChordScale(e.target.value)} /><label htmlFor="chord-minor">minor</label>
                    </div>
                </div>
            </div>
        </div>
        <div className="dividing-line mobile-hide"></div>
        <div className="mobile-show"></div>
        <div className="table-section">
            <h3 className="table-heading">Type</h3>
            <div className="type-table flex">
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="base" type="radio" name="type" value="base" onChange={e => handleChordType(e.target.value)} defaultChecked /><label htmlFor="base">Base*</label>
                    </div>
                    <div className="radio-holder">
                        <input id="fifth" type="radio" name="type" value="fifth" onChange={e => handleChordType(e.target.value)} /><label htmlFor="fifth">5th</label>
                    </div>
                    {!(root === 'g' && chordPitch === 'natural' && chordScale === 'minor') &&
                        <div className="radio-holder">
                            <input id="sixth" type="radio" name="type" value="sixth" onChange={e => handleChordType(e.target.value)} /><label htmlFor="sixth">6th</label>
                        </div>}
                    {!(root === 'a' && chordPitch === 'sharp' && chordScale === 'minor') && !(root === 'b' && chordPitch === 'flat' && chordScale === 'minor') &&
                        <div className="radio-holder">
                            <input id="seventh" type="radio" name="type" value="seventh" onChange={e => handleChordType(e.target.value)} /><label htmlFor="seventh">7th</label>
                        </div>}
                </div>
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="sus2" type="radio" name="type" value="sus2" onChange={e => handleChordType(e.target.value)} /><label htmlFor="sus2">Sus2</label>
                    </div>
                    <div className="radio-holder">
                        <input id="sus4" type="radio" name="type" value="sus4" onChange={e => handleChordType(e.target.value)} /><label htmlFor="sus4">Sus4</label>
                    </div>
                    <div className="radio-holder">
                        <input id="aug" type="radio" name="type" value="aug" onChange={e => handleChordType(e.target.value)} /><label htmlFor="aug">Aug</label>
                    </div>
                    <div className="radio-holder">
                        <input id="dim" type="radio" name="type" value="dim" onChange={e => handleChordType(e.target.value)} /><label htmlFor="dim">Dim</label>
                    </div>
                </div>
                <div className="column-section flex-col">
                    {!(chordPitch === 'flat' && chordScale === 'minor') && !(chordPitch === 'sharp' && chordScale === 'minor') &&
                        <div className="radio-holder">
                            <input id="add2" type="radio" name="type" value="add2" onChange={e => handleChordType(e.target.value)} /><label htmlFor="add2">Add2</label>
                        </div>}
                    {!(chordPitch === 'flat' && chordScale === 'minor') && !(chordPitch === 'sharp' && chordScale === 'minor') &&
                        <div className="radio-holder">
                            <input id="add9" type="radio" name="type" value="add9" onChange={e => handleChordType(e.target.value)} /><label htmlFor="add9">Add9</label>
                        </div>}
                    {!(chordPitch === 'natural' && chordScale === 'minor') && !(chordPitch === 'flat' && chordScale === 'minor') && !(chordPitch === 'sharp' && chordScale === 'minor') &&
                        <div className="radio-holder">
                            <input id="maj7" type="radio" name="type" value="maj7" onChange={e => handleChordType(e.target.value)} /><label htmlFor="maj7">Maj7</label>
                        </div>}
                    {/* <div className="radio-holder">
                                        <input id="slash" type="radio" name="type" value="slash" onChange={e => handleChordType(e.target.value)} /><label htmlFor="slash">Slash</label>
                                    </div> */}
                </div>
            </div>
        </div>
    </div>
)