import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Breadcrumb, FeedbackForm, Footer, Loader } from '../components';
import {
    Algebra,
    Astrology,
    Astronomy,
    Chemistry,
    ChineseZodiac,
    Coding,
    CookingDash,
    CookingTips,
    CookingUnits,
    Nutrition,
    Spices,
    WasteConservation,
} from '../resource-page';
import { useLoader } from '../../hooks/use-loader';

export const Resource = ({
    checkHeight,
    match,
    location,
    history,
}) => {
    const { id } = match.params;
    const pathname = location.pathname;
    const [isReady, setIsReady] = useState(false);

    const displayResource = () => {
        if (id === 'algebra') return <Algebra />;
        else if (id === 'astrology') return <Astrology />;
        else if (id === 'astronomy') return <Astronomy />;
        else if (id === 'chemistry') return <Chemistry />;
        else if (id === 'chinese-zodiac') return <ChineseZodiac />;
        else if (id === 'coding') return <Coding />;
        else if (id === 'cooking' && pathname === '/resources/life-skills/cooking/cooking-tips') return <CookingTips />;
        else if (id === 'cooking' && pathname === '/resources/life-skills/cooking/cooking-units') return <CookingUnits />;
        else if (id === 'cooking' && pathname === '/resources/life-skills/cooking/spices') return <Spices />;
        else if (id === 'cooking') return <CookingDash history={history} />;
        else if (id === 'nutrition') return <Nutrition />;
        else if (id === 'waste-conservation') return <WasteConservation />;
        return <Redirect to="/resources" />;
    }

    useEffect(() => {
        setTimeout(() => {
            setIsReady(true);
        }, 50);
    }, [])

    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />

    return (
        <div id="resource-details-page" className="resource page" onScroll={() => checkHeight('.breadcrumbs')}>
            <div className="container">
                <Breadcrumb type="resource" />
                <div className="resource-container">
                    {displayResource()}
                </div>
            </div>
            <FeedbackForm product={'resource-' + id} type="resource" />
            <Footer />
        </div>
    );
}
