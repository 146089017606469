import React from 'react';
import { DIFFICULTY_LEVEL } from './index';
import { toTitleCase } from '../../../../utils';

export const Levels = ({ difficulty, setDifficulty, disabled }) => {
    return (
        <div className="difficulty-container">
            <h4>Difficulty Level</h4>
            <div className="options">
                {Object.values(DIFFICULTY_LEVEL).map(level => (
                    <span
                        key={level}
                        className={`option three purple ${difficulty === level ? 'selected' : ''}`}
                        onClick={() => {
                            if (disabled) return;
                            setDifficulty(level)
                        }}
                    >
                        {toTitleCase(level)}
                    </span>
                ))}
            </div>
        </div>
    )
}