import React from 'react';
import { useRouteMatch } from "react-router-dom";
import { useAsset } from '../../../queries';

export const SlideOut = ({ routeObj, history }) => {
    const { data: gameLogo, isFetched: gameIsFetched } = useAsset({
        folder: 'logo',
        name: 'game-logo.png'
    });

    const { data: contactLogo, isFetched: contactIsFetched } = useAsset({
        folder: 'logo',
        name: 'contact-logo.png'
    });

    const { data: blogLogo, isFetched: blogIsFetched } = useAsset({
        folder: 'logo',
        name: 'blog-logo.png'
    });

    const { data: aboutLogo, isFetched: aboutIsFetched } = useAsset({
        folder: 'logo',
        name: 'about-logo.png'
    });

    // const { data: bookLogo, isFetched: bookIsFetched } = useAsset({
    //     folder: 'logo',
    //     name: 'book-logo.png'
    // });

    const { data: musicLogo, isFetched: musicIsFetched } = useAsset({
        folder: 'logo',
        name: 'music-logo.png'
    });

    const { data: resourceLogo, isFetched: resourceIsFetched } = useAsset({
        folder: 'logo',
        name: 'craft-logo.png'
    });

    const { data: closeLogo, isFetched: closeIsFetched } = useAsset({
        folder: 'logo',
        name: 'x.png'
    })

    const isGamesExact = useRouteMatch("/products/games")?.isExact;
    // const isBooksExact = useRouteMatch("/products/books")?.isExact;
    const isBlogsExact = useRouteMatch("/blogs")?.isExact;
    const isResourcesExact = useRouteMatch("/resources")?.isExact;

    const handleClose = () => {
        document.getElementById('slide-out').classList.remove('opened');
        document.getElementById('slide-out').classList.add('closed');
        setTimeout(() => {
            document.querySelector('#root').style.height = 'unset';
            document.querySelector('#root').style.overflowY = 'unset';
            document.querySelector('#root').style.position = 'unset';
            document.querySelector('.overlay').classList.remove('show');
        }, 500);
    }

    const handleRouting = (path, isPath) => {
        if (!isPath) {
            history.push(path);
        }
        handleClose();
    }

    const linkPlaceholder = <div className="img-placeholder"></div>

    return (
        <>
            <div className="overlay" onClick={handleClose}></div>
            <div id="slide-out" className="slide-out">
                <button onClick={handleClose} className="close-btn">
                    {closeIsFetched ? <img src={closeLogo} alt="close-button" className="close-btn-logo" /> : <div className="close-btn"></div>}
                </button>
                <ul className="route-list">
                    <li onClick={() => handleRouting('/products/games', isGamesExact)} className={`${routeObj.games ? "match-route" : ""}`}>
                        {gameIsFetched ? <img className="game-logo" src={gameLogo} alt="game logo" /> : linkPlaceholder}
                        <span>Games</span>
                    </li>
                    {/* 
                        <li onClick={() => handleRouting('/products/books', isBooksExact)} className={`${routeObj.books ? "match-route" : ""}`}>
                            {bookIsFetched ? <img className="book-logo" src={bookLogo} alt="book logo" />: linkPlaceholder}
                            <span>Books</span>
                        </li> 
                    */}
                    <li onClick={() => handleRouting('/music', routeObj.music)} className={`${routeObj.music ? "match-route" : ""}`}>
                        {musicIsFetched ? <img className="music-logo" src={musicLogo} alt="music logo" /> : linkPlaceholder}
                        <span>Music</span>
                    </li>
                    <li onClick={() => handleRouting('/resources', isResourcesExact)} className={`${routeObj.resources ? "match-route" : ""}`}>
                        {resourceIsFetched ? <img className="resource-logo" src={resourceLogo} alt="resource logo" /> : linkPlaceholder}
                        <span>Resources</span>
                    </li>
                    <li onClick={() => handleRouting('/blogs', isBlogsExact)} className={`${routeObj.blogs ? "match-route" : ""}`}>
                        {blogIsFetched ? <img className="blog-logo" src={blogLogo} alt="blog logo" /> : linkPlaceholder}
                        <span>Blogs</span>
                    </li>
                    <li onClick={() => handleRouting('/about', routeObj.about)} className={`${routeObj.about ? "match-route" : ""}`}>
                        {aboutIsFetched ? <img className="about-logo" src={aboutLogo} alt="about logo" /> : linkPlaceholder}
                        <span>About</span>
                    </li>
                    <li onClick={() => handleRouting('/contact', routeObj.contact)} className={`${routeObj.contact ? "match-route" : ""}`}>
                        {contactIsFetched ? <img className="contact-logo" src={contactLogo} alt="contact logo" /> : linkPlaceholder}
                        <span>Contact Us</span>
                    </li>
                </ul>
            </div>
        </>
    )
}