import React from 'react';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { formatPhoneNumber } from '../../utils';
import { useAsset } from '../../queries';

export const Copy = ({ text, type, icon }) => {
    const [isCopied, setIsCopied] = useState(false);
    const displayedValue = type === 'phone' ? formatPhoneNumber(text) : text;

    const { data: copyLogo, isFetched: copyIsFetched } = useAsset({
        folder: 'logo',
        name: 'copy-logo.png',
    });

    const copyToClipboard = () => {
        copy(text);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }

    return (
        isCopied ? (
            <button className="copy-button">
                <img className={`contact-logo ${type}`} src={icon} alt={`${type} icon`} />
                <span className="contact-link">{displayedValue}</span>
                <span className="copy-text">Copied!</span>
            </button>
        ) : (
            <button className="copy-button" onClick={copyToClipboard}>
                <img className={`contact-logo ${type}`} src={icon} alt={`${type} icon`} />
                <span className="contact-link">{displayedValue}</span>
                {copyIsFetched ? (
                    <img src={copyLogo} alt="copy" className="copy-img" />
                ) : (
                    <div className="copy-img"></div>
                )}
            </button>
        )
    )
}