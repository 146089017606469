import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { useAsset, useResource } from '../../../queries';
import { Guitar } from './Guitar';

export const Guitar101 = () => {
    const { data: content = [] } = useResource({
        category: 'music',
        id: 'guitar-101'
    });

    const { TableOfContent } = useTableOfContent(content);

    const { data: cMajorChord } = useAsset({
        folder: 'music',
        name: 'c-major-chord.png'
    });

    const { data: fMajorChord } = useAsset({
        folder: 'music',
        name: 'f-major-chord.png'
    });

    const { data: anatomy } = useAsset({
        folder: 'music',
        name: 'guitar-anatomy.png'
    });

    return (
        <div className="learning-page guitar-101">
            <TableOfContent icons={content} />
            <ContentContainer
                content={content}
                additionalContent={{
                    'Guitar Anatomy': <img src={anatomy} alt="guitar-anatomy" />,
                    'X\'s and O\'s': <img src={cMajorChord} alt="c-major-chords" />,
                    'Barre Chords': <img src={fMajorChord} alt="f-major-chords" />,
                    'Interactive Guitar': <Guitar />,
                }}
                customStyling={{
                    'Picking/Strumming': "overlapping-guitar"
                }}
            />
        </div>
    )
}