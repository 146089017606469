import React from 'react';

export const NoteTable = ({
    handleNoteUpdate,
    selectedNote,
    handlePitchUpdate,
}) => (
    <div className="table-holder notes-holder flex">
        <div className="table-section">
            <h3 className="table-heading">Note</h3>
            <div className="letter-table flex">
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="note-a" type="radio" name="note" value="a" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-a">A</label>
                    </div>
                    <div className="radio-holder">
                        <input id="note-b" type="radio" name="note" value="b" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-b">B</label>
                    </div>
                    <div className="radio-holder">
                        <input id="note-c" type="radio" name="note" value="c" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-c">C</label>
                    </div>
                    <div className="radio-holder">
                        <input id="note-d" type="radio" name="note" value="d" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-d">D</label>
                    </div>
                </div>
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="note-e" type="radio" name="note" value="e" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-e">E</label>
                    </div>
                    <div className="radio-holder">
                        <input id="note-f" type="radio" name="note" value="f" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-f">F</label>
                    </div>
                    <div className="radio-holder">
                        <input id="note-g" type="radio" name="note" value="g" onChange={e => handleNoteUpdate(e.target.value)} /><label htmlFor="note-g">G</label>
                    </div>
                </div>
            </div>
        </div>
        <div className="dividing-line"></div>
        <div className="table-section">
            <h3 className="table-heading">Pitch</h3>
            <div className="type-table flex">
                <div className="column-section flex-col">
                    <div className="radio-holder">
                        <input id="note-natural" type="radio" name="pitch" value="natural" onClick={e => handlePitchUpdate(e.target.value)} defaultChecked /><label htmlFor="note-natural">Natural (♮)</label>
                    </div>
                    {selectedNote !== 'b' && selectedNote !== 'e' &&
                        <div className="radio-holder">
                            <input id="note-sharp" type="radio" name="pitch" value="sharp" onClick={e => handlePitchUpdate(e.target.value)} /><label htmlFor="note-sharp">Sharp (♯)</label>
                        </div>}
                    {selectedNote !== 'c' && selectedNote !== 'f' &&
                        <div className="radio-holder">
                            <input id="note-flat" type="radio" name="pitch" value="flat" onClick={e => handlePitchUpdate(e.target.value)} /><label htmlFor="note-flat">Flat (♭)</label>
                        </div>}
                </div>
            </div>
        </div>
    </div>
)