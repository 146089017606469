import React, { useState } from 'react';

export const Pitch = () => {
    const [selectedPitch, setSelectedPitch] = useState('flat');
    return (
        <div className="pitch-container learning-tips">
            <div className="pitch-buttons-container">
                <button className="default-btn" onClick={() => setSelectedPitch('flat')}>
                    Flat
                </button>
                <button className="default-btn" onClick={() => setSelectedPitch('natural')}>
                    Natural
                </button>
                <button className="default-btn" onClick={() => setSelectedPitch('sharp')}>
                    Sharp
                </button>
            </div>
            <div className="learning-tips">
                <p><span className="btn-indicator">Click</span> the buttons above.</p>
                {selectedPitch === 'flat' && <p className="pitch-text"><strong>Flats:</strong><br />Takes a specific note pitch and moves it half a step down. Accidentals are symbols that raise or lower the pitches of a note, including both sharps and flats.<br /><br /><span className="example-indicator">Example</span><br />A♭, read as A flat, is a half step below A and a half step above G.</p>}
                {selectedPitch === 'natural' && <p className="pitch-text"><strong>Natural:</strong><br />Takes a specific note pitch and returns it to its normal or neutral pitch. Typically regular note pitches don't require any natural symbol.<br /><br /><span className="example-indicator">Example</span><br />G♮, read as G natural, however the ♮ is typically left off. It is really only used to return a flattened or sharpened note to its neutral pitch.</p>}
                {selectedPitch === 'sharp' && <p className="pitch-text"><strong>Sharps:</strong><br />Takes a specific note pitch and moves it half a step up. Accidentals are symbols that raise or lower the pitches of a note, including both sharps and flats.<br /><br /><span className="example-indicator">Example</span><br />F♯, read as F sharp, is a half step above F and a half step below G.</p>}
            </div>
        </div >
    )
}