export const useBlackjack = () => {
    const DRAW_THRESHOLD = 15;
    const DEALER_THRESHOLD = 17;
    const BUST_THRESHOLD = 21;

    const formatHands = (hands) => {
        const formattedHands = {};
        for (const key in hands) {
            const hand = hands[key];
            const formattedHand = hand.map((card, cardIndex) => {
                if (cardIndex === 0 && key === '5') card.show = false;
                return card;
            });
            formattedHands[key] = formattedHand;
        }
        return formattedHands;
    }

    const handleAces = (hand) => {
        const onlyAces = hand.filter(card => card.name === 'A');
        const nonAces = hand.filter(card => card.name !== 'A');
        const nonAceValues = nonAces.reduce((total, card) => total + card.value, 0);
        return onlyAces.map((_, index) => {
            const aceCount = onlyAces.length;
            switch (aceCount) {
                case 1:
                    if (nonAceValues <= 10) return 11;
                    return 1;
                case 2:
                    if (index === 0 && nonAceValues <= 9) return 11;
                    return 1;
                case 3:
                    if (index === 0 && nonAceValues <= 8) return 11;
                    return 1;
                case 4:
                    if (index === 0 && nonAceValues <= 7) return 11;
                    return 1;
                default:
                    return 1;
            }
        }).reduce((total, value) => total + value, 0) + nonAceValues;
    }

    const checkValue = (hand) => {
        if (hand.some(card => card.name === 'A')) {
            return handleAces(hand);
        }
        return hand.reduce((total, card) => total + card.value, 0);
    }

    const validateDraw = (hand, isDealer) => hand.length < 5 && checkValue(hand) < (isDealer ? DEALER_THRESHOLD : DRAW_THRESHOLD);

    const validateHand = (hand) => hand.length < 5 && checkValue(hand) <= BUST_THRESHOLD;

    return {
        formatHands,
        validateDraw,
        validateHand,
        checkValue,
    }
}