import io from 'socket.io-client';
export const socket = io();

// Unfortunately this needs to be maintained on the FE too 
export const SOCKET_CLIENT_EVENTS = {
    JOIN_ROOM: ['join room', 'joined room'],
    JOIN_GAME: ['join game', 'joined game'],
    INVITE: ['invite', 'invited'],
    ACCEPT: ['accept', 'accepted'],
    SET: ['set', 'settled'],
    UPDATE_STATUS: ['update status', 'updated status'],
    SEND: ['send', 'sent'],
    LEAVE: ['leave', 'left'],
};