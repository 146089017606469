import React, { useState } from "react";

const ImageListItem = ({ handleClick, img, index, selectedIndex, setSelectedIndex }) => (
    <div className={`carousel-img-container ${index === selectedIndex ? 'selected' : ''}`}>
        {img ? (
            <img
                className={`carousel-img carousel-img-${index}`}
                src={img}
                alt={'image-' + index}
                onClick={() => {
                    setSelectedIndex(index);
                    handleClick(index);
                }}
            />
        ) : (
            <div className="carousel-img"></div>
        )}
    </div>
);

const MobileImageListItem = ({ handleClick, index, selectedIndex, setSelectedIndex }) => (
    <div className={`mobile-carousel-img-container ${index === selectedIndex ? 'selected' : ''}`}>
        <div className="mobile-carousel-img" onClick={() => {
            setSelectedIndex(index);
            handleClick(index);
        }}></div>
    </div>
)

export const ImageCarousel = ({ images, name }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleScroll = () => {
        const isLargeScreen = window.screen.width > 1088;
        const margin = isLargeScreen ? 140 : 80;
        const horizontal = document.querySelector('.slider').scrollLeft;
        const imageWidth = document.querySelector('#img-0').width + margin;
        const position = Math.round(horizontal / imageWidth);
        setSelectedIndex(position);
    }

    const handleClick = (index) => {
        const isLargeScreen = window.screen.width > 1088;
        const margin = isLargeScreen ? 160 : 80;
        const imageWidth = document.querySelector('#img-0').width + margin;
        const horizontal = imageWidth * index;
        document.querySelector('.slider').scrollTo({
            left: horizontal,
            behavior: 'smooth',
        });
    }

    return (
        <div className="image-carousel">
            {images.length > 1 ?
                <div className="slider-container">
                    <div className="slider" onScroll={(e) => handleScroll(e)}>
                        {images.map((image, index) => (
                            <img
                                key={index}
                                id={`img-${index}`}
                                className="slider-img"
                                src={image}
                                alt={`selected-${name}`}
                            />
                        ))}
                    </div>
                </div>
                :
                <img
                    className="slider-img"
                    src={images[selectedIndex]}
                    alt={`selected-${name}`}
                />
            }
            {images.length > 1 &&
                <div className="carousel desktop">
                    {images.map((img, index) => <ImageListItem key={index} {...{ handleClick, img, index, selectedIndex, setSelectedIndex }} />)}
                </div>
            }
            {images.length > 1 &&
                <div className="carousel mobile">
                    {images.map((img, index) => <MobileImageListItem key={index} {...{ handleClick, index, selectedIndex, setSelectedIndex }} />)}
                </div>
            }
        </div>
    )
}