import React, { useState } from 'react';
import { Question } from './Question';
import { Answer } from './Answer';

export const QuestionAndAnswer = ({ questions }) => {
    const [show, setShow] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <section className="question-and-answer">
            {questions && questions.length ? (
                <Question
                    questions={questions}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    show={show}
                    setShow={setShow}
                />
            ) : null}
            {show && questions && questions.length ? (
                <Answer
                    questions={questions}
                    selectedIndex={selectedIndex}
                />
            ) : null}
        </section>
    )
}