import React from 'react';
import { toTitleCase } from '../../../../utils';
import {
    S1, S2, S3, S4, S5, S6, S7, S8, S9, // switches
    D1, D2, D3, D4, D5, D6, D7, D8, D9, // dashed
    C1, C2, C3, C4, C5, C6, C7, C8, C9, // controls
    H1, H2, H3, H4, H5, H6, H7, H8, H9, // hard
    B, // block
    P, // prior
} from '.';

export const SliderPreview = ({
    puzzle,
    piecePosition,
    switch1Enabled,
    switch2Enabled,
    switch3Enabled,
    switch4Enabled,
    switch5Enabled,
    switch6Enabled,
    switch7Enabled,
    switch8Enabled,
    switch9Enabled,
    control1Enabled,
    control2Enabled,
    control3Enabled,
    control4Enabled,
    control5Enabled,
    control6Enabled,
    control7Enabled,
    control8Enabled,
    control9Enabled,

}) => {
    const getExitDirection = (row, column) => {
        const bottomSquare = puzzle[row - 1]?.[column];
        const topSquare = puzzle[row + 1]?.[column];
        const rightSquare = puzzle[row]?.[column - 1];
        const leftSquare = puzzle[row]?.[column + 1];

        const border = {
            top: typeof topSquare === 'string' && topSquare.includes(P),
            bottom: typeof bottomSquare === 'string' && bottomSquare.includes(P),
            left: typeof leftSquare === 'string' && leftSquare.includes(P),
            right: typeof rightSquare === 'string' && rightSquare.includes(P),
        };

        let borderStyling = '';
        for (const key in border) {
            if (border[key]) {
                borderStyling += 'border' + toTitleCase(key) + ' ';
            }
        }

        const centerPiece = row !== 0 && row !== puzzle.length - 1 && column !== 0 && column !== puzzle[0].length - 1;
        if (centerPiece) borderStyling = 'border-all';
        return borderStyling;
    }

    return (
        <div className="preview-puzzle-container">
            {puzzle?.map((row, rowIndex) => (
                <div key={rowIndex} className="puzzle-row">
                    {row.map((column, columnIndex) => {
                        const isString = typeof column === 'string';
                        const includesValue = (value) => isString && column.includes(value);
                        const piece = rowIndex === piecePosition[0] && columnIndex === piecePosition[1] ? 'piece' : '';
                        const block = includesValue(B) ? 'block' : '';
                        const checkInitialDashedBlock = (value, enabled) => includesValue(value) ? (enabled ? 'block' : 'dashed-block') : '';
                        const checkSuccessiveDashedBlock = (value, enabled) => includesValue(value) ? (enabled ? 'dashed-block' : 'block') : '';
                        const transition1Block = checkInitialDashedBlock(D1, switch1Enabled);
                        const transition2Block = checkInitialDashedBlock(D2, switch2Enabled);
                        const transition3Block = checkInitialDashedBlock(D3, switch3Enabled);
                        const transition4Block = checkInitialDashedBlock(D4, switch4Enabled);
                        const transition5Block = checkInitialDashedBlock(D5, switch5Enabled);
                        const transition6Block = checkInitialDashedBlock(D6, switch6Enabled);
                        const transition7Block = checkInitialDashedBlock(D7, switch7Enabled);
                        const transition8Block = checkInitialDashedBlock(D8, switch8Enabled);
                        const transition9Block = checkInitialDashedBlock(D9, switch9Enabled);
                        const transition11Block = checkSuccessiveDashedBlock(H1, control1Enabled);
                        const transition12Block = checkSuccessiveDashedBlock(H2, control2Enabled);
                        const transition13Block = checkSuccessiveDashedBlock(H3, control3Enabled);
                        const transition14Block = checkSuccessiveDashedBlock(H4, control4Enabled);
                        const transition15Block = checkSuccessiveDashedBlock(H5, control5Enabled);
                        const transition16Block = checkSuccessiveDashedBlock(H6, control6Enabled);
                        const transition17Block = checkSuccessiveDashedBlock(H7, control7Enabled);
                        const transition18Block = checkSuccessiveDashedBlock(H8, control8Enabled);
                        const transition19Block = checkSuccessiveDashedBlock(H9, control9Enabled);
                        const switches = typeof column === 'string' && (
                            column.includes(S1) ||
                            column.includes(S2) ||
                            column.includes(S3) ||
                            column.includes(S4) ||
                            column.includes(S5) ||
                            column.includes(S6) ||
                            column.includes(S7) ||
                            column.includes(S8) ||
                            column.includes(S9) ||
                            column.includes(C1) ||
                            column.includes(C2) ||
                            column.includes(C3) ||
                            column.includes(C4) ||
                            column.includes(C5) ||
                            column.includes(C6) ||
                            column.includes(C7) ||
                            column.includes(C8) ||
                            column.includes(C9)
                        ) ? 'switch' : '';
                        const exit = column === 2 ? getExitDirection(rowIndex, columnIndex) : '';
                        const className = [block, exit, transition1Block, transition2Block, transition3Block, transition4Block, transition5Block, transition6Block, transition7Block, transition8Block, transition9Block, transition11Block, transition12Block, transition13Block, transition14Block, transition15Block, transition16Block, transition17Block, transition18Block, transition19Block, switches].filter(item => item).join(' ');

                        if (switches) {
                            return (
                                <div key={columnIndex} className={`puzzle-item ${className}`}>
                                    {piece && <div className="piece"></div>}
                                    <div className="switch-board">
                                        <div className="switch-buttons">
                                            <div className="switch-button switch-button-1"></div>
                                            <div className="switch-button switch-button-2"></div>
                                            <div className="switch-button switch-button-3"></div>
                                            <div className="switch-button switch-button-4"></div>
                                        </div>
                                        <div className="switch-display">
                                            <div className="display">Switch</div>
                                            <div className="additional-buttons">
                                                <div className="additional-button-container">
                                                    <div className="bar red"></div>
                                                    <div className="bar orange"></div>
                                                    <div className="bar yellow"></div>
                                                    <div className="bar blue"></div>
                                                    <div className="bar orange"></div>
                                                    <div className="bar red"></div>
                                                </div>
                                                <div className="additional-button-container">
                                                    <div className="bar blue"></div>
                                                    <div className="bar red"></div>
                                                    <div className="bar green"></div>
                                                    <div className="bar red"></div>
                                                    <div className="bar yellow"></div>
                                                    <div className="bar blue"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        return (
                            <div key={columnIndex} className={`puzzle-item ${className}`}>
                                {piece && <div className="piece"></div>}
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}