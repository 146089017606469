import React from 'react';
import { CreateSliderMapOptions } from './CreateSliderMapOptions';

export const SliderCreate = ({
    puzzle,
    setPuzzle,
}) => {
    const options = CreateSliderMapOptions(puzzle);

    const updatePuzzle = ({ value, rowIndex, columnIndex }) => {
        const puzzleCopy = [...puzzle];
        puzzleCopy[rowIndex][columnIndex] = value;
        setPuzzle(puzzleCopy);
    }

    // const isACornerPiece = (rowIndex, columnIndex) => (
    //     (rowIndex === 0 && columnIndex === 0) ||
    //     (rowIndex === 0 && columnIndex === puzzle[0].length - 1) ||
    //     (rowIndex === puzzle.length - 1 && columnIndex === 0) ||
    //     (rowIndex === puzzle.length - 1 && columnIndex === puzzle[0].length - 1)
    // )

    return (
        <div className="create-puzzle-container">
            {puzzle?.map((row, rowIndex) => (
                <div key={rowIndex} className="desktop puzzle-row">
                    {row.map((column, columnIndex) => (
                        <select
                            key={columnIndex}
                            id={rowIndex + '-' + columnIndex}
                            name={rowIndex + '-' + columnIndex}
                            className="select-dropdown"
                            onChange={(e) => updatePuzzle({
                                value: e.target.value,
                                rowIndex,
                                columnIndex
                            })}
                            value={column}
                        >
                            {options.filter(({ hide }) => !hide).map(({ value, label, disabled }, optionIndex) =>
                                <option
                                    key={columnIndex + '-' + optionIndex}
                                    value={value}
                                    disabled={disabled}
                                >
                                    {label}
                                </option>
                            )}
                        </select>
                    ))}
                </div>
            ))}
            {puzzle?.map((row, rowIndex) => (
                <div key={rowIndex} className="mobile puzzle-row">
                    {row.map((column, columnIndex) => (
                        <select
                            key={columnIndex}
                            id={rowIndex + '-' + columnIndex}
                            name={rowIndex + '-' + columnIndex}
                            className="select-dropdown"
                            onChange={(e) => updatePuzzle({
                                value: e.target.value,
                                rowIndex,
                                columnIndex
                            })}
                            value={column}
                        >
                            {options.filter(({ hide }) => !hide).map(({ value, disabled }, optionIndex) =>
                                <option
                                    key={columnIndex + '-' + optionIndex}
                                    value={value}
                                    disabled={disabled}
                                >
                                    {value}
                                </option>
                            )}
                        </select>
                    ))}
                </div>
            ))}
        </div>
    )
}

// || (value === '2' && isACornerPiece(rowIndex, columnIndex))