import React from 'react';

export const FAQ = ({ product }) => (
    <div className="faq">
        <h4 className="section-heading">Frequently Asked Questions</h4>
        <ol className="q-and-a-list">
            {product.faq.filter(item => item.question).map((item, index) => (
                <li key={index} className="q-and-a-item">
                    <strong className="question">{item.question} {item.isDifferent && '*'}</strong>
                    <p className="answer">{item.answer}</p>
                    {item.additionalAnswer && <ul className="additional-answer">{item.additionalAnswer.map((item, index) => <li key={index} className="additional-answer-list-item">{item}</li>)}</ul>}
                </li>
            ))}
        </ol>
        {product.faq.find(item => item.isDifferent) &&
            <p className="disclaimer">* = There are changes to this question or it has been added since the printing of the rules.</p>
        }
    </div>
)