import React, { useEffect, useState } from 'react';
import { useUsername } from '../../../../hooks';
import { ACTIVE_GAME_LOCAL_STORAGE_KEYS, getDecryptedValue, removeLocalStorageItem, setEncryptedValue } from '../../../../utils';
import { MODES, socket, SOCKET_CLIENT_EVENTS, USER_STATUSES, WaitingRoom } from '../WaitingRoom';
import { Board } from './Board';
import { Score } from './Score';

export const NumberRoyale = ({ ...product }) => {
    const [mode, setMode] = useState(MODES.WAITING);
    const [activeGame, setActiveGame] = useState();

    const { getUsername } = useUsername();
    const username = getUsername() ?? '';
    const opponent = activeGame?.players?.find(player => player !== username);

    const loadData = () => {
        if (getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.NUMBER_ROYALE)) {
            const activeGame = JSON.parse(getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.NUMBER_ROYALE));
            setActiveGame(activeGame);
            const currentRoundIndex = activeGame?.currentRound - 1;
            const currentRound = activeGame?.selections?.[currentRoundIndex];
            if (currentRound?.[username] && currentRound?.[opponent]) {
                startGame(currentRound?.[username]);
            } else {
                setMode(MODES.SET_UP);
            }
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_BUSY });
        } else {
            setMode(MODES.WAITING);
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_AVAILABLE });
        }
    }

    const onGameEnd = () => {
        setTimeout(() => {
            setActiveGame(null);
            removeLocalStorageItem(ACTIVE_GAME_LOCAL_STORAGE_KEYS.NUMBER_ROYALE);
            setMode(MODES.WAITING);
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_AVAILABLE });
        }, 3000);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [mode]);

    const setGame = () => {
        setMode(MODES.SET_UP);
        socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_BUSY });
    }

    const startGame = async (selections) => {
        await setMode(MODES.IN_PLAY);
        
        const newSelections = activeGame.selections.map(selection => ({ ...selection }));
        const currentRoundIndex = activeGame.currentRound - 1;
        const currentSelection = newSelections[currentRoundIndex];
        const newSelection = { ...currentSelection, [username]: selections };
        newSelections[currentRoundIndex] = newSelection;
        const newData = { ...activeGame, selections: newSelections };

        if (newSelection?.[opponent]) {
            const userSelection = newSelection[username];
            const opponentSelection = newSelection[opponent];

            const winners = userSelection.map((number, index) =>
                number === opponentSelection[index] ?
                    null : number > opponentSelection[index] ?
                        username : opponent
            );

            const score = {
                [username]: activeGame?.score?.[username] ?? 0,
                [opponent]: activeGame?.score?.[opponent] ?? 0
            };

            const onlyPlayers = winners.filter(winner => winner !== null);
            const usernameWins = onlyPlayers.filter(player => player === username).length;
            const opponentWins = onlyPlayers.filter(player => player === opponent).length;
            if (usernameWins > opponentWins) {
                score[username] = score[username] + 1;
            } else if (opponentWins > usernameWins) {
                score[opponent] = score[opponent] + 1;
            }

            const message = usernameWins === opponentWins ? 'It\'s a tie!' : usernameWins > opponentWins ? `${username} wins` : `${opponent} wins`;

            const messages = { 
                [username]: message,
                [opponent]: message,
            }

            const usernameIsWinner = score[username] === 3;
            const opponentIsWinner = score[opponent] === 3;

            if (usernameIsWinner) {
                messages[username] = 'You win!';
                messages[opponent] = 'You lose!';
                messages.finished = true;
            } else if (opponentIsWinner) {
                messages[username] = 'You lose!';
                messages[opponent] = 'You win!';
                messages.finished = true;
            }

            newData.score = score;
            newData.messages = messages;
            newData.currentRound = activeGame.currentRound + 1;
            setTimeout(async () => {
                await setMode(MODES.SET_UP);
            }, 3000);
        }
        socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], newData);
        setActiveGame(newData);
        setEncryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.NUMBER_ROYALE, JSON.stringify(newData));
    }

    return (
        <section className="number-royale-board">
            <WaitingRoom
                mode={mode}
                setMode={setMode}
                product={product}
                setGame={setGame}
                startGame={startGame}
                activeGame={activeGame}
                setActiveGame={setActiveGame}
                activeGameLocalStorageKey={ACTIVE_GAME_LOCAL_STORAGE_KEYS.NUMBER_ROYALE}
                additionalGameData={{
                    currentRound: activeGame?.currentRound ?? 1,
                    selections: [...(activeGame?.selections ?? [])],
                }}
                onGameEnd={onGameEnd}
            />
            {mode !== MODES.WAITING && activeGame && (
                <div className="number-royale-container">
                    <Score 
                        activeGame={activeGame}
                        username={username}
                        opponent={opponent}
                    />
                    <Board
                        activeGame={activeGame}
                        opponent={opponent}
                        startGame={startGame}
                        username={username}
                        mode={mode}
                    />
                </div>
            )}
        </section>
    )
}