import React, { useState } from 'react';
import dayjs from 'dayjs';
import { ZodiacImages } from './ZodiacImages';
import { YearSelector } from './YearSelector';
import { Overview } from './Overview';
import { ZodiacList } from './ZodiacList';
import { useResource } from '../../../queries';

const currentYear = dayjs().format('YYYY');

const sortZodiacs = (zodiacs, year) => {
    const index = (zodiacs || []).findIndex(zodiac => zodiac.years.includes(Number(year)));
    if (index > -1) {
        const beginning = zodiacs.slice(index);
        return [...beginning, ...zodiacs.slice(0, index)]
    }
    return zodiacs || [];
}

export const ChineseZodiac = () => {
    const [year, setYear] = useState(currentYear);

    const { data: content } = useResource({
        category: 'recreation',
        id: 'chinese-zodiac'
    });

    const zodiacs = content?.zodiac || [];
    const overview = content?.overview || '';

    const sortedZodiacs = sortZodiacs(zodiacs, year);

    return (
        <div className="learning-pages">
            <div className="zodiac-wheel">
                <ZodiacImages
                    zodiacs={zodiacs}
                    onClick={(zodiac) => {
                        const foundYear = zodiac.years.find(year => currentYear <= year && Number(currentYear) < year + 12);
                        setYear(foundYear)
                    }}
                    selected={year}
                    type="chinese-zodiac"
                />
                <YearSelector
                    year={year}
                    setYear={setYear}
                />
            </div>
            <div className="learning-tips">
                <Overview overview={overview} />
                {zodiacs.length > 0 && (
                    <ZodiacList zodiacs={sortedZodiacs} />
                )}
            </div>
        </div>
    )
}
