import React, { useState } from 'react';
import { convertTimeToNumber } from '../../../../utils';

export const TIME = {
    one: '1:00',
    two: '2:00',
    three: '3:00',
}

export const Timer = ({ time, setTime, onTimeStart, onTimeEnd, hasCountdown, canStop, hideTimer }) => {
    const [running, setRunning] = useState(false);
    const [showCountdown, setShowCountdown] = useState(false);

    const formatTimer = (time) => {
        if (time === 0) {
            setTime('0:00');
            onTimeEnd();
        } else {
            let remainingTime = time;
            const minutes = Math.floor(time / 60000);
            remainingTime -= (minutes * 60000);

            const seconds = Math.floor(remainingTime / 1000);
            let separator = ':';
            if (seconds < 10) {
                separator += '0';
            }
            setTime(minutes + separator + seconds);
        }
    }

    const startCountdown = async () => {
        setRunning(true);
        await setShowCountdown(true);
        let countdown = 3;
        const countdownNumJSX = document.querySelector('.countdown');
        if (countdownNumJSX) {
            setTimeout(() => {
                setShowCountdown(false);
                startTimer();
                onTimeStart();
            }, 4000);
            setTimeout(() => {
                countdown -= 1;
                countdownNumJSX.innerText = countdown;
            }, 3000);
            setTimeout(() => {
                countdown -= 1;
                countdownNumJSX.innerText = countdown;
            }, 2000);
            setTimeout(() => {
                countdown -= 1;
                countdownNumJSX.innerText = countdown;
            }, 1000);
            countdownNumJSX.innerText = countdown;
        }
    }

    const startTimer = () => {
        let number = convertTimeToNumber(time);
        const timer = setInterval(() => {
            number -= 1000;
            if (formatTimer) {
                formatTimer(number);
            }
            if (number <= 0) {
                clearInterval(timer);
            }
        }, 1000);
    }

    const startTimerDirectly = () => {
        setRunning(true);
        startTimer();
        onTimeStart();
    }

    return (
        <div className="timer-container">
            {hasCountdown && running && !hideTimer && (
                <div className="timer">{time}</div>
            )}
            {!hasCountdown && !hideTimer && (
                <div className="timer">{time}</div>
            )}
            {hasCountdown && (
                running ? (
                    hasCountdown && showCountdown ? (
                        <h3 className="countdown">3</h3>
                    ) : null
                ) : (
                    <button className="start-button" onClick={startCountdown}>
                        Start
                    </button>
                )
            )}
            {!hasCountdown && !canStop && (
                running ? null : <button className="start-button" onClick={startTimerDirectly}>Start</button>
            )}
            {!hasCountdown && canStop && (
                <button className="start-button" onClick={startTimerDirectly}>{running ? 'Stop' : 'Start'}</button>
            )}
        </div>
    )
}