import React from 'react';
import { useAsset } from '../../../queries';

export const InstructionsBuilder = ({ setUpInstructions, instructions, goal, facts }) => {
    const { data: arrowLogo, isFetched: arrowIsFetched } = useAsset({
        folder: 'logo',
        name: 'arrow.png',
    })

    const handleScroll = () => {
        document.querySelector('.page').scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <div className="instructions-container">
            <h3 className='section-heading'>Goal:</h3>
            <p className="goal">{goal}</p>
            {setUpInstructions && (
                <>
                    <h3 className='section-heading'>Set Up:</h3>
                    <ol className="set-up">
                        {setUpInstructions.map((item, index) => <li key={index} className="item">{item}</li>)}
                    </ol>
                </>
            )}
            <h3 className='section-heading'>Rules:</h3>
            <ol className="rules">
                {instructions.map((item, index) => <li key={index} className="item">{item}</li>)}
            </ol>
            <div className="bottom-direction-btn-container">
                <button className='mobile-btn' onClick={handleScroll}>
                    {arrowIsFetched ? <img className="arrow-logo" src={arrowLogo} alt="arrow" /> : <div className="arrow-logo"></div>}
                </button>
            </div>
            {facts && (
                <>
                    <h3 className='section-heading'>Facts:</h3>
                    <ul className="facts">
                        {facts.map((item, index) => <li key={index} className="item">{item}</li>)}
                    </ul>
                </>
            )}
        </div>
    )
}