import React from "react";
import { FlippingButton } from "../../../components";

export const SliderPreviewButtons = ({ previewIsDisabled, resetIsDisabled, isPreview, setIsPreview, reset }) => (
    <div className="preview-buttons-container">
        <FlippingButton
            buttonText={isPreview ? "Edit" : "Preview"}
            color="orange"
            onClick={() => setIsPreview(!isPreview)}
            width={isPreview ? 80 : 112}
            shouldTransform
            isDisabled={previewIsDisabled}
        />
        <FlippingButton
            buttonText="Reset"
            color="red"
            onClick={reset}
            width={90}
            shouldTransform
            isDisabled={resetIsDisabled}
        />
    </div>
)