import React from 'react';

export const Vitamins = () => {
    const vitamins = [
        {
            name: 'Vitamin A',
            purposes: 'Helps the immune system, vision, reproductive system, organ bodily functionality, and cell communication.',
            sources: 'dairy products, fortified cereals, fish, carrots, liver, broccoli, squash, and cantaloupe.'
        },
        {
            name: 'Vitamin C',
            purposes: 'Helps with cell protection from harmful emissions and exposure to free radicals such as cigarette smoke, air pollution, and uv rays from the sun. It is crucial to make collagen which is a protein to help heal wounds. And lastly helps you get more iron out of your vegetables and strengthens your immune system.',
            sources: 'Fruits and vegetables including oranges, grapefruit, bell peppers, kiwi, broccoli, strawberries, cantaloupe, potatoes, and tomatoes, and citrus juices.'
        },
        {
            name: 'Vitamin D',
            purposes: 'Helps with stronger bones through calcium it provides the body. It also helps the muscles and nerve cells with mobility. And lastly helps with the immune system.',
            sources: 'Fatty fish like salmon, tuna and mackerel, cheese, egg yolks, mushrooms, milk (cow, soy, almond, and oat), and some other dairy products.'
        },
        {
            name: 'Vitamin E',
            purposes: 'Helps protect cells from harmful emissions and exposure to free radicals similar to Vitamin C. It helps the immune system, blood clots, and cell functionality.',
            sources: 'Nuts (like peanuts, hazelnuts, almonds) and seeds (like sunflower seeds), and some leafy greens such as spinach and broccoli.'
        },
        {
            name: 'Vitamin K',
            purposes: 'Helps with preventing blood clots and healthier bones',
            sources: 'leafy greens, vegetable oil, blueberries, meat, cheese, eggs, and soybean.'
        },
        {
            name: 'Thiamin: (AKA Vitamin B1)',
            purposes: 'Helps your body turn food into energy and cell development and functionality.',
            sources: 'Whole grains and fortified breads, pasta, rice, cereal, meat, fish, black beans, soybeans, seeds, and nuts.'
        },
        {
            name: 'Riboflavin: (AKA Vitamin B2)',
            purposes: 'Helps your body turn food into energy and cell development and functionality.',
            sources: 'Eggs, kidneys and liver, lean meat, low-fat milk, green vegetables, fortified cereal, bread, and grains.'
        },
        {
            name: 'Niacin: (AKA Vitamin B3)',
            purposes: 'Helps your body turn food into energy and cell development and functionality.',
            sources: 'Meats, nuts, beans, grains, enriched and fortified breads and cereals.'
        },
        {
            name: 'Pantothenic Acid: (AKA Vitamin B5)',
            purposes: 'Helps your body turn food into energy and cell development and functionality.',
            sources: 'Beef, poultry, seafood, eggs, milk, avocados, potatoes, broccoli, whole grains, peanuts, sunflower seeds, and chickpeas.'
        },
        {
            name: 'Biotin',
            purposes: 'Helps your body turn food into energy and cell development and functionality.',
            sources: 'Meat, fish, eggs, seeds, nuts, potatoes, spinach, and broccoli.'
        },
        {
            name: 'B6',
            purposes: 'Helps your body turn food into energy and cell development and functionality.',
            sources: 'Poultry, fish, potatoes, starchy vegetables, non-citrusy fruits.'
        },
        {
            name: 'B12',
            purposes: 'Helps with making DNA, improves health of cell, and prevents anemia which cause tiredness and faintness.',
            sources: 'Beef liver, clams, fish, meat, poultry, eggs, milk and other dairy products.'
        },
        {
            name: 'Folate',
            purposes: 'Helps to make DNA and cell division.',
            sources: 'Beef liver, dark leafy greens, asparagus, oranges, nuts, beans, and peas.'
        },
    ];

    return (
        <ul className="learning-tips">
            {vitamins.map(vitamin => (
                <li key={vitamin.name} className="vitamin">
                    <span className="vitamin-name">{vitamin.name}</span>
                    <p className="vitamin-purposes">
                        <span className="vitamin-label">Purposes:</span>
                        <span>{vitamin.purposes}</span>
                    </p>
                    <p>
                        <span className="vitamin-label">Sources:</span>
                        <span>{vitamin.sources}</span>
                    </p>
                </li>
            ))}
        </ul>
    )
}