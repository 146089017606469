import React from 'react';
import { calculateDiscountedPrice, isDifferent } from '../../utils';
import { FlippingButton } from '../components/FlippingButton';

export const ProductDetails = ({ product }) => {
    const discountedPrice = calculateDiscountedPrice(product.price, product.discount);

    const navigateToAmazon = () => {
        if (product.url) window.open(product.url, '_blank');
    }

    return (
        <div className="details-container">
            <h3 className="product-name">{product.name}</h3>
            <h5 className="product-pricing">
                <span className={`${isDifferent(product.price, discountedPrice) ? 'discounted-price' : 'product-price'}`}>${product.price}</span>
                {product.price > discountedPrice && <span className="product-price">${discountedPrice}</span>}
            </h5>
            {product.overview && <h6 className="product-overview">{product.overview}</h6>}
            <div className="product-transaction">
                <FlippingButton
                    buttonText="Open in Amazon"
                    color="orange"
                    onClick={navigateToAmazon}
                    width={210}
                />
            </div>
        </div>
    )
}