import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { randomNumber } from '../../../../utils';

const MAX_TURNS = 10;
const DEFAULT_GUESSES = Array(MAX_TURNS).fill('');

export const SecretNumber = () => {
    const [number, setNumber] = useState(null);
    const [turn, setTurn] = useState(0);
    const [guess, setGuess] = useState('');
    const [guesses, setGuesses] = useState(DEFAULT_GUESSES);
    const remainingGuesses = MAX_TURNS - turn;

    const getSecretNumber = () => {
        const number = randomNumber(1000);
        setNumber(number);
    }

    useEffect(() => {
        getSecretNumber();
    }, []);

    const handleGuess = (e) => {
        if (turn < MAX_TURNS) {
            let guess = e.target.value.replace(/[^0-9]+/g, '');
            setGuess(guess);
        }
    }

    const submitGuess = () => {
        if (Number(guess) === number) {
            toast.success(`Congratulations! ${number} was the secret number.`, { className: 'success' });
            setGuesses(DEFAULT_GUESSES);
            setTurn(0);
            getSecretNumber();
        } else if (turn + 1 === MAX_TURNS) {
            toast.error(`Sorry! You didn't guess ${number} in time.`, { className: 'error' });
            setGuesses(DEFAULT_GUESSES);
            setTurn(0);
            getSecretNumber();
        } else {
            const newGuesses = [...guesses];
            newGuesses[turn] = guess;
            setGuesses(newGuesses);
            setTurn(turn + 1);
        }
        setGuess('');
    }

    const getComparisonValue = (value) => number < value ? 'less' : 'greater';

    // const getDigits = () => {
    //     const numberString = number?.toString();
    //     const digitsObj = {
    //         hundreds: 0,
    //         tens: 0,
    //         ones: 0
    //     };
    //     if (number > 99) {
    //         digitsObj.hundreds = numberString[0];
    //         digitsObj.tens = numberString[1];
    //         digitsObj.ones = numberString[2];
    //     } else if (number > 9) {    
    //         digitsObj.hundreds = 0;    
    //         digitsObj.tens = numberString[0];
    //         digitsObj.ones = numberString[1];
    //     } else if (number) {
    //         digitsObj.hundreds = 0;
    //         digitsObj.tens = 0;
    //         digitsObj.ones = numberString[0];
    //     }
    //     return digitsObj;
    // }
    // const digits = getDigits();

    return (
        <section className="secret-number-container">
            {/* <div className="secret-number">
                <div className="digit">{digits.hundreds}</div>
                <div className="digit">{digits.tens}</div>
                <div className="digit">{digits.ones}</div>
            </div> */}
            <div className="guess-form">
                <input
                    type="text"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && guess !== '') submitGuess();
                    }}
                    onChange={handleGuess}
                    className="guess-input"
                    maxLength="3"
                    value={guess}
                />
                <button className="default-btn" onClick={submitGuess}>Submit</button>
            </div>
            <span className="guesses-left">You have {remainingGuesses} {remainingGuesses !== 1 ? 'guesses' : 'guess'} left</span>
            <div className="guesses-list-container">
                <h4>Guesses</h4>
                <div className="guesses-list">
                    {guesses.map((item, i) => (
                        <div key={i} className="guess-item">
                            <span className="guess-number">
                                <span className="number">{i + 1}.</span>
                                {item && 'The secret number is'}
                            </span>
                            {item && (
                                <span className={`comparison-label ${getComparisonValue(item)}`}>
                                    {getComparisonValue(item)}
                                </span>
                            )}
                            {item && (
                                <span>than</span>
                            )}
                            {item && (
                                <span className={`guess ${getComparisonValue(item)}`}>{item}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}