import React from 'react';

export const Score = ({ activeGame, username, opponent }) => {
    const turn = activeGame.turn;
    const players = activeGame.players;
    const first = players?.[0];

    return (
        <div className="score-container">
            <div className="score-holder">
                <span>{username}</span>
                {username === turn && <div className={`turn-marker ${username === first ? 'red' : 'yellow'}`}></div>}
            </div>
            <div className="score-holder">
                <span>{opponent}</span>
                {opponent === turn && <div className={`turn-marker ${opponent === first ? 'red' : 'yellow'}`}></div>}
            </div>
        </div>
    )
}