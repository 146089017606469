import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTab } from '../../../components';
import { boards, boardMapping, tabData, DifficultyTab, NumberTab, SliderArrows, SliderButtons, SliderFraction, SliderPreview } from '.';
import { useSliderHook } from './hooks/updatePosition';

export const Slider = ({ toastMessages, history, id }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedPuzzle, setSelectedPuzzle] = useState({ puzzle: [] });
    const [difficultyLevel, setDifficultyLevel] = useState('any');
    const [selectedNumber, setSelectedNumber] = useState('1');
    const { selectedTab, getTabs } = useTab({ tabData });

    const next = () => {
        if (selectedTab === 'number') {
            const nextNumber = Number(selectedNumber) + 1;
            if (Number(selectedNumber) < boards.length) {
                setSelectedNumber(nextNumber);
            }
            else toast(toastMessages.missing, { className: 'error' });
        } else {
            const newIndex = selectedIndex + 1;
            if (boardMapping[difficultyLevel][newIndex]) setSelectedIndex(newIndex);
            else toast(toastMessages.missing, { className: 'error' });
        }
    }

    const getPiecePosition = () => {
        let selectedPuzzle;
        if (selectedTab === 'number') {
            selectedPuzzle = boards.find(board => board.id === Number(selectedNumber))
        } else {
            selectedPuzzle = boardMapping[difficultyLevel][selectedIndex];
        }
        let position = [0, 0];
        if (selectedPuzzle) {
            selectedPuzzle.puzzle.forEach((row, rowIndex) => {
                row.forEach((column, columnIndex) => {
                    if (column === 1) {
                        position = [rowIndex, columnIndex];
                        return;
                    }
                })
            })
        }
        return position;
    }

    const {
        inMotion,
        reset,
        piecePosition,
        updatePosition,
        switch1Enabled,
        switch2Enabled,
        switch3Enabled,
        switch4Enabled,
        switch5Enabled,
        switch6Enabled,
        switch7Enabled,
        switch8Enabled,
        switch9Enabled,
        control1Enabled,
        control2Enabled,
        control3Enabled,
        control4Enabled,
        control5Enabled,
        control6Enabled,
        control7Enabled,
        control8Enabled,
        control9Enabled,
    } = useSliderHook({
        puzzle: selectedPuzzle.puzzle,
        next,
        toastMessages,
        getPiecePosition,
    })

    const getPuzzles = () => {
        let selectedPuzzle;
        if (selectedTab === 'number') {
            selectedPuzzle = boards.find(board => board.id === Number(selectedNumber))
        } else {
            selectedPuzzle = boardMapping[difficultyLevel][selectedIndex];
        }
        setSelectedPuzzle(selectedPuzzle);
    }

    useEffect(() => {
        if (selectedTab === 'number') {
            const currentIndex = boards.findIndex(board => board.id === Number(selectedNumber));
            if (currentIndex > -1) {
                setSelectedIndex(currentIndex);
            }
        } else {
            setSelectedIndex(0);
        }
        getPuzzles()
        // eslint-disable-next-line
    }, [difficultyLevel, selectedNumber, selectedTab]);

    // eslint-disable-next-line
    useEffect(() => getPuzzles(), []);

    // eslint-disable-next-line
    useEffect(() => getPuzzles(), [selectedIndex]);

    return (
        <section className="slider-board">
            <h2 className="puzzle-header">{'Puzzle #' + selectedPuzzle?.id} <span className='puzzle-author'>by {selectedPuzzle?.author}</span></h2>
            <SliderFraction
                boardMapping={boardMapping}
                boards={boards}
                difficultyLevel={difficultyLevel}
                selectedIndex={selectedIndex}
                selectedNumber={selectedNumber}
                selectedTab={selectedTab}
            />
            <SliderButtons next={next} reset={reset} />
            <div className="slider-main">
                <SliderPreview
                    puzzle={selectedPuzzle?.puzzle}
                    piecePosition={piecePosition}
                    switch1Enabled={switch1Enabled}
                    switch2Enabled={switch2Enabled}
                    switch3Enabled={switch3Enabled}
                    switch4Enabled={switch4Enabled}
                    switch5Enabled={switch5Enabled}
                    switch6Enabled={switch6Enabled}
                    switch7Enabled={switch7Enabled}
                    switch8Enabled={switch8Enabled}
                    switch9Enabled={switch9Enabled}
                    control1Enabled={control1Enabled}
                    control2Enabled={control2Enabled}
                    control3Enabled={control3Enabled}
                    control4Enabled={control4Enabled}
                    control5Enabled={control5Enabled}
                    control6Enabled={control6Enabled}
                    control7Enabled={control7Enabled}
                    control8Enabled={control8Enabled}
                    control9Enabled={control9Enabled}
                />
                <SliderArrows
                    inMotion={inMotion}
                    piecePosition={piecePosition}
                    updatePosition={updatePosition}
                />
            </div>
            <div className="selection-container">
                {getTabs()}
                {selectedTab === 'difficulty' && <DifficultyTab boardMapping={boardMapping} difficultyLevel={difficultyLevel} setDifficultyLevel={setDifficultyLevel} />}
                {selectedTab === 'number' && <NumberTab boards={boards} selectedNumber={selectedNumber} setSelectedNumber={setSelectedNumber} />}
            </div>
            <div className="build-your-own-puzzle-text-container" onClick={() => history.push(`/products/games/${id}/builder`)}>
                <h2>Want to feel like a game designer?</h2>
                <p>Create your own puzzle map <span className="here-text">here</span> and send it our way. We will review it and make sure that it works.</p>
            </div>
        </section>
    )
}

// useEffect(() => {
//     document.onkeydown = (event) => {
//         switch (event.key) {
//             case 'ArrowUp':
//                 updatePosition({ type: 'row', position: piecePosition, direction: '-' })
//                 break;
//             case 'ArrowLeft':
//                 updatePosition({ type: 'column', position: piecePosition, direction: '-' })
//                 break;
//             case 'ArrowRight':
//                 updatePosition({ type: 'column', position: piecePosition, direction: '+' })
//                 break;
//             case 'ArrowDown':
//                 updatePosition({ type: 'row', position: piecePosition, direction: '+' })
//                 break;
//             default:
//                 break;
//         }
//     }
//     setInMotion(false);
//     // eslint-disable-next-line
// }, [piecePosition])
