import React from "react";

export const Loader = () => (
    <div className='loading-container'>
        <div className='loader'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='text'></div>
        </div>
    </div>
)
