import React from 'react';
import { useAsset } from '../../../../queries';

const getCenterSuit = ({ color, displayName, emoji, name, suit }) => {
    const centerSuitClass = `center-suit ${color}`;
    const rotateCenterSuitClass = `center-suit suit-rotate ${color}`;

    const centerSuit = <h3 className={centerSuitClass}>{suit}</h3>;
    const reversedCenterSuit = <h3 className={rotateCenterSuitClass}>{suit}</h3>;

    const doubleSuitColumn = (
        <div className="double-suits-column">
            {centerSuit}
            {centerSuit}
        </div>
    );

    const reversedDoubleSuitColumn = (
        <div className="double-suits-column">
            {reversedCenterSuit}
            {reversedCenterSuit}
        </div>
    );

    const floatingSuitColumn = <div className="single-suit-column floating-suit">{centerSuit}</div>;

    switch (name) {
        case 'K':
        case 'Q':
        case 'J':
            return (
                <div className="face-suits-container">
                    <span className={centerSuitClass} role="img" aria-label={displayName + ' face card'}>
                        {emoji}
                    </span>
                </div>
            );
        case 10:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    <div className="single-suit-column smaller-floating-suit">{centerSuit}</div>
                    <div className="single-suit-column reversed-smaller-floating-suit">{reversedCenterSuit}</div>
                </div>
            );
        case 9:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    <div className="single-suit-column centered-floating-suit">{centerSuit}</div>
                </div>
            );
        case 8:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    {floatingSuitColumn}
                    <div className="single-suit-column reversed-floating-suit">{reversedCenterSuit}</div>
                </div>
            );
        case 7:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    {floatingSuitColumn}
                </div>
            );
        case 6:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                </div>
            );
        case 5:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                    <div className="single-suit-column">{centerSuit}</div>
                </div>
            );
        case 4:
            return (
                <div className="numbered-suits-container">
                    {doubleSuitColumn}
                    {reversedDoubleSuitColumn}
                </div>
            );
        case 3:
            return (
                <div className="numbered-suits-container">
                    {centerSuit}
                    {centerSuit}
                    {reversedCenterSuit}
                </div>
            );
        case 2:
            return (
                <div className="numbered-suits-container">
                    {centerSuit}
                    {reversedCenterSuit}
                </div>
            );
        case 'A':
        default:
            return <div className="face-suits-container">{centerSuit}</div>
    }
}

export const Card = ({ card }) => {
    const { data: logo } = useAsset({
        folder: 'logo',
        name: 'budding-acorn.png',
    });

    const cardClass = card.class ? card.class : '';

    const getCardCorner = (direction) => (
        <div className={`front-corner ${direction}`}>
            <h3 className={`card-name ${card.color}`}>{card.name}</h3>
            <h3 className={`card-suit ${card.color}`}>{card.suit}</h3>
        </div>
    );

    if (card.show) {
        return (
            <div className={`playing-card ${cardClass}`}>
                <div key={card.id} className="card-front-container">
                    {getCardCorner('left')}
                    <div className="card-center-suits-container">
                        {getCenterSuit(card)}
                    </div>
                    {getCardCorner('right')}
                </div>
            </div>
        )
    } else {
        return (
            <div className={`playing-card ${cardClass}`}>
                <div key={card.id} className="card-back-container">
                    <img src={logo} alt="budding-acorn-logo" className="card-back-logo" />
                </div>
            </div>
        )
    }
}