import React from 'react';
import { useResource } from '../../../queries';
import { useAsset } from '../../../queries';

export const CookingTips = () => {
    const { data: content = [] } = useResource({
        category: 'life-skills',
        id: 'cooking-tips'
    });

    const { data: checkmark } = useAsset({
        folder: 'life-skills',
        name: 'checkmark.png'
    })

    const reducedCategories = content.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
    }, {});

    return (
        <div className="learning-pages">
            {Object.values(reducedCategories).map((category, categoryIndex) => (
                <div className="section-container" key={categoryIndex}>
                    <h5 className="section-heading">{category[0].type}</h5>
                    <ul className="cooking-tips">
                        {category.map((item, index) => (
                            <li key={categoryIndex + '-' + index} className="cooking-tip">
                                <span>{item.content}</span> {item.validated && <img className="checkmark-logo" src={checkmark} alt="checkmark" />}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}