import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { randomize } from '../../../utils/game';

export const Codebreaker = ({ toastMessages }) => {
    const options = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];
    const [answer, setAnswer] = useState([]);
    const [currentGuess, setCurrentGuess] = useState(['', '', '', '']);
    const [guesses, setGuesses] = useState([]);

    const generateAnswer = () => {
        const answer = randomize([...options, ...options, ...options, ...options], 4);
        setAnswer(answer);
    }

    useEffect(() => {
        generateAnswer()
        // eslint-disable-next-line
    }, [])

    const handleSelection = (value, index) => {
        const guess = [...currentGuess];
        guess[index] = value;
        setCurrentGuess(guess);
    }

    const resetGuess = () => setCurrentGuess(['', '', '', '']);

    const resetEverything = () => {
        generateAnswer([]);
        setGuesses([]);
    }

    const submitGuess = async () => {
        const isFull = currentGuess.includes('');
        const addGuessToList = () => {
            const list = [...guesses];
            list.push({
                guess: currentGuess,
                indicators: getIndicators()
            });
            setGuesses(list);
        }

        if (isFull) {
            toast(toastMessages.incomplete, { className: 'error' });
        } else if (guesses.length >= 10) {
            toast(toastMessages.failed, { className: 'error' });
        } else if (!isFull && guesses.length < 10) {
            addGuessToList();
            resetGuess();
            const solved = isEqual(currentGuess, answer);
            if (solved) {
                toast(toastMessages.success, { className: 'success' });
                setTimeout(resetEverything, 5000);
            } else {
                setTimeout(() => {
                    if (guesses.length === 10 && !solved) {
                        toast(toastMessages.failed, { className: 'error' });
                    }
                }, 1000);
            }
        }
    }

    const getIndicators = () => {
        const indicators = [];
        const getWhiteCount = () => currentGuess.filter((code, i) => code === answer[i]).length;
        const removeCorrectOnes = (arr, compArr) => arr.filter((color, i) => color !== compArr[i]); // need to remove the ones that are in the correct spots in both arrays
        const getRedCount = () => {
            const filteredGuess = removeCorrectOnes(currentGuess, answer);
            const filteredAnswer = removeCorrectOnes(answer, currentGuess);
            // remove ones that have already been accounted for
            return filteredGuess.filter(code => {
                if (filteredAnswer.includes(code)) {
                    const foundIndex = filteredAnswer.findIndex(instance => code === instance);
                    filteredAnswer.splice(foundIndex, 1);
                    return code;
                }
                return filteredAnswer.includes(code);
            }).length;
        }

        const white = getWhiteCount();
        const red = getRedCount();
        for (let i = 0; i < white; i++) {
            indicators.push('white');
        }
        for (let i = 0; i < red; i++) {
            indicators.push('red');
        }
        for (let i = indicators.length; i < 4; i++) {
            indicators.push('');
        }
        return indicators;
    }

    const getRemainingGuesses = () => {
        const remainingGuesses = [];
        for (let i = 0; i < (10 - guesses.length); i++) {
            remainingGuesses.push({ guess: ['', '', '', ''], indicators: ['', '', '', ''] });
        }
        return remainingGuesses;
    }
    const remainingGuesses = getRemainingGuesses();

    const guessedCorrectly = guesses.find(({ guess }) => isEqual(guess, answer));

    return (
        <div className="codebreaker-board">
            <div className="left-selection-container">
                <strong>Code to Break:</strong>
                <div className="answer-container">
                    {answer.map((color, i) => (
                        <div key={i} className={`answer-circle ${guessedCorrectly ? color : 'white'}`}>?</div>
                    ))}
                </div>
                <strong>Possible Code Options:</strong>
                <ul className="possible-colors-container">
                    {options.map((color, i) => (
                        <li key={i} className={`color-option ${color}`}></li>
                    ))}
                </ul>
                <strong>Select Colors in the Dropdown:</strong>
                <div className="current-container">
                    <div className="current-guesses-container">
                        {currentGuess.map((color, i) => (
                            <div key={i} className='current-guess'>
                                <div key={i} className={`current-circle ${color} ${color ? 'selected-color' : ''}`}>{i + 1}</div>
                                <select
                                    className="color-dropdown"
                                    name={`color-selection-${i}`}
                                    value={color}
                                    onChange={(e) => handleSelection(e.target.value, i)}
                                >
                                    <option value="">Choose</option>
                                    {options.map(option => <option key={option} value={option}>{option}</option>)}
                                </select>
                            </div>
                        ))}
                    </div>
                    <div className="btn-container">
                        <button className="default-btn" onClick={resetGuess} disabled={currentGuess.filter(item => item).length === 0}>Clear</button>
                        <button className="default-btn" onClick={submitGuess} disabled={currentGuess.filter(item => item).length !== 4}>Submit</button>
                    </div>
                </div>
            </div>
            <div className="right-display-container">
                <strong>{remainingGuesses.length} Guesses Remaining</strong>
                <div className="code-guesses-container">
                    {[...guesses, ...remainingGuesses].map(({ guess, indicators }, index) => (
                        <div key={index} className={`guess-container ${guess.filter(item => item).length ? '' : 'opacity'}`}>
                            <span className="guess-number">{index + 1}</span>
                            <div className="guess-holder">
                                {guess.map((color, index) => (
                                    <div key={index} className={`code-guess ${color}`}></div>
                                ))}
                            </div>
                            <div className="indicator-holder">
                                {indicators.map((indicator, index) => (
                                    <div key={index} className={`indicator ${indicator ? indicator : 'lightgray'}`}></div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
