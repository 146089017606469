import React from 'react';
// import { toast } from "react-toastify";
// import { addLocalStorageValue, getLocalStorageValue } from '../../utils';
import { Footer, Loader, ProductCard, ResourceCard } from '../components';
import { Redirect } from 'react-router-dom';
import { useProductsByType, useResourcesByCategory } from '../../queries';
import { useLoader } from '../../hooks/use-loader';
import { toTitleCase } from '../../utils';

const allowedProducts = ['games', 'books', 'crafts'];
const singularProductsMap = {
    games: 'game',
    books: 'book',
    crafts: 'craft'

}

export const Products = ({ checkHeight, history, match }) => {
    const type = match.params.type;
    const { data: products, isLoading: isProductLoading, isFetching: isProductsFetching } = useProductsByType({ type });
    const { data: resources, isLoading: isResourcesLoading, isFetching: isResourcesFetching } = useResourcesByCategory(type);
    const isProductsReady = !isProductLoading && !isProductsFetching;
    const isResourcesReady = !isResourcesLoading && !isResourcesFetching;

    const handleProductRouting = (id) => history.push({ pathname: `/products/${type}/${id}` });
    const handleResourceRouting = (url) => history.push(url);

    const isLoading = useLoader(isResourcesReady && isProductsReady);
    if (isLoading) return <Loader />;

    if (allowedProducts.includes(type)) {
        return (
            <div id={`${type}-page`} className="products page" onScroll={() => checkHeight('.heading')}>
                <div className="container product-container">
                    <h2 className="heading">{type}</h2>
                    <div className="product-list">
                        {(isProductsReady && products) ?
                            products.map(product =>
                                <ProductCard key={product.id}  {...{ handleRouting: () => handleProductRouting(product.id), product }} />
                            )
                            :
                            <p>Products to be announced!</p>
                        }
                    </div>
                    <h2 className="heading">{toTitleCase(singularProductsMap[type])} Resources</h2>
                    <div className="resource-list">
                        {(isResourcesReady && resources.length) ?
                            resources.map(resource => (
                                <ResourceCard key={resource.id} {...{ ...resource, handleRouting: () => handleResourceRouting(resource.url) }} />
                            ))
                            :
                            null
                        }
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
    return <Redirect to="/" />
}