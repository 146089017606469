import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { MODES } from '../WaitingRoom/WaitingRoom';
import { Ship, GameBoard, CARRIER, BATTLESHIP, CRUISER, SUBMARINE, DESTROYER } from '.';
import { BOARD_TYPES, SQUARE_OPTIONS } from './GameBoard';
import { socket, SOCKET_CLIENT_EVENTS } from '../WaitingRoom/socket';

const SHIP_CONDITIONS = {
    SUNK: 'SUNK',
    DAMAGED: 'DAMAGED',
    IN_TACT: 'IN_TACT',
}
const ALREADY_TARGETED = 'ALREADY_TARGETED';

export const GamePlay = ({ activeGame, setActiveGame, setMode, opponent, username }) => {
    const mode = MODES.IN_PLAY;

    const getShip = (position) => {
        const fleet = cloneDeep(activeGame[opponent].fleet);
        for (const ship in fleet) {
            if (fleet[ship].positions.find(p => p[0] === position[0] && p[1] === position[1])) {
                return ship;
            }
        }
        return undefined;
    }

    const updateShipCondition = (ship, position) => {
        const newBoard = cloneDeep(activeGame[opponent].board);
        const newFleet = cloneDeep(activeGame[opponent].fleet);
        let condition = '';
        if (ship) {
            const newShip = newFleet[ship];
            if (!newShip.hitPositions.find(p => p[0] === position[0] && p[1] === position[1])) {
                const hitPositions = [...newShip.hitPositions, position];
                newShip.hitPositions = hitPositions;
                newBoard[position[0]][position[1]] = SQUARE_OPTIONS.HIT;
                newFleet[ship] = newShip;
                condition = checkShipCondition(hitPositions, newShip.count);
            } else {
                condition = ALREADY_TARGETED;
            }
        } else if (
            newBoard[position[0]][position[1]] === SQUARE_OPTIONS.HIT ||
            newBoard[position[0]][position[1]] === SQUARE_OPTIONS.MISS
        ) {
            condition = ALREADY_TARGETED;
        } else {
            newBoard[position[0]][position[1]] = SQUARE_OPTIONS.MISS;
        }

        const isOver = checkIsGameOver(newFleet);

        return { condition, board: newBoard, fleet: newFleet, isOver };
    }

    const checkShipCondition = (hits, count) =>
        hits.length && hits.length === count ?
            SHIP_CONDITIONS.SUNK :
            hits.length ?
                SHIP_CONDITIONS.DAMAGED : SHIP_CONDITIONS.IN_TACT;


    const checkIsGameOver = (fleet) => Object.values(fleet)
        .every(ship => {
            const hits = ship.hitPositions;
            const count = ship.count;
            return checkShipCondition(hits, count) === SHIP_CONDITIONS.SUNK;
        });

    const handleMessage = ({ ship, condition, isOver }) => {
        const messages = {
            [username]: '',
            [opponent]: '',
            finished: isOver,
        }
        if (isOver) {
            messages[username] = 'You won!';
            messages[opponent] = 'You lost!';
        } else if (condition === ALREADY_TARGETED) {
            messages[username] = "You've already targeted that square!";
            messages[opponent] = "They've already targeted that square!";
        } else if (condition === SHIP_CONDITIONS.SUNK) {
            messages[username] = `You sunk ${opponent}'s ${ship.toLowerCase()}!`;
            messages[opponent] = `${username} sunk your ${ship.toLowerCase()}!`;
        } else if (condition === SHIP_CONDITIONS.DAMAGED) {
            messages[username] = "Hit!";
            messages[opponent] = "Your ship was hit!";
        } else {
            messages[username] = `Miss! It's ${opponent}'s turn.`;
            messages[opponent] = "They missed! It's your turn.";
        }
        return messages;
    }

    const fire = async (position) => {
        if (activeGame.turn !== username) return;
        const ship = getShip(position);
        const { condition, board, fleet, isOver } = await updateShipCondition(ship, position);
        if (condition === SHIP_CONDITIONS.SUNK) {
            document.querySelector(`.away-board #${ship.toLowerCase()}-copy`).classList.remove('hidden');
        }
        const messages = handleMessage({ ship, condition, isOver });
        socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], {
            ...activeGame,
            [opponent]: { board, fleet },
            messages,
            turn: condition ? username : opponent,
        });
    }

    const getHitPositions = (positions, hits) => {
        return positions.map(position => {
            const row = position[0];
            const column = position[1];
            return hits.find(hit => hit[0] === row && hit[1] === column ? SQUARE_OPTIONS.HIT : '')
        })
    }

    const getShipProps = (type, fleetType) => ({
        type,
        peg: fleetType?.fleet?.[type]?.count,
        orientation: fleetType?.fleet?.[type]?.orientation,
        hitPositions: getHitPositions(fleetType?.fleet?.[type]?.positions, fleetType?.fleet?.[type]?.hitPositions),
        mode: MODES.IN_PLAY,
    });

    const positionShip = (fleet, boardType) => {
        for (const selected in fleet) {
            const { orientation, positions } = fleet[selected];
            if (positions.length) {
                const [row, column] = positions[0];
                const ship = document.querySelector(`.${boardType}-fleet #` + selected.toLowerCase()).cloneNode(true);
                const square = document.querySelector(`.${boardType}-board #` + mode + '-' + row + '-' + column + ' .square-hole');
                ship.setAttribute('id', selected.toLowerCase() + '-copy');
                ship.classList.add('ship-copy');
                if (boardType === 'away') {
                    const condition = checkShipCondition(fleet[selected].hitPositions, fleet[selected].count);
                    if (condition !== SHIP_CONDITIONS.SUNK) {
                        ship.classList.add('hidden');
                    }
                }
                ship.classList.add(orientation.toLowerCase());
                square.appendChild(ship);
            }
        }
    }

    useEffect(() => {
        positionShip(activeGame[username].fleet, 'home');
        positionShip(activeGame[opponent].fleet, 'away');
        // eslint-disable-next-line
    }, [activeGame[username].fleet, activeGame[opponent].fleet]);

    return (
        <div className="game-play-container">
            {/* AWAY */}
            <GameBoard board={activeGame[opponent].board} {...{ mode, fire, type: BOARD_TYPES.AWAY, turn: activeGame.turn === opponent, title: opponent + '\'s Fleet' }} />
            {/* HOME */}
            <GameBoard board={activeGame[username].board} {...{ mode, fire: () => { }, type: BOARD_TYPES.HOME, turn: activeGame.turn === username, title: username + '\'s Fleet' }} />
            <div className="home-fleet">
                <Ship {...getShipProps(CARRIER, activeGame[username])} />
                <Ship {...getShipProps(BATTLESHIP, activeGame[username])} />
                <Ship {...getShipProps(CRUISER, activeGame[username])} />
                <Ship {...getShipProps(SUBMARINE, activeGame[username])} />
                <Ship {...getShipProps(DESTROYER, activeGame[username])} />
            </div>
            <div className="away-fleet">
                <Ship {...getShipProps(CARRIER, activeGame[opponent])} />
                <Ship {...getShipProps(BATTLESHIP, activeGame[opponent])} />
                <Ship {...getShipProps(CRUISER, activeGame[opponent])} />
                <Ship {...getShipProps(SUBMARINE, activeGame[opponent])} />
                <Ship {...getShipProps(DESTROYER, activeGame[opponent])} />
            </div>
        </div>
    )
}