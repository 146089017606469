import { useCallback, useState } from "react";
import { randomize } from "../../../../utils";
import { cards } from "./card-data";

export const useCards = ({ initialHands, formatHands, validateDraw, gameOver }) => {
    const [deck, setDeck] = useState(randomize(cards));
    const [hands, setHands] = useState(initialHands);

    const deal = useCallback(({ numberOfCards, reset }) => {
        const newDeck = [...randomize(deck)];
        const newHands = reset ? Object.assign({}, initialHands) : Object.assign({}, hands);

        for (const key in hands) {
            const hand = hands[key];
            const newHand = [...hand];
            for (let i = newHand.length; i < numberOfCards; i++) {
                const card = newDeck.shift();
                newHand.push(card);
            }
            newHands[key] = newHand;
        }

        const formattedHands = formatHands ? formatHands(newHands) : newHands;

        setDeck(newDeck);
        setHands(formattedHands);
        // eslint-disable-next-line
    }, [gameOver]);

    const draw = ({ numberOfCards = 1, handNumber }) => {
        const newDeck = deck.map(card => ({ ...card }));
        const newHands = { ...hands };
        const newHand = newHands[handNumber].map(card => ({ ...card }));

        for (let i = 0; i < numberOfCards; i++) {
            const card = newDeck.splice(0, 1)[0];
            newHand.push(card);
            setDeck((prev) => [...prev].filter(c => c.id !== card.id));
            setHands((prev) => ({ ...prev, [handNumber]: newHand }));
        }
    }

    const groupDraw = ({ numberOfCards = 1, group = [], format, dealer, onComplete, onFinish }) => {
        const newDeck = deck.map(card => ({ ...card }));
        const newHands = { ...hands };

        let numberOfCardsDrawn = 0;

        for (let i = 0; i < group.length; i++) {
            const hand = newHands[group[i]];
            const newHand = hand.map(card => format ? format({ ...card }) : { ...card });
            let count = 0;
            while (count < numberOfCards && validateDraw(newHand, dealer === group[i])) {
                const [card] = newDeck.splice(0, 1);
                newHand.push(card);
                count++;
                numberOfCardsDrawn++;
            }
            newHands[group[i]] = newHand;
        }

        setDeck(prev => [...prev].slice(numberOfCardsDrawn));
        setHands(prev => Object.assign({}, prev, newHands));

        if (onComplete) onComplete({ deck: newDeck, hands: newHands });
    }

    const reset = () => {
        setDeck(randomize(cards));
        setHands(initialHands);
    };

    return {
        deck,
        hands,
        deal,
        draw,
        groupDraw,
        reset,
    }
}