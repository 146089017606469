import React from 'react';

export const BlogItem = ({ blog, handleRouting }) => (
    <div className="blog-item" key={blog.id} onClick={() => handleRouting(blog.id)}>
        <img className={`img img-${blog.id}`} src={blog.hero} alt={blog.title} />
        <div className="copy-container">
            <div className="top-container">
                <h3 className="name">{blog.title}</h3>
                <div className="text">{blog.text[0].section[0]}</div>
            </div>
            <div className="bottom-container">
                <span className="date">{blog.date}</span>
                <button className="default-btn" onClick={() => handleRouting(blog.id)}>Read More</button>
            </div>
        </div>
    </div>
);
