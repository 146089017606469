import React, { useState } from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import {
    AugmentationDots,
    BassNotes,
    ChromaticScale,
    DynamicNotations,
    MusicStaff,
    MusicSymbolTypes,
    Pitch,
    Rhythm,
    TrebleNotes,
} from '.';
import { trebleNotes, bassNotes } from './notes';
import { useAsset, useResource } from '../../../queries';

export const MusicBasics = () => {
    const [selected, setSelected] = useState();

    const { data: content = [] } = useResource({
        category: 'music',
        id: 'music-basics'
    });

    const { data: trebleClef } = useAsset({
        folder: 'music',
        name: 'treble-clef.png'
    });

    const { data: bassClef } = useAsset({
        folder: 'music',
        name: 'bass-clef.png'
    });

    const { data: tie } = useAsset({
        folder: 'music',
        name: 'tie.png'
    });

    const { data: quarterRest } = useAsset({
        folder: 'music',
        name: 'quarter-rest.png'
    });

    const { data: eighthRest } = useAsset({
        folder: 'music',
        name: 'eighth-rest.png'
    });

    const { TableOfContent } = useTableOfContent(content);

    const fullMusicStaff = (
        <>
            <MusicStaff
                type="treble"
                clef={trebleClef}
                notes={trebleNotes}
                selected={selected}
                setSelected={setSelected}
            />
            <MusicStaff
                type="bass"
                clef={bassClef}
                notes={bassNotes}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    )

    const ChromaticComponent = (
        <>
            <ChromaticScale type="sharp" />
            <p className="or-statement">OR</p>
            <ChromaticScale type="flat" />
        </>
    )

    return (
        <div className="learning-pages">
            <TableOfContent icons={content} />
            <ContentContainer
                content={content}
                additionalContent={{
                    'Notes': (
                        <>
                            {fullMusicStaff}
                            <div className="learning-tips">
                                <p><span className="btn-indicator">Click</span> the buttons above.</p>
                            </div>
                        </>
                    ),
                    'Music Staff': <MusicStaff />,
                    'Treble Clef': <MusicStaff type="treble" clef={trebleClef} />,
                    'Bass Clef': <MusicStaff type="bass" clef={bassClef} />,
                    'Middle C': fullMusicStaff,
                    'Ledger Lines': (
                        <>
                            <MusicStaff
                                type="treble"
                                clef={trebleClef}
                                notes={[
                                    { name: 'C', value: 'middle-c' },
                                    { name: 'D', value: 'low-d' },
                                    { name: 'G', value: 'high-g' },
                                ]}
                            />
                            <MusicStaff
                                type="bass"
                                clef={bassClef}
                                notes={[
                                    { name: 'G', value: 'low-g' },
                                    { name: 'B', value: 'bass-b' },
                                    { name: 'C', value: 'bass-middle-c' },
                                ]}
                            />
                        </>
                    ),
                    'Treble Notes': <TrebleNotes />,
                    'Bass Notes': <BassNotes />,
                    'Pitches Intro': <Pitch />,
                    'Chromatic Scale': ChromaticComponent,
                    'Accidental-less': ChromaticComponent,
                    'Enharmonic Spelling': (
                        <>
                            <ChromaticScale type="sharp" showOne={true} />
                            <p className="or-statement">OR</p>
                            <ChromaticScale type="flat" showOne={true} />
                        </>
                    ),
                    'Key Signatures': (
                        <MusicStaff
                            type="treble"
                            clef={trebleClef}
                            notes={[]}
                            additionalComponent={(
                                <>
                                    <div className={`key-signature key-signature-f`}>♯</div>
                                    <div className={`key-signature key-signature-c`}>♯</div>
                                </>
                            )}
                        />
                    ),
                    'Scattered Pitches': (
                        <MusicStaff
                            type="treble"
                            clef={trebleClef}
                            notes={[]}
                            additionalComponent={(
                                <>
                                    <div className={`c note`}></div>

                                    <div className="pitch natural-pitch">♮</div>
                                    <div className="measure-bar"></div>

                                    <div className={`key-signature key-signature-f`}>♯</div>
                                    <div className={`key-signature key-signature-c`}>♯</div>
                                </>
                            )}
                        />
                    ),
                    'Rhythm Intro': <Rhythm />,
                    'Time Signatures': (
                        <MusicStaff
                            type="treble"
                            clef={trebleClef}
                            notes={[]}
                            additionalComponent={(
                                <>
                                    <div className="time-signature top-signature">4</div>
                                    <div className="time-signature bottom-signature">4</div>
                                </>
                            )}
                        />
                    ),
                    'Note Types': (
                        <MusicSymbolTypes
                            type="beat"
                            symbolType={[
                                {
                                    name: 'Whole Note',
                                    value: 'whole-note',
                                    beats: '4',
                                },
                                {
                                    name: 'Half Note',
                                    value: 'half-note',
                                    beats: '2',
                                },
                                {
                                    name: 'Quarter Note',
                                    value: 'quarter-note',
                                    beats: '1',
                                    font: '♩',
                                },
                                {
                                    name: 'Eighth Note',
                                    value: 'eighth-note',
                                    beats: '1 / 2',
                                    font: '♪',
                                },
                                {
                                    name: 'Sixteenth Note',
                                    value: 'sixteenth-note',
                                    beats: '1 / 4',
                                    font: '♬',
                                },
                            ]}
                        />
                    ),
                    'Augmentation Dots': <AugmentationDots />,
                    'Tenuto Ties': <img src={tie} className="tie" alt="tenuto tie" />,
                    'Rest Types': (
                        <MusicSymbolTypes
                            type="rest"
                            symbolType={[
                                {
                                    name: 'Whole Rest',
                                    value: 'whole-rest',
                                    beats: '4',
                                },
                                {
                                    name: 'Half Rest',
                                    value: 'half-rest',
                                    beats: '2',
                                },
                                {
                                    name: 'Quarter Rest',
                                    value: 'quarter-rest',
                                    beats: '1',
                                    symbol: quarterRest,
                                },
                                {
                                    name: 'Eighth Rest',
                                    value: 'eighth-rest',
                                    beats: '1 / 2',
                                    symbol: eighthRest,
                                },
                            ]}
                        />
                    ),
                    'Dynamic Notations': <DynamicNotations />
                }}
            />
        </div>
    )
}