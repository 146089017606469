import axios from 'axios';
import { useMutation } from 'react-query';

export const mutationUrls = {
    feedback: '/api/feedback',
    message: '/api/message',
    sliderPuzzle: '/api/slider-puzzle',
    subscribe: '/api/subscribe',
}

export const useMutationQuery = () => useMutation({
    mutationFn: ({ url, body }) => axios.post(url, body),
});