import React from 'react';

export const DateSelector = ({
    selectedDay,
    selectedMonth,
    setSelectedDay,
    setSelectedMonth,
}) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const days = [];
    let numOfDays = 31;
    if (selectedMonth === 'Feb') {
        numOfDays = 28;
    } else if (selectedMonth === 'Apr' || selectedMonth === 'Jun' || selectedMonth === 'Sep' || selectedMonth === 'Nov') {
        numOfDays = 30;
    }

    for (let num = 1; num <= numOfDays; num++) {
        days.push(num);
    }

    return (
        <div className="date-selector-dropdowns p-abs flex-col align-ctr">
            <span className="birth-date-label">Birth Date:</span>
            <div>
                <select name="month" id="month-selector" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                    {months.map(month => <option key={month} value={month}>{month}</option>)}
                </select>
                <select name="day" id="day-selector" onChange={(e) => setSelectedDay(Number(e.target.value))} value={selectedDay}>
                    {days.map(day => <option key={day} value={day}>{day}</option>)}
                </select>
            </div>
        </div >
    )
}