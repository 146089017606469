import React from 'react';
import { formatContent } from './format-content';

export const Answer = ({
    questions,
    selectedIndex
}) => (
    <div className="answer-container">
        {formatContent(questions?.[selectedIndex]?.answer, 'answer')}
    </div>
)
