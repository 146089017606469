import React from 'react';
import { Redirect } from 'react-router-dom';
import { Breadcrumb, FeedbackForm, Footer, Loader } from '../components';
import { useBlog } from '../../queries';
import { useLoader } from '../../hooks/use-loader';

const truncate = (text) => text.substring(0, 30);

const videoExtensions = ['mp4'];
const imgExtensions = ['jpeg', 'png', 'jpg', 'JPEG', 'JPG'];

export const Blog = ({
    checkHeight,
    match,
}) => {
    const { id } = match.params;
    const { data: blog, isFetching, isLoading: isBlogLoading } = useBlog({ id });
    const isReady = !isBlogLoading && !isFetching;
    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />

    if (blog && Object.keys(blog).length) {
        return (
            <div id="blog-details-page" className="blog page" onScroll={() => checkHeight('.breadcrumbs')}>
                <div className="container">
                    <Breadcrumb type="blog" />
                    <div className="blog-container">
                        <img className="hero" src={blog.hero} alt={blog.title} />
                        <h2 className="title">{blog.title}</h2>
                        <p className="author">by {blog.author}</p>
                        <p className="date">{blog.date}</p>
                        <ul className="list">{blog?.text?.map(({ heading, section, visuals }, index) => (
                            <li key={index} className="list-item">
                                {heading ? <h4 className='section-heading'>{heading}</h4> : null}
                                {visuals?.filter(visual => visual.placement === 'before' && visual.source)?.map((visual, index) => {
                                    if (!visual.caption && !!imgExtensions.find(src => visual.source.includes(src))) return <img key={truncate(visual.caption) + '-' + index} className="content-img" src={visual.source} alt={heading + '-' + visual.type} />
                                    if (!visual.caption && !!videoExtensions.find(visual.source)) {
                                        return (
                                            <video controls autoPlay className="content-img">
                                                <source src={visual.source} type="video/mp4" alt={heading + '-' + visual.type} />
                                            </video>
                                        )
                                    }
                                    return (
                                        <div key={truncate(visual.caption) + '-' + index} className='list-item-text caption'>
                                            {!!imgExtensions.find(src => visual.source.includes(src)) && <img className="content-img" src={visual.source} alt={heading + '-' + visual.type} />}
                                            {!!videoExtensions.find(src => visual.source.includes(src)) && (
                                                <video controls autoPlay className="content-img">
                                                    <source src={visual.source} type="video/mp4" alt={heading + '-' + visual.type} />
                                                </video>
                                            )}
                                            <p><strong>Caption:</strong> {visual.caption}</p>
                                        </div>
                                    )
                                })}
                                <ul className='nested-list'>{section.map((innerItem, innerItemIndex) => {
                                    if (typeof innerItem === 'string') {
                                        return <li key={innerItemIndex} className='list-item-text'>{innerItem}</li>
                                    }
                                    if (innerItem.inline) {
                                        return (
                                            <div key={innerItemIndex} className='list-item-text'>
                                                <li className='nested-list-item-text'><strong>{innerItem.subheading}</strong> {innerItem.subsection[0]}</li>
                                                {innerItem.subsection.slice(1).map((item, doubleNestedIndex) => (<li key={doubleNestedIndex} className='nested-list-item-text'>{item}</li>))}
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={innerItemIndex} className='list-item-text'>
                                            <li className='nested-list-item-text'>{innerItem.subheading}</li>
                                            <ul className='nested-list'>
                                                {innerItem.subsection.map((subItem, subItemIndex) => {
                                                    return <li key={subItemIndex} className='nested-list-item-text'>{subItem}</li>
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })}</ul>
                                {visuals?.filter(visual => visual.placement === 'after' && visual.source)?.map(visual => {
                                    if (!visual.caption && !!imgExtensions.find(visual.source)) return <img key={truncate(visual.caption) + '-' + index} className="content-img" src={visual.source} alt={heading + '-' + visual.type} />
                                    if (!visual.caption && !!videoExtensions.find(visual.source)) {
                                        return (
                                            <video controls autoPlay muted className="content-img">
                                                <source src={visual.source} type="video/mp4" alt={heading + '-' + visual.type} />
                                            </video>
                                        )
                                    }
                                    return (
                                        <div key={truncate(visual.caption) + '-' + index} className='list-item-text caption'>
                                            {!!imgExtensions.find(src => visual.source.includes(src)) && <img className="content-img" src={visual.source} alt={heading + '-' + visual.type} />}
                                            {!!videoExtensions.find(src => visual.source.includes(src)) && (
                                                <video controls autoPlay muted className="content-img">
                                                    <source src={visual.source} type="video/mp4" alt={heading + '-' + visual.type} />
                                                </video>
                                            )}
                                            <p><strong>Caption:</strong> {visual.caption}</p>
                                        </div>
                                    )
                                })}
                            </li>
                        ))}</ul>
                    </div>
                </div>
                <FeedbackForm product={blog.title} type="blog" />
                <Footer />
            </div>
        )
    } else if (isReady) {
        return <Redirect to="/" />
    }
    return null;
}
