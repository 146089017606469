export const toTitleCase = (text, delimiter = ' ') =>
    text.split(delimiter)
        .map(word => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase())
        .join(' ');

export const convertListToString = (items, delimiter = ', ') => {
    return items.reduce((acc, item, index) => {
        if (index === 0) return item;
        if (index === items.length - 1) return `${acc} and ${item}`;
        return `${acc}${delimiter}${item}`;
    }, '');
}