import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { App } from './App';
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      fetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

// const environment = process.env.NODE_ENV;

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        {/* {environment !== 'production' && <ReactQueryDevtools initialIsOpen={false} />} */}
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
);
