import React from 'react';

export const ChromaticScale = ({
    scale,
}) => {
    const all = [
        'C',
        'C♯',
        'D',
        'D♯',
        'E',
        'F',
        'F♯',
        'G',
        'G♯',
        'A',
        'A♯',
        'B',
        'C',
    ];

    const getPosition = {
        0: 'root-position',
        1: 'second-position',
        2: 'third-position',
        3: 'fourth-position',
        4: 'fifth-position',
        5: 'sixth-position',
        6: 'seventh-position',
    }

    return (
        <div className="scale-chromatic-scale-container learning-tips">
            {all.map((note, index) => {
                const position = scale.indexOf(note);
                if (position > -1) return <span key={index} className={`note big-blue ${getPosition[position]}`}>{note}</span>
                return <span key={index} className="note">{note}</span>
            })}
        </div>
    )
}