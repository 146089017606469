import React from 'react';
import { Footer, Loader } from "../components";
import { useMission, usePersons } from '../../queries';
import { useLoader } from '../../hooks/use-loader';

const PersonItem = ({ person, index }) => (
    <div className="person-container">
        <img src={person.img} alt={person.name} className={`person-img person-img-${index}`} />
        <h6 className='person-name-and-title'>Kevin Ung, <span className='person-title'>CEO and Founder</span></h6>
        <p className='bio'>Hi, my name is Kevin Ung. I love learning and problem solving. I dabble with the guitar, enjoy a good pickleball game, Ravenclaw through and through, listener of Christmas music spontaneously throughout the year, and I want to inspire others to follow their passions!</p>
    </div>
);

export const About = ({ checkHeight }) => {
    const { data: mission, isLoading: isMissionLoading } = useMission();
    const { data: persons, isFetched, isLoading: isPersonLoading } = usePersons();

    const isReady = !isMissionLoading && !isPersonLoading;
    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />

    return (
        <div id="about-page" className="about page" onScroll={() => checkHeight('.heading')}>
            <div className="container about">
                <div className="about-container">
                    <div className="about-company">
                        <h1 className="heading">About the Company</h1>
                        <p className='about-text'>
                            {mission}
                            {/* <br /><br />Eventually we would love a percentage of our proceeds to be donated to organizations */}
                        </p>
                    </div>
                    <div className="about-people">
                        <h2 className="heading">About the People</h2>
                        {isFetched && persons.length && <div className="person-list-container">
                            {persons.map((person, index) => <PersonItem key={index} person={person} index={index} />)}
                        </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}