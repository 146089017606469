import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { MusicStaff } from './MusicStaff';
import { MusicButtons } from './MusicButtons';
import { useAsset } from '../../../queries';

export const TrebleNotes = () => {
    const [hidden, setHidden] = useState([]);
    const spaces = ['f', 'a', 'c', 'high-e'];
    const lines = ['e', 'g', 'b', 'd', 'high-f'];

    const hiddenTypes = {
        lines: () => setHidden(spaces),
        spaces: () => setHidden(lines),
        reset: () => setHidden([]),
    }

    const { data: trebleClef } = useAsset({
        folder: 'music',
        name: 'treble-clef.png'
    });

    return (
        <>
            <MusicStaff
                type="treble"
                clef={trebleClef}
                notes={[
                    { name: 'E', value: 'e' },
                    { name: 'F', value: 'f' },
                    { name: 'G', value: 'g' },
                    { name: 'A', value: 'a' },
                    { name: 'B', value: 'b' },
                    { name: 'C', value: 'c' },
                    { name: 'D', value: 'd' },
                    { name: 'E', value: 'high-e' },
                    { name: 'F', value: 'high-f' },
                ]}
                hidden={hidden}
            />
            <MusicButtons hiddenTypes={hiddenTypes} />

            <div className="learning-tips">
                <p><span className="btn-indicator">Click</span> the buttons above.</p><br /><br />
                {isEqual(hidden, []) && <p>Knowing the notes on the treble staff will come in handy as you begin your musical career/journey.</p>}
                {isEqual(hidden, spaces) && <p>A mnemonic that can help you remember the notes for each line on the treble staff:<br /><br /><span className="mnemonic-tip"><span className="underline">E</span>very <span className="underline">G</span>ood <span className="underline">B</span>oy <span className="underline">D</span>eserves <span className="underline">F</span>udge.</span></p>}
                {isEqual(hidden, lines) && <p>A way to remember the notes for each space on the treble staff is remembering the word it spells:<br /><br /><span className="mnemonic-tip"><span className="underline">F</span> <span className="underline">A</span> <span className="underline">C</span> <span className="underline">E</span></span></p>}
            </div>
        </>
    )
}