import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    S1, S2, S3, S4, S5, S6, S7, S8, S9, // switches
    D1, D2, D3, D4, D5, D6, D7, D8, D9, // dashed
    C1, C2, C3, C4, C5, C6, C7, C8, C9, // controls
    H1, H2, H3, H4, H5, H6, H7, H8, H9, // hard
    B, // block
    P, // prior
} from '..';


export const useSliderHook = ({
    puzzle,
    next,
    toastMessages,
    getPiecePosition,
}) => {
    const [inMotion, setInMotion] = useState(false);
    const [piecePosition, setPiecePosition] = useState([]) // [row, column];
    const [switch1Enabled, setSwitch1Enabled] = useState(false); // enabled means harden
    const [switch2Enabled, setSwitch2Enabled] = useState(false); // enabled means harden
    const [switch3Enabled, setSwitch3Enabled] = useState(false); // enabled means harden
    const [switch4Enabled, setSwitch4Enabled] = useState(false); // enabled means harden
    const [switch5Enabled, setSwitch5Enabled] = useState(false); // enabled means harden
    const [switch6Enabled, setSwitch6Enabled] = useState(false); // enabled means harden
    const [switch7Enabled, setSwitch7Enabled] = useState(false); // enabled means harden
    const [switch8Enabled, setSwitch8Enabled] = useState(false); // enabled means harden
    const [switch9Enabled, setSwitch9Enabled] = useState(false); // enabled means harden
    const [control1Enabled, setControl1Enabled] = useState(false); // enabled means hollow
    const [control2Enabled, setControl2Enabled] = useState(false); // enabled means hollow
    const [control3Enabled, setControl3Enabled] = useState(false); // enabled means hollow
    const [control4Enabled, setControl4Enabled] = useState(false); // enabled means hollow
    const [control5Enabled, setControl5Enabled] = useState(false); // enabled means hollow
    const [control6Enabled, setControl6Enabled] = useState(false); // enabled means hollow
    const [control7Enabled, setControl7Enabled] = useState(false); // enabled means hollow
    const [control8Enabled, setControl8Enabled] = useState(false); // enabled means hollow
    const [control9Enabled, setControl9Enabled] = useState(false); // enabled means hollow

    useEffect(() => {
        const position = getPiecePosition();
        setPiecePosition(position);
        // eslint-disable-next-line
    }, [puzzle])

    const disableAll = () => {
        setSwitch1Enabled(false);
        setSwitch2Enabled(false);
        setSwitch3Enabled(false);
        setSwitch4Enabled(false);
        setSwitch5Enabled(false);
        setSwitch6Enabled(false);
        setSwitch7Enabled(false);
        setSwitch8Enabled(false);
        setSwitch9Enabled(false);
        setControl1Enabled(false);
        setControl2Enabled(false);
        setControl3Enabled(false);
        setControl4Enabled(false);
        setControl5Enabled(false);
        setControl6Enabled(false);
        setControl7Enabled(false);
        setControl8Enabled(false);
        setControl9Enabled(false);
    }

    const reset = () => {
        const position = getPiecePosition();
        setPiecePosition(position);
        setInMotion(false);
        disableAll();
    }

    const checkNextValueIsBlock = (nextValue) => {
        const isString = typeof nextValue === 'string';
        return (
            nextValue === B ||
            (isString && nextValue.includes(D1) && switch1Enabled) ||
            (isString && nextValue.includes(D2) && switch2Enabled) ||
            (isString && nextValue.includes(D3) && switch3Enabled) ||
            (isString && nextValue.includes(D4) && switch4Enabled) ||
            (isString && nextValue.includes(D5) && switch5Enabled) ||
            (isString && nextValue.includes(D6) && switch6Enabled) ||
            (isString && nextValue.includes(D7) && switch7Enabled) ||
            (isString && nextValue.includes(D8) && switch8Enabled) ||
            (isString && nextValue.includes(D9) && switch9Enabled) ||
            (isString && nextValue.includes(H1) && !control1Enabled) ||
            (isString && nextValue.includes(H2) && !control2Enabled) ||
            (isString && nextValue.includes(H3) && !control3Enabled) ||
            (isString && nextValue.includes(H4) && !control4Enabled) ||
            (isString && nextValue.includes(H5) && !control5Enabled) ||
            (isString && nextValue.includes(H6) && !control6Enabled) ||
            (isString && nextValue.includes(H7) && !control7Enabled) ||
            (isString && nextValue.includes(H8) && !control8Enabled) ||
            (isString && nextValue.includes(H9) && !control9Enabled) ||
            (nextValue === undefined)
        )
    }

    const updatePosition = ({ type, position, direction }) => {
        setInMotion(true);
        const row = position[0];
        const column = position[1];
        const prevValue = puzzle[row][column];

        const checkData = () => {
            switch (type) {
                case 'row':
                    if (direction === '+') return row < puzzle.length - 1;
                    if (direction === '-') return row > 0;
                    break;
                case 'column':
                    if (direction === '+') return column < puzzle[0].length - 1;
                    if (direction === '-') return column > 0;
                    break;
                default:
                    return false;
            }
        }

        const check = checkData();

        const updatePositionAndPiece = (newPosition) => {
            setPiecePosition(newPosition);
            setTimeout(() => updatePosition({ type, position: newPosition, direction }), 50);
        }

        const handleIfStatements = (newPosition, value, nextValue) => {
            const isString = typeof value === 'string';
            if (Number(value) === 2 && typeof prevValue === 'string' && prevValue.includes(P)) {
                // finishing case
                setPiecePosition(newPosition);
                toast(toastMessages.success, { className: 'success' });
                setTimeout(() => {
                    setInMotion(false);
                    next();
                }, 3000)
            } else if (isString && value.includes(S1) && checkNextValueIsBlock(nextValue)) {
                // toggle S1 + hardening T1
                updatePositionAndPiece(newPosition);
                setSwitch1Enabled(!switch1Enabled);
            } else if (isString && value.includes(S2) && checkNextValueIsBlock(nextValue)) {
                // toggle S2 + hardening T2
                updatePositionAndPiece(newPosition);
                setSwitch2Enabled(!switch2Enabled);
            } else if (isString && value.includes(S3) && checkNextValueIsBlock(nextValue)) {
                // toggle S3 + hardening T3
                updatePositionAndPiece(newPosition);
                setSwitch3Enabled(!switch3Enabled);
            } else if (isString && value.includes(S4) && checkNextValueIsBlock(nextValue)) {
                // toggle S4 + hardening T4
                updatePositionAndPiece(newPosition);
                setSwitch4Enabled(!switch4Enabled);
            } else if (isString && value.includes(S5) && checkNextValueIsBlock(nextValue)) {
                // toggle S5 + hardening T5
                updatePositionAndPiece(newPosition);
                setSwitch5Enabled(!switch5Enabled);
            } else if (isString && value.includes(S6) && checkNextValueIsBlock(nextValue)) {
                // toggle S6 + hardening T6
                updatePositionAndPiece(newPosition);
                setSwitch6Enabled(!switch6Enabled);
            } else if (isString && value.includes(S7) && checkNextValueIsBlock(nextValue)) {
                // toggle S7 + hardening T7
                updatePositionAndPiece(newPosition);
                setSwitch7Enabled(!switch7Enabled);
            } else if (isString && value.includes(S8) && checkNextValueIsBlock(nextValue)) {
                // toggle S8 + hardening T8
                updatePositionAndPiece(newPosition);
                setSwitch8Enabled(!switch8Enabled);
            } else if (isString && value.includes(S9) && checkNextValueIsBlock(nextValue)) {
                // toggle S9 + hardening T9
                updatePositionAndPiece(newPosition);
                setSwitch9Enabled(!switch9Enabled);
            } else if (isString && value.includes(C1) && checkNextValueIsBlock(nextValue)) {
                // toggle C11 + hollowing O11
                updatePositionAndPiece(newPosition);
                setControl1Enabled(!control1Enabled);
            } else if (isString && value.includes(C2) && checkNextValueIsBlock(nextValue)) {
                // toggle C12 + hollowing O12
                updatePositionAndPiece(newPosition);
                setControl2Enabled(!control2Enabled);
            } else if (isString && value.includes(C3) && checkNextValueIsBlock(nextValue)) {
                // toggle C13 + hollowing O13
                updatePositionAndPiece(newPosition);
                setControl3Enabled(!control3Enabled);
            } else if (isString && value.includes(C4) && checkNextValueIsBlock(nextValue)) {
                // toggle C14 + hollowing O14
                updatePositionAndPiece(newPosition);
                setControl4Enabled(!control4Enabled);
            } else if (isString && value.includes(C5) && checkNextValueIsBlock(nextValue)) {
                // toggle C15 + hollowing O15
                updatePositionAndPiece(newPosition);
                setControl5Enabled(!control5Enabled);
            } else if (isString && value.includes(C6) && checkNextValueIsBlock(nextValue)) {
                // toggle C16 + hollowing O16
                updatePositionAndPiece(newPosition);
                setControl6Enabled(!control6Enabled);
            } else if (isString && value.includes(C7) && checkNextValueIsBlock(nextValue)) {
                // toggle C17 + hollowing O17
                updatePositionAndPiece(newPosition);
                setControl7Enabled(!control7Enabled);
            } else if (isString && value.includes(C8) && checkNextValueIsBlock(nextValue)) {
                // toggle C18 + hollowing O18
                updatePositionAndPiece(newPosition);
                setControl8Enabled(!control8Enabled);
            } else if (isString && value.includes(C9) && checkNextValueIsBlock(nextValue)) {
                // toggle C19 + hollowing O19
                updatePositionAndPiece(newPosition);
                setControl9Enabled(!control9Enabled);
            } else if (isString && value.includes(D1) && !switch1Enabled) {
                // go through the T1 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D2) && !switch2Enabled) {
                // go through the T2 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D3) && !switch3Enabled) {
                // go through the T3 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D4) && !switch4Enabled) {
                // go through the T4 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D5) && !switch5Enabled) {
                // go through the T5 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D6) && !switch6Enabled) {
                // go through the T6 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D7) && !switch7Enabled) {
                // go through the T7 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D8) && !switch8Enabled) {
                // go through the T8 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(D9) && !switch9Enabled) {
                // go through the T9 since it's toggled off
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H1) && control1Enabled) {
                // go through the O11 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H2) && control2Enabled) {
                // go through the O12 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H3) && control3Enabled) {
                // go through the O13 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H4) && control4Enabled) {
                // go through the O14 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H5) && control5Enabled) {
                // go through the O15 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H6) && control6Enabled) {
                // go through the O16 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H7) && control7Enabled) {
                // go through the O17 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H8) && control8Enabled) {
                // go through the O18 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (isString && value.includes(H9) && control9Enabled) {
                // go through the O19 since it's toggled on
                updatePositionAndPiece(newPosition);
            } else if (
                isString && (
                    value.includes(B) ||
                    (value.includes(D1) && switch1Enabled) ||
                    (value.includes(D2) && switch2Enabled) ||
                    (value.includes(D3) && switch3Enabled) ||
                    (value.includes(D4) && switch4Enabled) ||
                    (value.includes(D5) && switch5Enabled) ||
                    (value.includes(D6) && switch6Enabled) ||
                    (value.includes(D7) && switch7Enabled) ||
                    (value.includes(D8) && switch8Enabled) ||
                    (value.includes(D9) && switch9Enabled) ||
                    value.includes(H1) ||
                    value.includes(H2) ||
                    value.includes(H3) ||
                    value.includes(H4) ||
                    value.includes(H5) ||
                    value.includes(H6) ||
                    value.includes(H7) ||
                    value.includes(H8) ||
                    value.includes(H9)
                )) {
                // hit a block
                setInMotion(false);
            } else {
                // no value
                updatePositionAndPiece(newPosition);
            }
        }

        if (type === 'row') {
            if (check) {
                const newRow = direction === '+' ? row + 1 : row - 1;
                const nextRow = direction === '+' ? row + 2 : row - 2;
                const value = puzzle[newRow][column];
                const nextValue = puzzle[nextRow]?.[column];
                const position = [newRow, column];
                handleIfStatements(position, value, nextValue);
            } else {
                setInMotion(false);
            }
        } else {
            if (check) {
                const newColumn = direction === '+' ? column + 1 : column - 1;
                const nextColumn = direction === '+' ? column + 2 : column - 2;
                const value = puzzle[row][newColumn];
                const nextValue = puzzle[row]?.[nextColumn];
                const position = [row, newColumn];
                handleIfStatements(position, value, nextValue);
            } else {
                setInMotion(false);
            }
        }
    }

    return {
        inMotion,
        reset,
        piecePosition,
        setPiecePosition,
        updatePosition,
        switch1Enabled,
        switch2Enabled,
        switch3Enabled,
        switch4Enabled,
        switch5Enabled,
        switch6Enabled,
        switch7Enabled,
        switch8Enabled,
        switch9Enabled,
        control1Enabled,
        control2Enabled,
        control3Enabled,
        control4Enabled,
        control5Enabled,
        control6Enabled,
        control7Enabled,
        control8Enabled,
        control9Enabled,
    }
}

