import React from 'react';
import { BlogItem, Footer, Loader } from '../components';
import { useBlogs } from '../../queries';
import { useLoader } from '../../hooks/use-loader';

export const Blogs = ({
    checkHeight,
    history,
}) => {
    const { data: blogs, isFetching, isLoading: isBlogsLoading } = useBlogs();
    const handleRouting = (id) => history.push({ pathname: `/blogs/${id}` });

    const isReady = !isBlogsLoading && !isFetching;

    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />;

    return (
        <div id="blogs-page" className="blogs page" onScroll={() => checkHeight('.heading')}>
            <div className="container blogs-container">
                <h1 className="heading">Blogs</h1>
                <div className="blog-list">
                    {(!!isReady && blogs) ?
                        blogs.map(blog =>
                            <BlogItem key={blog.id}  {...{ handleRouting, blog }} />
                        )
                        :
                        <p>Blogs to be announced!</p>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}