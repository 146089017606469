import React, { useEffect, useState } from 'react';
import { randomize } from '../../../../utils';
import { useTab } from '../../../components';
import { NumberTab, RandomTab, rebusPuzzles, SelectedPuzzle, tabData } from '.';
import { toast } from 'react-toastify';

const randomizedPuzzles = randomize(rebusPuzzles);

export const Rebus = ({ toastMessages }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedPuzzle, setSelectedPuzzle] = useState(randomizedPuzzles[0]);
    const { selectedTab, getTabs } = useTab({ tabData });

    const findNumber = (number) => {
        const foundIndex = rebusPuzzles.findIndex(puzzle => Number(puzzle.id) === number);
        setSelectedIndex(foundIndex);
    }

    const getPuzzle = () => {
        let selected;
        if (selectedTab === 'number') {
            selected = rebusPuzzles[selectedIndex];
        } else {
            selected = randomizedPuzzles[selectedIndex];
        }
        setSelectedPuzzle(selected);
    }

    const next = () => {
        let isLast;
        if (selectedTab === 'number') {
            const lastIndex = rebusPuzzles.length - 1;
            const lastItem = rebusPuzzles[lastIndex];
            isLast = selectedPuzzle?.id === lastItem?.id;
        } else {
            const lastIndex = randomizedPuzzles.length - 1;
            isLast = selectedIndex === lastIndex;
        }

        if (!isLast) {
            if (selectedTab === 'number') {
                const nextId = selectedPuzzle.id + 1;
                const foundIndex = rebusPuzzles.findIndex(puzzle => Number(puzzle.id) === nextId);
                if (foundIndex > -1) {
                    setSelectedIndex(foundIndex);
                } else {
                    toast(toastMessages.missing, { className: 'error' });
                }
            } else {
                setSelectedIndex(selectedIndex + 1);
            }
        } else {
            toast(toastMessages.missing, { className: 'error' });
        }
    }

    useEffect(() => {
        getPuzzle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, selectedTab])

    return (
        <section className="rebus-board">
            <SelectedPuzzle handleNext={next} selectedPuzzle={selectedPuzzle} toastMessages={toastMessages} />
            <div className="rebus-tabs-container">
                {getTabs()}
                {selectedTab === 'random' && <RandomTab />}
                {selectedTab === 'number' && (
                    <NumberTab
                        puzzles={rebusPuzzles}
                        selectedPuzzle={selectedPuzzle}
                        findNumber={findNumber}
                    />
                )}
            </div>
        </section>
    )
}