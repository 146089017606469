import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { useResource } from '../../../queries';

export const WasteConservation = () => {
    const { data: content = [] } = useResource({
        category: 'science',
        id: 'waste-conservation'
    });

    const { TableOfContent } = useTableOfContent(content);

    return (
        <div className="learning-pages">
            <TableOfContent icons={content} />
            <ContentContainer
                content={content}
            />
        </div>
    )
}