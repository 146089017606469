const currentDate = (new Date()).getTime();
const recent = 90;

export const checkRecent = (date) => {
    const releaseDate = new Date(date).getTime();
    const difference = (releaseDate - currentDate) / (1000 * 3600 * 24);
    return difference < recent;
}

export const checkPreOrder = (date) => {
    const releaseDate = new Date(date).getTime();
    const difference = (releaseDate - currentDate) / (1000 * 3600 * 24) ?? 0;
    return difference < 0;
}

export const formatDateInput = (value) => {
    // eslint-disable-next-line
    let date = value.replace(/[^0-9\/]$/g, '');
    const match = date.match(/^(\d{0,2})(\d{0,2})$/);
    if (match) {
        date = `${match[1]}/${match[2]}`;
    }
    return date;
}