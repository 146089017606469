import React from 'react';

export const formatContent = (content) => Array.isArray(content) ? (
    content.map((text, index) => {
        if (Array.isArray(text)) {
            return (
                <ul key={index} className="content-list">
                    {text.map((bullet, listIndex) => <li key={index + '-' + listIndex} className="content-list-item">{bullet}</li>)}
                </ul>
            )
        }
        return <p key={index} className="content">{text}</p>
    })
) : (
    <p className="content">{content}</p>
);