import { randomize } from '../../../../../utils';

const piecesMapping = {
    B: 'B',
    S1: 'S1',
    S2: 'S2',
    S3: 'S3',
    S4: 'S4',
    S5: 'S5',
    S6: 'S6',
    S7: 'S7',
    S8: 'S8',
    S9: 'S9',
    D1: 'D1',
    D2: 'D2',
    D3: 'D3',
    D4: 'D4',
    D5: 'D5',
    D6: 'D6',
    D7: 'D7',
    D8: 'D8',
    D9: 'D9',
    C1: 'C1',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4',
    C5: 'C5',
    C6: 'C6',
    C7: 'C7',
    C8: 'C8',
    C9: 'C9',
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4',
    H5: 'H5',
    H6: 'H6',
    H7: 'H7',
    H8: 'H8',
    H9: 'H9',
    P: 'P',
}

export const {
    S1, S2, S3, S4, S5, S6, S7, S8, S9, // switches
    D1, D2, D3, D4, D5, D6, D7, D8, D9, // dashed
    C1, C2, C3, C4, C5, C6, C7, C8, C9, // controls
    H1, H2, H3, H4, H5, H6, H7, H8, H9, // hard
    B, // block
    P, // prior
} = piecesMapping;


const sliderMap = [
    {
        "id": 1,
        "puzzle": [
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", B, "", "", ""],
            ["", "", "", "", "", "", P, 2],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", B, "", "", "", ""],
            ["", "", "", "", "", "", "", B],
            [1, "", "", "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 2,
        "puzzle": [
            ["", "", "", B, "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", B, B, "", "", ""],
            ["", "", "", B, 1, "", "", ""],
            ["", "", "", "", "", "", B, B],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", P, 2],
            ["", "", B, "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 3,
        "puzzle": [
            ["", "", "", B, "", "", "", ""],
            ["", "", "", "", "", "", P, 2],
            ["", "", "", "", "", B, "", ""],
            ["", "", 1, "", "", "", "", B],
            [B, "", "", "", "", "", "", B],
            ["", "", "", "", "", "", "", B],
            ["", "", B, "", "", "", "", ""],
            ["", "", "", "", "", B, "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 4,
        "puzzle": [
            ["", "", "", "", B, "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", B, 1],
            ["", "", B, "", "", "", "", B],
            ["", "", "", B, "", "", P, ""],
            ["", "", "", "", "", "", 2, ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 5,
        "puzzle": [
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", P, 1, B, B],
            ["", "", "", B, 2, B, "", ""],
            ["", "", "", "", P, "", "", ""],
            [B, "", "", B, "", "", "", ""],
            ["", "", "", B, "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 6,
        "puzzle": [
            [1, B, "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", P, "", "", "", "", ""],
            ["", "", 2, "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 7,
        "puzzle": [
            ["", "", "", B, "", "", "", ""],
            ["", "", "", B, "", "", "", ""],
            ["", "", "", B, "", "", "", ""],
            ["", "", "", B, "", "", "", ""],
            ["", "", "", "", "", "", P, 2],
            [B, "", "", B, "", "", "", ""],
            [1, "", "", B, "", "", "", ""],
            ["", "", "", B, "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 8,
        "puzzle": [
            ["", "", "", "", "", "", "", ""],
            ["", B, B, B, B, B, B, ""],
            ["", B, "", "", "", "", B, ""],
            ["", B, "", B, B, "", B, ""],
            ["", B, "", 1, B, "", B, ""],
            ["", B, "", "", B, "", B, ""],
            ["", B, B, B, B, "", B, P],
            ["", "", "", "", "", "", B, 2]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 9,
        "puzzle": [
            ["", "", "", "", "", "", P, 2],
            ["", B, B, "", "", B, B, B],
            ["", B, "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", B, 1, "", "", "", B, ""],
            ["", B, B, "", "", B, B, ""],
            ["", "", "", "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 10,
        "puzzle": [
            ["", "", "", "", "", "", "", B],
            [2, P, "", "", "", "", "", ""],
            ["", "", "", "", "", B, "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", B, "", "", "", ""],
            ["", "", B, "", "", "", "", ""],
            ["", B, "", "", "", "", "", ""],
            [1, "", "", "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 11,
        "puzzle": [
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", B, B, B, B, ""],
            ["", "", "", B, "", "", B, ""],
            ["", "", "", B, "", "", "", ""],
            [P, "", "", B, "", B, "", ""],
            [2, "", "", B, 1, B, "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 12,
        "puzzle": [
            ["", "", "", "", B, "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            [B, B, B, "", "", "", B, B],
            ["", "", "", "", "", "", "", ""],
            ["", B, B, "", "", "", "", ""],
            ["", "", B, "", "", P, "", ""],
            [B, 1, B, "", "", 2, "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 13,
        "puzzle": [
            ["", "", "", B, "", "", "", B],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", B, "", ""],
            ["", "", "", "", B, "", "", ""],
            ["", "", "", B, "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", B, P, "", "", "", "", ""],
            [B, 1, 2, "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 14,
        "puzzle": [
            ["", "", "", "", "", "", 2, ""],
            ["", "", B, "", "", "", P, ""],
            ["", "", "", "", "", "", "", B],
            [B, "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            [1, "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", B],
            ["", B, "", "", "", "", "", B]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 15,
        "puzzle": [
            ["", "", "", B, 1, "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", B, "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", B, "", ""],
            ["", "", B, "", P, "", "", ""],
            ["", "", "", "", 2, "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 16,
        "puzzle": [
            ["", "", "", "", "", "", "", ""],
            ["", "", D1, "", "", "", "", ""],
            [D1, "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            [2, P, B, "", "", "", "", ""],
            [S1, B, 1, "", "", "", "", ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 1,
    },
    {
        "id": 17,
        "puzzle": [
            ["", "", D2, "", "", "", "", ""],
            [2, P, "", "", B, "", "", B],
            ["", "", "", "", "", "", "", S2],
            ["", "", "", "", "", "", "", B],
            [S1, "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", ""],
            ["", "", "", D1, "", "", 1, ""]
        ],
        "author": "Kevin Ung",
        "difficulty": 2,
    },
    {
        "id": 18,
        "puzzle": [
            ["", "", B, "", S2, B, "", ""],
            ["", "", "", "", "", "", "", ""],
            [B, S1, "", "", B, "", "", ""],
            ["", B, "", B, "", "", "", D2],
            [D1, C1, "", "", "", "", "", ""],
            ["", "", "", "", "", "", H1 + '-' + P, 2],
            ["", "", "", "", 1, "", "", ""],
            ["", "", "", B, "", "", "", B]
        ],
        "author": "Justin Ung",
        "difficulty": 3,
    },
    {
        "id": 19,
        "puzzle": [
            ["", "", D1, "", "", "", "", B],
            ["", B, "", "", "", "", "", ""],
            ["", "", "", "", "", B, "", ""],
            ["", "", "", P, "", "", "", ""],
            [B, S1, "", 2, "", "", B, ""],
            ["", "", B, B, B, "", "", ""],
            ["", B, "", 1, "", "", B, ""],
            ["", "", "", "", "", "", "", ""]
        ],
        "author": "Justin Ung",
        "difficulty": 2,
    },
    {
        "id": 20,
        "puzzle": [
            ["", D1, B, "", "", "", D5, B],
            ["", "", D2, B, "", P, "", ""],
            ["", "", "", D3, B, 2, "", ""],
            ["", "", "", "", "", B, "", ""],
            ["", "", "", S3, "", "", "", ""],
            ["", "", S2, B, "", B, "", ""],
            ["", S1, B, S5, "", "", "", D5],
            [1, B, S4, "", "", "", D4, ""]
        ],
        "author": "Justin Ung",
        "difficulty": 2,
    },
    {
        "id": 21,
        "puzzle": [
            ["", "", B, "", 1, B, "", ""],
            ["", "", B, "", "", "", "", ""],
            ["", "", "", "", "", "", "", B],
            ["", H3, B, C1, B, "", "", B],
            [B, H1 + '-' + P, 2, B, C2, "", "", ""],
            ["", H2, B, C3, B, "", B, ""],
            ["", B, B, "", "", "", "", ""],
            ["", "", "", "", B, "", "", B]
        ],
        "author": "Justin Ung",
        "difficulty": 3,
    },
    {
        "id": 22,
        "puzzle": [
            [C4, "", D5, S3, "", C7, D3, ""],
            ["", "", "", "", "", "", "", ""],
            [B, B, B, B, B, S5, "", ""],
            [H3, C1, S1, C6, H3, H6, "", S2],
            [C3, "", "", "", B, B, B, D2],
            [S4, D4, "", "", H5, B, H1, ""],
            [H2, C5, "", S4, H7 + '-' + P, 2, B, H4],
            [B, D1, 1, B, B, B, B, S5]
        ],
        "author": "Kevin Ung",
        "difficulty": 3,
    },
    {
        "id": 23,
        "puzzle": [
            ["", "", B, "", "", "", C2, B],
            ["", "", "", "", D8, "", "", S7],
            [B, "", "", B, B, B, B, B],
            [1, "", S8, D5, H1, H2, H3 + '-' + P, 2],
            ["", "", "", B, B, B, B, B],
            [S6, "", "", "", "", "", B, D6],
            [B, D7, "", "", "", "", "", ""],
            [C4, C3, H4, B, "", S5, B, C1]
        ],
        "author": "Justin Ung",
        "difficulty": 3,
    },
    {
        "id": 24,
        "puzzle": [
            [B, "", C4, "", "", "", B, S1],
            [B, "", C3, B, "", "", "", ""],
            [B, B, "", "", D1, "", "", ""],
            [B, "", "", "", "", "", "", B],
            [B, "", B, "", "", "", "", C1],
            [B, "", 1, "", "", "", "", B],
            [H1, H3, "", "", "", "", H4, C2],
            [2, H2 + '-' + P, B, B, B, B, B, B]
        ],
        "author": "Justin Ung",
        "difficulty": 1,
    }
]

/*
    "puzzle": [
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""]
    ],
*/

export const boards = randomize(sliderMap);

export const boardMapping = {
    'one-only': [],
    'two-only': [],
    'two-up': [],
    'three-only': [],
    'three-up': [],
    'four-only': [],
    'four-up': [],
    'five-only': [],
    'any': boards,
}

boards.forEach((board) => {
    const id = board.id;
    const difficulty = board.difficulty;

    const checkBoard = (arr) => arr.find(board => board.id === id);

    if (difficulty === 5) {
        if (!checkBoard(boardMapping['five-only'])) {
            boardMapping['five-only'].push(board);
        }
        if (!checkBoard(boardMapping['four-up'])) {
            boardMapping['four-up'].push(board);
        }
        if (!checkBoard(boardMapping['three-up'])) {
            boardMapping['three-up'].push(board);
        }
        if (!checkBoard(boardMapping['two-up'])) {
            boardMapping['two-up'].push(board);
        }
    } else if (difficulty === 4) {
        if (!checkBoard(boardMapping['four-only'])) {
            boardMapping['four-only'].push(board);
        }
        if (!checkBoard(boardMapping['four-up'])) {
            boardMapping['four-up'].push(board);
        }
        if (!checkBoard(boardMapping['three-up'])) {
            boardMapping['three-up'].push(board);
        }
        if (!checkBoard(boardMapping['two-up'])) {
            boardMapping['two-up'].push(board);
        }
    } else if (difficulty === 3) {
        if (!checkBoard(boardMapping['three-only'])) {
            boardMapping['three-only'].push(board);
        }
        if (!checkBoard(boardMapping['three-up'])) {
            boardMapping['three-up'].push(board);
        }
        if (!checkBoard(boardMapping['two-up'])) {
            boardMapping['two-up'].push(board);
        }
    } else if (difficulty === 2) {
        if (!checkBoard(boardMapping['two-only'])) {
            boardMapping['two-only'].push(board);
        }
        if (!checkBoard(boardMapping['two-up'])) {
            boardMapping['two-up'].push(board);
        }
    } else if (difficulty === 1) {
        if (!checkBoard(boardMapping['one-only'])) {
            boardMapping['one-only'].push(board);
        }
    }
});

export const tabData = [
    {
        label: 'Difficulty Level',
        value: 'difficulty',
    },
    {
        label: 'Selected Puzzle',
        value: 'number',
    },
];