import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { useResource } from '../../../queries';

export const Spices = () => {
    const { data: content = [] } = useResource({
        category: 'life-skills',
        id: 'spices'
    });

    const reducedContent = content.map((item) => {
        const { title, img, taste, benefits, dishes } = item;
        return {
            title,
            img,
            content: [
                'Taste: ', taste.join(' '),
                benefits ? 'Benefits: ' : null,
                benefits ? benefits : null,
                'Dishes: ', dishes.join(', ')
            ].filter(item => item)
        }
    })

    const { TableOfContent } = useTableOfContent(content);

    return (
        <div className="learning-pages">
            <TableOfContent icons={reducedContent} />
            <ContentContainer content={reducedContent} />
        </div>
    )
}