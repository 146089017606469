import React from 'react';

export const SetUpButtons = ({
    isDeployed,
    isSelected,
    isPositioned,
    select,
    orient,
    undo,
    deploy,
}) => (
    <div className="setup-buttons">
        {isDeployed ? (
            <span className="deployed-label">Deployed</span>
        ) : isPositioned ? (
            <>
                <button className="default-btn blue" onClick={undo}>Undo</button>
                <button className="default-btn purple deploy-btn" onClick={deploy}>Deploy</button>
            </>
        ) : (
            isSelected ? (
                <button className="default-btn green" onClick={orient}>Orient</button>
            ) : (
                <button className="default-btn" onClick={select}>Select</button>
            )
        )}
    </div>
);