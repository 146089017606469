import React from 'react';

export const YearSelector = ({
    year,
    setYear,
}) => {
    let numbers = [];

    for (let num = 1923; num < 2100; num++) {
        numbers.unshift(num);
    }

    return (
        <div className="year-selector-dropdowns p-abs flex-col align-ctr">
            <span className="birth-year-label">Birth Year:</span>
            <select name="year" id="year-selector" onChange={(e) => setYear(Number(e.target.value))} value={Number(year)}>
                {numbers.map(num => {
                    return (
                        <option key={num} value={Number(num)}>{num}</option>
                    )
                })}
            </select>
        </div>
    )
}