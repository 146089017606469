import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { Breadcrumb, FlippingButton, Footer } from "../../../components";
import { SliderArrows, SliderCreate, SliderPreview, SliderPreviewButtons } from '.';
import { validator } from "../../../../utils";
import { P } from '.';
import { useSliderHook } from './hooks/updatePosition';
import { InstructionsBuilder } from '../InstructionsBuilder';
import { mutationUrls, useMutationQuery } from '../../../../queries';

const emptyPuzzle = [
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
    ["", "", "", "", "", "", "", ""],
]

export const SliderPuzzleBuilder = () => {
    const [isPreview, setIsPreview] = useState(false);
    const [puzzle, setPuzzle] = useState(emptyPuzzle);
    const [formattedPuzzle, setFormattedPuzzle] = useState(puzzle);
    const [tested, setTested] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showLegend, setShowLegend] = useState(false);

    const { mutate } = useMutationQuery();

    const getPiecePosition = () => {
        let position = [0, 0];
        puzzle.forEach((row, rowIndex) => {
            row.forEach((column, columnIndex) => {
                if (column === "1") {
                    position = [rowIndex, columnIndex];
                    return;
                }
            })
        });
        return position;
    }

    useEffect(() => {
        if (isPreview) {
            getPiecePosition();
        }
        // eslint-disable-next-line
    }, [isPreview]);

    const checkStartAndFinishExist = () => {
        const flattenedPuzzle = puzzle.flat(2);
        const includesStart = flattenedPuzzle.find(square => square === '1');
        const includesEnd = flattenedPuzzle.find(square => square === '2');
        return !!(includesStart && includesEnd);
    }

    const {
        inMotion,
        reset,
        piecePosition,
        updatePosition,
        switch1Enabled,
        switch2Enabled,
        switch3Enabled,
        switch4Enabled,
        switch5Enabled,
        switch6Enabled,
        switch7Enabled,
        switch8Enabled,
        switch9Enabled,
        control1Enabled,
        control2Enabled,
        control3Enabled,
        control4Enabled,
        control5Enabled,
        control6Enabled,
        control7Enabled,
        control8Enabled,
        control9Enabled,
    } = useSliderHook({
        puzzle: formattedPuzzle,
        toastMessages: { success: 'You did it!' },
        getPiecePosition,
        next: () => {
            setTested(true);
        },
    });

    const clearBoard = () => {
        setPuzzle(emptyPuzzle);
        setFormattedPuzzle(emptyPuzzle);
    }

    const submit = async () => {
        if (!checkStartAndFinishExist() || !name || !email) {
            toast('Looks like you are missing some details.', { className: 'error' });
            return;
        }

        try {
            await mutate({
                url: mutationUrls.sliderPuzzle,
                body: { name, email, puzzle: formattedPuzzle },
            });
            toast('Submitted form', { className: 'success' });
            setName('');
            setEmail('');
            reset();
            clearBoard();
            setTested(false);
        } catch (error) {
            toast('Sorry there was a problem submitting. Please try again.', { className: 'error' });
        }
    }

    const getExitPosition = () => {
        let position = [];
        puzzle.forEach((row, rowIndex) => {
            const includes2 = row.findIndex(column => column === '2');
            if (includes2 > -1) {
                position = [rowIndex, includes2];
            }
        });
        return position;
    }

    const checkSquareCanBeP = (rowIndex, columnIndex) => {
        const square = puzzle[rowIndex][columnIndex];

        const checked = {
            D1: !switch1Enabled,
            D2: !switch2Enabled,
            D3: !switch3Enabled,
            D4: !switch4Enabled,
            D5: !switch5Enabled,
            D6: !switch6Enabled,
            D7: !switch7Enabled,
            D8: !switch8Enabled,
            D9: !switch9Enabled,
            H1: control1Enabled,
            H2: control2Enabled,
            H3: control3Enabled,
            H4: control4Enabled,
            H5: control5Enabled,
            H6: control6Enabled,
            H7: control7Enabled,
            H8: control8Enabled,
            H9: control9Enabled,
        }

        if (square === '') {
            return true;
        } else if (checked[square]) {
            return true;
        }

        return false;
    }

    const getSurroundingSquares = (exit) => {
        const [row, column] = exit;
        const firstRow = puzzle[0];
        const lastRowIndex = puzzle.length - 1;
        const lastColumnIndex = firstRow.length - 1;
        const surroundingSquares = [];

        if (row === 0 && column === 0) {
            // top left corner
            surroundingSquares.push([row + 1, column], [row, column + 1])
        } else if (row === 0 && column === lastColumnIndex) {
            // top right corner
            surroundingSquares.push([row + 1, column], [row, column - 1])
        } else if (row === lastRowIndex && column === 0) {
            // bottom left corner
            surroundingSquares.push([row - 1, column], [row, column + 1])
        } else if (row === lastRowIndex && column === lastColumnIndex) {
            // bottom right corner
            surroundingSquares.push([row - 1, column], [row, column - 1])
        } else if (row === 0) {
            // top edge
            surroundingSquares.push([row + 1, column]);
        } else if (row === lastRowIndex) {
            // bottom edge
            surroundingSquares.push([row - 1, column]);
        } else if (column === 0) {
            // left edge
            surroundingSquares.push([row, column + 1]);
        } else if (column === lastColumnIndex) {
            // right edge
            surroundingSquares.push([row, column - 1]);
        } else {
            // middle
            if (row > 0 && checkSquareCanBeP(row - 1, column)) {
                // above
                surroundingSquares.push([row - 1, column]);
            }
            if (row < lastRowIndex && checkSquareCanBeP(row + 1, column)) {
                // below
                surroundingSquares.push([row + 1, column]);
            }
            if (column > 0 && checkSquareCanBeP(row, column - 1)) {
                // left
                surroundingSquares.push([row, column - 1]);
            }
            if (column < lastColumnIndex && checkSquareCanBeP(row, column + 1)) {
                // right
                surroundingSquares.push([row, column + 1]);
            }
        }

        return surroundingSquares;
    }

    const getFormattedPuzzle = () => {
        const exit = getExitPosition();
        const copyPuzzle = cloneDeep(puzzle);
        if (exit.length) {
            const surroundingSquares = getSurroundingSquares(exit);
            surroundingSquares.forEach(([start, end]) => {
                const ogSquare = copyPuzzle[start][end];
                if (ogSquare === '') {
                    copyPuzzle[start][end] = P;
                } else {
                    copyPuzzle[start][end] = ogSquare + '-' + P;
                }
            })
            copyPuzzle[exit[0]][exit[1]] = 2;
        }
        return copyPuzzle;
    }

    useEffect(() => {
        setFormattedPuzzle(getFormattedPuzzle());
        // eslint-disable-next-line
    }, [puzzle]);

    const legend = [
        {
            name: 'Start',
            abbreviation: '1',
            description: 'Slider piece starting position.',
        },
        {
            name: 'Exit',
            abbreviation: '2',
            description: 'Square players are aiming for.',
        },
        {
            name: 'Blocks',
            abbreviation: 'B',
            description: 'Blocks that are permanently in place.',
        },
        {
            name: 'Switches',
            abbreviation: 'S1-S9',
            description: 'Switches to toggle the corresponding Dashed blocks (D1 - D9).',
        },
        {
            name: 'Dashed Blocks',
            abbreviation: 'D1-D9',
            description: 'Dashed blocks initially then will become hard by toggling the corresponding Switches (S1 - S9). Then vice versa.',
        },
        {
            name: 'Controls',
            abbreviation: 'C1-C9',
            description: 'Controls to toggle the corresponding Hard blocks (H1 - H9).',
        },
        {
            name: 'Hard Blocks',
            abbreviation: 'H1-H9',
            description: 'Hard blocks initially then will become dashed by toggling the corresponding Controls (C1 - C9). Then vice versa.',
        }
    ]
    const instructions = [
        'Select items in the dropdown to create your puzzle. You can select as many of the same items you would like besides the Start (1) and Exit (2).',
        'Once you have selected your items, click the "Preview" button to see your puzzle and test it out.',
        'Make the necessary tweaks that you would like to your puzzle and continue with the previous step until you are happy with it.',
        'Once you are happy with your puzzle, fill out the rest of the form and click the "Submit" button to send us your Slider Puzzle.',
        'It will be added, once we have reviewed it.',
    ];
    const goal = 'Your goal is to create a puzzle that is challenging using just the building blocks provided.';

    return (
        <div id="slider-puzzle-builder-page" className="slider-puzzle-builder page">
            <div className="container">
                <Breadcrumb />
                <h1 className="heading">Create Slider Puzzle</h1>

                <FlippingButton
                    buttonText={showLegend ? "Hide Legend" : "Show Legend"}
                    color="purple"
                    width={170}
                    shouldTransform
                    onClick={() => setShowLegend(!showLegend)}
                />
                {showLegend && (
                    <div className="legend-container">
                        {legend.map(({ name, abbreviation, description }, index) => (
                            <p key={index} className="legend-item"><strong>{name} ({abbreviation}):</strong> {description}</p>
                        ))}
                    </div>
                )}

                <div className="slider-puzzle-builder-container">
                    <SliderPreviewButtons
                        previewIsDisabled={!checkStartAndFinishExist()}
                        resetIsDisabled={!getFormattedPuzzle().flat(2).filter(square => square !== '').length || !isPreview}
                        isPreview={isPreview}
                        setIsPreview={setIsPreview}
                        reset={reset}
                    />
                    {isPreview ?
                        <SliderPreview
                            puzzle={formattedPuzzle}
                            piecePosition={piecePosition}
                            switch1Enabled={switch1Enabled}
                            switch2Enabled={switch2Enabled}
                            switch3Enabled={switch3Enabled}
                            switch4Enabled={switch4Enabled}
                            switch5Enabled={switch5Enabled}
                            switch6Enabled={switch6Enabled}
                            switch7Enabled={switch7Enabled}
                            switch8Enabled={switch8Enabled}
                            switch9Enabled={switch9Enabled}
                            control1Enabled={control1Enabled}
                            control2Enabled={control2Enabled}
                            control3Enabled={control3Enabled}
                            control4Enabled={control4Enabled}
                            control5Enabled={control5Enabled}
                            control6Enabled={control6Enabled}
                            control7Enabled={control7Enabled}
                            control8Enabled={control8Enabled}
                            control9Enabled={control9Enabled}
                        />
                        :
                        <SliderCreate
                            puzzle={puzzle}
                            setPuzzle={setPuzzle}
                        />
                    }

                    <SliderArrows
                        inMotion={inMotion || !isPreview}
                        piecePosition={piecePosition}
                        updatePosition={updatePosition}
                    />

                    <div className="form-container">
                        <div className="form-field">
                            <span>Name</span>
                            <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-field">
                            <span>Email</span>
                            <input className={`${validator({ type: 'email', text: email }) ? 'validated' : 'invalid'}`} required type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>

                    <FlippingButton
                        buttonText="Submit"
                        color="green"
                        onClick={submit}
                        width={102}
                        shouldTransform
                        isDisabled={!checkStartAndFinishExist() || !name || !validator({ type: 'email', text: email }) || !tested}
                    />
                </div>
            </div>
            <InstructionsBuilder instructions={instructions} goal={goal} />
            <Footer />
        </div>
    )
}