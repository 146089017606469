import React, { useState } from 'react';
import noteFunctions from './GuitarNotes';
import chordFunctions from './GuitarChords';
import removeOld from './RemoveOld';
import { Instrument } from './Instrument';
import { NoteTable } from './NoteTable';
import { ChordTable } from './ChordTable';

export const Guitar = () => {
    const { showAllX, initializeNote } = noteFunctions;
    const { createChord } = chordFunctions;
    const [root, updateRoot] = useState(null);
    const [chordPitch, updateChordPitch] = useState('natural');
    const [chordScale, updateScale] = useState('major');
    const [chordType, updateChordType] = useState('base');

    const [selectedNote, updateNote] = useState(null);
    const [notePitch, updateNotePitch] = useState('natural');

    const [tableDisplay, updateTableDisplay] = useState('chords');

    const handleNoteUpdate = async (note) => {
        await updateNote(note);
        if (((note === 'b' || note === 'e') && notePitch === 'sharp') || ((note === 'c' || note === 'f') && notePitch === 'flat')) {
            await updateNotePitch('natural');
            document.querySelector('#note-natural').checked = true;
        }
        showAllX(note, notePitch);
    }

    const handlePitchUpdate = async (pitch) => {
        await updateNotePitch(pitch);
        if (selectedNote) {
            await showAllX(selectedNote, pitch);
        }
    }

    const handleChordCreation = async (start) => {
        await updateRoot(start);
        if ((chordScale === 'minor' && chordType === 'maj7') ||
            (start === 'a' && chordPitch === 'sharp' && chordScale === 'minor' && chordType === 'seventh') ||
            (start === 'b' && chordPitch === 'flat' && chordScale === 'minor' && chordType === 'seventh') ||
            (start === 'g' && chordPitch === 'natural' && chordScale === 'minor' && chordType === 'sixth') ||
            (chordPitch === 'flat' && chordScale === 'minor' && chordType === 'add2') ||
            (chordPitch === 'flat' && chordScale === 'minor' && chordType === 'add9') ||
            (chordPitch === 'sharp' && chordScale === 'minor' && chordType === 'add2') ||
            (chordPitch === 'sharp' && chordScale === 'minor' && chordType === 'add9')) {
            await updateChordType('base');
            document.querySelector('#base').checked = true;
        }
        if (((start === 'b' || start === 'e') && chordPitch === 'sharp') || ((start === 'c' || start === 'f') && chordPitch === 'flat')) {
            await updateChordPitch('natural');
            document.querySelector('#chord-natural').checked = true;
        }
        createChord(start, chordPitch, chordScale, chordType);
    }

    const handleChordPitch = async (pitch) => {
        await updateChordPitch(pitch);
        if ((chordScale === 'minor' && chordType === 'maj7') ||
            (root === 'a' && pitch === 'sharp' && chordScale === 'minor' && chordType === 'seventh') ||
            (root === 'b' && pitch === 'flat' && chordScale === 'minor' && chordType === 'seventh') ||
            (root === 'g' && pitch === 'natural' && chordScale === 'minor' && chordType === 'sixth') ||
            (pitch === 'flat' && chordScale === 'minor' && chordType === 'add2') ||
            (pitch === 'flat' && chordScale === 'minor' && chordType === 'add9') ||
            (pitch === 'sharp' && chordScale === 'minor' && chordType === 'add2') ||
            (pitch === 'sharp' && chordScale === 'minor' && chordType === 'add9')) {
            await updateChordType('base');
            document.querySelector('#base').checked = true;
        }
        if (root) {
            createChord(root, pitch, chordScale, chordType);
        }
    }

    const handleChordScale = async (scaleType) => {
        await updateScale(scaleType);
        if ((scaleType === 'minor' && chordType === 'maj7') ||
            (root === 'a' && chordPitch === 'sharp' && scaleType === 'minor' && chordType === 'seventh') ||
            (root === 'b' && chordPitch === 'flat' && scaleType === 'minor' && chordType === 'seventh') ||
            (root === 'g' && chordPitch === 'natural' && scaleType === 'minor' && chordType === 'sixth') ||
            (chordPitch === 'flat' && scaleType === 'minor' && chordType === 'add2') ||
            (chordPitch === 'flat' && scaleType === 'minor' && chordType === 'add9') ||
            (chordPitch === 'sharp' && scaleType === 'minor' && chordType === 'add2') ||
            (chordPitch === 'sharp' && scaleType === 'minor' && chordType === 'add9')) {
            await updateChordType('base');
            document.querySelector('#base').checked = true;
        }
        if (scaleType === 'minor' && (chordType === 'add2' || chordType === 'add9' || chordType === 'sus2' || chordType === 'sus4')) {
            await updateChordType('base');
            document.querySelector('#base').checked = true;
        }
        if (root) {
            createChord(root, chordPitch, scaleType, chordType);
        }
    }

    const handleChordType = async (type) => {
        await updateChordType(type);
        if ((chordScale === 'minor' && type === 'maj7') ||
            (root === 'a' && chordPitch === 'sharp' && chordScale === 'minor' && type === 'seventh') ||
            (root === 'b' && chordPitch === 'flat' && chordScale === 'minor' && type === 'seventh') ||
            (root === 'g' && chordPitch === 'natural' && chordScale === 'minor' && type === 'sixth') ||
            (chordPitch === 'flat' && chordScale === 'minor' && type === 'add2') ||
            (chordPitch === 'flat' && chordScale === 'minor' && type === 'add9') ||
            (chordPitch === 'sharp' && chordScale === 'minor' && type === 'add2') ||
            (chordPitch === 'sharp' && chordScale === 'minor' && type === 'add9')) {
            await updateChordType('base');
            document.querySelector('#base').checked = true;
        }
        if (root) {
            createChord(root, chordPitch, chordScale, type);
        }
    }

    const handleTableDisplayToggle = async (type) => {
        removeOld();
        await updateNote(null);
        await updateRoot(null);
        updateTableDisplay(type);
    }

    return (
        <div>
            <div className={`selector-box-holder flex-col justify-between ${tableDisplay === 'notes' && 'selector-notes-holder'}`}>
                <div className="page-heading heading-container justify-between">
                    <div className="flex align-ctr">
                        {tableDisplay === 'chords' && (
                            <>
                                <h2 className="chords-heading">Chords</h2>
                                <button className="toggle-btn default-btn" onClick={() => handleTableDisplayToggle('notes')}>Toggle</button>
                            </>
                        )}
                        {tableDisplay === 'notes' && (
                            <>
                                <h2 className="notes-heading">Notes</h2>
                                <button className="toggle-btn default-btn" onClick={() => handleTableDisplayToggle('chords')}>Toggle</button>
                            </>
                        )}
                    </div>
                </div>
                {tableDisplay === 'notes' && (
                    <NoteTable {...{
                        handleNoteUpdate,
                        selectedNote,
                        handlePitchUpdate,
                    }} />
                )}
                {tableDisplay === 'chords' && (
                    <ChordTable {...{
                        handleChordCreation,
                        root,
                        handleChordPitch,
                        handleChordScale,
                        handleChordType,
                        chordPitch,
                        chordScale,
                    }} />
                )}
            </div>

            <Instrument {...{
                initializeNote,
                tableDisplay,
            }} />
        </div>
    )
}