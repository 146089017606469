import React from 'react';
import { Levels } from './Levels';
import { ShowTimer } from './ShowTimer';
import { TimerOptions } from './TimerOptions';

export const Settings = ({
    difficulty,
    setDifficulty,
    showTimer,
    setShowTimer,
    timerOption,
    setTimeOption,
    showGame,
}) => {
    return (
        <div className="settings-container">
            <Levels disabled={showGame} difficulty={difficulty} setDifficulty={setDifficulty} />
            <ShowTimer disabled={showGame} showTimer={showTimer} setShowTimer={setShowTimer} />
            <TimerOptions disabled={showGame} timerOption={timerOption} setTimeOption={setTimeOption} />
        </div>
    )
}