import React, { useEffect, useState } from 'react';
import { generateNumbersArr, randomize } from '../../../../utils';
import { MODES } from '../WaitingRoom';

const allNumbers = generateNumbersArr(1, 100);
const initialOrder = ['---', '---', '---'];
const positions = ['1st', '2nd', '3rd'];


export const Board = ({ startGame, activeGame, username, opponent, mode }) => {
    const currentRoundIndex = (activeGame?.currentRound ?? 1) - 1;
    const currentGame = activeGame?.selections?.[currentRoundIndex];
    const [numbers, setNumbers] = useState([]);
    const [order, setOrder] = useState(initialOrder);

    const generateNumbers = () => {
        const randomNumbers = randomize(allNumbers, 3);
        setNumbers(randomNumbers);
    }

    useEffect(() => {
        if (mode === MODES.SET_UP) {
            generateNumbers();
            setOrder(initialOrder);
        }
        // @ts-ignore
    }, [mode]);

    if (currentGame?.[username]) {
        return (
            <div className="board-container">
                <h4 className="heading-label">Your Selection</h4>
                <div className="all-numbers-selectors-container">
                    {currentGame?.[username]?.map((number) => (
                        <span key={number} className="number">
                            {number}
                        </span>
                    ))}
                </div>
                {currentGame?.[opponent] && (
                    <h4 className="heading-label">Opponent's Selection</h4>
                )}
                {currentGame?.[opponent] && (
                    <div className="all-numbers-selectors-container">
                        {currentGame?.[opponent]?.map((number) => (
                            <span key={number} className="number">
                                {number}
                            </span>
                        ))}
                    </div>
                )}

                <span>Waiting for {opponent}</span>
            </div>
        )
    } else {
        return (
            <div className="board-container">
                <h4 className="heading-label">Your Options</h4>
                <div className="all-numbers-selectors-container">
                    {numbers.map((number, numIndex) => (
                        <div key={number} className="numbers-selectors-container">
                            <span className="number">
                                {number}
                            </span>
                            <select
                                className="selector"
                                value={order[numIndex]}
                                onChange={(e) => {
                                    const newOrder = order.slice();
                                    newOrder[numIndex] = e.target.value;
                                    setOrder(newOrder);
                                }}
                            >
                                <option className="position">---</option>
                                {positions.map((position, posIndex) => (
                                    <option key={posIndex} className="position" disabled={order.includes(position)}>
                                        {position}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>
                <button
                    className='default-btn'
                    onClick={() => {
                        const first = numbers[order.indexOf('1st')];
                        const second = numbers[order.indexOf('2nd')];
                        const third = numbers[order.indexOf('3rd')];
                        startGame([first, second, third]);
                    }}
                    disabled={!positions.every(item => order.includes(item))}
                >
                    Submit
                </button>
            </div>
        )
    }
}