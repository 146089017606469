import axios from 'axios';
import { useQuery } from 'react-query';

const select = response => response.data;

export const useMission = () => useQuery({
    queryKey: ['mission'],
    queryFn: () => axios.get('/api/mission'),
    select,
});

export const useCoverPhoto = () => useQuery({
    queryKey: ['coverPhoto'],
    queryFn: () => axios.get('/api/cover-photo'),
    select,
});

export const usePersons = () => useQuery({
    queryKey: ['persons'],
    queryFn: () => axios.get('/api/person'),
    select,
});

export const useBreadcrumb = ({ id, url }) => useQuery({
    queryKey: ['breadcrumbs', id],
    queryFn: async () => await axios.get(url),
    select,
});

// ASSETS
export const useAsset = ({ folder, name }) => useQuery({
    queryKey: ['education', folder, 'image', name],
    queryFn: async () => await axios.get(`/api/asset?folder=${folder}&name=${name}`),
    select,
});

// BLOGS
export const useBlogs = () => useQuery({
    queryKey: ['blogs'],
    queryFn: async () => await axios.get("/api/blog"),
    select,
});

export const useBlog = ({ id }) => useQuery({
    queryKey: ['blogs', id],
    queryFn: async () => await axios.get(`/api/blog?id=${id}`),
    select,
});

// MUSIC
export const useMusicResources = () => useQuery({
    queryKey: ['resources', 'music'],
    queryFn: async () => await axios.get("/api/resource/music"),
    select,
});

// PRODUCTS
export const useProducts = () => useQuery({
    queryKey: ['products'],
    queryFn: () => axios.get('/api/product'),
    select,
});

export const useRecommendedProducts = () => useQuery({
    queryKey: ['products', 'recommended'],
    queryFn: async () => await axios.get('/api/product?recommended=true'),
    select,
});

export const useProductsByType = ({ type }) => useQuery({
    queryKey: ['products', type],
    queryFn: async () => await axios.get(`/api/product?type=${type}`),
    select,
});

export const useProduct = ({ id }) => useQuery({
    queryKey: ['products', id],
    queryFn: async () => await axios.get(`/api/product?id=${id}`),
    select,
});

// RESOURCES
export const useHomeResources = () => useQuery({
    queryKey: ['home', 'resources'],
    queryFn: async () => await axios.get("/api/home/resource"),
    select,
});

export const useResourcesByCategory = (category) => useQuery({
    queryKey: ['resources', 'category'],
    queryFn: async () => await axios.get("/api/resource/category/" + category),
    select,
});

export const useResources = () => useQuery({
    queryKey: ['resources'],
    queryFn: async () => await axios.get("/api/resource"),
    select,
});

export const useResourceCategory = ({ id }) => useQuery({
    queryKey: ['resources', id],
    queryFn: async () => await axios.get(`/api/resource?id=${id}`),
    select,
});

export const useResource = ({ category, id }) => useQuery({
    queryKey: ['education', category, id],
    queryFn: async () => await axios.get('/api/material/' + id),
    select,
});

// RESOURCES - MUSIC
export const useGuitarChordsByNotes = ({ note }) => useQuery({
    queryKey: ['education', 'music', 'guitar-chords', note],
    queryFn: async () => await axios.get(`/music/guitar/chords/${note}`),
    select,
});