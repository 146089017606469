import React, { useEffect, useState } from 'react';
import { useUsername } from '../../../../hooks';
import { ACTIVE_GAME_LOCAL_STORAGE_KEYS, getDecryptedValue } from '../../../../utils';
import { MODES, socket, SOCKET_CLIENT_EVENTS, USER_STATUSES, WaitingRoom } from '../WaitingRoom';
import { GamePlay, GameSetUp } from '.';

// FLEET SHIP
export const CARRIER = 'CARRIER';
export const BATTLESHIP = 'BATTLESHIP';
export const CRUISER = 'CRUISER';
export const SUBMARINE = 'SUBMARINE';
export const DESTROYER = 'DESTROYER';

// ORIENTATION
export const HORIZONTAL = 'HORIZONTAL';
export const VERTICAL = 'VERTICAL';

export const Battleship = ({ toastMessages, setToastTime, ...product }) => {
    const [mode, setMode] = useState();
    const [activeGame, setActiveGame] = useState();
    const { getUsername } = useUsername();
    const username = getUsername() ?? '';
    const opponent = activeGame?.players?.find(player => player !== username);

    const checkAllDeployed = (fleet) => fleet && Object.values(fleet).every(ship => ship.deployed);

    const loadData = () => {
        if (getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.BATTLESHIP)) {
            const activeGame = JSON.parse(getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.BATTLESHIP));
            setActiveGame(activeGame);
            socket.emit(SOCKET_CLIENT_EVENTS.JOIN_GAME[0], activeGame);
            if (checkAllDeployed(activeGame?.[username]?.fleet) && checkAllDeployed(activeGame?.[opponent]?.fleet)) {
                setMode(MODES.IN_PLAY);
            } else if (activeGame?.players) {
                setMode(MODES.SET_UP);
            }
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_BUSY });
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [mode]);

    useEffect(() => {
        setToastTime(1000);
        // eslint-disable-next-line
    }, []);

    // need to handle some room logic here
    const setGame = () => {
        setMode(MODES.SET_UP);
    }

    const startGame = () => {
        setMode(MODES.IN_PLAY);
    }

    const onGameEnd = () => {
        setTimeout(async () => {
            await localStorage.removeItem(ACTIVE_GAME_LOCAL_STORAGE_KEYS.BATTLESHIP);
            setActiveGame();
            setMode();
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_AVAILABLE })
        }, 5000);
    }

    return (
        <section className="battleship-board">
            <WaitingRoom
                mode={mode}
                setMode={setMode}
                product={product}
                setGame={setGame}
                startGame={startGame}
                activeGame={activeGame}
                setActiveGame={setActiveGame}
                activeGameLocalStorageKey={ACTIVE_GAME_LOCAL_STORAGE_KEYS.BATTLESHIP}
                onGameEnd={onGameEnd}
            />
            {mode === MODES.SET_UP && (
                <GameSetUp 
                    home={activeGame?.[username]}
                    activeGame={activeGame}
                    setActiveGame={setActiveGame}
                    username={username}
                />
            )}
            {mode === MODES.IN_PLAY && activeGame && (
                <GamePlay
                    username={username}
                    opponent={opponent}
                    activeGame={activeGame}
                    setActiveGame={setActiveGame}
                    setMode={setMode}
                />
            )}
        </section>
    )
}
