import React, { useEffect, useState } from 'react';
import { initializeBoard } from '../../../../utils';
import { MODES } from '../WaitingRoom/WaitingRoom';
import { BATTLESHIP, CARRIER, CRUISER, DESTROYER, Fleet, GameBoard, HORIZONTAL, SUBMARINE, Ship } from '.';
import { socket, SOCKET_CLIENT_EVENTS } from '../WaitingRoom/socket';

export const GameSetUp = ({ home, username, activeGame, setActiveGame }) => {
    const mode = MODES.SET_UP;
    const [selected, setSelected] = useState('');
    const [board, setBoard] = useState(home?.board ?? initializeBoard({ rows: 10, columns: 10, value: '' }));
    const initializeFleet = () => ({
        [CARRIER]: { positions: [], count: 5, orientation: HORIZONTAL, deployed: false, hitPositions: [] },
        [BATTLESHIP]: { positions: [], count: 4, orientation: HORIZONTAL, deployed: false, hitPositions: [] },
        [CRUISER]: { positions: [], count: 3, orientation: HORIZONTAL, deployed: false, hitPositions: [] },
        [SUBMARINE]: { positions: [], count: 3, orientation: HORIZONTAL, deployed: false, hitPositions: [] },
        [DESTROYER]: { positions: [], count: 2, orientation: HORIZONTAL, deployed: false, hitPositions: [] },
    })
    const [fleet, setFleet] = useState(home?.fleet ?? initializeFleet());

    const checkSquare = (positions) =>
        positions.every(([row, col]) => board?.[row]?.[col] === '');

    const checkFleetDeployed = () => Object.values(fleet).every(ship => ship.deployed);

    const setup = (startingPosition) => {
        if (selected === null || !fleet?.[selected]) return;
        if (fleet?.[selected].positions.length) return;
        const [row, column] = startingPosition;
        const { count, orientation } = fleet?.[selected];

        const positions = [];
        for (let i = 0; i < count; i++) {
            if (orientation === HORIZONTAL) {
                positions.push([row, column + i]);
            } else {
                positions.push([row + i, column]);
            }
        }

        const canAdd = checkSquare(positions);

        if (canAdd) {
            const newBoard = board.map(row => row.slice());
            positions.forEach(([row, col]) => {
                newBoard[row][col] = 'ship';
            });

            setBoard(newBoard);
            const ship = document.getElementById(selected.toLowerCase()).cloneNode(true);
            const square = document.querySelector('#' + mode + '-' + row + '-' + column + ' .square-hole');
            ship.setAttribute('id', selected.toLowerCase() + '-copy');
            ship.classList.add('ship-copy');
            ship.classList.add(orientation.toLowerCase());
            square.appendChild(ship);
            setFleet({
                ...fleet,
                [selected]: {
                    ...fleet[selected],
                    positions,
                }
            })
        }
    }

    useEffect(() => {
        if (checkFleetDeployed()) {
            const newData = { ...activeGame, username, gameData: { board, fleet } };
            socket.emit(SOCKET_CLIENT_EVENTS.SET[0], newData);
        }
        // eslint-disable-next-line
    }, [checkFleetDeployed()]);

    const doesShipExist = () => {
        const carrier = document.querySelector('.game-board-container .carrier');
        const battleship = document.querySelector('.game-board-container .battleship');
        const cruiser = document.querySelector('.game-board-container .cruiser');
        const submarine = document.querySelector('.game-board-container .submarine');
        const destroyer = document.querySelector('.game-board-container .destroyer');
        return carrier && battleship && cruiser && submarine && destroyer;
    }

    useEffect(() => {
        if (home?.fleet && !doesShipExist()) {
            for (const selected in home.fleet) {
                const { orientation, positions } = home.fleet[selected];
                if (positions.length) {
                    const [row, column] = positions[0];
                    const ship = document.querySelector('#' + selected.toLowerCase()).cloneNode(true);
                    const square = document.querySelector('#' + mode + '-' + row + '-' + column + ' .square-hole');
                    ship.setAttribute('id', selected.toLowerCase() + '-copy');
                    ship.classList.add('ship-copy');
                    ship.classList.add(orientation.toLowerCase());
                    square.appendChild(ship);
                }
            }
        }
        // eslint-disable-next-line
    }, [home?.fleet])

    const props = {
        mode,
        board,
        setBoard,
        fleet,
        setFleet,
        selected,
        setSelected,
        fire: setup,
    };

    const getShipProps = (type) => ({
        type,
        peg: home?.fleet?.[type]?.count,
        orientation: home?.fleet?.[type]?.orientation,
        mode: MODES.IN_PLAY,
    });

    return (
        <div className="game-set-up-container">
            <Fleet {...props} />
            <GameBoard {...props} />
            <div className="home-fleet">
                <Ship {...getShipProps(CARRIER)} />
                <Ship {...getShipProps(BATTLESHIP)} />
                <Ship {...getShipProps(CRUISER)} />
                <Ship {...getShipProps(SUBMARINE)} />
                <Ship {...getShipProps(DESTROYER)} />
            </div>
        </div>
    )
}