import React from 'react';
import { withRouter } from 'react-router-dom';
import { Copy } from './Copy';
import { Subscribe } from './Subscribe';
import { useAsset } from '../../queries';

const FooterComp = ({ history }) => {
    const { data: emailLogo, isFetched: emailIsFetched } = useAsset({
        folder: 'logo',
        name: 'gmail.png',
    });

    const { data: phoneLogo, isFetched: phoneIsFetched } = useAsset({
        folder: 'logo',
        name: 'phone-call.png',
    });

    // const { data: facebookLogo, isFetched: facebookIsFetched } = useAsset({
    //     folder: 'logo',
    //     name: 'facebook.png',
    // });

    return (
        <footer className="footer">
            <p className="contact-us-label" onClick={() => history.push('/contact')}>Contact Us</p>
            <div className="contact-icons">
                {emailIsFetched ? (
                    <Copy
                        {...{
                            icon: emailLogo,
                            text: "support@buddingacorn.dev",
                            type: "email",
                        }}
                    />
                ) : (
                    <div className="copy-img"></div>
                )}
                <span className="divider">|</span>
                {phoneIsFetched ? (
                    <Copy
                        {...{
                            icon: phoneLogo,
                            text: "8018556361",
                            type: "phone",
                        }}
                    />
                ) : (
                    <div className="copy-img"></div>
                )}
                {/* 
                    <span className="divider">|</span>
                    {facebookIsFetched ? (
                        <Copy
                            {...{
                                icon: facebookLogo,
                                text: "facebook.com",
                                type: "phone",
                            }}
                        />
                    ) : (
                        <div className="copy-img"></div>
                    )} 
                */}
            </div>
            <Subscribe />
            <p className="budding-acorn">Budding Acorn<sup>™</sup></p>
        </footer>
    )
}

export const Footer = withRouter(FooterComp);