import React from 'react';
import { useLoader } from '../../hooks/use-loader';
import { useMusicResources } from '../../queries';
import { FeedbackForm, Footer, IconItems, Loader } from '../components';

export const MusicLanding = ({ history }) => {
    const { data: resources, isLoading: isResourceLoading, isFetching } = useMusicResources();
    const isReady = !isResourceLoading && !isFetching;
    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />;

    return (
        <div id="music-landing-page" className="music page">
            <div className="container">
                <h2 className="heading">Music</h2>
                <div className="music-landing-container">
                    <IconItems icons={resources} history={history} customUrl />
                </div>
            </div>
            <FeedbackForm product="resource-music" type="resource" />
            <Footer />
        </div>
    )
}