import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useBreadcrumb } from '../../queries';
import { toTitleCase } from "../../utils";

const LocalCrumb = ({ location, history, match, type }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const { id } = match.params;

    const urlMap = {
        'blog': `/api/blog?id=${id}`,
        'product': `/api/product?id=${id}`,
        'resource': `/api/resource?id=${id}`,
    }

    const url = urlMap[type];

    const { data } = useBreadcrumb({ id, url });

    const getBreadcrumb = () => {
        const path = location.pathname;

        let breadcrumb = path.split('/').filter(item => item && item !== 'types-resources');

        if (breadcrumb.includes('blogs') && data) {
            if (breadcrumb.length === 2) {
                breadcrumb[1] = data.title;
            }
        } else if (breadcrumb.includes('products') && data) {
            breadcrumb = new Set(breadcrumb);
            breadcrumb.delete('products');
            breadcrumb = Array.from(breadcrumb);
            if (breadcrumb.length === 3) {
                breadcrumb[1] = data.name;
            }
            if (breadcrumb.length === 2) {
                breadcrumb[1] = data.name;
            }
        }
        return breadcrumb;
    }

    useEffect(() => {
        const breadcrumb = getBreadcrumb();
        setBreadcrumbs(breadcrumb);
        // eslint-disable-next-line
    }, [location.pathname, data])

    const handleRouting = (crumb, index) => {
        if (crumb === "blogs") {
            history.push('/blogs');
        }
        if (crumb === "music") {
            history.push('/music');
        }
        if (crumb === "resources") {
            history.push('/resources');
        }
        if (crumb === "games") {
            history.push('/products/games');
        }
        if (crumb === "books") {
            history.push('/products/books');
        }
        if (crumb === "Slider") {
            history.push('/products/games/9');
        }
        if (crumb === 'cooking' && index === 2) {
            history.push('/resources/life-skills/cooking');
        }
        if (type === 'resource' && index === 1) {
            if (location.pathname !== '/resources/' + crumb) {
                history.push('/resources/' + crumb);
            }
        }
    }

    return (
        <div className="breadcrumbs">
            {breadcrumbs.map((crumb, i) => {
                const isLast = (breadcrumbs.length - 1) === i;
                return (
                    <React.Fragment key={i}>
                        <span className={`crumb ${!isLast ? 'cursor' : ''}`} onClick={() => handleRouting(crumb, i)}>{toTitleCase(crumb, '-')}</span>
                        {!isLast && <span className="angle">{`>`}</span>}
                    </React.Fragment>

                )
            })}
        </div>
    )
}

export const Breadcrumb = withRouter(LocalCrumb);