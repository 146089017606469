import React from 'react';
import { toTitleCase } from '../../../../utils';
import { MODES } from '../WaitingRoom/WaitingRoom';
import { BATTLESHIP, CARRIER, CRUISER, DESTROYER, HORIZONTAL, SUBMARINE, VERTICAL, SetUpButtons, SQUARE_OPTIONS } from '.';

export const Ship = ({
    type,
    orientation,
    peg,
    mode,
    hitPositions,
    ...rest
}) => {
    return (
        <div className={`ship-container ${type?.toLowerCase() ?? ''} ${orientation?.toLowerCase() ?? ''}`} style={{ display: mode === MODES.IN_PLAY ? 'none' : '' }}>
            {mode === MODES.SET_UP && (
                <div className="ship-heading-container">
                    <h4 className="ship-name">{toTitleCase(type)}</h4>
                    <SetUpButtons {...rest} />
                </div>
            )}
            <div id={type.toLowerCase()} className="ship">
                {Array(peg).fill(1).map((_num, index) => (
                    <div key={index} className={`peg ${hitPositions?.[index] ? 'hit' : ''}`}></div>
                ))}
            </div>
        </div>
    )
}

const getHitPositions = (positions, hits) => positions.map(position =>
    hits.find(hit => hit[0] === position[0] && hit[1] === position[1] ? SQUARE_OPTIONS.HIT : ''))

export const Fleet = ({
    mode,
    board,
    setBoard,
    fleet,
    setFleet,
    selected,
    setSelected,
}) => {
    const checkIsSelected = (type) => selected === type;

    const orient = (type) => {
        setFleet({
            ...fleet,
            [type]: {
                ...fleet[type],
                orientation: fleet[type].orientation === VERTICAL ? HORIZONTAL : VERTICAL
            }
        })
    }

    const undo = (type) => {
        // remove the ship from the board
        document.getElementById(type.toLowerCase() + '-copy').remove();

        // remove the ship from the board
        const newBoard = board.map(row => row.slice());
        fleet[type].positions.forEach(([row, col]) => {
            newBoard[row][col] = '';
        });
        setBoard(newBoard);

        // remove the ship from the fleet
        setFleet({
            ...fleet,
            [type]: {
                ...fleet[type],
                positions: []
            }
        })
    }

    const deploy = (type) => {
        if (!fleet[type].positions.length) return;
        setFleet({
            ...fleet,
            [type]: {
                ...fleet[type],
                deployed: true,
            }
        })
    }

    const props = (type) => ({
        type,
        mode,
        peg: fleet[type].count,
        isDeployed: fleet[type].deployed,
        isSelected: checkIsSelected(type),
        isPositioned: fleet[type].positions.length,
        orientation: fleet[type].orientation,
        hitPositions: getHitPositions(fleet[type].positions, fleet[type].hitPositions),
        orient: () => orient(type),
        select: () => setSelected(type),
        undo: () => undo(type),
        deploy: () => deploy(type),
    });

    return (
        <section className="fleet-container">
            <h3>Fleet</h3>
            <div className="fleet">
                <Ship {...props(CARRIER)} />
                <Ship {...props(BATTLESHIP)} />
                <Ship {...props(CRUISER)} />
                <Ship {...props(SUBMARINE)} />
                <Ship {...props(DESTROYER)} />
            </div>
        </section>
    )
}