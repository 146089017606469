import React from 'react';

export const Score = ({ activeGame, username, opponent }) => {
    const currentRoundIndex = (activeGame?.currentRound ?? 1) - 1;
    const previousRounds = activeGame?.selections?.slice(0, currentRoundIndex) ?? [];

    const calculate = (number, otherNumber) => {
        if (number === otherNumber) {
            return 'tie';
        }
        return number > otherNumber ? 'win' : 'lose';
    }

    if (previousRounds.length === 0) {
        return null;
    }
    return (
        <div className="score-container">
            {previousRounds.map((round, index) => (
                <div key={index} className="round-container">
                    <span className="round-label">Round {index + 1}</span>
                    <div className="round-table">
                        <div className="round-rows">
                            <div className="round-row">
                                <span className="username">{username}</span>
                                {round[username].map((number, numberIndex) => (
                                    <span key={numberIndex} className={`number ${calculate(number, round[opponent][numberIndex])}`}>
                                        {number}
                                    </span>
                                ))}
                            </div>
                            <div className="round-row">
                                <span className="username">{opponent}</span>
                                {round[opponent].map((number, numberIndex) => (
                                    <span key={numberIndex} className={`number ${calculate(number, round[username][numberIndex])}`}>
                                        {number}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}