import React from 'react';

export const ResourceCard = ({ id, name, description, image, handleRouting }) => (
    <div className="resource-card" onClick={handleRouting}>
        <img className={`resource-img resource-img-${id}`} src={image} alt={name} />
        <div className="copy-container">
            <div className="top-container">
                <h3 className="resource-name">{name}</h3>
                <div className="resource-description">{description}</div>
            </div>
            <div className="bottom-container">
                <button className="default-btn" onClick={handleRouting}>View</button>
            </div>
        </div>
    </div>
);