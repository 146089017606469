import React from 'react';
import { useAsset } from '../../../queries';

export const Keyboard = ({
    list,
    onClick,
    onDelete,
    onEnter,
    enterText,
    disableOnSelect,
    keyUsedOnce,
}) => {
    const { data: backspaceLogo, isFetched: backspaceIsFetched } = useAsset({
        folder: 'logo',
        name: 'backspace.png',
    })

    const top = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
    const middle = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
    const bottom = ['z', 'x', 'c', 'v', 'b', 'n', 'm'];
    const { included, excluded } = list;

    const createButtonRow = (row) => row.map(key =>
        <button
            key={key}
            onClick={() => {
                if (keyUsedOnce && (included.includes(key) || excluded.includes(key))) return;
                if (disableOnSelect) return;
                onClick(key);
            }} 
            className={`computer-key ${included.includes(key) ? 'included' : ''} ${excluded.includes(key) ? 'excluded' : ''}`}
        >
            {key}
        </button>
    )

    return (
        <div className="keyboard">
            <div className="keyboard-row">
                {createButtonRow(top)}
            </div>
            <div className="keyboard-row">
                {createButtonRow(middle)}
            </div>
            <div className="keyboard-row">
                {onDelete && (
                    <button className="computer-key" onClick={onDelete}>
                        {backspaceIsFetched ? <img className="backspace-img" src={backspaceLogo} alt="backspace" /> : <div className="backspace-img"></div>}
                    </button>
                )}
                {createButtonRow(bottom)}
                {onEnter && (
                    <button className="computer-key" onClick={onEnter}>{enterText}</button>
                )}
            </div>
        </div>
    )
}