import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { Keyboard } from "../Keyboard";
import { randomize } from '../../../../utils';
import wordBank from '../words/word-bank-5.json';

export const Wordle = ({ toastMessages }) => {
    const max = 6;
    const [word, setWord] = useState([]);
    const comprehensiveFailed = `${toastMessages.failed} "${word.join('')}".`;
    const [guesses, setGuesses] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const guess = guesses[currentIndex] ?? [];

    const getNewWord = () => {
        const [newWord] = randomize(wordBank, 1);
        setWord(newWord.split(''));
    }

    // eslint-disable-next-line
    useEffect(() => getNewWord(), []);

    const removeEmptyStrings = (arr) => arr.filter(item => item);

    const getNewGuess = (letter) => {
        const newGuess = removeEmptyStrings(guess);

        if (letter) {
            newGuess.push(letter);
        } else {
            newGuess.pop();
        }

        for (let i = newGuess.length; i < 5; i++) {
            newGuess.push('');
        }

        return newGuess;
    }

    const updateGuessesUpdate = (newGuess) => {
        const newGuesses = [...guesses];
        newGuesses[currentIndex] = newGuess;
        setGuesses(newGuesses);
    }

    const onClick = (letter) => {
        if (removeEmptyStrings(guess).length < 5 && guesses.length - 1 < max) {
            const newGuess = getNewGuess(letter);
            updateGuessesUpdate(newGuess);
        }
    }

    const onDelete = () => {
        if (guess.length > 0) {
            const newGuess = getNewGuess();
            updateGuessesUpdate(newGuess);
        }
    }

    const onEnter = () => {
        const increment = () => {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
        }

        const getCorrectOnes = (arr, compArr) => arr.map((letter, i) => {
            if (letter === compArr[i]) return 'correct';
            return letter;
        });
        const replaceCorrectOnes = (arr, compArr) => arr.map((letter, i) => {
            if (letter === compArr[i]) return '';
            return letter;
        });

        const getChecked = () => {
            let checked = getCorrectOnes(guess, word); // e.g. ['a', 'b', 'correct', 't', 'm'];
            let replacedWord = replaceCorrectOnes(word, guess); // e.g. ['f', 'i', '', 's', 't'];

            checked = checked.map(letter => {
                if (letter.length === 1) {
                    const included = replacedWord.findIndex(instance => instance === letter);
                    if (included > -1) {
                        replacedWord[included] = '';
                        return 'incorrect';
                    }
                    return 'nothing';
                }
                return letter;
            })

            return checked;
        }

        if (removeEmptyStrings(guess).length < 5) {
            toast(toastMessages.incomplete, { className: 'error' });
        }
        else if (removeEmptyStrings(guess).length === 5) {
            const checked = getChecked();

            document.querySelectorAll(`#guess-row-${currentIndex} .guess-letter`).forEach((letter, i) => {
                letter.classList.add('flip');
                letter.style.animationDelay = `${i * 1000}ms`;

                setTimeout(() => {
                    letter.classList.add(checked[i], 'letter');
                }, i * 1000);
            })

            setTimeout(() => {
                const solved = isEqual(guess, word);
                if (solved) {
                    toast(toastMessages.success, { className: 'success' });
                    setTimeout(resetEverything, 3000);
                    setTimeout(removeStyles, 2950);
                } else {
                    if (guesses.length === max && !solved) {
                        setTimeout(() => {
                            toast(comprehensiveFailed, { className: 'error' });
                            setTimeout(resetEverything, 3000);
                            setTimeout(removeStyles, 2950);
                            removeStyles();
                        }, 500);
                    } else {
                        setTimeout(() => {
                            increment();
                        }, 50);
                    }
                }
            }, 5000);
        }
    }

    const removeStyles = () => {
        document.querySelectorAll('.guess-letter').forEach(item => {
            item.classList.remove('flat');
            item.classList.remove('letter');
            item.classList.remove('correct');
            item.classList.remove('incorrect');
            item.classList.remove('nothing');
        });
    }

    const resetEverything = () => {
        setGuesses([]);
        setCurrentIndex(0);
        getNewWord();
    }

    const getRemainingGuesses = () => {
        const remainingGuesses = [];
        for (let i = 0; i < (max - guesses.length); i++) {
            remainingGuesses.push(['', '', '', '', '']);
        }
        return remainingGuesses;
    }
    const remainingGuesses = getRemainingGuesses();

    const getNonCurrentGuess = () => {
        const nonCurrentGuess = [...guesses];
        nonCurrentGuess.splice(currentIndex);
        return nonCurrentGuess;
    }
    const getReducedGuesses = () => getNonCurrentGuess().reduce((acc, guess) => {
        const included = guess.filter(letter => word.includes(letter));
        const excluded = guess.filter(letter => !word.includes(letter));
        return {
            included: [...new Set(acc.included.concat(included))],
            excluded: [...new Set(acc.excluded.concat(excluded))],
        }
    }, { included: [], excluded: [] });
    const reducedGuesses = getReducedGuesses();

    return (
        <section className="wordle-board">
            <div className="guesses-container">
                {[...guesses, ...remainingGuesses].map((guess, guessIndex) => (
                    <div key={guessIndex} id={'guess-row-' + guessIndex} className="guess-row">
                        <strong className="guess-number">{guessIndex + 1}</strong>
                        {guess.map((letter, letterIndex) => <div key={letterIndex} className={`guess-letter ${currentIndex === guessIndex || guess.filter(item => item).length ? '' : 'opacity'}`}>{letter}</div>)}
                    </div>
                ))}
            </div>
            <Keyboard
                enterText="Check"
                list={reducedGuesses}
                onClick={onClick}
                onDelete={onDelete}
                onEnter={onEnter}
            />
        </section>
    )
}