import React, { useEffect, useState } from 'react';
import { useUsername } from '../../../../hooks';
import { ACTIVE_GAME_LOCAL_STORAGE_KEYS, getDecryptedValue, initializeBoard, setEncryptedValue } from '../../../../utils';
import { MODES, socket, SOCKET_CLIENT_EVENTS, USER_STATUSES, WaitingRoom } from '../WaitingRoom';
import { Board } from './Board';
import { Score } from './Score';

export const TicTacToe = ({ ...product }) => {
    const [mode, setMode] = useState();
    const [activeGame, setActiveGame] = useState();
    const { getUsername } = useUsername();
    const username = getUsername() ?? '';
    const opponent = activeGame?.players?.find(player => player !== username);
    const [disabled, setDisabled] = useState(false);

    const loadData = () => {
        if (getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.TIC_TAC_TOE)) {
            const activeGame = JSON.parse(getDecryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.TIC_TAC_TOE));
            setActiveGame(activeGame);
            setMode(MODES.IN_PLAY);
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_BUSY });
        } else {
            setMode(MODES.WAITING);
            socket.emit(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[0], { username, roomName: product.name, status: USER_STATUSES.STATUS_AVAILABLE });
        }
    }

    const onGameEnd = (data) => {
        setTimeout(() => {
            const players = data?.players; // [x, o];
            const x = players?.[0];
            const o = players?.[1];
            const user = data.turn !== x ? x : o;
            const otherUser = user === x ? o : x;
            const score = {
                [user]: (data?.score?.[user] ?? 0) + 1,
                [otherUser]: (data?.score?.[otherUser] ?? 0)
            };
            const newData = {
                ...data,
                board: initializeBoard({ rows: 3, columns: 3, value: null }),
                turn: otherUser,
                score
            }
            setActiveGame(newData);
            setEncryptedValue(ACTIVE_GAME_LOCAL_STORAGE_KEYS.TIC_TAC_TOE, JSON.stringify(newData));
        }, 3000);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [mode]);

    const startGame = () => {
        setMode(MODES.IN_PLAY);
    }

    return (
        <section className="tic-tac-toe-board">
            <WaitingRoom
                mode={mode}
                setMode={setMode}
                product={product}
                startGame={startGame}
                activeGame={activeGame}
                setActiveGame={setActiveGame}
                activeGameLocalStorageKey={ACTIVE_GAME_LOCAL_STORAGE_KEYS.TIC_TAC_TOE}
                additionalGameData={{
                    board: initializeBoard({ rows: 3, columns: 3, value: null })
                }}
                onGameEnd={onGameEnd}
            />
            {mode === MODES.IN_PLAY && activeGame && (
                <div className="tic-tac-toe-container">
                    <Score
                        activeGame={activeGame}
                        username={username}
                        opponent={opponent}
                    />
                    <Board
                        activeGame={activeGame}
                        setActiveGame={setActiveGame}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        username={username}
                        opponent={opponent}
                    />
                </div>
            )}
        </section>
    )
}