import React from 'react';

export const TimerOptions = ({ timerOption, setTimeOption, disabled }) => (
    <div className="timer-option-container">
        <h4>Timer Options</h4>
        <div className="options">
            {[
                {
                    label: '1:00',
                    value: 'one',
                },
                {
                    label: '2:00',
                    value: 'two',
                },
                {
                    label: '3:00',
                    value: 'three',
                },
            ].map(({ value, label }) => (
                <span
                    key={value}
                    className={`option three blue ${value === timerOption ? 'selected' : ''}`}
                    onClick={() => {
                        if (disabled) return;
                        setTimeOption(value)
                    }}
                >
                    {label}
                </span>
            ))}
        </div>
    </div>
);