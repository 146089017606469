import React from 'react';
import { useAsset } from '../../queries';

const BackToTop = () => {
    const handleBackToTop = () => {
        document.querySelector('.container').scrollIntoView({ behavior: 'smooth' });
    }

    const { data: arrowLogo, isFetched: arrowIsFetched } = useAsset({
        folder: 'logo',
        name: 'arrow.png',
    });

    return (
        <div className="back-to-top-container" onClick={handleBackToTop}>
            {arrowIsFetched ? <img className="arrow-logo" src={arrowLogo} alt="arrow" /> : <div className="arrow-logo"></div>}
        </div>
    )
}

export const ContentContainer = ({ content, additionalContent, customStyling }) => (
    <div className="content-container">
        {content.map((item, index) => {
            const additional = item?.title && additionalContent && additionalContent[item?.title] ? additionalContent[item.title] : null;
            const classNames = customStyling && customStyling[item?.title] ? customStyling[item?.title] : '';

            return (
                <div key={index} id={`content-item-${index}`} className={`item-content-container ${classNames}`}>
                    <h6 className="content-title">
                        {index + 1}. {item.title}
                        <BackToTop />
                    </h6>
                    <div className="content-text-container learning-tips">
                        {item.content.filter(text => text).map((text, index) => {
                            if (Array.isArray(text)) {
                                return (
                                    <ul key={index} className="content-text bulleted-list">
                                        {text.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                )
                            }
                            return (
                                <p key={index} className="content-text">{text}</p>
                            )
                        })}
                    </div>
                    {additional}
                </div>
            )
        })}
    </div>
);
