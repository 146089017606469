import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import { FlippingButton } from './FlippingButton';
import { mutationUrls, useMutationQuery } from '../../queries';

export const FeedbackForm = ({ type, product }) => {
    const [message, setMessage] = useState('');
    const [expirationDate, setExpirationDate] = useState();
    const [count, setCount] = useState(0);

    const reset = () => setMessage('');

    const { mutate } = useMutationQuery();

    useEffect(() => {
        if (count === 1) {
            const expirationDate = dayjs().add(1, 'h');
            setExpirationDate(expirationDate);
        }
    }, [count]);

    const checkDate = () => {
        if (!expirationDate) return true;
        const currentDate = dayjs();
        const isValid = currentDate > expirationDate;
        if (isValid) {
            setExpirationDate();
            setCount(0);
        };
        return isValid;
    }

    const submit = async (e) => {
        e.preventDefault();
        if (message && checkDate()) {
            const currentCount = count + 1;
            try {
                await mutate({ 
                    url: mutationUrls.feedback, 
                    body: { message, product }, 
                });
                toast('Submitted form', { className: 'success' });
                reset();
            } catch {
                toast('Sorry there was a problem.', { className: 'error' });
            }
            setCount(currentCount);
        } else {
            if (!message) toast('Please fill out all the required form fields.', { className: 'error' });
            else if (count >= 1) toast('Please wait an hour to leave more feedback.', { className: 'error' });
        }
    }
    return (
        <form className={`feedback-form ${type}`} action="submit">
            <h4 className="feedback-label">Leave Feedback</h4>
            <textarea id="feedback-form" className="feedback-input" value={message} onChange={(e) => setMessage(e.target.value)} />
            <div className="btn-container">
                <FlippingButton
                    buttonText="Submit"
                    color="orange"
                    onClick={submit}
                    width={148}
                    isDisabled={!message}
                />
            </div>
        </form>
    )
}
