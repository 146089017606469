import React from 'react';
import { toTitleCase } from '../../../../utils';

export const DifficultyTab = ({ boardMapping, difficultyLevel, setDifficultyLevel }) => {
    const difficultyArr = [
        {
            value: 'any',
            label: 'any',
        },
        {
            value: 'one-only',
            label: '1 only',
        },
        {
            value: 'two-only',
            label: '2 only',
        },
        {
            value: 'two-up',
            label: '2+',
        },
        {
            value: 'three-only',
            label: '3 only',
        },
        {
            value: 'three-up',
            label: '3+',
        },
        {
            value: 'four-only',
            label: '4 only',
        },
        {
            value: 'four-up',
            label: '4+',
        },
        {
            value: 'five-only',
            label: '5 only',
        },
    ];

    return (
        <div className="difficulty-container">
            <h2 className="difficulty-header">Select Your Difficulty Level:</h2>
            <div className="difficulty-labels">
                {difficultyArr.filter(difficulty => !!boardMapping[difficulty.value].length).map((difficulty, index) => (
                    <div key={index} className="label-container">
                        <label htmlFor={difficulty.value}>
                            <input type="radio" id={difficulty.value} name="difficulty" checked={difficultyLevel === difficulty.value} value={difficulty.value} onChange={(e) => setDifficultyLevel(e.target.value)} />
                            {toTitleCase(difficulty.label)}
                            <strong className='difficulty-count'>({boardMapping[difficulty.value].length})</strong>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}