import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default (value) => (
    <ToastContainer
        position="bottom-right"
        hideProgressBar={true}
        closeOnClick={true}
        autoClose={value ?? 3000}
        transition={Slide}
        draggable={true}
        draggablePercent={80}
    />
)