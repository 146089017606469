import Axios from 'axios';
import removeOld from './RemoveOld';
import { toTitleCase } from '../../../utils';

const standardTuning = ['e', 'b', 'g', 'd', 'a', 'e'];
const notes = ['c', 'c♯', 'd', 'd♯', 'e', 'f', 'f♯', 'g', 'g♯', 'a', 'a♯', 'b'];
const notes1 = ['c', 'd♭', 'd', 'e♭', 'e', 'f', 'g♭', 'g', 'a♭', 'a', 'b♭', 'b'];

const chordFunctions = {
    createChord: async (root, pitch, scale, type) => {
        const { getNote, checkExceptions } = chordFunctions;
        removeOld();

        const isExceptions = checkExceptions(root, pitch, scale, type);
        if (isExceptions) {
            root = isExceptions.root;
            pitch = isExceptions.pitch;
            scale = isExceptions.scale;
            type = isExceptions.type;
        }

        const key = root + toTitleCase(pitch) + toTitleCase(scale) + toTitleCase(type);
        const response = await Axios.get(`/music/guitar/chord/${root}/${key}`);

        const chord = response.data;

        chord.forEach(async (fret, index) => {
            let string = index + 1;
            let note = await getNote(string, fret, pitch);
            if (fret > 0) {
                document.querySelector(`.string-${string}.fret-${fret}`).innerHTML = `<div class="note-circle">${note}</div>`;
            } else if (fret === 0) {
                document.querySelector(`.note-circle-string-${string}`).classList.remove('d-none');
                document.querySelector(`.note-circle-string-${string}`).innerHTML = `<div class="note-circle">${note}</div>`;
            } else if (fret === 'x') {
                document.querySelector(`.x-string-${string}`).classList.remove('d-none');
            }
        });
    },

    checkExceptions: (root, pitch, scale, type) => {
        if ((scale === 'minor' && type === 'maj7') ||
            (root === 'a' && pitch === 'sharp' && scale === 'minor' && type === 'seventh') ||
            (root === 'b' && pitch === 'flat' && scale === 'minor' && type === 'seventh') ||
            (root === 'g' && pitch === 'natural' && scale === 'minor' && type === 'sixth') ||
            (pitch === 'flat' && scale === 'minor' && type === 'add2') ||
            (pitch === 'flat' && scale === 'minor' && type === 'add9') ||
            (pitch === 'sharp' && scale === 'minor' && type === 'add2') ||
            (pitch === 'sharp' && scale === 'minor' && type === 'add9')) {
            let exceptionObj = {};
            exceptionObj.root = root;
            exceptionObj.pitch = pitch;
            exceptionObj.scale = scale;
            exceptionObj.type = 'base';
            return exceptionObj;
        }
        return false;
    },

    getNote: (string, fret, pitch) => {
        let startingNote = standardTuning[string - 1];
        let copy = notes;
        if (pitch === 'flat') {
            copy = notes1;
        }
        let startingIndex = copy.findIndex(note => note === startingNote);
        let notesCopy = [...copy, ...copy];
        let scale = notesCopy.slice(startingIndex, startingIndex + 13);
        return scale[fret];
    }
}

export default chordFunctions;