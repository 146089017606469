import React from 'react';
import { useCheckWinner } from '../../../../hooks';
import { socket, SOCKET_CLIENT_EVENTS } from '../WaitingRoom';

export const Board = ({
    activeGame,
    setActiveGame,
    disabled,
    setDisabled,
    username,
    opponent,
}) => {
    const board = activeGame?.board;
    const turn = activeGame?.turn;
    const players = activeGame?.players; // [x, o];
    const x = players?.[0];

    const { getMessages } = useCheckWinner({
        winCount: 3,
        username,
        opponent,
        turn,
    });

    const handleClick = async (row, column) => {
        if (username !== turn) return;
        const newBoard = [...board].map(row => [...row]) // deep copy of the board
        if (newBoard[row][column] === null) {
            setDisabled(true);
            newBoard[row][column] = turn === x ? 'X' : 'O';
            const value = turn === x ? 'X' : 'O';

            const data = {
                ...activeGame,
                board: newBoard,
                turn: username === turn ? opponent : username,
                messages: getMessages({ row, column, value, board: newBoard }),
            };
            setActiveGame(data);
            socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], data);
            setDisabled(false);
        }
    }

    return (
        <div className="board">
            {board.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                    {row.map((column, columnIndex) => (
                        <div
                            key={columnIndex}
                            className="square"
                            onClick={() => {
                                if (disabled || turn !== username) return;
                                handleClick(rowIndex, columnIndex);
                            }}
                        >
                            {column}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}