import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { useResource } from '../../../queries';

export const Coding = () => {
    const { data: content = [] } = useResource({
        category: 'technical',
        id: 'coding'
    });

    const { TableOfContent } = useTableOfContent(content);

    return (
        <div className="learning-pages">
            <TableOfContent icons={content} />
            <ContentContainer content={content} />
        </div>
    )
}