import crypto from 'crypto-js';
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const ACTIVE_GAME_LOCAL_STORAGE_KEYS = {
    BATTLESHIP: 'battleshipActiveGame',
    CHECKERS: 'checkersActiveGame',
    CONNECT_4: 'connect4ActiveGame',
    MANCALA: 'mancalaActiveGame',
    NUMBER_ROYALE: 'numberRoyaleActiveGame',
    TIC_TAC_TOE: 'ticTacToeActiveGame',
}

export const getDecryptedValue = (prop) => {
    const value = localStorage.getItem(prop);
    return value ? crypto.AES.decrypt(value, encryptionKey).toString(crypto.enc.Utf8) : value;
}

export const setEncryptedValue = (prop, value) => {
    const encryptedValue = crypto.AES.encrypt(value, encryptionKey).toString();
    localStorage.setItem(prop, encryptedValue);
}

export const removeLocalStorageItem = (prop) => {
    localStorage.removeItem(prop);
}