import React, { useState } from 'react';
import { elements } from './elements';

export const PeriodicTable = () => {
    const [filter] = useState(null);
    // const [isModalOpen, updateIsModalOpen] = useState(false);
    // let selectedElement;

    const checkElementGroup = (element, group) => {
        if (group === 1 && element.class === 'alkali-metal' && filter === 'alkali') {
            return true;
        } else if (group === 2 && element.class === 'alkaline-earth-metal' && filter === 'alkali-earth') {
            return true;
        } else if (element.class === 'transition-metal' && filter === 'transition') {
            return true;
        } else if (element.class === 'post-transition-metal' && filter === 'post-transition') {
            return true;
        } else if (element.class === 'metalloid' && filter === 'metalloid') {
            return true;
        } else if (element.class === 'reactive-nonmetal' && filter === 'reactive-nonmetal') {
            return true;
        } else if (Number.isInteger(element.id) && element.class === 'lanthanoid' && filter === 'lanthanoid') {
            return true;
        } else if (Number.isInteger(element.id) && element.class === 'actinoid' && filter === 'actinoid') {
            return true;
        } else if (group === 13 && element.class !== 'unknown' && filter === 'boron') {
            return true;
        } else if (group === 14 && element.class !== 'unknown' && filter === 'carbon') {
            return true;
        } else if (group === 15 && element.class !== 'unknown' && filter === 'nitrogen') {
            return true;
        } else if (group === 16 && element.class !== 'unknown' && filter === 'oxygen') {
            return true;
        } else if (group === 17 && element.class !== 'unknown' && filter === 'halogen') {
            return true;
        } else if (element.class === 'noble-gas' && filter === 'noble-gas') {
            return true;
        } else if (element.class === 'unknown' && filter === 'unknown-class') {
            return true;
        } else if (element.element === filter && filter) {
            // selectedElement = element;
            return true;
        }
        return false
    }

    const checkElementState = (element) => {
        if (element.state === filter) {
            return true;
        } else if (element.element === filter && filter) {
            // selectedElement = element;
        }
        return false;
    }

    const createPeriodicTableColumn = () => {
        return elements.map((group, column) => {
            if (column < 18) {
                const groupNum = column + 1;
                return (
                    <div
                        key={`column-${column}`}
                        className={`periodic-group ${filter && 'hide-all-borders'}`}>
                        {group.map(element => {
                            let cat = checkElementGroup(element, groupNum);
                            let state = checkElementState(element, groupNum);
                            return (
                                <div
                                    key={`element-${element.id}`}
                                    className={`element-${element.id} group-${element.class && element.class} ${filter && (state || cat) && 'chosen-element'} ${filter && !(state || cat) && 'un-chosen-element'} periodic-element`}
                                // onClick={() => modalOpen(element)}
                                >
                                    <h6 className="atomic-number">{Number.isInteger(element.id) && element.id}</h6>
                                    <h4>{element.symbol}</h4>
                                </div>
                            )
                        })}
                    </div>
                )
            } else {
                return null
            }
        })
    }

    const createPeriodicTableRow = () => {
        let row = 0;
        return elements.map((group, groupNum) => {
            if (groupNum >= 18) {
                row += 1;
                return (
                    <div key={`row-${row}`} className={`periodic-row row-${row} ${filter && 'hide-all-borders'}`}>
                        {group.map(element => {
                            let cat = checkElementGroup(element, groupNum + 1);
                            let state = checkElementState(element, groupNum + 1);
                            return (
                                <div
                                    key={`element-${element.id}`}
                                    className={`element-${element.id} group-${element.class && element.class} ${filter && (state || cat) && 'chosen-element'} ${filter && !(state || cat) && 'un-chosen-element'} periodic-element`}
                                // onClick={() => modalOpen(element)}
                                >
                                    <h6 className="atomic-number">{Number.isInteger(element.id) && element.id}</h6>
                                    <h4>{element.symbol}</h4>
                                </div>
                            )
                        })}
                    </div>
                )
            } else {
                return null
            }
        })
    }

    // const modalOpen = (element) => {
    //     if (!isModalOpen) {
    //         setTimeout(() => {
    //             document.querySelector('body').style.overflow = 'hidden';
    //             document.querySelector('body').style.height = '100%';
    //         }, 1000);
    //         updateIsModalOpen(true);
    //     } else {
    //         // setSelectedElement(element.element);
    //         document.querySelectorAll('.ind-group-container input').forEach(input => input.checked = false);
    //         document.querySelectorAll('.ind-state-container input').forEach(input => input.checked = false);
    //     }
    // }

    return (
        <div className="periodic-table">
            <div className="periodic-table-top">
                {createPeriodicTableColumn()}
            </div>
            <div className="periodic-table-bottom">
                {createPeriodicTableRow()}
            </div>
        </div>
    )
}