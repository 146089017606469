import wordBank5 from './word-bank-5';
import wordBank6 from './word-bank-6';
import wordBank7 from './word-bank-7';
import wordBank8 from './word-bank-8';
import wordBank9 from './word-bank-9';
import wordBank10 from './word-bank-10';
import wordBank11 from './word-bank-11';
import wordBank12 from './word-bank-12';

const wordBankMap = {
    5: wordBank5,
    6: wordBank6,
    7: wordBank7,
    8: wordBank8,
    9: wordBank9,
    10: wordBank10,
    11: wordBank11,
    12: wordBank12
};

export const getComprehensiveWordBank = (arr = [5, 6, 7, 8, 9, 10, 11, 12]) => {
    const wordBank = arr.flatMap(number => wordBankMap[number]);
    return wordBank;
}