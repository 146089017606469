import React from 'react';
import turnMarker from "../turn-marker.png";

export const Score = ({ activeGame, username, opponent }) => {
    const turn = activeGame.turn;
    const players = activeGame.players;
    const first = players?.[0];

    return (
        <div className="score-container">
            <div className="score-holder">
                <h4 className="username">
                    <span>{username === first ? `${username} (X)` : `${username} (O)`}</span>
                    {turn === username && <img src={turnMarker} alt="turn-marker" className="turn-marker" />}
                </h4>
                <p className="point">{activeGame?.score?.[username] ?? 0}</p>
            </div>
            <div className="score-holder">
                <h4 className="username">
                    <span>{opponent} {opponent === first ? '(X)' : '(O)'}</span>
                    {turn === opponent && <img src={turnMarker} alt="turn-marker" className="turn-marker" />}
                </h4>
                <p className="point">{activeGame?.score?.[opponent] ?? 0}</p>
            </div>
        </div>
    )
}