import React from 'react';
import { FlippingButton } from '../../../components';

export const SliderButtons = ({ next, reset }) => (
    <div className="puzzle-buttons-container">
        <FlippingButton
            buttonText="Reset"
            color="red"
            onClick={reset}
            width={96}
        />
        <FlippingButton
            buttonText="Next"
            color="orange"
            onClick={next}
            width={80}
        />
        <button className="default-btn reset-btn" onClick={reset}>Reset</button>
        <button className="default-btn next-btn" onClick={next}>Next</button>
    </div>
)