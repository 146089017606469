import React from 'react';
import { useAsset } from "../../../../queries"

export const DirectionNote = () => {
    const { data: arrowLogo, isFetched: arrowIsFetched } = useAsset({
        folder: 'math',
        name: 'right-arrow.png',
    })

    if (!arrowIsFetched) return null;
    return (
        <p className="direction-note-container">
            Go that direction
            <img src={arrowLogo} alt="arrow" />
        </p>
    )
}