import React from 'react';

export const ZodiacList = ({ zodiacs }) => zodiacs.map((zodiac, index) => (
    <div id={index + 1} key={index} className="learning-tips ind-zodiac-container">
        <img className="zodiac-image" src={zodiac.img} alt={zodiac.animal} />
        <h2 className="zodiac-name">{zodiac.animal}</h2>
        <div className="zodiac-info">
            {/* ASTROLOGY */}
            {zodiac?.element ? (
                <>
                    <h4>Element:</h4>
                    <p>{zodiac.element}</p>
                </>
            ) : null}
            {zodiac.bodyParts ? (
                <>
                    <h4>Body Part:</h4>
                    <p>{zodiac.bodyPart}</p>
                </>
            ) : null}
            {zodiac.personalities ? (
                <>
                    <h4>Characteristics:</h4>
                    <ul>
                        {zodiac.personalities.map((trait, index) => {
                            return <li key={index}>{trait}</li>
                        })}
                    </ul>
                </>
            ) : null}
            {/* CHINESE ZODIAC */}
            {zodiac?.strengths?.length ? (
                <>
                    <h4>Strengths:</h4>
                    <ul>
                        {zodiac.strengths.map((strength, index) => {
                            return <li key={index}>{strength}</li>
                        })}
                    </ul>
                </>
            ) : null}
            {zodiac?.weaknesses?.length ? (
                <>
                    <h4>Weaknesses:</h4>
                    <ul>
                        {zodiac.weaknesses.map((weakness, index) => {
                            return <li key={index}>{weakness}</li>
                        })}
                    </ul>
                </>
            ) : null}
            {zodiac.career ? (
                <>
                    <h4>Careers:</h4>
                    <p>{zodiac.career}</p>
                </>
            ) : null}
            {zodiac?.years?.length ? (
                <>
                    <h4>Years:</h4>
                    <p className="year-container">
                        {zodiac.years.map((year, index) => {
                            return <span key={index} className="year">{year}{index !== zodiac.years.length - 1 && ','}</span>
                        })}
                    </p>
                </>
            ) : null}
        </div>
    </div>
)) 