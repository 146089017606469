import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { About } from './components/pages/About';
import { Blog } from './components/pages/Blog';
import { Blogs } from './components/pages/Blogs';
import { Contact } from './components/pages/Contact';
import { Dashboard } from './components/pages/Dashboard';
import { MusicDetails } from './components/pages/MusicDetails';
import { MusicLanding } from './components/pages/MusicLanding';
import { Products } from './components/pages/Products';
import { ProductDetails } from './components/pages/ProductDetails';
import { Resource } from './components/pages/Resource';
import { ResourceCategory } from './components/pages/ResourceCategory';
import { Resources } from './components/pages/Resources';
import { SliderPuzzleBuilder } from './components/product-page/online-games/Slider/SliderPuzzleBuilder';
import { TypeResourceDetail } from './components/types-resources-page/TypeResourceDetail';

const routes = (routeProps) => (
    <Switch>
        <Route path="/about" render={(props) => <About {...props} {...routeProps} />} />
        <Route path="/contact" render={(props) => <Contact {...props} {...routeProps} />} />
        <Route path="/blogs/:id" render={(props) => <Blog {...props} {...routeProps} />} />
        <Route path="/blogs" render={(props) => <Blogs {...props} {...routeProps} />} />
        <Route path="/music/:id" render={(props) => <MusicDetails {...props} {...routeProps} />} />
        <Route path="/music" render={(props) => <MusicLanding {...props} {...routeProps} />} />
        <Route path="/types-resources/:type/:id" render={(props) => <TypeResourceDetail {...props} {...routeProps} />} />
        <Route path="/products/:type/:id/builder" render={(props) => <SliderPuzzleBuilder {...props} {...routeProps} />} />
        <Route path="/products/:type/:id" render={(props) => <ProductDetails {...props} {...routeProps} />} />
        <Route path="/products/:type" render={(props) => <Products {...props} {...routeProps} />} />
        <Route path="/resources/:type/:id" render={(props) => <Resource {...props} {...routeProps} />} />
        <Route path="/resources/:id" render={(props) => <ResourceCategory {...props} {...routeProps} />} />
        <Route path="/resources" render={(props) => <Resources {...props} {...routeProps} />} />
        <Route exact path="/" render={(props) => <Dashboard {...props} {...routeProps} />} />
        <Route path='*' render={() => <Redirect to={{ pathname: '/', state: { status: 301 } }} />} />
    </Switch>
);

export default routes;