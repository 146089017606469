import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { omit } from 'lodash';
import { useUsername } from '../../../../hooks';
import { getDecryptedValue, setEncryptedValue } from '../../../../utils';
import { socket, SOCKET_CLIENT_EVENTS } from './socket';

export const USER_STATUSES = {
    STATUS_AVAILABLE: 'available',
    STATUS_BUSY: 'busy',
};

export const MODES = {
    WAITING: 'WAITING',
    SET_UP: 'SET_UP',
    IN_PLAY: 'IN_PLAY',
};

export const WaitingRoom = ({
    mode,
    product,
    setGame,
    startGame,
    activeGame,
    trackStarting,
    setActiveGame,
    activeGameLocalStorageKey,
    additionalGameData,
    onGameEnd
}) => {
    const { getUsername, setUsername } = useUsername(product.name);
    const [users, setUsers] = useState([]);
    const username = getUsername() ?? '';
    const [name, setName] = useState(username);
    const [invited, setInvited] = useState([]);

    const handleUsernameChange = () => {
        if (!name) return;
        setUsername(name);
        socket.emit(SOCKET_CLIENT_EVENTS.JOIN_ROOM[0], { username: name, roomName: product.name });
    }

    useEffect(() => {
        socket.emit(SOCKET_CLIENT_EVENTS.JOIN_ROOM[0], { username: getUsername(), roomName: product.name, status: USER_STATUSES.STATUS_AVAILABLE });

        if (getDecryptedValue(activeGameLocalStorageKey)) {
            const activeGame = JSON.parse(getDecryptedValue(activeGameLocalStorageKey));
            socket.emit(SOCKET_CLIENT_EVENTS.JOIN_GAME[0], activeGame);
            setActiveGame(activeGame);
        }

        socket.on(SOCKET_CLIENT_EVENTS.JOIN_GAME[1], data => {
            setEncryptedValue(activeGameLocalStorageKey, JSON.stringify(data));
            setActiveGame(data);
        });

        socket.on(SOCKET_CLIENT_EVENTS.JOIN_ROOM[1], data => {
            setUsers(data.users);
            const message = data.username === username ? 'You have joined the room!' : `${data.username} has joined the waiting room!`;
            toast.dismiss();
            toast.success(message);
        });

        socket.on(SOCKET_CLIENT_EVENTS.LEAVE[1], data => {
            setUsers(data.users);
            const message = data.username === username ? 'You have left the waiting room!' : `${data.username} has left the waiting room!`;
            toast.dismiss();
            toast.warning(message);
        });

        socket.on(SOCKET_CLIENT_EVENTS.INVITE[1], data => {
            if (data.invited === username) {
                toast.dismiss();
                toast.info(`${data.inviter} has invited you!`);
                setInvited([...invited, data.inviter]);
            }
        });

        socket.on(SOCKET_CLIENT_EVENTS.ACCEPT[1], async data => {
            if (data.inviter === username) {
                toast.dismiss();
                toast.success(`${data.invited} has accepted your invitation!`);
            }
            if (data.inviter === username || data.invited === username) {
                if (setGame) {
                    setGame();
                } else {
                    startGame();
                }
                const players = [data.inviter, data.invited].sort((a, b) => a.localeCompare(b));
                const roomName = product.name + '-' + players.join('-');

                const gameData = {
                    players, roomName, turn: players[0], ...{ ...(additionalGameData ? additionalGameData : {}) }
                }
                
                if (trackStarting && players) {
                    gameData.isFirst = players[0];
                }

                socket.emit(SOCKET_CLIENT_EVENTS.JOIN_GAME[0], gameData);
                await setEncryptedValue(activeGameLocalStorageKey, JSON.stringify(gameData));
                await setActiveGame(gameData);
            }
            setUsers(data.users);
        });

        socket.on(SOCKET_CLIENT_EVENTS.SET[1], data => {
            if (mode === MODES.IN_PLAY) return;
            if (data.username !== username) {
                toast.dismiss();
                toast.info(`${data.username} is ready!`);
            }
            const sanitizedData = omit(data, 'username', 'gameData');
            const finalData = { ...sanitizedData, [data.username]: data.gameData };
            setActiveGame(finalData);
            setEncryptedValue(activeGameLocalStorageKey, JSON.stringify(finalData));

            if (data.players.every(player => finalData[player])) {
                startGame();
            }
        });

        socket.on(SOCKET_CLIENT_EVENTS.UPDATE_STATUS[1], data => {
            setUsers(data.users);
        });

        socket.on(SOCKET_CLIENT_EVENTS.SEND[1], data => {
            const message = data?.messages?.[username];
            if (message) {
                toast.dismiss();
                toast.info(message);
            }
            const filteredData = omit(data, 'messages');
            setActiveGame(filteredData);
            setEncryptedValue(activeGameLocalStorageKey, JSON.stringify(filteredData));
            if (data?.messages?.finished) {
                onGameEnd(filteredData);
            }
        })

        return () => {
            socket.emit(SOCKET_CLIENT_EVENTS.LEAVE[0], { username, roomName: product.name });
            socket.disconnect();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <section className="waiting-room-container">
            {!username ? (
                <>
                    <h3>Enter A Username</h3>
                    <div className="username-container">
                        <div className="enter-username-input-container">
                            <input
                                maxLength={8}
                                className="username-input"
                                type="text"
                                value={name}
                                placeholder="Select Your Username"
                                onChange={(e) => setName(e.target.value.toLowerCase())}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') handleUsernameChange();
                                }}
                            />
                            <button
                                className="default-btn"
                                onClick={handleUsernameChange}
                                disabled={!name}
                            >
                                Set
                            </button>
                        </div>
                        <span>Usernames are limited to 8 characters</span>
                    </div>
                </>
            ) : (
                <>
                    <h3>Waiting Room</h3>
                    <ul className="waiting-room">
                        {users.sort((a, b) => {
                            if (a.username === username) return -1;
                            if (b.username === username) return 1;
                            return 0;
                        }).map((user) => {
                            return (
                                <li
                                    key={user.username}
                                    className={`user ${user.username === username ? '' : 'is-other'} ${user.username === activeGame ? 'selected-user' : ''}`}
                                    onClick={() => {
                                        // if you are in a game, do nothing
                                        const me = users.find(user => user.username === username);
                                        if (me) {
                                            if (me.status === USER_STATUSES.STATUS_BUSY) {
                                                toast.error('You are currently in a game!');
                                                return;
                                            }
                                        }
                                        // if the user is you or the user is busy, do nothing
                                        if (user.username === username || user.status === USER_STATUSES.STATUS_BUSY) return;

                                        if (invited.includes(user.username)) {
                                            // you accept the invitation
                                            setInvited(invited.filter(inviter => inviter !== user.username));
                                            toast.dismiss();
                                            toast.info(`You have accepted the invitation from ${user.username}!`);
                                            socket.emit(SOCKET_CLIENT_EVENTS.ACCEPT[0], {
                                                invited: username,
                                                inviter: user.username,
                                                roomName: product.name,
                                            })
                                        } else {
                                            // you are inviting the user
                                            toast.dismiss();
                                            toast.info(`You have invited ${user.username}!`);
                                            socket.emit(SOCKET_CLIENT_EVENTS.INVITE[0], {
                                                inviter: username,
                                                invited: user.username,
                                                roomName: product.name,
                                            });
                                        }
                                    }}
                                >
                                    {user.username} {user.username === username && '(me)'}
                                    {username !== user.username && (
                                        <span className={`indicator ${invited.includes(user.username) ? 'invited' : ''} ${user.status === USER_STATUSES.STATUS_BUSY ? 'busy' : 'available'}`}></span>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </>
            )}
        </section>
    )
}

// BLUE means they have invited you.
// RED means they are in a game.
// GREEN means you are available.