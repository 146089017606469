import React from 'react';

export const Instrument = ({
    initializeNote,
    tableDisplay,
}) => (
    <div className="guitar align-ctr">
        <div className="head-container">
            <div className="peg peg-e-high"></div>
            <div className="peg peg-b"></div>
            <div className="peg peg-g"></div>
            <div className="peg peg-d"></div>
            <div className="peg peg-a"></div>
            <div className="peg peg-e-low"></div>
            <div className="head">
                <div className="tuning-peg peg-e-high"></div>
                <div className="tuning-peg peg-b"></div>
                <div className="tuning-peg peg-g"></div>
                <div className="tuning-peg peg-d"></div>
                <div className="tuning-peg peg-a"></div>
                <div className="tuning-peg peg-e-low"></div>
                <div className="string string-e-high-part-1"></div>
                <div className="string string-e-high-part-2"></div>
                <div className="string string-b-part-1"></div>
                <div className="string string-b-part-2"></div>
                <div className="string string-g-part-1"></div>
                <div className="string string-g-part-2"></div>
                <div className="string string-d-part-1"></div>
                <div className="string string-d-part-2"></div>
                <div className="string string-a-part-1"></div>
                <div className="string string-a-part-2"></div>
                <div className="string string-e-low-part-1"></div>
                <div className="string string-e-low-part-2"></div>
                <div className="nut"></div>
            </div>
        </div>
        <div className="neck flex">
            <div className="note-circle-string-1 d-none"></div>
            <div className="note-circle-string-2 d-none"></div>
            <div className="note-circle-string-3 d-none"></div>
            <div className="note-circle-string-4 d-none"></div>
            <div className="note-circle-string-5 d-none"></div>
            <div className="note-circle-string-6 d-none"></div>
            <div className="x-string-1 d-none">x</div>
            <div className="x-string-2 d-none">x</div>
            <div className="x-string-3 d-none">x</div>
            <div className="x-string-4 d-none">x</div>
            <div className="x-string-5 d-none">x</div>
            <div className="x-string-6 d-none">x</div>
            <div className="fret fret-1"></div>
            <div className="fret fret-2"></div>
            <div className="fret fret-3">
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-4"></div>
            <div className="fret fret-5">
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-6"></div>
            <div className="fret fret-7">
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-8"></div>
            <div className="fret fret-9">
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-10"></div>
            <div className="fret fret-11"></div>
            <div className="fret fret-12">
                <div className="fret-marker"></div>
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-13"></div>
            <div className="fret fret-14"></div>
            <div className="fret fret-15">
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-16"></div>
            <div className="fret fret-17">
                <div className="fret-marker"></div>
            </div>
            <div className="fret fret-18"></div>
            <div className="fret fret-19"></div>
            <div className="fret fret-20"></div>
            <div className="string string-e-high flex">
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 1)} className="string-1 flex-ctr fret-1"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 2)} className="string-1 flex-ctr fret-2"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 3)} className="string-1 flex-ctr fret-3"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 4)} className="string-1 flex-ctr fret-4"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 5)} className="string-1 flex-ctr fret-5"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 6)} className="string-1 flex-ctr fret-6"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 7)} className="string-1 flex-ctr fret-7"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 8)} className="string-1 flex-ctr fret-8"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 9)} className="string-1 flex-ctr fret-9"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 10)} className="string-1 flex-ctr fret-10"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 11)} className="string-1 flex-ctr fret-11"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 12)} className="string-1 flex-ctr fret-12"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 13)} className="string-1 flex-ctr fret-13"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 14)} className="string-1 flex-ctr fret-14"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 15)} className="string-1 flex-ctr fret-15"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 16)} className="string-1 flex-ctr fret-16"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 17)} className="string-1 flex-ctr fret-17"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 18)} className="string-1 flex-ctr fret-18"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(1, 19)} className="string-1 flex-ctr fret-19"></button>
            </div>
            <div className="string string-b flex">
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 1)} className="string-2 flex-ctr fret-1"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 2)} className="string-2 flex-ctr fret-2"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 3)} className="string-2 flex-ctr fret-3"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 4)} className="string-2 flex-ctr fret-4"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 5)} className="string-2 flex-ctr fret-5"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 6)} className="string-2 flex-ctr fret-6"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 7)} className="string-2 flex-ctr fret-7"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 8)} className="string-2 flex-ctr fret-8"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 9)} className="string-2 flex-ctr fret-9"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 10)} className="string-2 flex-ctr fret-10"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 11)} className="string-2 flex-ctr fret-11"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 12)} className="string-2 flex-ctr fret-12"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 13)} className="string-2 flex-ctr fret-13"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 14)} className="string-2 flex-ctr fret-14"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 15)} className="string-2 flex-ctr fret-15"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 16)} className="string-2 flex-ctr fret-16"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 17)} className="string-2 flex-ctr fret-17"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 18)} className="string-2 flex-ctr fret-18"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(2, 19)} className="string-2 flex-ctr fret-19"></button>
            </div>
            <div className="string string-g flex">
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 1)} className="string-3 flex-ctr fret-1"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 2)} className="string-3 flex-ctr fret-2"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 3)} className="string-3 flex-ctr fret-3"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 4)} className="string-3 flex-ctr fret-4"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 5)} className="string-3 flex-ctr fret-5"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 6)} className="string-3 flex-ctr fret-6"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 7)} className="string-3 flex-ctr fret-7"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 8)} className="string-3 flex-ctr fret-8"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 9)} className="string-3 flex-ctr fret-9"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 10)} className="string-3 flex-ctr fret-10"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 11)} className="string-3 flex-ctr fret-11"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 12)} className="string-3 flex-ctr fret-12"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 13)} className="string-3 flex-ctr fret-13"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 14)} className="string-3 flex-ctr fret-14"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 15)} className="string-3 flex-ctr fret-15"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 16)} className="string-3 flex-ctr fret-16"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 17)} className="string-3 flex-ctr fret-17"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 18)} className="string-3 flex-ctr fret-18"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(3, 19)} className="string-3 flex-ctr fret-19"></button>
            </div>
            <div className="string string-d flex">
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 1)} className="string-4 flex-ctr fret-1"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 2)} className="string-4 flex-ctr fret-2"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 3)} className="string-4 flex-ctr fret-3"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 4)} className="string-4 flex-ctr fret-4"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 5)} className="string-4 flex-ctr fret-5"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 6)} className="string-4 flex-ctr fret-6"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 7)} className="string-4 flex-ctr fret-7"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 8)} className="string-4 flex-ctr fret-8"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 9)} className="string-4 flex-ctr fret-9"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 10)} className="string-4 flex-ctr fret-10"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 11)} className="string-4 flex-ctr fret-11"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 12)} className="string-4 flex-ctr fret-12"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 13)} className="string-4 flex-ctr fret-13"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 14)} className="string-4 flex-ctr fret-14"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 15)} className="string-4 flex-ctr fret-15"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 16)} className="string-4 flex-ctr fret-16"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 17)} className="string-4 flex-ctr fret-17"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 18)} className="string-4 flex-ctr fret-18"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(4, 19)} className="string-4 flex-ctr fret-19"></button>
            </div>
            <div className="string string-a flex">
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 1)} className="string-5 flex-ctr fret-1"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 2)} className="string-5 flex-ctr fret-2"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 3)} className="string-5 flex-ctr fret-3"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 4)} className="string-5 flex-ctr fret-4"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 5)} className="string-5 flex-ctr fret-5"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 6)} className="string-5 flex-ctr fret-6"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 7)} className="string-5 flex-ctr fret-7"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 8)} className="string-5 flex-ctr fret-8"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 9)} className="string-5 flex-ctr fret-9"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 10)} className="string-5 flex-ctr fret-10"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 11)} className="string-5 flex-ctr fret-11"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 12)} className="string-5 flex-ctr fret-12"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 13)} className="string-5 flex-ctr fret-13"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 14)} className="string-5 flex-ctr fret-14"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 15)} className="string-5 flex-ctr fret-15"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 16)} className="string-5 flex-ctr fret-16"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 17)} className="string-5 flex-ctr fret-17"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 18)} className="string-5 flex-ctr fret-18"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(5, 19)} className="string-5 flex-ctr fret-19"></button>
            </div>
            <div className="string string-e-low flex">
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 1)} className="string-6 flex-ctr fret-1"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 2)} className="string-6 flex-ctr fret-2"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 3)} className="string-6 flex-ctr fret-3"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 4)} className="string-6 flex-ctr fret-4"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 5)} className="string-6 flex-ctr fret-5"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 6)} className="string-6 flex-ctr fret-6"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 7)} className="string-6 flex-ctr fret-7"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 8)} className="string-6 flex-ctr fret-8"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 9)} className="string-6 flex-ctr fret-9"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 10)} className="string-6 flex-ctr fret-10"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 11)} className="string-6 flex-ctr fret-11"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 12)} className="string-6 flex-ctr fret-12"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 13)} className="string-6 flex-ctr fret-13"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 14)} className="string-6 flex-ctr fret-14"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 15)} className="string-6 flex-ctr fret-15"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 16)} className="string-6 flex-ctr fret-16"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 17)} className="string-6 flex-ctr fret-17"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 18)} className="string-6 flex-ctr fret-18"></button>
                <button disabled={tableDisplay !== 'notes'} style={{ cursor: tableDisplay !== 'notes' && 'unset' }} onClick={() => initializeNote(6, 19)} className="string-6 flex-ctr fret-19"></button>
            </div>
        </div>
        <div className="body">
            <div className="sound-hole"></div>
            <div className="body-background"></div>
            <div className="string string-e-high"></div>
            <div className="string string-b"></div>
            <div className="string string-g"></div>
            <div className="string string-d"></div>
            <div className="string string-a"></div>
            <div className="string string-e-low"></div>
            <div className="bridge-pin string-e-high"></div>
            <div className="bridge-pin string-b"></div>
            <div className="bridge-pin string-g"></div>
            <div className="bridge-pin string-d"></div>
            <div className="bridge-pin string-a"></div>
            <div className="bridge-pin string-e-low"></div>
            <div className="saddle"></div>
            <div className="bridge"></div>
        </div>
    </div>
)