import React from 'react';

export const SliderFraction = ({
    boardMapping,
    boards,
    difficultyLevel,
    selectedIndex,
    selectedNumber,
    selectedTab,
}) => {
    if (selectedTab === 'difficulty') {
        return (
            <h4 className="puzzle-fraction">
                {(selectedIndex + 1)}&nbsp;/&nbsp;{boardMapping[difficultyLevel].length}
            </h4>
        )
    } else if (selectedTab === 'number') {
        return (
            <h4 className="puzzle-fraction">
                {selectedNumber}&nbsp;/&nbsp;{boards.length}
            </h4>
        )
    }
    return null;
}