export const elements = [
    [
        { id: 1, element: 'Hydrogen', symbol: 'H', mass: 1.008, class: 'reactive-nonmetal', state: 'gas', metal: 0 },
        { id: 3, element: 'Lithium', symbol: 'Li', mass: 6.94, class: 'alkali-metal', state: 'solid', metal: 1 },
        { id: 11, element: 'Sodium', symbol: 'Na', mass: 22.990, class: 'alkali-metal', state: 'solid', metal: 1 },
        { id: 19, element: 'Potassium', symbol: 'K', mass: 39.098, class: 'alkali-metal', state: 'solid', metal: 1 },
        { id: 37, element: 'Rubidium', symbol: 'Rb', mass: 85.468, class: 'alkali-metal', state: 'solid', metal: 1 },
        { id: 55, element: 'Caesium', symbol: 'Cs', mass: 132.91, class: 'alkali-metal', state: 'solid', metal: 1 },
        { id: 87, element: 'Francium', symbol: 'Fr', mass: 223, class: 'alkali-metal', state: 'solid', metal: 1 },
    ], //column 1
    [
        { id: 4, element: 'Beryllium', symbol: 'Be', mass: 9.0122, class: 'alkaline-earth-metal', state: 'solid', metal: 1 },
        { id: 12, element: 'Magnesium', symbol: 'Mg', mass: 24.305, class: 'alkaline-earth-metal', state: 'solid', metal: 1 },
        { id: 20, element: 'Calcium', symbol: 'Ca', mass: 40.078, class: 'alkaline-earth-metal', state: 'solid', metal: 1 },
        { id: 38, element: 'Strontium', symbol: 'Sr', mass: 87.62, class: 'alkaline-earth-metal', state: 'solid', metal: 1 },
        { id: 56, element: 'Barium', symbol: 'Ba', mass: 137.33, class: 'alkaline-earth-metal', state: 'solid', metal: 1 },
        { id: 88, element: 'Radium', symbol: 'Ra', mass: 226, class: 'alkaline-earth-metal', state: 'solid', metal: 1 },
    ], //column 2
    [
        { id: 21, element: 'Scandium', symbol: 'Sc', mass: 44.956, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 39, element: 'Yttrium', symbol: 'Y', mass: 88.906, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 'L', element: null, symbol: null, mass: null, class: 'lanthanoid', state: null, metal: null },
        { id: 'A', element: null, symbol: null, mass: null, class: 'actinoid', state: null, metal: null },
    ], //column 3
    [
        { id: 22, element: 'Titanium', symbol: 'Ti', mass: 47.867, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 40, element: 'Zirconium', symbol: 'Zr', mass: 91.224, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 72, element: 'Hafnium', symbol: 'Hf', mass: 178.49, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 104, element: 'Rutherfordium', symbol: 'Rf', mass: 267, class: 'transition-metal', state: 'unknown', metal: 1 },
    ], //column 4
    [
        { id: 23, element: 'Vanadium', symbol: 'V', mass: 50.942, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 41, element: 'Niobium', symbol: 'Nb', mass: 92.906, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 73, element: 'Tantalum', symbol: 'Ta', mass: 180.95, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 105, element: 'Dubnium', symbol: 'Db', mass: 268, class: 'transition-metal', state: 'unknown', metal: 1 },
    ], //column 5
    [
        { id: 24, element: 'Chromium', symbol: 'Cr', mass: 51.996, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 42, element: 'Molybdenur', symbol: 'Mo', mass: 95.95, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 74, element: 'Tungsten', symbol: 'W', mass: 183.84, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 106, element: 'Seaborgium', symbol: 'Sg', mass: 269, class: 'transition-metal', state: 'unknown', metal: 1 },
    ], //column 6
    [
        { id: 25, element: 'Manganese', symbol: 'Mn', mass: 54.938, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 43, element: 'Technetium', symbol: 'Tc', mass: 98, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 75, element: 'Rhenium', symbol: 'Re', mass: 186.21, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 107, element: 'Bohrium', symbol: 'Bh', mass: 270, class: 'transition-metal', state: 'unknown', metal: 1 },
    ], //column 7
    [
        { id: 26, element: 'Iron', symbol: 'Fe', mass: 55.845, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 44, element: 'Ruthenium', symbol: 'Ru', mass: 101.07, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 76, element: 'Osmium', symbol: 'Os', mass: 190.23, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 108, element: 'Hassium', symbol: 'Hs', mass: 277, class: 'transition-metal', state: 'unknown', metal: 1 },
    ], //column 8
    [
        { id: 27, element: 'Cobalt', symbol: 'Co', mass: 57.933, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 45, element: 'Rhodium', symbol: 'Rh', mass: 102.91, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 77, element: 'Iridium', symbol: 'Ir', mass: 192.22, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 109, element: 'Meitnerium', symbol: 'Mt', mass: 278, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 9
    [
        { id: 28, element: 'Nickel', symbol: 'Ni', mass: 58.693, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 46, element: 'Palladium', symbol: 'Pd', mass: 106.42, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 78, element: 'Platinum', symbol: 'Pt', mass: 195.08, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 110, element: 'Darmstadtium', symbol: 'Ds', mass: 281, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 10
    [
        { id: 29, element: 'Copper', symbol: 'Cu', mass: 63.546, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 47, element: 'Silver', symbol: 'Ag', mass: 107.87, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 79, element: 'Gold', symbol: 'Au', mass: 196.97, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 111, element: 'Roentgenium', symbol: 'Rg', mass: 282, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 11
    [
        { id: 30, element: 'Zinc', symbol: 'Zn', mass: 65.38, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 48, element: 'Cadmium', symbol: 'Cd', mass: 112.41, class: 'transition-metal', state: 'solid', metal: 1 },
        { id: 80, element: 'Mercury', symbol: 'Hg', mass: 200.59, class: 'transition-metal', state: 'liquid', metal: 1 },
        { id: 112, element: 'Copemicium', symbol: 'Cn', mass: 285, class: 'transition-metal', state: 'unknown', metal: 1 },
    ], //column 12
    [
        { id: 5, element: 'Boron', symbol: 'B', mass: 10.81, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 13, element: 'Aluminium', symbol: 'Al', mass: 26.982, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 31, element: 'Gallium', symbol: 'Ga', mass: 69.723, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 49, element: 'Indium', symbol: 'In', mass: 114.82, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 81, element: 'Thallium', symbol: 'Tl', mass: 204.38, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 113, element: 'Nihonium', symbol: 'Nh', mass: 286, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 13
    [
        { id: 6, element: 'Carbon', symbol: 'C', mass: 12.011, class: 'reactive-nonmetal', state: 'solid', metal: 0 },
        { id: 14, element: 'Silicon', symbol: 'Si', mass: 28.085, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 32, element: 'Germanium', symbol: 'Ge', mass: 72.630, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 50, element: 'Tin', symbol: 'Sn', mass: 118.71, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 82, element: 'Lead', symbol: 'Pb', mass: 207.2, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 114, element: 'Flerovium', symbol: 'Fl', mass: 289, class: 'post-transition-metal', state: 'unknown', metal: 1 },
    ], //column 14
    [
        { id: 7, element: 'Nitrogen', symbol: 'N', mass: 14.007, class: 'reactive-nonmetal', state: 'gas', metal: 0 },
        { id: 15, element: 'Phosphorus', symbol: 'P', mass: 30.974, class: 'reactive-nonmetal', state: 'solid', metal: 0 },
        { id: 33, element: 'Arsenic', symbol: 'As', mass: 74.922, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 51, element: 'Antimony', symbol: 'Sb', mass: 121.76, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 83, element: 'Bismuth', symbol: 'Bi', mass: 208.98, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 115, element: 'Moscovium', symbol: 'Mc', mass: 290, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 15
    [
        { id: 8, element: 'Oxygen', symbol: 'O', mass: 15.999, class: 'reactive-nonmetal', state: 'gas', metal: 0 },
        { id: 16, element: 'Sulfur', symbol: 'S', mass: 32.06, class: 'reactive-nonmetal', state: 'solid', metal: 0 },
        { id: 34, element: 'Selenium', symbol: 'Se', mass: 78.971, class: 'reactive-nonmetal', state: 'solid', metal: 0 },
        { id: 52, element: 'Tellurium', symbol: 'Te', mass: 127.60, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 84, element: 'Polonium', symbol: 'Po', mass: 209, class: 'post-transition-metal', state: 'solid', metal: 1 },
        { id: 116, element: 'Livermorium', symbol: 'Lv', mass: 293, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 16
    [
        { id: 9, element: 'Fluorine', symbol: 'F', mass: 18.998, class: 'reactive-nonmetal', state: 'gas', metal: 0 },
        { id: 17, element: 'Chlorine', symbol: 'Cl', mass: 34.45, class: 'reactive-nonmetal', state: 'gas', metal: 0 },
        { id: 35, element: 'Bromine', symbol: 'Br', mass: 79.904, class: 'reactive-nonmetal', state: 'liquid', metal: 0 },
        { id: 53, element: 'Iodine', symbol: 'I', mass: 126.90, class: 'reactive-nonmetal', state: 'solid', metal: 0 },
        { id: 85, element: 'Astatine', symbol: 'At', mass: 210, class: 'metalloid', state: 'solid', metal: 2 },
        { id: 117, element: 'Tennessine', symbol: 'Ts', mass: 294, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 17
    [
        { id: 2, element: 'Helium', symbol: 'He', mass: 4.0026, class: 'noble-gas', state: 'gas', metal: 0 },
        { id: 10, element: 'Neon', symbol: 'Ne', mass: 20.180, class: 'noble-gas', state: 'gas', metal: 0 },
        { id: 18, element: 'Argon', symbol: 'Ar', mass: 39.948, class: 'noble-gas', state: 'gas', metal: 0 },
        { id: 36, element: 'Krypton', symbol: 'Kr', mass: 83.798, class: 'noble-gas', state: 'gas', metal: 0 },
        { id: 54, element: 'Xenon', symbol: 'Xe', mass: 131.29, class: 'noble-gas', state: 'gas', metal: 0 },
        { id: 86, element: 'Radon', symbol: 'Rn', mass: 222, class: 'noble-gas', state: 'gas', metal: 0 },
        { id: 118, element: 'Oganesson', symbol: 'Og', mass: 294, class: 'unknown', state: 'unknown', metal: 3 },
    ], //column 18
    [
        { id: 57, element: 'Lanthanum', symbol: 'La', mass: 138.91, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 58, element: 'Cerium', symbol: 'Ce', mass: 140.12, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 59, element: 'Praseodynium', symbol: 'Pr', mass: 140.91, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 60, element: 'Neodymium', symbol: 'Nd', mass: 144.24, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 61, element: 'Promethium', symbol: 'Pm', mass: 145, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 62, element: 'Samarium', symbol: 'Sm', mass: 150.36, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 63, element: 'Europium', symbol: 'Eu', mass: 151.96, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 64, element: 'Gadolinium', symbol: 'Gd', mass: 157.25, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 65, element: 'Terbium', symbol: 'Tb', mass: 158.93, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 66, element: 'Dysprosium', symbol: 'Dy', mass: 162.50, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 67, element: 'Holmium', symbol: 'Ho', mass: 164.93, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 68, element: 'Erbium', symbol: 'Er', mass: 167.26, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 69, element: 'Thulium', symbol: 'Tm', mass: 168.93, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 70, element: 'Ytterbium', symbol: 'Yb', mass: 173.05, class: 'lanthanoid', state: 'solid', metal: 1 },
        { id: 71, element: 'Lutetium', symbol: 'Lu', mass: 174.97, class: 'lanthanoid', state: 'solid', metal: 1 },
    ], //row 1
    [
        { id: 89, element: 'Actinium', symbol: 'Ac', mass: 227, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 90, element: 'Thorium', symbol: 'Th', mass: 232.04, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 91, element: 'Protactinium', symbol: 'Pa', mass: 231.04, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 92, element: 'Uranium', symbol: 'U', mass: 238.03, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 93, element: 'Neptunium', symbol: 'Np', mass: 237, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 94, element: 'Plutonium', symbol: 'Pu', mass: 244, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 95, element: 'Americium', symbol: 'Am', mass: 243, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 96, element: 'Curium', symbol: 'Cm', mass: 247, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 97, element: 'Berkelium', symbol: 'Bk', mass: 247, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 98, element: 'Californium', symbol: 'Cf', mass: 251, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 99, element: 'Einsteinium', symbol: 'Es', mass: 252, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 100, element: 'Fermium', symbol: 'Fm', mass: 257, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 101, element: 'Mendelevium', symbol: 'Md', mass: 258, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 102, element: 'Nobelium', symbol: 'No', mass: 259, class: 'actinoid', state: 'solid', metal: 1 },
        { id: 103, element: 'Lawrencium', symbol: 'Lr', mass: 266, class: 'actinoid', state: 'solid', metal: 1 },
    ], //row 2
];