import React from 'react';

export const Sonar = () => {
    return (
        <div className="sonar">
            <div className="sonar-center"></div>
            <div className="sonar-circle sonar-circle-inner"></div>
            <div className="sonar-circle sonar-circle-middle"></div>
            <div className="sonar-circle sonar-circle-outer"></div>
            {/* <div className="sonar-spinner"></div> */}
        </div>
    )
}