import React, { useState } from 'react';

export const Rhythm = () => {
    const [normal, setNormal] = useState(true);

    const determineRhythm = async (isSpeed) => {
        document.querySelectorAll('.rhythm-box').forEach(box => {
            if (isSpeed) {
                box.classList.add('faster');
            } else {
                box.classList.remove('faster');
            }
        });
    }

    return (
        <>
            <div className="rhythm-example-container">
                <div className="rhythm-box first">1</div>
                <div className="rhythm-box second">2</div>
                <div className="rhythm-box third">3</div>
                <div className="rhythm-box fourth">4</div>
            </div>
            <div className="btn-container">
                <button className={`default-btn ${normal && 'btn-selected'}`} onClick={() => { determineRhythm(false); setNormal(true); }}>Normal</button>
                <button className={`default-btn ${!normal && 'btn-selected'}`} onClick={() => { determineRhythm(true); setNormal(false); }}>Faster</button>
            </div>
            <div className="learning-tips">
                <p><span className="btn-indicator">Click</span> the buttons above.</p>
            </div>
        </>
    )
}