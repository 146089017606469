import React from 'react';

export const Perfect5ths = () => (
    <>
        <div className="perfect-fifths-circle">
            <div className="circle-tick c-tick"></div>
            <div className="circle-tick g-tick"></div>
            <div className="circle-tick d-tick"></div>
            <div className="circle-tick a-tick"></div>
            <div className="circle-tick e-tick"></div>
            <div className="circle-tick b-tick"></div>
            <div className="circle-tick f-sharp-tick"></div>
            <div className="circle-tick d-flat-tick"></div>
            <div className="circle-tick a-flat-tick"></div>
            <div className="circle-tick e-flat-tick"></div>
            <div className="circle-tick b-flat-tick"></div>
            <div className="circle-tick f-tick"></div>
        </div>
        <div className='learning-tips'>
            <span className="example-indicator">Example</span><br />To determine the Perfect 5th for the A Chord, you would look for the A on the circle and moving clockwise the fifth chord above it is E.
        </div>
    </>
)