import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FlippingButton } from '../../../components';

export const SelectedPuzzle = ({ handleNext, selectedPuzzle, toastMessages }) => {
    const [text, setText] = useState('');

    const submit = () => {
        const formattedAnswers = selectedPuzzle.answers.map(answer => answer.replace(/,./g, '').toLowerCase());
        const formattedText = text.replace(/,./g, '').toLowerCase();

        if (formattedAnswers.includes(formattedText)) {
            toast(toastMessages.success, { className: 'success' });
            setText('');
            setTimeout(() => {
                handleNext();
            }, 2000);
        } else {
            toast(toastMessages.incorrect, { className: 'error' });
            setTimeout(() => {
                setText('');
            }, 1000)
        }
    }

    return (
        <div className="puzzle">
            <FlippingButton
                buttonText="Next"
                color="green"
                onClick={handleNext}
                width={102}
                shouldTransform
                className="next-btn"
            />
            <img
                src={selectedPuzzle?.img}
                alt={'puzzle-' + selectedPuzzle?.id}
                className='rebus-img'
            />
            <textarea
                className='rebus-textarea'
                placeholder='Enter your answer here'
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <FlippingButton
                buttonText="Submit"
                color="orange"
                onClick={submit}
                width={148}
                shouldTransform
            />
        </div>
    )
}