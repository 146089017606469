import React, { useMemo } from 'react';
import { randomize } from '../../../../utils';
import { QuestionAndAnswer } from './QuestionAndAnswer';
import { useResource } from '../../../../queries';

export const DadJokes = () => {
    const { data: content = [] } = useResource({
        category: 'recreation',
        id: 'dad-jokes'
    });

    const randomizedContent = useMemo(() => randomize(content), [content])

    return (
        <QuestionAndAnswer questions={randomizedContent} />
    )
}