export const tabData = [
    {
        label: 'Stored Games',
        value: 'rooms',
    },
    {
        label: 'Game Details',
        value: 'details',
    },
    {
        label: 'Score Sheet',
        value: 'score',
    },
];