import React from 'react';

export const ButtonContainer = ({
    handNumber,
    draw,
    hold,
    shouldHold
}) => {
    return (
        <div className="button-container">
            {handNumber === '1' && (
                <button
                    disabled={shouldHold}
                    onClick={draw}
                    className={`default-btn ${handNumber === '1' && !shouldHold ? 'selectable' : ''}`}
                >
                    Draw
                </button>
            )}
            <button
                disabled={shouldHold}
                onClick={hold}
                className={`default-btn ${handNumber === '1' && !shouldHold ? 'selectable' : ''}`}
            >
                Hold
            </button>
        </div>
    )
}
