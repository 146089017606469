import React, { useState } from 'react';
import { Timer } from '../global/Timer';

export const Game = ({
    questions,
    setGuesses,
    currentIndex,
    setCurrentIndex,
    time,
    setTime,
    onGameStart,
    onGameEnd,
    showGame,
    setShowGame,
    showTimer,
    timePerQuestion,
    setTimePerQuestion,
}) => {
    const currentQuestion = questions[currentIndex];
    const [guess, setGuess] = useState('');

    const handleGuess = (value) => {
        if (guess.length < 3) {
            if (value === 'C') {
                setGuess('');
            } else if (value === '=') {
                submitGuess();
            } else {
                setGuess(prev => prev + value);
            }
        }
    }

    const submitGuess = async () => {
        if (!guess.length) return;
        setGuesses(prev => {
            return [
                ...prev,
                guess
            ]
        })
        setGuess('');
        const newIndex = currentIndex + 1;
        setCurrentIndex(newIndex);
        setTimePerQuestion([...timePerQuestion, time]);
    }

    const keypadData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'C', 0, '='];

    return (
        <div className='game-container'>
            <Timer
                time={time}
                setTime={setTime}
                onTimeStart={() => {
                    setShowGame(true);
                    onGameStart();
                }}
                onTimeEnd={() => {
                    setShowGame(false);
                    onGameEnd();
                }}
                hideTimer={!showTimer}
            />
            {showGame && (
                <>
                    <div className="current-expression">
                        <span className="number-block">{currentQuestion[0]}</span>
                        <span className="operator-block">{currentQuestion[1]}</span>
                        <span className="number-block">{currentQuestion[2]}</span>
                    </div>
                    <div className="guess">{guess}</div>
                    <div className="keypad-container">
                        {keypadData.map((key) => (
                            <button key={key} className="key" onClick={() => handleGuess(key)}>{key}</button>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}
