import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { randomize } from '../../../../utils';
import { getComprehensiveWordBank } from '../words/get-comprehensive-word-bank';
import { Keyboard } from "../Keyboard";
import { Tank } from './Tank';
import { Word } from './Word';

const wordBank = getComprehensiveWordBank([5, 6, 7, 8, 9, 10, 11, 12]);

export const Sinker = () => {
    const [word, setWord] = useState([]);
    const [guesses, setGuesses] = useState([]);
    const [selected, setSelected] = useState(false);
    const [fails, setFails] = useState(0);

    useEffect(() => {
        getNewWord();
    }, []);

    const getNewWord = () => {
        const [newWord] = randomize(wordBank, 1);
        setWord(newWord.split(''));
    }

    const onClick = (letter) => {
        setSelected(true);
        const newGuesses = [...guesses];
        newGuesses.push(letter.toLowerCase());
        setGuesses(newGuesses);
        checkCompleted(newGuesses);
        if (!word.includes(letter)) {
            const newFails = fails + 1;
            setFails(newFails);
            fillWater(newFails);
        }
        setSelected(false);
    };

    const checkCompleted = (guessedWord) => {
        setTimeout(() => {
            if (word.every(letter => guessedWord.includes(letter))) {
                toast.success(`You guessed "${word.join('')}".`, { className: 'success' });
                reset();
            }
        }, 300);
    }

    const fillWater = (newFails) => {
        const newFailsElement = document.querySelector('.tank-section-' + newFails);
        newFailsElement.classList.add('fill');
        setTimeout(() => {
            newFailsElement.classList.add('water');
            if (newFails === 6) {
                toast.success('Sorry you didn\'t guess "' + word.join('') + '" in time.', { className: 'error' });
                reset();
            }
        }, 1000);
    }

    const reset = () => {
        setFails(0);
        setGuesses([]);
        getNewWord();
        document.querySelectorAll('.tank-section').forEach(section => {
            section.classList.remove('fill');
            section.classList.remove('water');
        });
    }

    const list = guesses.reduce((acc, curr) => {
        if (word.includes(curr)) acc.included.push(curr);
        else acc.excluded.push(curr);
        return acc;
    }, { included: [], excluded: [] });

    return (
        <section className="sinker-board">
            <div className="top-container">
                <Tank />
                <div className="guess-container">
                    <Word word={word} guesses={guesses} />
                    <Keyboard list={list} onClick={onClick} disableOnSelect={selected} keyUsedOnce />
                </div>
            </div>
            {/* 
                TODO: add the extra features by pulling it from localStorage 
                <div className="bottom-container">
                </div>
            */}
        </section>
    )
}