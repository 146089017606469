import React from 'react';
import { Footer } from "../components/Footer";
// import { toast } from "react-toastify";
// import { addLocalStorageValue, getLocalStorageValue } from '../../utils';
import { Loader, ProductCard } from '../components';
import { useMission, useCoverPhoto, useRecommendedProducts, useHomeResources } from '../../queries';
import { ResourceCard } from '../components/ResourceCard';
import { useLoader } from '../../hooks/use-loader';

export const Dashboard = ({ checkHeight, history }) => {
    const { data: mission } = useMission();
    const { data: products, isLoading: productsIsLoading, isFetching: productsIsFetching } = useRecommendedProducts();
    const { data: resources, isLoading: resourcesIsLoading, isFetching: resourcesIsFetching } = useHomeResources();
    const { data: coverPhoto, isFetched: coverPhotoIsFetched } = useCoverPhoto();

    const handleProductRouting = (type, id) => history.push(`/products/${type}/` + id);

    const handleResourceRouting = (url) => history.push(url);

    const isReady = !productsIsLoading && !productsIsFetching && !resourcesIsLoading && !resourcesIsFetching;
    const isLoading = useLoader(isReady);

    if (isLoading) return <Loader />

    return (
        <div id="dashboard-page" className="dashboard page" onScroll={() => checkHeight('.cover-photo')}>
            {coverPhotoIsFetched ? <img className="cover-photo" src={coverPhoto} alt="cover" /> : <div className="cover-photo"></div>}
            <div className="container">
                <div className="mission-container">
                    <p>{mission}</p>
                </div>
                <div className="dashboard-container">
                    <h2 className="heading">Featured Products</h2>
                    <div className="latest-releases">
                        {(isReady && products?.length) ?
                            products.map(product => (
                                <ProductCard key={product.id} {...{ handleRouting: handleProductRouting, product }} />
                            ))
                            :
                            <p>Products to be announced!</p>
                        }
                    </div>
                    <h2 className="heading">Featured Resources</h2>
                    <div className="popular-resources">
                        {(isReady && resources?.length) ?
                            resources.map(resource => (
                                <ResourceCard key={resource.id} {...{ ...resource, handleRouting: () => handleResourceRouting(resource.url) }} />
                            ))
                            :
                            <p>Resources to be announced!</p>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}