import React from 'react';

export const Checkerboard = ({ board, moves, piece, selected, onMove, inverted }) => {
    const getPattern = (rowIndex, columnIndex) => {
        if (rowIndex % 2 === 0) {
            return columnIndex % 2 === 0 ? 'white' : 'black';
        } else {
            return columnIndex % 2 === 0 ? 'black' : 'white';
        }
    }

    const includedMove = (rowIndex, columnIndex) => {
        const cb = (position) => position[0] === rowIndex && position[1] === columnIndex;

        return moves.find(position => {
            if (Array.isArray(position) && Array.isArray(position[0])) {
                return position.flat().find(cb);
            }
            return cb(position);
        });
    }

    return (
        <div className={`checkerboard ${inverted ? 'inverted' : ''}`}>
            {board.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                    {row.map((square, columnIndex) => {
                        const isSelectable = selected && !!includedMove(rowIndex, columnIndex);
                        return (
                            <div
                                key={columnIndex}
                                className={`
                                    square
                                    ${getPattern(rowIndex, columnIndex)} 
                                    ${!!includedMove(rowIndex, columnIndex) ? 'available-move' : ''}
                                    ${isSelectable ? 'clickable' : ''}
                                `}
                                onClick={() => {
                                    if (isSelectable) {
                                        onMove([rowIndex, columnIndex])
                                    }
                                }}
                            >
                                {square && piece(square, [rowIndex, columnIndex])}
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}