import React, { useState } from 'react';
import { ChordMap, ChordSelector } from '.';

export const GuitarChords = () => {
    const [note, setNote] = useState('a');

    return (
        <div className="learning-pages">
            <ChordSelector {...{ note, setNote }} />
            <ChordMap note={note} />
        </div>
    )
}