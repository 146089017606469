import React from 'react';

export const MusicButtons = ({ hiddenTypes }) => (
    <div className="music-button-container">
        <button className="default-btn" onClick={hiddenTypes.lines}>
            Lines
        </button>
        <button className="default-btn" onClick={hiddenTypes.reset}>
            Reset
        </button>
        <button className="default-btn" onClick={hiddenTypes.spaces}>
            Spaces
        </button>
    </div>
)