import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUsername } from '../../../../hooks';
import { Card, useCards } from '../cards';

export const War = () => {
    const [inWar, setInWar] = useState(false);
    // const [count, setCount] = useState(0);
    const [currentCards, setCurrentCards] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [gameOver, setGameOver] = useState(false);

    const { getUsername } = useUsername();
    const username = getUsername() ?? 'You';

    const { hands, deal, reset } = useCards({
        initialHands: {
            1: [],
            2: [],
        },
        gameOver,
        formatHands: (hands) => {
            const formattedHands = {};
            for (const key in hands) {
                const hand = hands[key];
                const formattedHand = hand.map((card) => {
                    if (card.name === 'A') card.value = 11;
                    return card;
                });
                formattedHands[key] = formattedHand;
            }
            return formattedHands;
        }
    });

    const [localHands, setLocalHands] = useState(hands);
    const playersHand = localHands?.[1] ?? [];
    const cpusHand = localHands?.[2] ?? [];

    useEffect(() => {
        setLocalHands(hands);
    }, [hands]);

    useEffect(() => {
        initializeData();
        // eslint-disable-next-line
    }, []);

    const initializeData = () => {
        reset();
        deal({ numberOfCards: 26, reset: true });
    }

    useEffect(() => {
        if (gameOver) {
            handleWinner();
        }
        // eslint-disable-next-line
    }, [gameOver]);

    const handleCardSelection = async (number) => {
        const prevCurrentPlayers = currentCards?.[1] || [];
        const newCurrentPlayers = prevCurrentPlayers.concat(playersHand.slice(0, number));

        const prevCurrentCPUs = currentCards?.[2] || [];
        const newCurrentCPUs = prevCurrentCPUs.concat(cpusHand.slice(0, number));

        const remainingPlayers = playersHand.slice(number);
        const remainingCPUs = cpusHand.slice(number);

        await setCurrentCards(prev => ({
            1: [...(prev?.[1] ?? []), ...playersHand.slice(0, number)],
            2: [...(prev?.[2] ?? []), ...cpusHand.slice(0, number)],
        })); // combined current cards (previous + new cards)
        await setLocalHands({ 1: remainingPlayers, 2: remainingCPUs }); // deck without the current cards

        return {
            currentCards: { 1: newCurrentPlayers, 2: newCurrentCPUs },
            remainingCards: { 1: remainingPlayers, 2: remainingCPUs }
        };
    }

    const showCards = async () => {
        setInWar(true);
        const payload = await handleCardSelection(1); // how many cards to show
        setTimeout(() => challenge(payload), 1000);
    }

    const getCurrentCard = (handNumber, cards = currentCards) =>
        cards?.[handNumber]?.[currentIndex] ?? cards?.[handNumber]?.[cards?.[handNumber]?.length - 1];

    const handleBattle = (winner, cards) => {
        const obtainedCards = cards[1].concat(cards[2]);
        setLocalHands(prev => ({
            ...prev,
            [winner]: [...prev[winner], ...obtainedCards] // add the newly obtained cards
        }));
        setCurrentCards(null);
        toast.dismiss();
        const message = winner === 1 ? `${username} +${obtainedCards.length}` : `CPU +${obtainedCards.length}`;
        toast.success(message, { className: "success", autoClose: 1000 });
        setInWar(false);
        setCurrentIndex(0);
    }

    const challenge = (cards) => {
        // last card in the current cards array respectively
        const currentPlayer = getCurrentCard(1, cards.currentCards);
        const currentCPU = getCurrentCard(2, cards.currentCards);

        if (currentPlayer.value < currentCPU.value) {
            handleBattle(2, cards.currentCards);
        } else if (currentCPU.value < currentPlayer.value) {
            handleBattle(1, cards.currentCards);
        } else if (currentPlayer.value === currentCPU.value) {
            return declareWar(cards.remainingCards, 0);
        }

        if (cards.remainingCards[1].length === 0 || cards.remainingCards[2].length === 0) {
            setGameOver(true);
        }
    }

    const declareWar = async (decks, currentIndex) => {
        if (decks[1].length >= 4 && decks[2].length >= 4) {
            toast.dismiss();
            toast.success('I', { className: 'success', autoClose: 1000 });
            const cards = await handleCardSelection(4 + currentIndex);

            currentIndex += 1;
            setCurrentIndex(prev => prev + 1);

            setTimeout(() => {
                const currentPlayer = cards.currentCards[1][currentIndex];
                const currentCPU = cards.currentCards[2][currentIndex];

                console.log(cards.currentCards, currentPlayer, currentCPU, currentIndex);

                if (currentPlayer.value < currentCPU.value) {
                    handleBattle(2, cards.currentCards);
                } else if (currentCPU.value < currentPlayer.value) {
                    handleBattle(1, cards.currentCards);
                } else if (currentPlayer.value === currentCPU.value) {
                    return declareWar({
                        1: decks[1].slice(4),
                        2: decks[2].slice(4),
                    }, currentIndex);
                }
            }, 3000);

            setTimeout(async () => {
                toast.dismiss();
                toast.success('WAR!', { className: 'success', autoClose: 1000 });
            }, 2250);

            setTimeout(async () => {
                toast.dismiss();
                toast.success('CLARE', { className: 'success', autoClose: 1000 });
                currentIndex += 1;
                setCurrentIndex(prev => prev + 1);
            }, 1500);

            setTimeout(async () => {
                toast.dismiss();
                toast.success('De-', { className: 'success', autoClose: 1000 });
                currentIndex += 1;
                setCurrentIndex(prev => prev + 1);
            }, 750);
        } else {
            setGameOver(true);
        }
    }

    const handleWinner = () => {
        if (gameOver) {
            if (playersHand.length > cpusHand.length) {
                toast.dismiss();
                toast.success(`${username} wins!`, { className: "success" });
            } else if (cpusHand.length > playersHand.length) {
                toast.dismiss();
                toast.success('CPU wins!', { className: "success" });
            }
        }
        setTimeout(() => {
            setGameOver(false);
        }, 2000);
    }

    return (
        <section className="war-board">
            <div className="player-1-container">
                <h4 className="username">{username}</h4>
                <span className="card-count">{playersHand.length}</span>
                <div className="card-container">
                    {currentCards?.[1] && inWar && (
                        <Card card={getCurrentCard(1)} />
                    )}
                </div>
            </div>
            <div className="war-btn-holder">
                <button className="default-btn" onClick={showCards} disabled={inWar}>War!</button>
                {/* <button className="default-btn">Shuffle</button> */}
            </div>
            <div className="player-2-container">
                <h4 className="username">CPU</h4>
                <span className="card-count">{cpusHand.length}</span>
                <div className="card-container">
                    {currentCards?.[2] && inWar && (
                        <Card card={getCurrentCard(2)} />
                    )}
                </div>
            </div>
        </section>
    )
}

// TODO: double check that declare war is working properly
// TODO: double check that the game over functionality is working properly
// TODO: add shuffle functionality and the limit to how often that can be changed
// TODO: double check that the toast is saying the right thing