import React from 'react';

export const Word = ({ word, guesses }) => (
    <div className="word">
        {word.map((letter, index) => (
            <span key={index} className="letter">
                {guesses.find(l => l === letter)}
            </span>
        ))}
    </div>
);