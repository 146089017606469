import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { Vitamins } from './Vitamins';
import { useResource } from '../../../queries';

export const Nutrition = () => {
    const { data: content = [] } = useResource({
        category: 'life-skills',
        id: 'nutrition'
    });

    const { TableOfContent } = useTableOfContent(content);

    return (
        <div className="learning-pages">
            <TableOfContent icons={content} />
            <ContentContainer
                content={content}
                additionalContent={{
                    'Vitamins': <Vitamins />
                }}
            />
        </div>
    )
}