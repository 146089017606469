import React from 'react';
import { Link } from "react-router-dom";
import { useAsset } from '../../../queries';

export const MobileHeader = ({
    routeObj,
    isTransparent
}) => {
    const { data: acornLogo } = useAsset({
        folder: 'logo',
        name: 'budding-acorn.png'
    })

    const { data: hamburgerLogo } = useAsset({
        folder: 'logo',
        name: 'hamburger.png'
    })

    const handleOpen = () => {
        document.querySelector('#root').style.height = '100vh';
        document.querySelector('#root').style.overflowY = 'hidden';
        document.querySelector('#root').style.position = 'relative';
        document.querySelector('.overlay').classList.add('show');
        document.getElementById('slide-out').classList.remove('closed');
        document.getElementById('slide-out').classList.add('opened');
        document.getElementById('slide-out').scrollTo({
            behavior: 'smooth',
            top: 0,
            left: 0,
        });
    }

    return (
        <header className={`navigation mobile ${isTransparent && 'transparent'}`}>
            <img src={hamburgerLogo} alt="mobile" className="hamburger-logo" onClick={handleOpen} />
            <Link to="/" className={`company-logo-link link ${routeObj.main ? "match-route" : ""}`}>
                <img src={acornLogo} alt="Budding Acorn" className="company-logo mobile" />
            </Link>
        </header>
    )
};