export const cards = [
    { id: 1, name: 'A', value: 1, suit: '♥︎', color: 'red', show: true },
    { id: 2, name: 'A', value: 1, suit: '♦︎', color: 'red', show: true },
    { id: 3, name: 'A', value: 1, suit: '♠︎', color: 'black', show: true },
    { id: 4, name: 'A', value: 1, suit: '♣︎', color: 'black', show: true },
    { id: 5, name: 2, value: 2, suit: '♥︎', color: 'red', show: true },
    { id: 6, name: 2, value: 2, suit: '♦︎', color: 'red', show: true },
    { id: 7, name: 2, value: 2, suit: '♠︎', color: 'black', show: true },
    { id: 8, name: 2, value: 2, suit: '♣︎', color: 'black', show: true },
    { id: 9, name: 3, value: 3, suit: '♥︎', color: 'red', show: true },
    { id: 10, name: 3, value: 3, suit: '♦︎', color: 'red', show: true },
    { id: 11, name: 3, value: 3, suit: '♠︎', color: 'black', show: true },
    { id: 12, name: 3, value: 3, suit: '♣︎', color: 'black', show: true },
    { id: 13, name: 4, value: 4, suit: '♥︎', color: 'red', show: true },
    { id: 14, name: 4, value: 4, suit: '♦︎', color: 'red', show: true },
    { id: 15, name: 4, value: 4, suit: '♠︎', color: 'black', show: true },
    { id: 16, name: 4, value: 4, suit: '♣︎', color: 'black', show: true },
    { id: 17, name: 5, value: 5, suit: '♥︎', color: 'red', show: true },
    { id: 18, name: 5, value: 5, suit: '♦︎', color: 'red', show: true },
    { id: 19, name: 5, value: 5, suit: '♠︎', color: 'black', show: true },
    { id: 20, name: 5, value: 5, suit: '♣︎', color: 'black', show: true },
    { id: 21, name: 6, value: 6, suit: '♥︎', color: 'red', show: true },
    { id: 22, name: 6, value: 6, suit: '♦︎', color: 'red', show: true },
    { id: 23, name: 6, value: 6, suit: '♠︎', color: 'black', show: true },
    { id: 24, name: 6, value: 6, suit: '♣︎', color: 'black', show: true },
    { id: 25, name: 7, value: 7, suit: '♥︎', color: 'red', show: true },
    { id: 26, name: 7, value: 7, suit: '♦︎', color: 'red', show: true },
    { id: 27, name: 7, value: 7, suit: '♠︎', color: 'black', show: true },
    { id: 28, name: 7, value: 7, suit: '♣︎', color: 'black', show: true },
    { id: 29, name: 8, value: 8, suit: '♥︎', color: 'red', show: true },
    { id: 30, name: 8, value: 8, suit: '♦︎', color: 'red', show: true },
    { id: 31, name: 8, value: 8, suit: '♠︎', color: 'black', show: true },
    { id: 32, name: 8, value: 8, suit: '♣︎', color: 'black', show: true },
    { id: 33, name: 9, value: 9, suit: '♥︎', color: 'red', show: true },
    { id: 34, name: 9, value: 9, suit: '♦︎', color: 'red', show: true },
    { id: 35, name: 9, value: 9, suit: '♠︎', color: 'black', show: true },
    { id: 36, name: 9, value: 9, suit: '♣︎', color: 'black', show: true },
    { id: 37, name: 10, value: 10, suit: '♥︎', color: 'red', show: true },
    { id: 38, name: 10, value: 10, suit: '♦︎', color: 'red', show: true },
    { id: 39, name: 10, value: 10, suit: '♠︎', color: 'black', show: true },
    { id: 40, name: 10, value: 10, suit: '♣︎', color: 'black', show: true },
    { id: 41, name: 'J', value: 10, suit: '♥︎', color: 'red', show: true, emoji: '👲', displayName: 'Jack' },
    { id: 42, name: 'J', value: 10, suit: '♦︎', color: 'red', show: true, emoji: '👲', displayName: 'Jack' },
    { id: 43, name: 'J', value: 10, suit: '♠︎', color: 'black', show: true, emoji: '👲', displayName: 'Jack' },
    { id: 44, name: 'J', value: 10, suit: '♣︎', color: 'black', show: true, emoji: '👲', displayName: 'Jack' },
    { id: 45, name: 'Q', value: 10, suit: '♥︎', color: 'red', show: true, emoji: '👸', displayName: 'Queen' },
    { id: 46, name: 'Q', value: 10, suit: '♦︎', color: 'red', show: true, emoji: '👸', displayName: 'Queen' },
    { id: 47, name: 'Q', value: 10, suit: '♠︎', color: 'black', show: true, emoji: '👸', displayName: 'Queen' },
    { id: 48, name: 'Q', value: 10, suit: '♣︎', color: 'black', show: true, emoji: '👸', displayName: 'Queen' },
    { id: 49, name: 'K', value: 10, suit: '♥︎', color: 'red', show: true, emoji: '🤴', displayName: 'King' },
    { id: 50, name: 'K', value: 10, suit: '♦︎', color: 'red', show: true, emoji: '🤴', displayName: 'King' },
    { id: 51, name: 'K', value: 10, suit: '♠︎', color: 'black', show: true, emoji: '🤴', displayName: 'King' },
    { id: 52, name: 'K', value: 10, suit: '♣︎', color: 'black', show: true, emoji: '🤴', displayName: 'King' }
];