import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { randomize } from '../../../../utils';
import { Card, cards as defaultCards } from '../cards';

const CARD_DISPLAY_OPTIONS = {
    SORTED: 'SORTED',
    SHUFFLED: 'SHUFFLED',
}

export const CardDisplay = () => {
    const [selected, setSelected] = useState(CARD_DISPLAY_OPTIONS.SORTED);
    const [sortedCards, setSortedCards] = useState(cloneDeep(defaultCards));

    const sort = () => {
        if (selected === CARD_DISPLAY_OPTIONS.SORTED) return;
        setSortedCards(cloneDeep(defaultCards));
        setSelected(CARD_DISPLAY_OPTIONS.SORTED);
    }

    const shuffle = () => {
        setSortedCards(cloneDeep(randomize(sortedCards)));
        setSelected(CARD_DISPLAY_OPTIONS.SHUFFLED);
    }

    return (
        <div className="card-display-board">
            <div className="button-container">
                <button className="default-btn" onClick={sort}>Sort</button>
                <button className="default-btn" onClick={shuffle}>Shuffle</button>
            </div>
            <div className="cards-container">
                {sortedCards.map((card, cardIndex) => (
                    <Card key={cardIndex} card={card} />
                ))}
            </div>
        </div>
    )
}