import React, { useState } from 'react';
import { useAsset } from '../../../../queries';

export const GameDetails = ({ room, setRoom }) => {
    const [playerName, setPlayerName] = useState('');
    const {
        name = '',
        rounds = 1,
        players = [],
        bonusPerRound = false,
        bonusPerRoundScores = [],
        finalBonus = false,
        finalBonusScores = [],
        isMost = true,
        scores = [],
    } = room;

    const { data: closeLogo } = useAsset({
        folder: 'logo',
        name: 'close.png',
    });

    const { data: plusLogo } = useAsset({
        folder: 'logo',
        name: 'plus.png',
    });

    const setRoomData = (prop, value) => {
        const roomDataCopy = {
            name,
            rounds,
            players,
            bonusPerRound,
            bonusPerRoundScores: rounds ? bonusPerRoundScores.slice(0, Number(rounds)) : bonusPerRoundScores,
            finalBonus,
            finalBonusScores,
            isMost,
            scores: rounds ? scores.slice(0, Number(rounds)) : scores,
            [prop]: value,
        };
        if (!bonusPerRound) {
            delete roomDataCopy.bonusPerRoundScores;
        }
        if (!finalBonus) {
            delete roomDataCopy.finalBonusScores;
        }
        setRoom(roomDataCopy);
    }

    const updatePlayer = (index, value) => {
        const playersCopy = [...players];
        playersCopy[index] = value;
        setRoomData('players', playersCopy);
    }

    const addPlayer = () => {
        if (playerName === '') return;
        const playersCopy = [...players, playerName];
        setRoomData('players', playersCopy);
        setPlayerName('');
    }

    const removePlayer = (index) => {
        const playersCopy = [...players];
        playersCopy.splice(index, 1);
        setRoomData('players', playersCopy);
    }

    const createRadioGroup = (id, prop, propsValue) => (
        <div className="radio-group">
            {[{ label: 'Yes', value: true }, { label: 'No', value: false }].map(({ label, value }, index) => (
                <label key={index}>
                    <input id={id + '-' + index} type="radio" name={id} checked={propsValue === value} value={value} onChange={() => setRoomData(prop, value)} />
                    <span>{label}</span>
                </label>
            ))}
        </div>
    )

    return (
        <div className="game-details-container tab-section-container">
            <p className="game-name">
                <strong>Selected Game:</strong>
                <input
                    id="game-name-input"
                    className="default-input game-name-input"
                    type="text"
                    value={name}
                    onChange={(e) => setRoomData('name', e.target.value)}
                    placeholder="Game Name"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            document.querySelector('.game-name-input').blur();
                        }
                    }}
                />
            </p>
            <p className="game-rounds">
                <strong># of Rounds:</strong>
                <input
                    id="game-rounds-input"
                    className="default-input"
                    type="number"
                    value={rounds}
                    onChange={(e) => setRoomData('rounds', e.target.value)}
                    placeholder="Number of Rounds"
                />
            </p>
            <div className="game-players">
                <strong>Players:</strong>
                {players.length ? (
                    <ol className='players'>
                        {players?.map((player, index) => (
                            <li key={index} className="player">
                                <span>{index + 1}.</span>
                                <input
                                    id={'player-' + index}
                                    className="default-input existing-player"
                                    type="text"
                                    value={player}
                                    onChange={(e) => updatePlayer(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            document.getElementById('player-' + index).blur();
                                            document.querySelector('.player-input').focus();
                                        }
                                    }}
                                    placeholder="Player Name"
                                />
                                <img src={closeLogo} alt="close" className="close-logo" onClick={() => removePlayer(index)} />
                            </li>
                        ))}
                    </ol>
                ) : (
                    <>
                        <p className='no-player'>No players have been added.</p>
                    </>
                )}
                <div className="player new-player">
                    <span>{players.length + 1}.</span>
                    <input
                        id="player-name-input"
                        className="default-input player-input"
                        type="text"
                        placeholder="New Player Name"
                        onChange={(e) => setPlayerName(e.target.value)}
                        value={playerName}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                addPlayer()
                            }
                        }}
                    />
                    <img
                        src={plusLogo}
                        alt="plus"
                        className={`plus-logo ${name ? '' : 'disabled'}`}
                        onClick={() => {
                            if (!name) return;
                            addPlayer();
                        }}
                    />
                </div>
            </div>
            <div className="game-bonus-per-round">
                <strong>Bonus Per Round:</strong>
                <div className="radio-group">
                    {createRadioGroup('bonus-per-round', 'bonusPerRound', bonusPerRound)}
                </div>
            </div>
            <div className="game-final-bonus">
                <strong>Bonus At End:</strong>
                <div className="radio-group">
                    {createRadioGroup('bonus-at-end', 'finalBonus', finalBonus)}
                </div>
            </div>
            <div className="game-is-most">
                <strong>Most Points Wins:</strong>
                <div className="radio-group">
                    {createRadioGroup('most-points-wins', 'isMost', isMost)}
                </div>
            </div>
        </div>
    )
}