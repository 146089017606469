import React from 'react';
import { ContentContainer, useTableOfContent } from '../../components';
import { ChromaticScale, Perfect5ths } from '.';
import { useResource } from '../../../queries';

export const MusicScales = () => {
    const { data: content = [] } = useResource({
        category: 'music',
        id: 'music-scales'
    });

    const { TableOfContent } = useTableOfContent(content);

    return (
        <div className="learning-pages">
            <TableOfContent icons={content} />
            <ContentContainer
                content={content}
                additionalContent={{
                    'Scale Intro': <ChromaticScale scale={['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C']} />,
                    'Major': <ChromaticScale scale={['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C']} />,
                    'Natural Minor': <ChromaticScale scale={['C', 'D', 'D♯', 'F', 'G', 'G♯', 'A♯', 'C']} />,
                    'Melodic Minor': <ChromaticScale scale={['C', 'D', 'D♯', 'F', 'G', 'A', 'B', 'C']} />,
                    'Harmonic Minor': <ChromaticScale scale={['C', 'D', 'D♯', 'F', 'G', 'G♯', 'B', 'C']} />,
                    'Diminished': <ChromaticScale scale={['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C']} />,
                    'Whole Tone': <ChromaticScale scale={['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C']} />,
                    'Minor Pentatonic': <ChromaticScale scale={['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C']} />,
                    'Major Pentatonic': <ChromaticScale scale={['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C']} />,
                    'Perfect 5th': <Perfect5ths />
                }}
            />
        </div>
    )
}