import React, { useEffect, useState } from 'react';

export const IconItem = ({ icon, index, setSelectedItem }) => (
    <div
        id={'table-of-content-item-' + index}
        className="icon-item available"
        onClick={() => setSelectedItem(index)}
    >
        <img className={`icon-image-${index}`} src={icon.img} alt={icon.title} />
        <h2 className="icon-name">{index + 1}. {icon.title}</h2>
    </div>
)

export const useTableOfContent = (icons) => {
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (selectedItem !== null) {
            const tableOfContentItem = document.querySelector('#content-item-' + selectedItem);
            if (selectedItem >= 0 && tableOfContentItem) {
                tableOfContentItem.scrollIntoView({ behavior: 'smooth' });
                setSelectedItem(null);
            }
        }
    }, [selectedItem])

    return {
        selectedItem,
        setSelectedItem,
        TableOfContent: () => (
            <div className="table-of-content">
                {icons.map((icon, index) =>
                    <IconItem
                        key={icon.title}
                        {...{
                            icon,
                            selectedItem,
                            setSelectedItem,
                            index,
                        }}
                    />)}
            </div>
        ),
    }
}
