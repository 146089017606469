import React from 'react';

export const ChordSelector = ({
    note,
    setNote,
}) => (
    <select
        name="note-selector"
        id="note-selector"
        onChange={e => setNote(e.target.value)}
        defaultValue={note}
    >
        <option value="a">A</option>
        <option value="a♯">A♯/B♭</option>
        <option value="b">B</option>
        <option value="c">C</option>
        <option value="c♯">C♯/D♭</option>
        <option value="d">D</option>
        <option value="d♯">D♯/E♭</option>
        <option value="e">E</option>
        <option value="f">F</option>
        <option value="f♯">F♯/G♭</option>
        <option value="g">G</option>
        <option value="g♯">G♯/A♭</option>
    </select>
)