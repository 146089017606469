import { randomNumber } from '../../../../utils';

export const generateQuestions = (numbers) => {
    const max = Math.max(...numbers);
    const min = Math.min(...numbers);
    const operators = ['+', '-', 'x', '/'];
    const newQuestions = [];
    const newAnswers = [];
    for (let i = 0; i < 100; i++) {
        const number1 = randomNumber(max, min);
        const number2 = randomNumber(max, min);
        const operatorIndex = randomNumber(operators.length, undefined, true);
        const operator = operators[operatorIndex];
        const expression = [number1, operator, number2];

        if (!newQuestions.find(question => question[0] === number1 && question[2] === number2 && question[1] === operator)) {
            switch (operator) {
                case '-':
                    if (number1 > number2) {
                        newQuestions.push([number1, '-', number2]);
                        newAnswers.push(number1 - number2);
                    } else {
                        newQuestions.push([number2, '-', number1]);
                        newAnswers.push(number2 - number1);
                    }
                    break;
                case '/':
                    if (number1 % number2 === 0 && number2 !== 0) {
                        newQuestions.push(expression);
                        newAnswers.push(number1 / number2);
                    } else if (number2 % number1 === 0 && number1 !== 0) {
                        newQuestions.push([number2, '/', number1]);
                        newAnswers.push(number2 / number1);
                    } else {
                        newQuestions.push([number2, '+', number1]);
                        newAnswers.push(number2 + number1);
                    }
                    break;
                case 'x':
                    newQuestions.push(expression);
                    newAnswers.push(number1 * number2);
                    break;
                default:
                    newQuestions.push(expression);
                    newAnswers.push(number1 + number2);
                    break;
            }
        }
    }
    return { questions: newQuestions, answers: newAnswers };
}