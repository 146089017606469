import React from 'react';

export const MusicStaff = ({ type, clef, notes, selected, setSelected, hidden, additionalComponent }) => {
    const checkSelected = (value) => {
        if (selected) {
            const isMiddleC = selected === 'middle-c' || selected === 'bass-middle-c';
            if (isMiddleC && (value === 'middle-c' || value === 'bass-middle-c')) {
                return true;
            } else if (selected === value) {
                return true;
            }
        }
    }

    const checkHidden = (noteValue) => hidden?.find(value => value === noteValue);

    return (
        <div className={`music-staff ${type}-staff`}>
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
            <div className="line-4"></div>
            <div className="line-5"></div>

            {type && <img src={clef} className={`${type}-clef`} alt={`${type}-clef`} />}

            {notes && notes.length ? notes.map(note => (
                <div
                    key={note.value}
                    onClick={() => setSelected ? setSelected(note.value) : null}
                    className={`${note.value} note ${checkSelected(note.value) ? 'selected' : ''} ${checkHidden(note.value) ? 'd-none' : ''}`}
                >
                </div>
            )) : null}

            {notes && notes.length ? notes.map(note => (
                <button
                    key={note.value}
                    onClick={() => setSelected ? setSelected(note.value) : null}
                    className={`pitch-note ${note.value} ${checkSelected(note.value) ? 'selected' : ''} ${checkHidden(note.value) ? 'd-none' : ''}`}
                >
                    {note.name}
                </button>
            )) : null}

            {additionalComponent ? additionalComponent : null}
        </div>
    )
}