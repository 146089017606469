import React from 'react';
import { FAQ, ImageCarousel, ProductDetails as Details, Specifications } from '.';
import { Breadcrumb } from "../components";

export const RetailProduct = ({ product }) => (
    <>
        <Breadcrumb type="product" />
        <div className="retail-product-details">
            <div className="product-top-section line-break">
                <ImageCarousel {...{
                    images: product.images ?? [],
                    name: product.name,
                }} />
                <Details {...{ product }} />
            </div>
            <div className="product-bottom-section">
                <Specifications product={product} />
                {/* <Reviews /> */}
                <FAQ product={product} />
            </div>
        </div>
    </>
);
