import React from 'react';

export const Tank = () => (
    <div className="tank">
        <div className="long-horizontal-pipe pipe-1 water-pipe"></div>
        <div className="vertical-pipe pipe-2 water-pipe"></div>
        <div className="short-horizontal-pipe pipe-3 water-pipe"></div>
        <div className="vertical-pipe pipe-4 water-pipe"></div>
        <div className="measurement measure-5">5</div>
        <div className="measurement measure-4">4</div>
        <div className="measurement measure-3">3</div>
        <div className="measurement measure-2">2</div>
        <div className="measurement measure-1">1</div>
        <div className="tank-section tank-section-6"></div>
        <div className="tank-section tank-section-5"></div>
        <div className="tank-section tank-section-4"></div>
        <div className="tank-section tank-section-3"></div>
        <div className="tank-section tank-section-2"></div>
        <div className="tank-section tank-section-1"></div>
    </div>
)