import React from 'react';
import { toTitleCase } from '../../../../utils';

export const ShowTimer = ({ showTimer, setShowTimer, disabled }) => (
    <div className="show-timer-container">
        <h4>Show Timer</h4>
        <div className="options">
            {[{
                label: 'SHOW',
                value: true,
            }, {
                label: 'HIDE',
                value: false,
            }].map(({ value, label }) => (
                <span
                    key={value}
                    className={`option two orange ${value === showTimer ? 'selected' : ''}`}
                    onClick={() => {
                        if (disabled) return;
                        setShowTimer(value)
                    }}
                >
                    {toTitleCase(label)}
                </span>
            ))}
        </div>
    </div>
);