import React from 'react';

export const ChromaticScale = ({ type, showOne }) => (
    <div className="chromatic-scale-container learning-tips">
        <span>C</span>
        <span className="red">{type === 'sharp' ? 'C♯' : 'D♭'}</span>
        <span>D</span>
        <span className={`${!showOne ? 'red' : ''}`}>{type === 'sharp' ? 'D♯' : 'E♭'}</span>
        <span>E</span>
        <span>F</span>
        <span className={`${!showOne ? 'red' : ''}`}>{type === 'sharp' ? 'F♯' : 'G♭'}</span>
        <span>G</span>
        <span className={`${!showOne ? 'red' : ''}`}>{type === 'sharp' ? 'G♯' : 'A♭'}</span>
        <span>A</span>
        <span className={`${!showOne ? 'red' : ''}`}>{type === 'sharp' ? 'A♯' : 'B♭'}</span>
        <span>B</span>
        <span>C</span>
    </div>
)