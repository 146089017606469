import { useEffect, useState } from "react";

export const useLoader = (isReady) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isReady) {
            setTimeout(() => {
                setIsLoading(false);
            }, 750);
        }
    }, [isReady]);

    return isLoading;
}