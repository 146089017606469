import React from 'react';

export const DynamicNotations = () => {
    return (
        <div className="dynamic-notation-container">
            <div className="dynamic-labels">
                <h4>Quietest</h4>
                <h4>Loudest</h4>
            </div>
            <div className="dynamic-ranking-line">
                <div className="dynamic-line line-pp"></div>
                <div className="dynamic-line line-p"></div>
                <div className="dynamic-line line-mp"></div>
                <div className="dynamic-line line-mf"></div>
                <div className="dynamic-line line-f"></div>
                <div className="dynamic-line line-ff"></div>
            </div>

            <div className="dynamic-names">
                <h4>ppp</h4>
                <h4>pp</h4>
                <h4>p</h4>
                <h4>mp</h4>
                <h4>mf</h4>
                <h4>f</h4>
                <h4>ff</h4>
                <h4>fff</h4>
            </div>

            <div className="dynamic-holder">
                <div className="dynamic-container">
                    <div className="crescendo"></div>
                    <div className="dynamic-name">Crescendo</div>
                </div>
                <div className="dynamic-container">
                    <div className="diminuendo"></div>
                    <div className="dynamic-name">Diminuendo</div>
                </div>
            </div>
        </div>
    );
}