import { stateList } from "../data/state-list";

const validateEmail = (email) =>
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)

const validatePhone = (phone) => phone && phone?.length === 16;

const validateName = (text) => /^[a-zA-Z'-\s]+$/.test(text);

const validateAlphaCharacterWithSpace = (text) => /^[a-zA-Z\s]+$/.test(text);

const validateFullName = (text) => /^[a-zA-Z\s]+$/.test(text) && text.trim().includes(' ');

const validateNum = (num, limit, atLeast) => /^[0-9]+$/.test(num) && ((!atLeast && num.length === limit) || (atLeast && num.length > limit));

// eslint-disable-next-line
const validateHasMinimum = (text) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9\#\.\s]*$/.test(text)

const validateAddress = (subtype, text) => {
    switch (subtype) {
        case 'street': return validateHasMinimum(text);
        case 'city': return validateAlphaCharacterWithSpace(text);
        case 'state': return !!stateList.find(s => s.abbreviation.toLowerCase() === text.toLowerCase() || s.name.toLowerCase() === text.toLowerCase());
        case 'zipCode': return validateNum(text, 5);
        default: return;
    }
}

const validateCardSection = (section, num, cardNumber) => {
    const isAmex = Number(cardNumber?.substring(0, 1)) === 3;
    switch (section) {
        case 'first':
            return /^[0-9]+$/.test(num) && num.length === 4;
        case 'second':
            return /^[0-9]+$/.test(num) && ((isAmex && num.length === 6) || (!isAmex && num.length === 4));
        case 'third':
            return /^[0-9]+$/.test(num) && ((isAmex && num.length === 5) || (!isAmex && num.length === 4));
        case 'fourth':
            return /^[0-9]+$/.test(num) && num.length === 4;
        default:
            return false;
    }
}

const currentDate = new Date();
const formatDate = (year, month) => new Date(`20${year}`, month);
const formatMonth = (month) => Number(month) - 1;

const checkDate = (num) => currentDate < formatDate(num.substring(2, 4), formatMonth(num.substring(0, 2)));

const validateExpiration = (num) => /^[0-9]+$/.test(num) && num.length === 4 && checkDate(num);

const validateCode = (num, cardNumber) => {
    if (cardNumber) {
        const isAmex = Number(cardNumber.substring(0, 1)) === 3;
        if (isAmex) return /^[0-9]+$/.test(num) && num.length === 4;
        return /^[0-9]+$/.test(num) && num.length === 3;
    }
}

export const validator = ({ type, text, subtype, cardNumber }) => {
    if (type === 'email') return validateEmail(text);
    if (type === 'phone') return validatePhone(text);
    if (type === 'name') return validateName(text);
    if (type === 'address') return validateAddress(subtype, text);

    if (type === 'fullName') return validateFullName(text);
    if (type === 'card') return validateCardSection(subtype, text, cardNumber);
    if (type === 'security') return validateCode(text, cardNumber);
    if (type === 'date') return validateExpiration(text);
}