import React, { useEffect, useState } from 'react';
import { randomNumber, randomize } from '../../../../utils';
import { socket, SOCKET_CLIENT_EVENTS } from '../WaitingRoom';

const colors = ['water', 'macaroni', 'cherry', 'lavender', 'lightgreen', 'morning'];
const stoneColors = randomize([...colors, ...colors, ...colors]);
const percentages = ['thirty', 'thirty-five', 'forty', 'forty-five', 'fifty', 'fifty-five', 'sixty', 'sixty-five', 'seventy', 'seventy-five'];

const capturedHoles = {
    0: 12,
    1: 11,
    2: 10,
    3: 9,
    4: 8,
    5: 7,
    7: 5,
    8: 4,
    9: 3,
    10: 2,
    11: 1,
    12: 0,
}

export const Board = ({ home, away, activeGame, setActiveGame, username, opponent }) => {
    const board = activeGame?.board;
    const turn = activeGame?.turn;
    const players = activeGame?.players;
    const [gameIsOver, setGameIsOver] = useState(false);

    const handleTurn = ({ hole, oppositePocket, ownPocket }) => {
        const newBoard = [...board];
        let hand = newBoard[hole];
        let newTurn;
        newBoard[hole] = 0;
        hole += 1;
        let captured = 0;
        while (hand > 0) {
            if (hole === ownPocket) {
                newBoard[hole] += 1;
                hand -= 1;
                captured += 1;
                if (hand > 0) {
                    if (hole === 13) {
                        hole = 0;
                    } else {
                        hole += 1;
                    }
                }
            } else if (hole === oppositePocket) {
                if (hole === 13) {
                    hole = 0;
                } else {
                    hole += 1;
                }
            } else if (hole === 12 && ownPocket === 6) {
                newBoard[hole] += 1;
                hand -= 1;
                if (hand > 0) {
                    hole = 0;
                }
            } else if (hole === 12 && ownPocket === 13) {
                newBoard[hole] += 1;
                hand -= 1;
                if (hand > 0) {
                    hole = 13;
                }
            } else {
                newBoard[hole] += 1;
                hand -= 1;
                if (hand > 0) {
                    hole += 1;
                }
            }

            if (hand === 0 && (hole !== 6 && hole !== 13) && newBoard[hole] === 1 && ((ownPocket === 13 && hole > 6) || (ownPocket === 6 && hole < 6))) {
                captured = captured + newBoard[hole] + newBoard[capturedHoles[hole]];
                newBoard[ownPocket] += captured;
                newBoard[capturedHoles[hole]] = 0;
                newBoard[hole] = 0;
            }
        }

        if (hole === 6 || hole === 13) {
            newTurn = turn;
        } else {
            newTurn = turn === home ? away : home;
        }

        return { captured, newBoard, newTurn }
    }

    const handleClick = (hole) => {
        if (board[hole] === 0) return;
        if (turn !== username) return;
        if (turn === home && hole > 5) return;
        if (turn === away && hole < 7) return;

        const { captured, newBoard, newTurn } = handleTurn({
            hole,
            ownPocket: home === username ? 6 : 13,
            oppositePocket: home === username ? 13 : 6
        });

        const messages = {
            [username]: `You captured ${captured}.`,
            [opponent]: `${username} captured ${captured}.`,
        }

        const data = {
            ...activeGame,
            board: newBoard,
            messages,
            turn: newTurn,
        }
        socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], data);
        setActiveGame(data);
    }

    const checkGameCompletion = () => {
        const homeSide = board.slice(0, 6);
        const awaySide = board.slice(7, 13);

        if (!gameIsOver) {
            setTimeout(() => {
                if (homeSide.every(hole => hole === 0)) {
                    const captured = awaySide.reduce((acc, hole) => acc + hole, 0);
                    const newBoard = board.map((hole, i) => {
                        if (i !== 6 && i !== 13) return 0;
                        if (i === 13) return hole + captured;
                        return hole;
                    });
                    const newTurn = players[randomNumber(players.length, 0, true)];

                    const message = `${newBoard[6] === newBoard[13] ? 'It\'s a tie!' : newBoard[6] > newBoard[13] ? home : away} won by ${Math.abs(newBoard[6] - newBoard[13])} points.`

                    const data = {
                        ...activeGame,
                        board: newBoard,
                        messages: {
                            [away]: message,
                            [home]: message,
                            finished: true,
                        },
                        turn: newTurn,
                    }
                    setActiveGame(data);
                    socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], data);
                    setGameIsOver(true);
                }
                if (awaySide.every(hole => hole === 0)) {
                    const captured = homeSide.reduce((acc, hole) => acc + hole, 0);
                    const newBoard = board.map((hole, i) => {
                        if (i !== 6 && i !== 13) return 0;
                        if (i === 6) return hole + captured;
                        return hole;
                    });
                    const newTurn = players[randomNumber(players.length, 0, true)];

                    const message = `${newBoard[6] === newBoard[13] ? 'It\'s a tie!' : newBoard[6] > newBoard[13] ? home : away} won by ${Math.abs(newBoard[6] - newBoard[13])} points.`

                    const data = {
                        ...activeGame,
                        board: newBoard,
                        messages: {
                            [away]: message,
                            [home]: message,
                            finished: true,
                        },
                        turn: newTurn,
                    }
                    setActiveGame(data);
                    socket.emit(SOCKET_CLIENT_EVENTS.SEND[0], data);
                    setGameIsOver(true);
                }
            }, 3500);
        }
    }

    useEffect(() => {
        if (activeGame?.board) {
            checkGameCompletion();
        }
        // eslint-disable-next-line
    }, [activeGame.board]);

    return (
        <div className="mancala-container">
            <div className="wooden-board">
                <div className="home-hole home">
                    <div className={`stone ${stoneColors[6]} ${percentages[randomNumber(percentages.length, 0, true)]}`} />
                    <p>{home} <span className="score">{board?.[6] ?? 0}</span></p>
                </div>
                <div className="holes-container">
                    <div className="hole-row">
                        {[5, 4, 3, 2, 1, 0].map((number, i) => (
                            <div key={i} className={`hole ${turn === username ? 'selectable' : ''} ${turn === home && board?.[number] > 0 ? 'fade-blue' : ''}`} id={`hole-${number}`} onClick={() => handleClick(Number(number))}>
                                <div className={`stone ${stoneColors[number]} ${percentages[randomNumber(percentages.length, 0, true)]}`} />
                                <span>{board?.[number] ?? 0}</span>
                            </div>
                        ))}
                    </div>
                    <div className="hole-row">
                        {[7, 8, 9, 10, 11, 12].map((number, i) => (
                            <div key={i} className={`hole ${turn === username ? 'selectable' : ''} ${turn === away && board?.[number] > 0 ? 'fade-green' : ''}`} id={`hole-${number}`} onClick={() => handleClick(Number(number))}>
                                <span>{board?.[number] ?? 0}</span>
                                <div className={`stone ${stoneColors[number]} ${percentages[randomNumber(percentages.length, 0, true)]}`} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="home-hole away">
                    <div className={`stone ${stoneColors.at(-1)} ${percentages[randomNumber(percentages.length, 0, true)]}`} />
                    <p><span className="score">{board?.[13] ?? 0}</span> {away}</p>
                </div>
            </div>
        </div>
    )
}

/*
    [] capture the most stones
    [] mancala are the two holes at the ends
    [] mancala on the right is your home
    [] moves counter clockwise
    [] depositing one stone in each hole
    [] when you run into your mancala, deposit one stone and get an extra turn
    [] when you run into your opponent's mancala, skip it
    [] when you run into an empty hole on your side, capture that stone and any stones in the hole opposite 
    [] if the last stone you drop is in your mancala, you get another turn
    [] game ends when one side is empty
*/ 