export const rebusPuzzles = [
    {
        id: 1,
        img: "../assets/online/rebus/puzzle-1.png",
        answers: [
            'No pain, no gain'
        ],
    },
    {
        id: 2,
        img: "../assets/online/rebus/puzzle-2.png",
        answers: [
            'In between a rock and a hard place',
            'In between a rock & a hard place',
            'In between rock and hard place',
            'In between rock & hard place',
            'In between the rock and the hard place',
        ],
    },
    {
        id: 3,
        img: "../assets/online/rebus/puzzle-3.png",
        answers: [
            'This is the last straw',
            'Last straw',
            'The last straw'
        ],
    },
    {
        id: 4,
        img: "../assets/online/rebus/puzzle-4.png",
        answers: [
            'Bite the bullet',
            'Biting the bullet',
            'Bit the bullet',
        ],
    },
    {
        id: 5,
        img: "../assets/online/rebus/puzzle-5.png",
        answers: [
            'Break the ice',
            'Breaking the ice',
            'Split the ice',
            'Splitting the ice',
        ],
    },
    {
        id: 6,
        img: "../assets/online/rebus/puzzle-6.png",
        answers: [
            'On the ball',
            'On ball',
            'On a ball',
        ],
    },
    {
        id: 7,
        img: "../assets/online/rebus/puzzle-7.png",
        answers: ['Split second decision'],
    },
    {
        id: 8,
        img: "../assets/online/rebus/puzzle-8.png",
        answers: [
            'Read between the lines',
            'Reading between the lines',
            'Reading between lines',
        ],
    },
    {
        id: 9,
        img: "../assets/online/rebus/puzzle-9.png",
        answers: ['Try to understand'],
    },
    {
        id: 10,
        img: "../assets/online/rebus/puzzle-10.png",
        answers: [
            'Feel under the weather',
            'Feeling under the weather',
            'To feel under the weather',
        ],
    },
    {
        id: 11,
        img: "../assets/online/rebus/puzzle-11.png",
        answers: [
            'Travel over seas',
            'Travel overseas',
        ],
    },
    {
        id: 12,
        img: "../assets/online/rebus/puzzle-12.png",
        answers: [
            'What goes up, must come down',
        ],
    },
    {
        id: 13,
        img: "../assets/online/rebus/puzzle-13.png",
        answers: [
            'Piece of cake',
            'Piece of the cake',
        ],
    },
    {
        id: 14,
        img: "../assets/online/rebus/puzzle-14.png",
        answers: [
            'Cat out of the bag',
            'Cat out of bag',
        ],
    },
    {
        id: 15,
        img: "../assets/online/rebus/puzzle-15.png",
        answers: [
            'Cost an arm and a leg',
            'Costing an arm and a leg',
            'Costs an arm and a leg',
            'Costs arm and leg'
        ],
    },
    {
        id: 16,
        img: "../assets/online/rebus/puzzle-16.png",
        answers: [
            'Cut corners',
            'Cutting corners'
        ],
    },
    {
        id: 17,
        img: "../assets/online/rebus/puzzle-17.png",
        answers: [
            'Think outside the box',
            'Thinking outside the box',
            'Think out of the box',
            'Thinking out of the box',
            'Think outside box',
            'Thinking outside box',
        ],
    },
    {
        id: 18,
        img: "../assets/online/rebus/puzzle-18.png",
        answers: [
            'Big think',
            'Think big'
        ],
    },
    {
        id: 19,
        img: "../assets/online/rebus/puzzle-19.png",
        answers: [
            'Fly on the wall',
            'Fly on wall',
        ],
    },
    {
        id: 20,
        img: "../assets/online/rebus/puzzle-20.png",
        answers: ['Square dance'],
    },
    {
        id: 21,
        img: "../assets/online/rebus/puzzle-21.png",
        // img: "../assets/online/rebus/rebus-puzzle-21.png",
        answers: ['All roads lead to Rome'],
    },
    {
        id: 22,
        img: "../assets/online/rebus/puzzle-22.png",
        answers: [
            'Heart of stone',
            'Stone heart',
            'Heart stone'
        ],
    },
    {
        id: 23,
        img: "../assets/online/rebus/puzzle-23.png",
        answers: ['Once in my life'],
    },
    {
        id: 24,
        img: "../assets/online/rebus/puzzle-24.png",
        answers: ['Once in a blue moon'],
    },
    {
        id: 25,
        img: "../assets/online/rebus/puzzle-25.png",
        answers: ['Forget it'],
    },
    {
        id: 26,
        img: "../assets/online/rebus/puzzle-26.png",
        answers: [
            'Downtown',
            'Down town'
        ],
    },
    {
        id: 27,
        img: "../assets/online/rebus/puzzle-27.png",
        answers: [
            'Step father',
            'Stepfather'
        ],
    },
    {
        id: 28,
        img: "../assets/online/rebus/puzzle-28.png",
        answers: [
            'Eye shadow',
            'eye-shadow',
            'eyeshadow'
        ],
    },
    {
        id: 29,
        img: "../assets/online/rebus/puzzle-29.png",
        answers: [
            '3-D movies',
            '3-D movie',
            '3D movies',
            '3D movie'
        ],
    },
    {
        id: 30,
        img: "../assets/online/rebus/puzzle-30.png",
        answers: ['Potato', 'Potatoes'],
    },
    {
        id: 31,
        img: "../assets/online/rebus/puzzle-31.png",
        answers: ['Top secret'],
    },
    {
        id: 32,
        img: "../assets/online/rebus/puzzle-32.png",
        answers: [
            'Once upon a time',
            'Once upon time'
        ],
    },
    {
        id: 33,
        img: "../assets/online/rebus/puzzle-33.png",
        answers: [
            'Trip around the world',
            'Trip around world'
        ],
    },
    {
        id: 34,
        img: "../assets/online/rebus/puzzle-34.png",
        answers: [
            'Big bad wolf',
            'Big-bad wolf',
            'Big bad-wolf'
        ],
    },
    {
        id: 35,
        img: "../assets/online/rebus/puzzle-35.png",
        answers: ['Comfortable'],
    },
    {
        id: 36,
        img: "../assets/online/rebus/puzzle-36.png",
        answers: ['Advice'],
    },
    {
        id: 37,
        img: "../assets/online/rebus/puzzle-37.png",
        answers: [
            'Forty years',
            '40 years'
        ],
    },
    {
        id: 38,
        img: "../assets/online/rebus/puzzle-38.png",
        answers: ['Download'],
    },
    {
        id: 39,
        img: "../assets/online/rebus/puzzle-39.png",
        answers: [
            'Many thanks',
            'Thanks a lot',
            'Thanks a bunch'
        ],
    },
    {
        id: 40,
        img: "../assets/online/rebus/puzzle-40.png",
        answers: ['Excuse me'],
    },
    {
        id: 41,
        img: "../assets/online/rebus/puzzle-41.png",
        answers: ['No Idea'],
    },
    {
        id: 42,
        img: "../assets/online/rebus/puzzle-42.png",
        answers: ['Forehead'],
    },
    {
        id: 43,
        img: "../assets/online/rebus/puzzle-43.png",
        answers: [
            'wake up',
            'wakeup'
        ],
    },
    {
        id: 44,
        img: "../assets/online/rebus/puzzle-44.png",
        answers: [
            'foreign language',
            'foreign-language'
        ],
    },
    {
        id: 45,
        img: "../assets/online/rebus/puzzle-45.png",
        answers: [
            'tuna fish',
            'tuna-fish',
            'tunafish'
        ],
    },
    {
        id: 46,
        img: "../assets/online/rebus/puzzle-46.png",
        answers: ['Middle aged'],
    },
    {
        id: 47,
        img: "../assets/online/rebus/puzzle-47.png",
        answers: [
            'Seesaw',
            'See-saw',
            'See saw'
        ],
    },
    {
        id: 48,
        img: "../assets/online/rebus/puzzle-48.png",
        answers: [
            'Missing you',
            'Miss you',
            'Missed you'
        ],
    },
    {
        id: 49,
        img: "../assets/online/rebus/puzzle-49.png",
        answers: [
            'four-wheel drive',
            '4-wheel drive',
            '4 wheel drive',
            'four wheel drive',
            '4wd',
            '4x4'
        ],
    },
    {
        id: 50,
        img: "../assets/online/rebus/puzzle-50.png",
        answers: [
            'Apple pie',
            'apple-pie',
        ],
    },
    {
        id: 51,
        img: "../assets/online/rebus/puzzle-51.png",
        answers: ['Up to you'],
    },
    {
        id: 52,
        img: "../assets/online/rebus/puzzle-52.png",
        answers: ['Robin Hood'],
    },
    {
        id: 53,
        img: "../assets/online/rebus/puzzle-53.png",
        answers: ['Design'],
    },
    {
        id: 54,
        img: "../assets/online/rebus/puzzle-54.png",
        answers: ['Engineer'],
    },
    {
        id: 55,
        img: "../assets/online/rebus/puzzle-55.png",
        answers: ['vegetables'],
    },
    {
        id: 56,
        img: "../assets/online/rebus/puzzle-56.png",
        answers: [
            'Noon tea',
            'Noontea'
        ],
    },
    {
        id: 57,
        img: "../assets/online/rebus/puzzle-57.png",
        answers: [
            'Camping overnight',
            'Camping over night'
        ],
    },
    {
        id: 58,
        img: "../assets/online/rebus/puzzle-58.png",
        answers: ['Time to go'],
    },
    {
        id: 59,
        img: "../assets/online/rebus/puzzle-59.png",
        answers: ['Long time no see'],
    },
    {
        id: 60,
        img: "../assets/online/rebus/puzzle-60.png",
        answers: ['Polite'],
    },
    {
        id: 61,
        img: "../assets/online/rebus/puzzle-61.png",
        answers: ['Fishing hook'],
    },
    {
        id: 62,
        img: "../assets/online/rebus/puzzle-62.png",
        answers: ['Touchdown'],
    },
    {
        id: 63,
        img: "../assets/online/rebus/puzzle-63.png",
        answers: ['Love at first sight'],
    },
    {
        id: 64,
        img: "../assets/online/rebus/puzzle-64.png",
        answers: [
            'Corner stone',
            'Cornerstone'
        ],
    },
    {
        id: 65,
        img: "../assets/online/rebus/puzzle-65.png",
        answers: ['Hiking in the woods'],
    },
    {
        id: 66,
        img: "../assets/online/rebus/puzzle-66.png",
        answers: [
            'Sandpaper',
            'Sand paper'
        ],
    },
    {
        id: 67,
        img: "../assets/online/rebus/puzzle-67.png",
        answers: [
            'Crossbow',
            'Cross bow'
        ],
    },
    {
        id: 68,
        img: "../assets/online/rebus/puzzle-68.png",
        answers: [
            'Eggs over easy',
            'Eggs over-easy',
            'Eggs overeasy',
            'Overeasy eggs',
            'Over-easy eggs',
            'Over easy eggs'
        ],
    },
    {
        id: 69,
        img: "../assets/online/rebus/puzzle-69.png",
        answers: [
            'Multiple choice',
            'Multiple-choice'
        ],
    },
    {
        id: 70,
        img: "../assets/online/rebus/puzzle-70.png",
        answers: [
            'Come into season',
            'Coming into season'
        ],
    },
    {
        id: 71,
        img: "../assets/online/rebus/puzzle-71.png",
        answers: [
            'I\'ll get over it',
            'I will get over it'
        ],
    },
    {
        id: 72,
        img: "../assets/online/rebus/puzzle-72.png",
        answers: [
            'Raincheck',
            'Rain check'
        ],
    },
    {
        id: 73,
        img: "../assets/online/rebus/puzzle-73.png",
        answers: ['I\'m bigger than you'],
    },
    {
        id: 74,
        img: "../assets/online/rebus/puzzle-74.png",
        answers: [
            'Double agent',
            'Double-agent'
        ],
    },
    {
        id: 75,
        img: "../assets/online/rebus/puzzle-75.png",
        answers: [
            'Good afternoon',
            'Good-afternoon'
        ],
    },
    {
        id: 76,
        img: "../assets/online/rebus/puzzle-76.png",
        answers: [
            'One foot in the door',
            'Foot in the door',
            'Foot in door',
            'One foot in door'
        ],
    },
    {
        id: 77,
        img: "../assets/online/rebus/puzzle-77.png",
        answers: ['lemonade'],
    },
    {
        id: 78,
        img: "../assets/online/rebus/puzzle-78.png",
        answers: ['Long legs', 'Long-legs'],
    },
    {
        id: 79,
        img: "../assets/online/rebus/puzzle-79.png",
        answers: ['tooth pick', 'toothpick', 'tooth-pick'],
    },
    {
        id: 80,
        img: "../assets/online/rebus/puzzle-80.png",
        answers: ['Light rain'],
    },
    {
        id: 81,
        img: "../assets/online/rebus/puzzle-81.png",
        answers: ['Way to go'],
    },
    {
        id: 82,
        img: "../assets/online/rebus/puzzle-82.png",
        answers: ['Heartbeat', 'Heart beat', 'beating heart', 'heart beating'],
    },
    {
        id: 83,
        img: "../assets/online/rebus/puzzle-83.png",
        answers: ['Pardon me'],
    },
    {
        id: 84,
        img: "../assets/online/rebus/puzzle-84.png",
        answers: ['thunderstorm', 'thunder-storm', 'thunder storm'],
    },
    {
        id: 85,
        img: "../assets/online/rebus/puzzle-85.png",
        answers: ['Boxing ring', 'Boxing-ring'],
    },
    {
        id: 86,
        img: "../assets/online/rebus/puzzle-86.png",
        answers: ['Angkor Wat'],
    },
    {
        id: 87,
        img: "../assets/online/rebus/puzzle-87.png",
        answers: ['Cuba'],
    },
    {
        id: 88,
        img: "../assets/online/rebus/puzzle-88.png",
        answers: ['xray', 'x-ray', 'x ray'],
    },
    {
        id: 89,
        img: "../assets/online/rebus/puzzle-89.png",
        answers: ['microscope', 'micro-scope', 'micro scope'],
    },
    {
        id: 90,
        img: "../assets/online/rebus/puzzle-80.png",
        answers: ['blanket'],
    },
    {
        id: 91,
        img: "../assets/online/rebus/puzzle-91.png",
        answers: ['tennis shoes', 'tennis-shoes', 'tennis shoes'],
    },
    {
        id: 92,
        img: "../assets/online/rebus/puzzle-92.png",
        answers: ['metaphor'],
    },
    {
        id: 93,
        img: "../assets/online/rebus/puzzle-93.png",
        answers: ['bad influence'],
    },
    {
        id: 94,
        img: "../assets/online/rebus/puzzle-94.png",
        answers: ['wise guy'],
    },
    {
        id: 95,
        img: "../assets/online/rebus/puzzle-95.png",
        answers: ['Stay Indoors'],
    },
    {
        id: 96,
        img: "../assets/online/rebus/puzzle-96.png",
        answers: ['Elbow'],
    },
    {
        id: 97,
        img: "../assets/online/rebus/puzzle-97.png",
        answers: ['Fowl words', 'Fowl language'],
    },
    {
        id: 98,
        img: "../assets/online/rebus/puzzle-98.png",
        answers: ['Summer'],
    },
    {
        id: 99,
        img: "../assets/online/rebus/puzzle-99.png",
        answers: ['Indian food'],
    },
    {
        id: 100,
        img: "../assets/online/rebus/puzzle-100.png",
        answers: [
            'The birds and the bees',
            'birds and bees',
            'birds & bees',
            'The birds & the bees'
        ],
    },
    {
        id: 101,
        img: "../assets/online/rebus/puzzle-101.png",
        answers: [
            'tripod',
            'tri-pod',
            'tri pod'
        ],
    },
    {
        id: 102,
        img: "../assets/online/rebus/puzzle-102.png",
        answers: [
            'Three peas in a pod',
            '3 peas in a pod',
            'Three peas in pod',
            '3 peas in pod'
        ],
    },
    {
        id: 103,
        img: "../assets/online/rebus/puzzle-103.png",
        answers: ['seasoning'],
    },
    {
        id: 104,
        img: "../assets/online/rebus/puzzle-104.png",
        answers: ['easel'],
    },
    {
        id: 105,
        img: "../assets/online/rebus/puzzle-105.png",
        answers: ['discount'],
    },
    {
        id: 106,
        img: "../assets/online/rebus/puzzle-106.png",
        answers: [
            'fingers crossed',
            'fingers-crossed',
            'fingers cross',
            'crossed fingers'
        ],
    },
    {
        id: 107,
        img: "../assets/online/rebus/puzzle-107.png",
        answers: ['sixth sense', 'sixth-sense', 'sixth sense'],
    },
    {
        id: 108,
        img: "../assets/online/rebus/puzzle-108.png",
        answers: ['On second thought', 'On 2nd thought', 'On the right thought'],
    },
    {
        id: 109,
        img: "../assets/online/rebus/puzzle-109.png",
        answers: ['chain of events'],
    },
    {
        id: 110,
        img: "../assets/online/rebus/puzzle-110.png",
        answers: ['Sleep on it'],
    },
    {
        id: 111,
        img: "../assets/online/rebus/puzzle-111.png",
        answers: [
            'All in a days work',
            'All in a day\'s work',
            'All in days work',
            'All in day\'s work'
        ],
    },
    {
        id: 112,
        img: "../assets/online/rebus/puzzle-112.png",
        answers: [
            'Two ships pass in the night',
            'Two ships passing in the night',
            'Two ships pass in night',
            'Two ships passing in night',
            '2 ships pass in the night',
            '2 ships passing in the night',
            '2 ships pass in night',
            '2 ships passing in night'
        ],
    },
    {
        id: 113,
        img: "../assets/online/rebus/puzzle-113.png",
        answers: ['Backpedal', 'Back pedal', 'Pedal backward', 'Pedal backwards'],
    },
    {
        id: 114,
        img: "../assets/online/rebus/puzzle-114.png",
        answers: ['Peace on Earth', 'Peace on the Earth', 'Peace on earth'],
    },
    {
        id: 115,
        img: "../assets/online/rebus/puzzle-115.png",
        answers: ['hand in hand', 'hand-in-hand'],
    },
    {
        id: 116,
        img: "../assets/online/rebus/puzzle-116.png",
        answers: ['Mayonnaise'],
    },
    {
        id: 117,
        img: "../assets/online/rebus/puzzle-117.png",
        answers: ['Canine'],
    },
    {
        id: 118,
        img: "../assets/online/rebus/puzzle-118.png",
        answers: ['Nosy'],
    },
    {
        id: 119,
        img: "../assets/online/rebus/puzzle-119.png",
        answers: ['Wide awake'],
    },
    {
        id: 120,
        img: "../assets/online/rebus/puzzle-120.png",
        answers: ['Trail mix'],
    },
    {
        id: 121,
        img: "../assets/online/rebus/puzzle-121.png",
        answers: ['Micronesia'],
    },
    {
        id: 122,
        img: "../assets/online/rebus/puzzle-122.png",
        answers: ['Once in a lifetime'],
    },
    {
        id: 123,
        img: "../assets/online/rebus/puzzle-123.png",
        answers: [
            'Life begins at 40',
            'Life begins at forty',
            'Life starts at 40',
            'Life starts at forty'
        ],
    },
    {
        id: 124,
        img: "../assets/online/rebus/puzzle-124.png",
        answers: ['growing economy'],
    },
    {
        id: 125,
        img: "../assets/online/rebus/puzzle-125.png",
        answers: ['falling love', 'falling in love'],
    },
    {
        id: 126,
        img: "../assets/online/rebus/puzzle-126.png",
        answers: ['Space invaders'],
    },
    {
        id: 127,
        img: "../assets/online/rebus/puzzle-127.png",
        answers: ['Split level', 'Split-level'],
    },
    {
        id: 128,
        img: "../assets/online/rebus/puzzle-128.png",
        answers: [
            'Forgive and forget',
            'Forgiving and forgetting',
            'Forgive & forget',
            'Forgiving & forgetting'
        ],
    },
    {
        id: 129,
        img: "../assets/online/rebus/puzzle-129.png",
        answers: [
            'Corporate downsizing',
            'Corporate down sizing',
            'Corporate downsize',
            'Corporate down size'
        ],
    },
    {
        id: 130,
        img: "../assets/online/rebus/puzzle-130.png",
        answers: ['Red in the face', 'red in face'],
    },
    {
        id: 131,
        img: "../assets/online/rebus/puzzle-131.png",
        answers: ['win with ease', 'winning with ease'],
    },
    {
        id: 132,
        img: "../assets/online/rebus/puzzle-132.png",
        answers: ['on cloud nine', 'on cloud 9'],
    },
    {
        id: 133,
        img: "../assets/online/rebus/puzzle-133.png",
        answers: ['in between jobs'],
    },
    {
        id: 134,
        img: "../assets/online/rebus/puzzle-134.png",
        answers: ['You\'re full of baloney', 'ur full of baloney'],
    },
    {
        id: 135,
        img: "../assets/online/rebus/puzzle-135.png",
        answers: ['Bigger fish to fry', 'Other fish to fry'],
    },
    {
        id: 136,
        img: "../assets/online/rebus/puzzle-136.png",
        answers: [
            'Turn a new leaf',
            'Turn over a new leaf',
            'Turn new leaf',
            'Turn over new leaf',
            'Turning a new leaf',
            'Turning over a new leaf',
            'Turning new leaf',
            'Turning over new leaf'
        ],
    },
    {
        id: 137,
        img: "../assets/online/rebus/puzzle-137.png",
        answers: ['speechless', 'speech less'],
    },
    {
        id: 138,
        img: "../assets/online/rebus/puzzle-138.png",
        answers: ['golden rule', 'gold rule'],
    },
    {
        id: 139,
        img: "../assets/online/rebus/puzzle-139.png",
        answers: ['laughing stock', 'laughing-stock'],
    },
    {
        id: 140,
        img: "../assets/online/rebus/puzzle-140.png",
        answers: [
            'Page turner',
            'Turning the page',
            'fold the page',
            'folding the page',
            'turn the corner',
            'turning the corner',
            'turn corner',
            'turned corner'
        ],
    },
    {
        id: 141,
        img: "../assets/online/rebus/puzzle-141.png",
        answers: ['Lettuce leaf', 'Let us leave'],
    },
    {
        id: 142,
        img: "../assets/online/rebus/puzzle-142.png",
        answers: [
            'Genie cannot be put back in the bottle',
            'Genie can\'t be put back in the bottle',
            'Genie cannot be put back in bottle',
            'Genie can\'t be put back in bottle',
            'Genie can not be put back in the bottle',
            'Genie can not be put back in bottle',
            'Genie cannot be put back in the bottle',
            'Genie cannot be put back in bottle',
            'Cannot be put the genie back in the bottle',
            'Can not be put the genie back in the bottle',
            'Can\'t be put in the genie back in the bottle'
        ],
    },
    {
        id: 143,
        img: "../assets/online/rebus/puzzle-143.png",
        answers: [
            'Chew before you swallow',
            'Chew before swallowing',
            'Chew before swallowed'
        ],
    },
    {
        id: 144,
        img: "../assets/online/rebus/puzzle-144.png",
        answers: [
            'Beat around the bush',
            'Beating around the bush',
            'Beats around the bush',
            'Beating around bush',
            'Beat around bush',
            'Beats around bush',
        ],
    },
    {
        id: 145,
        img: "../assets/online/rebus/puzzle-145.png",
        answers: [
            'twenty-four seven',
            '24/7',
            '24-7',
            'twenty four seven',
        ],
    },
    {
        id: 146,
        img: "../assets/online/rebus/puzzle-146.png",
        answers: [
            '7-11',
            'seven eleven',
            'seven-eleven',
            'seven 11',
            '7 eleven',
        ],
    },
    {
        id: 147,
        img: "../assets/online/rebus/puzzle-147.png",
        answers: [
            'gloves are off',
            'gloves off',
            'glove\'s off',
        ],
    },
    {
        id: 148,
        img: "../assets/online/rebus/puzzle-148.png",
        answers: [
            'No harm, no foul',
            'No harm no foul',
        ],
    },
    {
        id: 149,
        img: "../assets/online/rebus/puzzle-149.png",
        answers: [
            'Bird in the hand is worth two in the bush',
            'Bird in hand is worth two in bush',
            'Bird in the hand is worth 2 in the bush',
            'Bird in hand is worth 2 in bush',
            'Bird in hand is worth two in the bush',
        ],
    },
    {
        id: 150,
        img: "../assets/online/rebus/puzzle-150.png",
        answers: [
            'Walking down memory lane',
            'Walk down memory lane',
            'Walking down memory ln',
            'Walk down memory ln',
        ],
    },
];

export const tabData = [
    {
        label: 'Randomize',
        value: 'random',
    },
    {
        label: 'Selected Puzzle',
        value: 'number',
    },
];